import Textarea from '@/components/basicComponents/textarea';
import Label from '@/components/fat-basicComponents/label';
import { Controller, useWatch } from 'react-hook-form';
import styled from 'styled-components';
import { IRenderTemplateRow } from '../types';

interface TextareaComponentProps {
  control: any;
  row: IRenderTemplateRow;
  ignoreRequired: boolean;
}

export const TextareaComponent = ({ control, row, ignoreRequired }: TextareaComponentProps) => {
  const { name, label, validation, dependsOn } = row;
  const dependentValue = dependsOn ? useWatch({ control, name: dependsOn.name }) : null;

  if (dependsOn && dependentValue !== dependsOn.value) return <></>;

  return (
    <>
      <div>
        {label && (
          <Label size="md" required={!!validation?.required && !ignoreRequired}>
            {label}
          </Label>
        )}
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <>
              <Textarea value={value} setValue={onChange} />
              {error?.message && <ErrorText>{error?.message}</ErrorText>}
            </>
          )}
        />
      </div>
    </>
  );
};

const ErrorText = styled.p`
  color: ${({ theme }) => theme.context.error};
  font-weight: 400;
  font-size: 16px;
  line-height: 22.4px;
`;
