import DatePicker from '@/components/fat-basicComponents/datePicker';
import Input from '@/components/fat-basicComponents/input';
import Select from '@/components/fat-basicComponents/select';
import { COUNTRIES } from '@trace-8/trace8-schema';
import React from 'react';
import { Controller } from 'react-hook-form';
import styled from 'styled-components';
import { IRenderTemplateField, IRenderTemplateRow } from '../../types';
import { Checkbox } from './checkbox';
import { PhoneNumber } from './phoneNumber';

interface CustomComponentProps {
  control: any;
  row: IRenderTemplateRow;
  ignoreRequired: boolean;
}

export const CustomComponent = ({ control, row, ignoreRequired }: CustomComponentProps) => {
  const getCurrentField = (field: IRenderTemplateField) => {
    switch (field.component) {
      case 'checkbox':
        return <Checkbox field={field} control={control} ignoreRequired={ignoreRequired} />;
      case 'date':
        return (
          <Controller
            name={field.name}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <DatePicker
                label={field?.label || ''}
                value={value || ''}
                onChange={(date) => {
                  onChange(date ? new Date(date) : '');
                }}
                errorText={error?.message}
                isRequiredField={!!field?.validation?.required && !ignoreRequired}
                size="md"
              />
            )}
          />
        );
      case 'phoneNumber':
        return <PhoneNumber field={field} control={control} ignoreRequired={ignoreRequired} />;
      case 'select':
        return (
          <Controller
            name={field.name}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Select
                label={field?.label || ''}
                value={value || ''}
                options={[
                  { label: 'Select', value: '' },
                  ...(field?.validation?.type === 'country'
                    ? COUNTRIES.map((country) => ({ label: country, value: country }))
                    : field?.config?.items || [])
                ]}
                setValue={onChange}
                errorText={error?.message}
                isRequiredField={!!field?.validation?.required && !ignoreRequired}
                size="md"
              />
            )}
          />
        );
      case 'text':
        return (
          <Controller
            name={field.name}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                label={field?.label || ''}
                value={value || ''}
                onChange={(e) => {
                  onChange(e.target.value);
                }}
                errorText={error?.message}
                isRequiredField={!!field?.validation?.required && !ignoreRequired}
                size="md"
              />
            )}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <RowWrapper>
      {row.fields.map((field, index) => (
        <React.Fragment key={field.name + index}>{getCurrentField(field)}</React.Fragment>
      ))}
    </RowWrapper>
  );
};

const RowWrapper = styled.div`
  display: flex;
  gap: 30px;
`;
