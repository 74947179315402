import { formatCash } from '@/utils/formatCash';
import styled from 'styled-components';
import { DefaultTheme } from 'styled-components/dist/types';

const getBackgroundColor = ({
  theme,
  isActive,
  isNextItemActive,
  isPrevItemActive,
  isLastItem
}: {
  theme: DefaultTheme;
  isActive: boolean;
  isNextItemActive: boolean;
  isPrevItemActive: boolean;
  isLastItem: boolean;
}) => {
  if (isNextItemActive) {
    return isPrevItemActive ? theme.action.active : theme.layer[12];
  }

  if (isActive) {
    if (isLastItem && isPrevItemActive) {
      return theme.action.active;
    }
    return isPrevItemActive ? theme.action.active : theme.layer[12];
  }

  if (isPrevItemActive) {
    return theme.action.active;
  }

  return theme.layer[12];
};

export interface IVisualFilteringBarData {
  title: string;
  count: number;
  amount: number;
  enabled: boolean;
}

interface VisualFilteringBarProps {
  filterBar: IVisualFilteringBarData[];
  onChangeFilterBar: (filterBarItems: IVisualFilteringBarData[]) => void;
}

const VisualFilteringBar = ({ filterBar, onChangeFilterBar }: VisualFilteringBarProps) => {
  const handleSelect = (id: number) => {
    const newData = [...filterBar];
    newData[id].enabled = !newData[id].enabled;
    onChangeFilterBar(newData);
  };

  return (
    <VisualFilteringBarWrapper>
      {filterBar.map((item, index) => (
        <FilterBarItemWrapper
          key={index}
          isActive={item.enabled}
          isNextItemActive={filterBar[index + 1]?.enabled}
          isPrevItemActive={filterBar[index - 1]?.enabled}
          isFirstItem={index === 0}
          isLastItem={index === filterBar.length - 1}
          onClick={() => handleSelect(index)}
        >
          <FilterBarItem>
            <FilterBarItemTitle>{item.title}</FilterBarItemTitle>
            <FilterBarItemDetails>
              <FilterBarItemCount>{item.count}</FilterBarItemCount>
              <FilterBarItemAmount>{formatCash(item.amount)}</FilterBarItemAmount>
            </FilterBarItemDetails>
          </FilterBarItem>
        </FilterBarItemWrapper>
      ))}
    </VisualFilteringBarWrapper>
  );
};

export default VisualFilteringBar;

const VisualFilteringBarWrapper = styled.div`
  display: flex;
  padding: 40px 0;
  width: 100%;
  cursor: pointer;
`;

const FilterBarItemWrapper = styled.div<{
  isActive: boolean;
  isNextItemActive: boolean;
  isPrevItemActive: boolean;
  isFirstItem: boolean;
  isLastItem: boolean;
}>`
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: ${({ isFirstItem, isLastItem }) => (isFirstItem ? '8px 0 0 8px' : isLastItem ? '0 8px 8px 0' : '0')};
  width: 100%;
  background: ${({ theme, isActive }) => (isActive ? theme.action.active : theme.layer[12])};
  transition: 0.3s ease-in-out;
  color: ${({ theme, isActive }) => (isActive ? theme.layer.base : theme.font.weak)};

  &::before {
    content: '';
    width: 51px;
    height: 51px;
    border-top: 1px solid ${({ theme }) => theme.border.base};
    border-right: 1px solid ${({ theme }) => theme.border.base};
    background-color: ${({ theme, isActive, isNextItemActive, isPrevItemActive, isLastItem }) =>
      getBackgroundColor({ theme, isActive, isNextItemActive, isPrevItemActive, isLastItem })};
    transform: rotate(45deg);
    display: ${({ isFirstItem }) => (isFirstItem ? 'none' : 'block')};
    position: relative;
    left: -26px;
    transition: 0.3s ease-in-out;
  }
`;

const FilterBarItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 20px 5px 20px 20px;
`;

const FilterBarItemTitle = styled.div`
  font-size: 10px;
  font-weight: 600;
  line-height: 10px;
`;

const FilterBarItemDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const FilterBarItemCount = styled.div`
  font-size: 36px;
  font-weight: 700;
  line-height: 20px;
`;

const FilterBarItemAmount = styled.div`
  font-size: 13px;
  font-weight: 400;
  line-height: 14px;
`;
