import { getUTCDate } from '@/components/allocations-page/utils';
import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import {
  MobileRow,
  MobileRowTitle,
  MobileRowValue,
  MobileTableRowWrap,
  MobileTableWrapper,
  NoResults
} from '@/components/table/mobileTable/mobileTable';
import styled from 'styled-components';
import { CommitmentForecastValueDto } from '../../../../types';
import MobileSubTotalRow from './mobileSubTotalRow';
import MobileSumFooter from './mobileSumFooter';

interface MobileTableProps {
  contributionsTableData: CommitmentForecastValueDto[];
  distributionsTableData: CommitmentForecastValueDto[];
  openOpportunityDetailsPage: (id: string) => void;
}

const MobileTable = ({ contributionsTableData, distributionsTableData, openOpportunityDetailsPage }: MobileTableProps) => {
  const TableRow = ({ el }: { el: CommitmentForecastValueDto }) => {
    return (
      <CustomMobileTableRowWrap key={el.id}>
        <MobileRow>
          <MobileRowTitle>Commitment</MobileRowTitle>
          <MobileRowValue onClick={() => openOpportunityDetailsPage(el.investmentId)} isRedirect>
            {el.name}
          </MobileRowValue>
        </MobileRow>

        {el.data.map((item) => (
          <MobileRow key={item.date}>
            <MobileRowTitle>{getUTCDate(new Date(item.date)).toUpperCase()}</MobileRowTitle>
            <MobileRowValue>
              <FormattingTooltip zIndex={1000}>{item.value}</FormattingTooltip>
            </MobileRowValue>
          </MobileRow>
        ))}
      </CustomMobileTableRowWrap>
    );
  };

  return (
    <MobileTableWrapper>
      {contributionsTableData?.length || !distributionsTableData.length ? (
        <>
          <ContributionsRowWrapper>Contributions</ContributionsRowWrapper>
          {contributionsTableData.map((el) => {
            return <TableRow el={el} key={el.id} />;
          })}
          <MobileSubTotalRow tableData={contributionsTableData} />
          <ContributionsRowWrapper>Distribution</ContributionsRowWrapper>
          {distributionsTableData.map((el) => {
            return <TableRow el={el} key={el.id} />;
          })}
          <MobileSubTotalRow tableData={distributionsTableData} />
          <MobileSumFooter contributionsTableData={contributionsTableData} distributionsTableData={distributionsTableData} />
        </>
      ) : (
        <NoResults>No Results</NoResults>
      )}
    </MobileTableWrapper>
  );
};

export default MobileTable;

const CustomMobileTableRowWrap = styled(MobileTableRowWrap)`
  background-color: ${({ theme }) => theme.layer.base};
`;

const ContributionsRowWrapper = styled.div`
  border-radius: 4px;
  background: ${({ theme }) => theme.layer[3]};
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 600;
  line-height: 22.4px;
  color: ${({ theme }) => theme.layer.base};
`;
