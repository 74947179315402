import Input from '@/components/fat-basicComponents/input';
import Select from '@/components/fat-basicComponents/select';
import { useResponsive } from '@/hooks/use-responsive';
import { Controller } from 'react-hook-form';
import styled from 'styled-components';
import { IRenderTemplateField } from '../../types';

interface CustomComponentProps {
  control: any;
  field: IRenderTemplateField;
  ignoreRequired: boolean;
}

export const PhoneNumber = ({ control, field, ignoreRequired }: CustomComponentProps) => {
  const { isMobile } = useResponsive();

  return (
    <PhoneFields isMobile={isMobile}>
      <Controller
        name={`${field.name}.${field?.validation?.mapping?.['countryCode'] ?? 'countryCode'}`}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Select
            label={field?.config?.labels['countryCode'] ?? 'Country Code'}
            value={value || ''}
            setValue={onChange}
            options={[{ label: '+1 (US/Canada)', value: '+1 (US/Canada)' }]}
            errorText={error?.message}
            isRequiredField={!!field?.validation?.required && !ignoreRequired}
            size="md"
            width="150px"
          />
        )}
      />
      <Controller
        name={`${field.name}.${field?.validation?.mapping?.['number'] ?? 'number'}`}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Input
            label={field?.config?.labels['number'] ?? 'Phone'}
            value={value || ''}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            errorText={error?.message}
            isRequiredField={!!field?.validation?.required && !ignoreRequired}
            size="md"
          />
        )}
      />
    </PhoneFields>
  );
};

const PhoneFields = styled.div<{ isMobile: boolean }>`
  display: flex;
  gap: 10px;
  width: ${({ isMobile }) => (isMobile ? '100%' : '50%')};
`;
