import { RejectedBannerIcon } from '@/assets/icons/info-icons/rejectedBanner';
import { Banner } from '@/components/basicComponents/banner';
import ModalLoader from '@/components/fat-basicComponents/modal/modalLoader';
import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import Select, { ISelectValue } from '@/components/fat-basicComponents/select';
import { useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { ICommitmentPlan, PaginatedCommitmentPlans } from '../../fat-pacingTab/types';
import { COMMITMENT_PLANS_MIN_QUERY, deleteCommitmentPlanMutation, updateCommitmentPlanMutation } from '../../queries';

interface DeletePlanProps {
  onClose: () => void;
  isOpen: boolean;
  currentRow: ICommitmentPlan | null;
  refetch: () => void;
}

const DEFAULT_ERROR_MESSAGE = `We're sorry. This process did not complete. Please try again.`;

const DeletePlan = ({ onClose, isOpen, currentRow, refetch }: DeletePlanProps) => {
  const params = useParams();
  const theme = useTheme();

  const [selectedPlanId, setSelectedPlanId] = useState<string>('');
  const [commitmentPlanSelectOptions, setCommitmentPlanSelectOptions] = useState<ISelectValue[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [deleteCommitmentPlan, { loading: deleteCommitmentPlanLoading }] = useMutation(deleteCommitmentPlanMutation, {
    onError: (error) => {
      console.error(error);
      setErrorMessage(DEFAULT_ERROR_MESSAGE);
    }
  });

  const [updateCommitmentPlan, { loading: updateCommitmentPlanLoading }] = useMutation(updateCommitmentPlanMutation, {
    onError: (error) => {
      console.error(error);
      setErrorMessage(DEFAULT_ERROR_MESSAGE);
    }
  });

  const { data: commitmentPlansData, loading: commitmentPlansLoading } = useQuery<{ commitmentPlans: PaginatedCommitmentPlans }>(
    COMMITMENT_PLANS_MIN_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      skip: !params?.entityId,
      variables: {
        input: {
          legalEntityId: params?.entityId,
          limit: 1000
        }
      },
      onCompleted: (data) => {
        setCommitmentPlanSelectOptions(
          data.commitmentPlans.data.map((plan) => ({ label: plan.name, value: plan.id })).filter((item) => item.value !== currentRow.id)
        );
      }
    }
  );

  const deletePlan = () => {
    setErrorMessage(null);

    if (commitmentPlansData?.commitmentPlans.total > 1 && currentRow.active) {
      updateCommitmentPlan({
        variables: {
          input: {
            id: selectedPlanId,
            active: true
          }
        },
        onCompleted: () => {
          deleteCommitmentPlan({
            variables: {
              id: currentRow.id
            },
            onCompleted: () => {
              refetch();
              onClose();
            }
          });
        }
      });
      return;
    }
    deleteCommitmentPlan({
      variables: {
        id: currentRow.id
      },
      onCompleted: () => {
        refetch();
        onClose();
      }
    });
  };

  if (!currentRow) return <></>;

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      title="Delete Commitment Plan"
      confirmButton={{
        name: 'Confirm',
        disabled:
          (commitmentPlansData?.commitmentPlans.total > 1 && currentRow.active && !selectedPlanId) ||
          commitmentPlansLoading ||
          updateCommitmentPlanLoading ||
          deleteCommitmentPlanLoading,
        onClick: deletePlan
      }}
      showCloseIcon={!updateCommitmentPlanLoading && !deleteCommitmentPlanLoading}
      showCancelButton={!updateCommitmentPlanLoading && !deleteCommitmentPlanLoading}
      isUseOutsideClick={!updateCommitmentPlanLoading && !deleteCommitmentPlanLoading}
    >
      {updateCommitmentPlanLoading || deleteCommitmentPlanLoading ? (
        <ModalLoader />
      ) : (
        <ModalBody>
          {errorMessage && (
            <Banner icon={<RejectedBannerIcon fill={theme.context.light} />} title="Error" description={errorMessage} bgColor={theme.context.error} />
          )}
          <SubTitle>
            Please confirm that you want to permanently delete this commitment Plan (<span style={{ fontWeight: 700 }}>{currentRow.name}</span>). This
            action cannot be undone.
          </SubTitle>
          {commitmentPlansData?.commitmentPlans.total > 1 && currentRow.active && (
            <Select
              value={selectedPlanId}
              options={[{ label: 'Select another plan', value: '' }, ...commitmentPlanSelectOptions]}
              setValue={setSelectedPlanId}
              defaultSelectLabel="Select another plan"
              label="Select one of the remaining plans to make it the active commitment plan"
              isRequiredField
            />
          )}
        </ModalBody>
      )}
    </ModalWrappedContent>
  );
};

export default DeletePlan;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 35px;
`;

const SubTitle = styled.p`
  text-align: center;
  font-size: 19px;
  font-weight: 400;
  line-height: 26.6px;
  color: ${({ theme }) => theme.font.base};
`;
