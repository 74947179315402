import { PendingCommitments } from '@/components/fat-pendingCommitments-page';
import { Helmet as Head } from 'react-helmet';
import useRefresh from '../../hooks/use-refresh';

const PendingCommitmentsPage = () => {
  useRefresh();
  return (
    <>
      <Head>
        <title>Pending Commitments</title>
      </Head>
      <PendingCommitments />
    </>
  );
};

export default PendingCommitmentsPage;
