import { DropFileArea } from '@/components/dueDiligence-page/dueDiligenceRecord/dropFileArea';
import FileUpload from '@/components/fat-basicComponents/fileUpload';
import { FormFooter } from '@/components/fat-basicComponents/formFooter';
import Input from '@/components/fat-basicComponents/input';
import Select from '@/components/fat-basicComponents/select';
import Header from '@/components/fat-header';
import { GoBackButton } from '@/components/fat-header/goBackButton';
import { PageTitle } from '@/components/fat-header/pageTitle';
import { useResponsive } from '@/hooks/use-responsive';
import { MainWrap, PaddingWrap } from '@/styles/common';
import { useState } from 'react';
import styled from 'styled-components';

const fileExtensions = ['PDF', 'DOC', 'DOCX', 'XLS', 'XLSX', 'CSV', 'JPEG', 'PNG', 'GIF', 'JPG'];
const types = [
  { label: 'W-9', value: 'W-9' },
  { label: 'Organization Docs', value: 'Organization Docs' },
  { label: 'IRS Determination Letter', value: 'IRS Determination Letter' },
  { label: 'Other', value: 'Other' }
];

interface AddAttachmentFileModalPageProps {
  closeModalWindow: () => void;
}

export const AddAttachmentFileModalPage = ({ closeModalWindow }: AddAttachmentFileModalPageProps) => {
  const { isMobile } = useResponsive();

  const [drag, setDrag] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [modalWindow, setModalWindow] = useState({ isOpen: false, type: '' });

  const allowFileExtensions = (files: FileList | null) => {
    return Array.from(files || []).filter((file: File) => {
      const fileExt = file.name.split('.').pop()?.toLowerCase();
      if ([...fileExtensions.map((item) => item.toLowerCase())].includes(fileExt || '')) {
        return file;
      }
    });
  };

  const onDropHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const uploadFiles = e.dataTransfer.files;

    setDrag(false);
    if (allowFileExtensions(uploadFiles).length) {
      setFiles([...(files || []), ...allowFileExtensions(uploadFiles)]);
      return;
    }
    setModalWindow({ isOpen: true, type: 'not-supported' });
  };

  const handleUploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const uploadFiles = e.target.files;

    if (allowFileExtensions(uploadFiles).length) {
      setFiles([...(files || []), ...(allowFileExtensions(uploadFiles) || [])]);
      return;
    }
    setModalWindow({ isOpen: true, type: 'not-supported' });
  };

  const openAttachment = async (url: string) => {
    window.open(url, '_blank');
  };

  const deletePreviewItem = (file: File, fileIndex: number) => {
    setFiles(files.filter((item: File, index) => index !== fileIndex));
  };

  return (
    <MainWrap>
      <Header modalControl={<GoBackButton handleClose={closeModalWindow} />} />
      <PageTitle title="Add An Attachment File" />
      <PaddingWrap>
        <AddDocumentForm>
          <AddDocumentWrapper isMobile={isMobile}>
            <TwoColumnWrapper isMobile={isMobile}>
              <Select label="Type" options={[{ label: 'Select', value: '' }, ...types]} size="md" isRequiredField />
              <Input label="Description (250 Characters Max)" value={''} onChange={(e) => {}} size="md" />
            </TwoColumnWrapper>
            {!!files.length && (
              <FilesWrapper>
                {files.map((file, index) => (
                  <FileUpload
                    key={file.lastModified}
                    type={file.type.includes('image') ? 'image' : 'document'}
                    imageSrc={URL.createObjectURL(file)}
                    fileName={file.name}
                    openFile={() => openAttachment(URL.createObjectURL(file))}
                    deleteFile={() => deletePreviewItem(file, index)}
                  />
                ))}
              </FilesWrapper>
            )}
            <DropFileArea
              id="add-aml-document"
              handleUploadFile={handleUploadFile}
              onDropHandler={onDropHandler}
              drag={drag}
              setDrag={setDrag}
              maxSize={10}
              fileExtensions={fileExtensions}
            />
          </AddDocumentWrapper>
          <FormFooter onCancel={closeModalWindow} onSave={() => {}} disableSaveButton={false} />
        </AddDocumentForm>
      </PaddingWrap>
    </MainWrap>
  );
};

const AddDocumentForm = styled.form`
  margin-top: 50px;
`;

const AddDocumentWrapper = styled.div<{ isMobile: boolean }>`
  padding: ${({ isMobile }) => (isMobile ? '20px' : '40px')};
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.border.base};
  background-color: ${({ theme }) => theme.layer[1]};
  display: flex;
  flex-direction: column;
  gap: ${({ isMobile }) => (isMobile ? '15px' : '25px')};
`;

const TwoColumnWrapper = styled.div<{ isMobile: boolean }>`
  display: grid;
  grid-template-columns: ${({ isMobile }) => (isMobile ? '1fr' : '1fr 1fr')};
  gap: ${({ isMobile }) => (isMobile ? '15px' : '30px')};
`;

const FilesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
