import { ActiveInvestmentsMenuIcon } from '@/assets/icons/fat-menu-icons/activeInvestments';
import { useResponsive } from '@/hooks/use-responsive';
import { MainWrap } from '@/styles/common';
import { InvestmentManager } from '@/types/investments';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import Header, { HeaderIconWrap, HeaderSubTitle, HeaderTitle, HeaderTitleWrap } from '../fat-header';
import { IMobileSort } from '../fat-pendingCommitments-page/types';
import { initialValuesFromTable } from '../table/constants';
import FilterPage from '../table/fat-filterPage';
import TablePagination from '../table/tablePagination';
import { ISort, SORT_ORDER } from '../table/types';
import { investmentTableSort, managersTableSort, viewByList } from './constatnts';
import InvestmentTable from './fat-investmentTable/table';
import ManagersTable from './fat-managersTable/table';
import { INVESTMENTS_QUERY, INVESTMENT_MANAGERS_QUERY, getCurrentManagers } from './queries';
import { IManagerInvestment, IManagerInvestments } from './types';

export const ActiveInvestments = () => {
  const { isMobile, isTablet, isPortrait } = useResponsive();
  const navigate = useNavigate();
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();

  const q = searchParams.get('q') || '';
  const viewBy = searchParams.get('viewBy') || 'Manager';
  const key = searchParams.get('key') || 'committedCapital';
  const asc = searchParams.get('asc') === 'true' || false;
  const primaryFilter = searchParams.get('primaryFilter') || 'All';
  const limit = searchParams.get('limit') || initialValuesFromTable.initialAmountRowsPerPage[0];
  const offset = searchParams.get('offset') || 0;
  const position = searchParams.get('position') || 0;

  const [primaryFilterList, setPrimaryFilterList] = useState<string[]>([]);
  const [managers, setManagers] = useState<InvestmentManager[]>([]);
  const [investments, setInvestments] = useState<IManagerInvestment[]>([]);
  const [mobileSortItems, setMobileSortItems] = useState<IMobileSort[]>([]);
  const [mobileSort, setMobileSort] = useState('');
  const sortItem = mobileSortItems.find((item) => item.title === mobileSort);
  const mobileSortList = mobileSortItems.map((item) => item.title);

  useEffect(() => {
    if (!isMobile) return;
    if (viewBy === 'Manager') {
      setMobileSortItems(managersTableSort);
      setMobileSort(managersTableSort[0].title);
      return;
    }
    if (viewBy === 'Strategy') {
      setMobileSortItems(investmentTableSort);
      setMobileSort(investmentTableSort[0].title);
      return;
    }
  }, [viewBy, isMobile]);

  useEffect(() => {
    if (!isMobile) return;
    getCurrentStatus().refetch({
      order: sortItem?.asc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
      sort: sortItem?.key
    });
  }, [mobileSort]);

  useQuery(getCurrentManagers, {
    variables: {
      withCommitments: true
    },
    onCompleted: (data) => {
      const sortedManagerList = [...data.getCurrentManagers.values].filter((manager: string) => manager !== '');
      setPrimaryFilterList(['All', ...sortedManagerList]);
    }
  });

  const { data, loading, error, refetch } = useQuery(INVESTMENT_MANAGERS_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      ...(primaryFilter !== 'All' && { managerId: primaryFilter }),
      page: 1,
      rowsPerPage: Number(limit),
      sort: isMobile ? sortItem?.key : key,
      order: isMobile ? (sortItem?.asc ? SORT_ORDER.ASC : SORT_ORDER.DESC) : asc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
      search: q.toLowerCase().trim(),
      withCommitments: true
    },
    skip: viewBy === 'Strategy',
    onCompleted: () => {
      makeScroll(position);
    }
  });

  const {
    data: investmentsData,
    loading: investmentsLoading,
    error: investmentsError,
    refetch: investmentsRefetch
  } = useQuery<{ ManagerInvestments: IManagerInvestments }>(INVESTMENTS_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      ...(primaryFilter !== 'All' && { managerId: primaryFilter }),
      sort: isMobile ? sortItem?.key : key,
      order: isMobile ? (sortItem?.asc ? SORT_ORDER.ASC : SORT_ORDER.DESC) : asc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
      page: 1,
      rowsPerPage: Number(limit),
      search: q.toLowerCase().trim()
    },
    skip: viewBy === 'Manager',
    onCompleted: (data) => {
      setInvestments(data.ManagerInvestments.investments);
      makeScroll(position);
    }
  });

  useEffect(() => {
    if (!error && !loading && data) {
      setManagers(data.InvestmentManagers.managers);
    }
  }, [data, loading, error]);

  const handleManagerClick = (name: string) => {
    onChangePrimaryFilter(name);
    onChangeViewBy('Strategy');
  };

  const openInvestmentDetails = (id: string) => {
    saveScrollPostion();
    navigate(`/activeInvestments/opportunityDetails/${id}?tab=Investors&backToTitle=Active Commitments`);
  };

  const getCurrentStatus = () => {
    switch (viewBy) {
      case 'Strategy':
        return {
          refetch: investmentsRefetch,
          total: investmentsData?.ManagerInvestments.total,
          subTitle: 'See other rollup for replacement instructions'
        };
      case 'Manager':
        return {
          refetch: refetch,
          total: data?.InvestmentManagers.total,
          subTitle: 'View account balances for all active commitments'
        };
      default:
        return {
          refetch: () => null as any,
          total: 0,
          subTitle: ''
        };
    }
  };

  const onChangeSearch = (searchValue: string) => {
    setSearchParams(
      (prev) => {
        if (!searchValue) {
          prev.delete('q');
          return prev;
        }
        prev.set('q', searchValue);
        return prev;
      },
      { replace: true }
    );
  };

  const onChangeViewBy = (viewByValue: string) => {
    setSearchParams(
      (prev) => {
        prev.set('viewBy', viewByValue);
        return prev;
      },
      { replace: true }
    );
  };

  const onChangeSort = (value: ISort) => {
    setSearchParams(
      (prev) => {
        prev.set('key', value.key);
        prev.set('asc', value.asc.toString());
        return prev;
      },
      { replace: true }
    );
  };

  const onChangePrimaryFilter = (filter: string) => {
    setSearchParams(
      (prev) => {
        prev.set('primaryFilter', filter);
        return prev;
      },
      { replace: true }
    );
  };

  const saveScrollPostion = () => {
    setSearchParams(
      (prev) => {
        prev.set('position', window.scrollY.toString());
        return prev;
      },
      { replace: true }
    );
  };

  const makeScroll = (position: string | number) => {
    if (!position) return;
    setTimeout(() => window.scrollTo({ top: Number(position), behavior: 'smooth' }), 0);
    setSearchParams(
      (prev) => {
        prev.delete('position');
        return prev;
      },
      { replace: true }
    );
  };

  const onChangePaginationData = (limit: number, offset: number) => {
    setSearchParams(
      (prev) => {
        prev.set('limit', limit.toString());
        prev.set('offset', offset.toString());
        return prev;
      },
      { replace: true }
    );
  };

  return (
    <>
      <MainWrap>
        <Header
          modalControl={
            <HeaderTitleWrap>
              <HeaderIconWrap>
                <ActiveInvestmentsMenuIcon style={{ width: isMobile ? 33 : 40, height: isMobile ? 33 : 40 }} fill={theme.font.strong} />
                <HeaderTitle isTablet={isTablet} isMobile={isMobile}>
                  Active Commitments
                </HeaderTitle>
              </HeaderIconWrap>
              {!isMobile && <HeaderSubTitle>{getCurrentStatus().subTitle}</HeaderSubTitle>}
            </HeaderTitleWrap>
          }
        />
      </MainWrap>
      <MainWrap>
        <PaddingWrap>
          <CustomFilterPage
            isMobile={isMobile}
            isTablet={isTablet}
            search={{
              value: q,
              onChange: onChangeSearch,
              placeholder: 'Search'
            }}
            primaryFilter={{
              value: primaryFilter,
              onChange: onChangePrimaryFilter,
              selects: primaryFilterList
            }}
            resultsValue={getCurrentStatus().total}
            refetch={getCurrentStatus().refetch}
            viewBySelect={{
              value: viewBy,
              onChange: onChangeViewBy,
              selects: viewByList
            }}
            mobileSort={{
              value: mobileSort,
              onChange: setMobileSort,
              selects: mobileSortList
            }}
          />
          {viewBy === 'Manager' && (
            <ManagersTable
              tableData={managers}
              sort={{ key, asc }}
              setSort={onChangeSort}
              refetch={() => null}
              loading={loading}
              handleManagerClick={handleManagerClick}
            />
          )}
          {viewBy === 'Strategy' && (
            <InvestmentTable
              tableData={investments}
              sort={{ key, asc }}
              setSort={onChangeSort}
              refetch={() => null}
              loading={investmentsLoading}
              openInvestmentDetails={openInvestmentDetails}
            />
          )}
          <PaginationWrap>
            <TablePagination
              savePagination={onChangePaginationData}
              paginationValues={{
                limit: Number(limit),
                offset: Number(offset),
                total: viewBy === 'Manager' ? data?.InvestmentManagers.total : investmentsData?.ManagerInvestments.total
              }}
              refetch={viewBy === 'Manager' ? refetch : investmentsRefetch}
              usePage
            />
          </PaginationWrap>
        </PaddingWrap>
      </MainWrap>
    </>
  );
};

const PaddingWrap = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  @media (min-width: 600px) {
    padding-left: 50px;
    padding-right: 50px;
  }
`;

const CustomFilterPage = styled(FilterPage)<{ isMobile: boolean; isTablet: boolean }>`
  position: sticky;
  top: ${({ isMobile, isTablet }) => (isMobile || isTablet ? '50px' : '0')};
  z-index: 9;
  margin-top: 0;
  margin-left: -1px;
  width: calc(100% + 2px);
  padding: ${({ isMobile }) => (isMobile ? '20px 0 0 0' : '20px 0 10px 0')};
`;

const PaginationWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
