import CheckBox from '@/components/basicComponents/checkbox';
import RadioButton from '@/components/basicComponents/radioButton';
import Input from '@/components/fat-basicComponents/input';
import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import Select from '@/components/fat-basicComponents/select';
import { Loader } from '@/components/loaders/loader';
import { useResponsive } from '@/hooks/use-responsive';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';

interface InvestorDetailsProps {
  isOpen: boolean;
  onClose: () => void;
}

interface IInvestorDetailsForm {
  category: string;
  taxExempt: boolean;
  entityType: string;
  customEntityType: string;
  eligibility: string[];
}

const defaultFormValues: IInvestorDetailsForm = {
  category: '',
  taxExempt: false,
  entityType: '',
  customEntityType: '',
  eligibility: []
};

const taxExemptSectionOptions = ['Taxable', 'Exempt'];
const eligibilityItems = ['Qualified Purchaser', 'Qualified Clients', 'Accredited Investor'];
const individualSelectOptions = [
  { label: 'Individual Ownership', value: 'Individual Ownership' },
  { label: 'Joint Tenants', value: 'Joint Tenants' },
  { label: 'Tenants in Common', value: 'Tenants in Common' },
  { label: 'IRA', value: 'IRA' }
];
const nonIndividualSelectOptions = [
  { label: 'Trust', value: 'Trust' },
  { label: 'Corporation', value: 'Corporation' },
  { label: '501(c)(3)', value: '501(c)(3)' },
  { label: 'Endowment', value: 'Endowment' },
  { label: 'Limited Partnership', value: 'Limited Partnership' },
  { label: 'Limited Liability Company', value: 'Limited Liability Company' },
  { label: 'Benefit Plan Investor', value: 'Benefit Plan Investor' },
  { label: 'Foundation', value: 'Foundation' },
  { label: 'Other', value: 'Other' }
];

const InvestorDetails = ({ isOpen, onClose }: InvestorDetailsProps) => {
  const isMobile = useResponsive();

  const {
    handleSubmit,
    getValues,
    control,
    reset,
    watch,
    formState: { isValid }
  } = useForm<IInvestorDetailsForm>({ defaultValues: defaultFormValues });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSave = () => {};

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      title={false ? 'Saving Investor Details' : 'Investor Details'}
      showRequiredFields={!false}
      showCloseIcon={!false}
      showCancelButton={!false}
      isUseOutsideClick={!false}
      confirmButton={{
        name: 'Save',
        onClick: handleSubmit(onSave),
        disabled: isSubmitting
      }}
    >
      {false ? (
        <LoaderWrapper>
          <ModalText>Please allow a few moment for this process to complete.</ModalText>
          <Loader />
        </LoaderWrapper>
      ) : (
        <ModalBody>
          {/* {false && <Banner icon={<InfoIcon width={26} height={26} />} title="Error" description={createAutoPlanError.message} bgColor="#D63B4B" />} */}
          <div>
            <SectionHeader>Entity Type</SectionHeader>
            <Controller
              name="category"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Category is required'
                }
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <Select
                    value={value}
                    setValue={onChange}
                    options={[
                      { label: 'Select', value: '' },
                      { label: 'Individual', value: 'Individual' },
                      { label: 'Non-Individual', value: 'Non-Individual' }
                    ]}
                    errorText={error?.message}
                    label="Select a Category"
                    isRequiredField
                    size="md"
                  />
                </>
              )}
            />
          </div>
          <Controller
            name="entityType"
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Category is required'
              }
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <Select
                  value={value}
                  setValue={onChange}
                  options={watch('category') === 'Individual' ? individualSelectOptions : nonIndividualSelectOptions}
                  errorText={error?.message}
                  label="Select an Entity Type"
                  styleType={!watch('category') ? 'disabled' : 'default'}
                  size="md"
                />
              </>
            )}
          />
          {watch('entityType') === 'Other' && (
            <Controller
              name="customEntityType"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Input
                  label="Entity Type"
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                  errorText={error?.message}
                  size="md"
                />
              )}
            />
          )}
          <div>
            <SectionHeader>Tax Status</SectionHeader>
            <TaxExemptOptionsWrapper>
              <Controller
                name="taxExempt"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <>
                    {taxExemptSectionOptions.map((option, index) => (
                      <RadioButton
                        key={option + index}
                        name="taxExempt"
                        radioButtonText={option}
                        selectedOption={value ? taxExemptSectionOptions[1] : taxExemptSectionOptions[0]}
                        handleRadioChange={(event) => onChange(event.target.value === taxExemptSectionOptions[1])}
                      />
                    ))}
                  </>
                )}
              />
            </TaxExemptOptionsWrapper>
          </div>
          <div>
            <SectionHeader>Eligibility</SectionHeader>
            <HelperText>At least one selection is required to continue</HelperText>
            <Controller
              name="eligibility"
              control={control}
              render={({ field: { value, onChange } }) => (
                <CheckboxComponentWrapper>
                  {eligibilityItems.map((item) => (
                    <CheckboxWrapper
                      key={item}
                      id={item}
                      onClick={() => {
                        const updatedEligibility = value.includes(item) ? value.filter((el) => el !== item) : [...value, item];
                        onChange(updatedEligibility);
                      }}
                    >
                      <CheckBox id={item} isActive={value.includes(item)} />
                      {item}
                    </CheckboxWrapper>
                  ))}
                </CheckboxComponentWrapper>
              )}
            />
          </div>
        </ModalBody>
      )}
    </ModalWrappedContent>
  );
};

export default InvestorDetails;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  height: 100%;
`;

const LoaderWrapper = styled.div`
  text-align: center;
`;

const ModalText = styled.div`
  display: inline-block;
  text-align: center;
  font-size: 19px;
  font-weight: 400;
  color: ${({ theme }) => theme.font.base};
`;

const TaxExemptOptionsWrapper = styled.div`
  display: flex;
`;

const SectionHeader = styled.h2`
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  padding: 10px 0;
  color: ${({ theme }) => theme.font.strong};
`;

const HelperText = styled.p`
  color: ${({ theme }) => theme.context.error};
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18.2px;
`;

const CheckboxComponentWrapper = styled.div`
  margin-top: 20px;
`;

const CheckboxWrapper = styled.div`
  cursor: pointer;
  display: flex;
  gap: 9px;
  color: ${({ theme }) => theme.font.base};
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  padding: 10px;
`;
