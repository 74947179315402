import CheckBox from '@/components/basicComponents/checkbox';
import Label from '@/components/fat-basicComponents/label';
import { Controller } from 'react-hook-form';
import styled from 'styled-components';
import { IRenderTemplateField } from '../../types';

interface CheckboxProps {
  control: any;
  field: IRenderTemplateField;
  ignoreRequired: boolean;
}

export const Checkbox = ({ field, control, ignoreRequired }: CheckboxProps) => {
  return (
    <CheckboxComponentWrapper>
      {field?.label && <Label size="md">{field?.label || ''}</Label>}
      <Controller
        name={field.name}
        control={control}
        render={({ field: { value, onChange } }) => (
          <CheckboxWrapper id={field.name} onClick={() => onChange(!value)}>
            <CheckBox id={field.name} isActive={!!value} /> {field?.config?.optionLabel || ''}
          </CheckboxWrapper>
        )}
      />
    </CheckboxComponentWrapper>
  );
};

const CheckboxComponentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 15px 0 10px 0;
`;

const CheckboxWrapper = styled.div`
  cursor: pointer;
  display: flex;
  gap: 9px;
  color: ${({ theme }) => theme.font.base};
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
`;
