import { ErrorBanner } from '@/components/dueDiligence-page/dueDiligenceRecord/errorBanner';
import { FormFooter } from '@/components/fat-basicComponents/formFooter';
import Input from '@/components/fat-basicComponents/input';
import Select from '@/components/fat-basicComponents/select';
import Header from '@/components/fat-header';
import { GoBackButton } from '@/components/fat-header/goBackButton';
import { PageTitle } from '@/components/fat-header/pageTitle';
import { useResponsive } from '@/hooks/use-responsive';
import { MainWrap, PaddingWrap } from '@/styles/common';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { FirmSettingsContainer } from '.';
import { fontSelect } from './constants';
import { IFirmSettings } from './types';

interface EditFontStyleProps {
  handleClose: () => void;
  createFirmSetting: (type: string, key: string, value: string) => void;
  updateFirmSetting: (id: string, value: string) => void;
  currentRow: IFirmSettings | null;
}

export const EditFontStyle = ({ handleClose, createFirmSetting, updateFirmSetting, currentRow }: EditFontStyleProps) => {
  const { isMobile } = useResponsive();
  const [searchParams, setSearchParams] = useSearchParams();

  const rowId = searchParams.get('id') || '';
  const edit = searchParams.get('edit') || '';
  const fieldName = searchParams.get('fieldName') || '';

  const [chosenFont, setChosenFont] = useState<{
    fontFamily: string;
    fontColor: string;
    fontWeight: string;
  }>({
    fontFamily: '',
    fontColor: '',
    fontWeight: ''
  });
  const [error, setError] = useState(false);

  useEffect(() => {
    if (currentRow?.value) {
      const values = JSON.parse(currentRow.value);
      setChosenFont({
        fontFamily: values.fontFamily,
        fontColor: values.fontColor,
        fontWeight: values.fontWeight
      });
    }
    return () => {
      setChosenFont({
        fontFamily: '',
        fontColor: '',
        fontWeight: ''
      });
    };
  }, [currentRow]);

  useEffect(() => {
    if (!currentRow?.value) {
      setChosenFont({
        fontFamily: edit === 'headerFontStyle' ? 'Sanchez' : 'Open Sans',
        fontColor: edit === 'headerFontStyle' ? '35739C' : '757575',
        fontWeight: edit === 'headerFontStyle' ? '400' : '600'
      });
    }
  }, [edit]);

  const validateInput = (value: string) => {
    const regex = /^[0-9a-f]{0,6}$/i;

    if (!regex.test(value)) {
      setError(true);
      return false;
    }
    setError(false);
    return true;
  };

  const saveValue = (key: string) => {
    if (!validateInput(chosenFont.fontColor)) return;
    const defaultColor = key === 'headerFontStyle' ? '35739C' : '757575';
    const headertFontValue = JSON.stringify({
      fontFamily: chosenFont.fontFamily,
      fontColor: chosenFont.fontColor.length ? chosenFont.fontColor : defaultColor,
      fontWeight: chosenFont.fontWeight
    });
    if (rowId !== 'null') {
      updateFirmSetting(rowId, headertFontValue);
      return;
    }
    createFirmSetting('templateStyling', key, headertFontValue);
  };

  return (
    <>
      <MainWrap>
        <Header modalControl={<GoBackButton handleClose={handleClose} backToTitle="Firm Settings" />} />
        <PageTitle title={fieldName} />
      </MainWrap>
      <MainWrap>
        {error && (
          <ErrorBanner
            errorMessage={'Please enter a valid hexidecimal value for the Header Font Color'}
            errorDescription={'Provide six characters using only 0 - 9 and a - f'}
          />
        )}
        <PaddingWrap>
          <FirmSettingsContainer isMobile={isMobile}>
            <FontStyleWrapper>
              <Select
                options={Object.keys(fontSelect)?.map((item) => ({ label: item, value: item }))}
                value={chosenFont.fontFamily}
                setValue={(value) =>
                  setChosenFont({
                    ...chosenFont,
                    fontWeight: fontSelect[value as keyof typeof fontSelect][0],
                    fontFamily: value
                  })
                }
                label="Header Font Family"
              />
              <Input
                type="text"
                value={chosenFont.fontColor}
                onChange={(event) =>
                  setChosenFont({
                    ...chosenFont,
                    fontColor: event.target.value
                  })
                }
                label="Header Font Color"
              />
              <Select
                options={fontSelect[chosenFont.fontFamily as keyof typeof fontSelect]?.map((item) => ({ label: item, value: item }))}
                value={chosenFont.fontWeight}
                setValue={(value) => setChosenFont({ ...chosenFont, fontWeight: value })}
                label="Header Font Weight"
              />
            </FontStyleWrapper>
            <FormFooter onCancel={handleClose} onSave={() => saveValue(edit)} disableSaveButton={false} showRequiredFieldsWarning={false} />
          </FirmSettingsContainer>
        </PaddingWrap>
      </MainWrap>
    </>
  );
};

const FontStyleWrapper = styled.div`
  display: flex;
  gap: 10px;
`;
