import { IListData } from './types';

export const defaultListData: IListData = {
  list: ['None'],
  chosen: [],
  duplicate: ''
};

export const distributionSettingsOptions = ['Show All', 'Restrict By Advisory Firm'];

export const yseNoOptions = ['Yes', 'No'];
