import * as React from 'react';

export const SignatureIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 48}
    height={props.height || 42}
    onClick={props.onClick || undefined}
    style={props?.style}
    viewBox="0 0 48 42"
    fill="none"
  >
    <g clipPath="url(#clip0_12044_145753)">
      <path
        d="M33.3837 13.2272C30.8052 10.7479 28.3135 8.35532 25.8094 5.95037C25.6482 6.08673 25.5366 6.16111 25.4499 6.26028C22.2019 9.65698 18.954 13.0537 15.7185 16.4628C15.4829 16.7107 15.3094 17.0702 15.235 17.4049C14.7515 19.438 14.2928 21.471 13.8342 23.5165C13.611 24.533 13.4003 25.5495 13.1523 26.6776C13.5614 26.6404 13.7846 26.6404 14.0077 26.5785C16.8342 25.8347 19.6482 25.0909 22.4623 24.3223C22.735 24.2479 23.0325 24.0867 23.2185 23.8884C26.578 20.3677 29.9251 16.8471 33.3714 13.2148L33.3837 13.2272ZM35.7267 10.6115C36.7061 9.68177 37.6358 8.86359 38.4788 7.98342C39.235 7.17764 39.2846 6.2107 38.5408 5.47929C36.8176 3.76855 35.0449 2.10739 33.2474 0.45863C32.578 -0.148808 31.7722 -0.148808 31.078 0.45863C30.0614 1.35119 29.0821 2.28094 28.0408 3.23549C30.768 5.85119 33.2598 8.24375 35.7267 10.6115Z"
        fill="white"
      />
      <path
        d="M0.0244141 34.0041C1.09053 30.1487 3.8426 28.0289 7.43764 26.752C8.76408 26.281 9.71863 26.6653 10.0533 27.7438C10.3881 28.7851 9.87979 29.5537 8.59053 30.0372C6.8178 30.6942 5.19384 31.562 4.12772 33.2107C3.12359 34.7603 3.47069 35.7024 5.28061 36.0867C7.35086 36.5206 9.38392 36.0496 11.3674 35.4917C14.8137 34.5372 18.2228 33.4339 21.6938 32.5537C24.5079 31.8347 27.3839 31.438 30.2847 31.971C33.0244 32.4669 34.3013 34.314 33.9542 37.0785C33.8922 37.5372 33.855 37.9958 33.793 38.5661C35.7641 38.5413 37.6236 38.3181 39.4211 37.6983C41.4294 37.0041 43.4128 36.2603 45.4211 35.5785C46.6236 35.1694 47.5038 35.5661 47.8509 36.5702C48.2228 37.6363 47.7517 38.4297 46.4376 38.8636C43.6112 39.8181 40.7847 40.7603 37.9583 41.6777C37.4624 41.8388 36.9418 41.9008 36.4211 42H33.6195C33.4335 41.938 33.2476 41.8512 33.0616 41.814C31.2765 41.5041 30.5203 40.7231 30.3715 38.9132C30.3095 38.1198 30.3467 37.314 30.4459 36.533C30.5451 35.6529 30.136 35.2686 29.3426 35.281C27.8178 35.3181 26.2558 35.219 24.7806 35.5413C21.7434 36.1983 18.7558 37.0661 15.7558 37.8967C12.5947 38.7644 9.4707 39.7934 6.11119 39.6322C2.75169 39.471 1.1897 38.4297 0.0244141 35.4049L0.0244141 34.0041Z"
        fill="#2B2B2B"
      />
      <path
        d="M0.0244141 35.4049C1.1897 38.4297 2.75169 39.471 6.11119 39.6322C9.47069 39.7933 12.5947 38.7644 15.7558 37.8966C18.7558 37.0661 21.7434 36.1983 24.7806 35.5413C26.2558 35.2189 27.8178 35.3181 29.3426 35.2809C30.136 35.2685 30.5451 35.6528 30.4459 36.533C30.3591 37.3264 30.3095 38.1322 30.3715 38.9132C30.5203 40.7231 31.2765 41.5041 33.0616 41.814C33.2476 41.8512 33.4335 41.938 33.6195 41.9999H0.0244141V35.4049Z"
        fill="white"
      />
      <path
        d="M33.384 13.2272C29.9378 16.8595 26.5907 20.3801 23.2312 23.9008C23.0328 24.0991 22.7477 24.2603 22.475 24.3347C19.6609 25.1033 16.8345 25.8471 14.0204 26.5909C13.8097 26.6529 13.5741 26.6405 13.165 26.69C13.4006 25.5619 13.6113 24.5454 13.8469 23.5289C14.3055 21.4958 14.7642 19.4504 15.2477 17.4173C15.3221 17.0826 15.4956 16.7231 15.7312 16.4752C18.9667 13.0661 22.2146 9.66938 25.4626 6.27269C25.5493 6.17351 25.6609 6.09913 25.8221 5.96277C28.3262 8.36773 30.8179 10.7603 33.3964 13.2396L33.384 13.2272ZM14.7642 24.9917C16.8717 24.4214 18.8055 23.9132 20.8882 23.3553C19.2394 21.7933 17.7394 20.3677 16.1278 18.8553C15.6568 20.9628 15.2353 22.8843 14.7518 25.0041L14.7642 24.9917Z"
        fill="#575757"
      />
      <path
        d="M35.727 10.6115C33.26 8.24376 30.7683 5.85119 28.041 3.23549C29.0823 2.28094 30.0617 1.35119 31.0782 0.45863C31.7724 -0.148808 32.5782 -0.148808 33.2476 0.45863C35.0451 2.09499 36.8055 3.76855 38.541 5.47929C39.2848 6.2107 39.2476 7.17764 38.479 7.98342C37.6361 8.87599 36.7063 9.68177 35.727 10.6115Z"
        fill="#8F8F8F"
      />
      <path
        d="M14.7637 24.9917C15.2347 22.8718 15.6686 20.9627 16.1397 18.8429C17.7513 20.3677 19.2389 21.7809 20.9 23.3429C18.805 23.9007 16.8835 24.4214 14.7761 24.9793L14.7637 24.9917Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_12044_145753">
        <rect width="47.9504" height="42" fill="white" transform="translate(0.0244141)" />
      </clipPath>
    </defs>
  </svg>
);
