import { ApprovedIcon } from '@/assets/icons/info-icons/approved';
import { maskedBankAccountFields } from '@/components/fat-transactions-page/generateSignaturePacket-page/templateComponents/rowComponents/bankAccountsComponent';
import { HeaderComponent } from '@/components/fat-transactions-page/generateSignaturePacket-page/templateComponents/styles';
import { IRenderTemplateRow } from '@/components/fat-transactions-page/generateSignaturePacket-page/templateComponents/types';
import { useResponsive } from '@/hooks/use-responsive';
import _ from 'lodash';
import styled, { useTheme } from 'styled-components';

interface PageRowsProps {
  row: IRenderTemplateRow;
}

export const PageRows = ({ row }: PageRowsProps) => {
  const { isMobile } = useResponsive();
  const theme = useTheme();

  switch (row.component) {
    case 'custom':
      return row.fields.map(
        (field) =>
          !!field?.value && (
            <TwoColumnWrapper key={field.name} isMobile={isMobile}>
              <GeneralInformationItem>{field.label}</GeneralInformationItem>
              <GeneralInformationItem isBold>{field?.value ?? ''}</GeneralInformationItem>
            </TwoColumnWrapper>
          )
      );
    case 'textarea':
      return (
        !!row?.value && (
          <TextareaComponentWrapper>
            <GeneralInformationItem>{row.label}</GeneralInformationItem>
            <GeneralInformationItem isBold>{row?.value ?? ''}</GeneralInformationItem>
          </TextareaComponentWrapper>
        )
      );
    case 'checkboxGroup':
      return (
        <div>
          {row.config.items.map(
            (item: any) =>
              !!item?.value && (
                <CheckboxGroupItem key={item.name}>
                  <ApprovedIcon width={28} height={28} fill={theme.font.action} />
                  {item.label}
                </CheckboxGroupItem>
              )
          )}
        </div>
      );
    case 'header':
      return row?.config?.content && <HeaderComponent>{row.config.content}</HeaderComponent>;
    case 'radio':
      return (
        row?.label &&
        row?.value && (
          <TwoColumnWrapper isMobile={isMobile}>
            <GeneralInformationItem>{row.label}</GeneralInformationItem>
            <GeneralInformationItem isBold>{row?.value ?? ''}</GeneralInformationItem>
          </TwoColumnWrapper>
        )
      );
    case 'address':
      return (
        row?.value &&
        Object.entries(row.value).map(([key, value]) => (
          <TwoColumnWrapper key={key} isMobile={isMobile}>
            <GeneralInformationItem>{_.startCase(key)}</GeneralInformationItem>
            <GeneralInformationItem isBold>{value as string}</GeneralInformationItem>
          </TwoColumnWrapper>
        ))
      );
    case 'bankAccounts':
      return (
        row?.value && (
          <>
            <ApprovedIconWrapper>
              <ApprovedIcon width={28} height={28} fill={theme.font.action} />
              Enter Bank Accounts
            </ApprovedIconWrapper>
            <BankAccountsWrapper>
              {row.value.map((value: any, index: number) => (
                <BankAccountItem key={index}>
                  {Object.entries(value).map(([key, value]) => (
                    <TwoColumnWrapper key={key} isMobile={isMobile}>
                      <GeneralInformationItem>{_.startCase(key)}</GeneralInformationItem>
                      <GeneralInformationItem isBold>
                        {maskedBankAccountFields.includes(key) ? (value as string).replace(/./g, '*') : (value as string)}
                      </GeneralInformationItem>
                    </TwoColumnWrapper>
                  ))}
                </BankAccountItem>
              ))}
            </BankAccountsWrapper>
          </>
        )
      );
    default:
      return <></>;
  }
};

const TwoColumnWrapper = styled.div<{ isMobile: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 20px;
  width: ${({ isMobile }) => (isMobile ? '100%' : '60%')};
  padding: 2px 10px;
`;

const TextareaComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  white-space: pre-line;
`;

const BankAccountsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const BankAccountItem = styled.div`
  padding: 20px;
`;

const ApprovedIconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  color: ${({ theme }) => theme.font.base};
  padding: 10px 10px 0 10px;
`;

const GeneralInformationItem = styled.div<{ isBold?: boolean }>`
  font-family: Blinker;
  font-size: 16px;
  font-weight: ${({ isBold }) => (isBold ? 600 : 400)};
  line-height: 22.4px;
  color: ${({ theme }) => theme.font.base};
  padding: 4px;
`;

const CheckboxGroupItem = styled.div`
  display: flex;
  align-items: center;
  gap: 9px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  padding: 8px;
  color: ${({ theme }) => theme.font.base};
`;
