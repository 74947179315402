import { useAuth } from '@/hooks/use-auth';
import { MainWrap } from '@/styles/common';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import Button from '../fat-basicComponents/button';
import Header from '../fat-header';
import { DealFunnelEditor } from '../settings-page/settings-pages/dealFunnelEditor';
import { DueDiligenceImportData } from '../settings-page/settings-pages/dealFunnelEditor/dueDiligenceUploads';
import { AssetClassDefaultTargetsPage } from './assetClassDefaultTargets';
import { FirmSettings } from './firmSettings';
import { ImportData } from './importData';
import { Tenants } from './tenants';
import { TransactionImportData } from './transactionImportData';
import { Users } from './users';

export const Settings = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const menuConfig = JSON.parse(process.env.REACT_APP_MENU_CONFIG);

  const action = searchParams.get('action') || 'default';

  const handleRedirect = (path: string) => {
    navigate(`/settings?action=${path}`);
  };

  const handleClose = () => {
    navigate('/settings');
  };

  const handleCloseTenants = () => {
    navigate('/settings?action=transactionImportData');
  };

  return (
    <>
      {action === 'importData' && <ImportData handleCloseImportData={handleClose} backToTitle="Settings" />}
      {action === 'transactionImportData' && <TransactionImportData handleClose={handleClose} backToTitle="Settings" />}
      {action === 'users' && <Users handleClose={handleClose} backToTitle="Settings" />}
      {action === 'dueDiligence' && <DealFunnelEditor handleClose={handleClose} />}
      {action === 'dueDiligenceImportData' && <DueDiligenceImportData handleClose={handleClose} />}
      {action === 'tenants' && <Tenants handleClose={handleCloseTenants} backToTitle="Super Admin" />}
      {action === 'firmSettings' && menuConfig?.settings?.firmSettings && <FirmSettings handleClose={handleClose} backToTitle="Settings" />}
      {action === 'assetClasses' && <AssetClassDefaultTargetsPage closeAssetClassTargetsPage={handleClose} backToTitle="Settings" />}
      {action === 'default' && (
        <MainWrap>
          <Header />
          <PaddingWrap>
            {user && user.tenant?.type === 'fundManager' && (user.roles.includes('Super Admin') || user.roles.includes('Admin')) && (
              <SettingsWrapper>
                <SettingsItem>
                  <ItemTitle id="itemTitle" onClick={() => handleRedirect('importData')}>
                    Import Data
                  </ItemTitle>
                  <OpenButtonWrapper id="openButton">
                    <Button onClick={() => handleRedirect('importData')}>Open</Button>
                  </OpenButtonWrapper>
                </SettingsItem>

                <SettingsItem>
                  <ItemTitle id="itemTitle" onClick={() => handleRedirect('dueDiligence')}>
                    Due Diligence
                  </ItemTitle>
                  <OpenButtonWrapper id="openButton">
                    <Button onClick={() => handleRedirect('dueDiligence')}>Open</Button>
                  </OpenButtonWrapper>
                </SettingsItem>

                <SettingsItem>
                  <ItemTitle id="itemTitle" onClick={() => handleRedirect('users')}>
                    Users
                  </ItemTitle>
                  <OpenButtonWrapper id="openButton">
                    <Button onClick={() => handleRedirect('users')}>Open</Button>
                  </OpenButtonWrapper>
                </SettingsItem>

                {menuConfig?.settings?.firmSettings && (
                  <SettingsItem>
                    <ItemTitle id="itemTitle" onClick={() => handleRedirect('firmSettings')}>
                      Firm Settings
                    </ItemTitle>
                    <OpenButtonWrapper id="openButton">
                      <Button onClick={() => handleRedirect('firmSettings')}>Open</Button>
                    </OpenButtonWrapper>
                  </SettingsItem>
                )}

                {user && user.roles.includes('Super Admin') && (
                  <SettingsItem>
                    <ItemTitle id="itemTitle" onClick={() => handleRedirect('transactionImportData')}>
                      Super Admin
                    </ItemTitle>
                    <OpenButtonWrapper id="openButton">
                      <Button onClick={() => handleRedirect('transactionImportData')}>Open</Button>
                    </OpenButtonWrapper>
                  </SettingsItem>
                )}
              </SettingsWrapper>
            )}

            {user && user.tenant?.type === 'advisoryFirm' && (
              <SettingsWrapper>
                <SettingsItem>
                  <ItemTitle id="itemTitle" onClick={() => handleRedirect('assetClasses')}>
                    Asset Classes
                  </ItemTitle>
                  <OpenButtonWrapper id="openButton">
                    <Button onClick={() => handleRedirect('assetClasses')}>Open</Button>
                  </OpenButtonWrapper>
                </SettingsItem>

                {menuConfig?.settings?.firmSettings && (
                  <SettingsItem>
                    <ItemTitle id="itemTitle" onClick={() => handleRedirect('firmSettings')}>
                      Firm Settings
                    </ItemTitle>
                    <OpenButtonWrapper id="openButton">
                      <Button onClick={() => handleRedirect('firmSettings')}>Open</Button>
                    </OpenButtonWrapper>
                  </SettingsItem>
                )}
              </SettingsWrapper>
            )}
          </PaddingWrap>
        </MainWrap>
      )}
    </>
  );
};

const PaddingWrap = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  @media (min-width: 600px) {
    padding-left: 50px;
    padding-right: 50px;
  }
`;

const SettingsWrapper = styled.div`
  margin-top: 30px;
  background: ${({ theme }) => theme.layer[1]};
  padding: 40px 60px;
  border: 1px solid ${({ theme }) => theme.border.base};
  border-radius: 10px;
`;

const SettingsItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 21px 14px;
  border-bottom: 1px solid ${({ theme }) => theme.border.base};

  &:hover #openButton {
    opacity: 1;
    transition: background-color 100ms ease;
  }
  &:hover #itemTitle {
    color: ${({ theme }) => theme.font.action};
  }
  &:hover {
    background-color: ${({ theme }) => theme.layer[2]};
    transition: background-color 100ms ease;
  }
`;

const ItemTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.font.strong};
  cursor: pointer;
`;

const OpenButtonWrapper = styled.div`
  opacity: 0;
`;
