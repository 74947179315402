import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import { Count, CountWrap, Text } from '@/components/fat-dealCatalog-page/fat-allocator-page/fat-modals/submitTo';
import { createAllocationMutation } from '@/components/opportunities-entity/pendingAllocation/constants';
import { PROPOSAL_TYPE } from '@/types/investments';
import { useMutation } from '@apollo/client';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { RejectedBannerIcon } from '@/assets/icons/info-icons/rejectedBanner';
import { Banner } from '@/components/basicComponents/banner';
import Input from '@/components/fat-basicComponents/input';
import { formatDollarInputValue, formatMaskedDollarInputValue } from '@/components/fat-basicComponents/input/utils';
import styled, { useTheme } from 'styled-components';
import { ClientEntityAllocationsTableType } from '../types';

interface EditAmountProps {
  onClose: () => void;
  isOpen: boolean;
  row: ClientEntityAllocationsTableType | null;
  refetch: () => void;
  ignoreBiteSize?: boolean | null;
}

interface ErrorMessage {
  title: string;
  message: string;
}

const EditAmount = ({ onClose, isOpen, row, refetch, ignoreBiteSize = false }: EditAmountProps) => {
  const params = useParams();
  const theme = useTheme();

  const [value, setValue] = useState('');
  const [errorText, setErrorText] = useState<ErrorMessage | null>(null);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);

  useEffect(() => {
    if (!row) return;

    setValue((ignoreBiteSize ? row.minimum?.toString() : row.committedCapital?.toString()) || '');
  }, [row]);

  const [createAllocations, { loading: createAllocationsLoading }] = useMutation(createAllocationMutation);

  const validateAmount = (amount: number, allocation: ClientEntityAllocationsTableType) => {
    let errorMessage: ErrorMessage | null = null;
    if (value[0] === '0') {
      errorMessage = {
        title: 'Cannot be less than 0',
        message: 'Any increase investment value must be greater than 0'
      };
    }
    if (allocation.proposalType === PROPOSAL_TYPE.INITIAL) {
      if (amount < allocation.minimum) {
        errorMessage = {
          title: 'Cannot be less than fund minimum',
          message: 'The initial investment amount must be equal or greater than the investment minimum.'
        };
      }
    }
    if (allocation.proposalType === PROPOSAL_TYPE.INCREASE) {
      if (amount <= 0) {
        errorMessage = {
          title: 'Cannot be less than 0',
          message: 'Any increase investment value must be greater than 0'
        };
      }
    }

    setErrorText(errorMessage);
    return errorMessage;
  };

  const saveValue = () => {
    const errorMessage = validateAmount(parseFloat(value), row);
    if (errorMessage) return;
    if (!isConfirmationDialogOpen) {
      setIsConfirmationDialogOpen(true);
      return;
    }
    createAllocations({
      variables: {
        data: [
          {
            investmentId: row.investmentId,
            legalEntityId: params?.entityId,
            committedCapital: parseFloat(value),
            allocationStatus: 'Outstanding',
            proposalType: row.proposalType,
            suitability: row.suitability,
            decision: true,
            reason: ''
          }
        ]
      },
      onCompleted: () => {
        setValue('');
        refetch();
        onClose();
      }
    });

    return;
  };

  if (!row) return <></>;

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      title={!isConfirmationDialogOpen ? 'Edit Investment Amount' : 'Confirmation'}
      confirmButton={{
        name: isConfirmationDialogOpen ? 'Confirm' : 'Confirm Allocation',
        onClick: saveValue,
        disabled: !value || createAllocationsLoading
      }}
    >
      <AnimatePresence>
        {isConfirmationDialogOpen ? (
          <motion.div
            initial={{
              opacity: 0
            }}
            animate={{
              opacity: 1
            }}
          >
            <ModalBody>
              <Text fontWeight="400">Please review.</Text>
              <Text fontWeight="400">
                <CountWrap>
                  <Count>1</Count>
                </CountWrap>
                allocation(s) will move to <Text fontWeight="700">Pending Commitments</Text>
              </Text>
            </ModalBody>
          </motion.div>
        ) : (
          <>
            {errorText && (
              <Banner
                icon={<RejectedBannerIcon fill={theme.context.light} />}
                title={errorText?.title ?? ''}
                description={errorText?.message ?? ''}
                bgColor="#D63B4B"
              />
            )}
            <Input
              value={value ? formatMaskedDollarInputValue(value) : ''}
              onChange={(e) => {
                const formattedValue = formatDollarInputValue(e.target.value);
                setValue(formattedValue);
              }}
              label={row.proposalType === 'Increase' ? 'Follow-on Investment Amount' : 'Initial Investment Amount'}
              isRequiredField
              withDollarSign
              styleType={value ? 'success' : 'default'}
              autoFocus
            />
            <Description>{row.proposalType === 'Increase' ? '' : `Initial Investment minimum: $${row.minimum}`}</Description>
          </>
        )}
      </AnimatePresence>
    </ModalWrappedContent>
  );
};

export default EditAmount;

const Description = styled.span`
  padding-top: 10px;
  font-weight: 300;
  font-size: 13px;
  line-height: 18px;
  color: ${({ theme }) => theme.font.weak};
`;

const ModalBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;
  margin: 30px 0;
`;
