import { MinusScoreIcon } from '@/assets/icons/minusScore';
import { getUTCDate } from '@/components/allocations-page/utils';
import BasicTooltip from '@/components/basicComponents/tooltip';
import Button from '@/components/fat-basicComponents/button';
import NoResults from '@/components/table/noResults';
import RedirectTitleCell from '@/components/table/redirectTitleCell';
import { TableRowCell, TableRowWrapper, TextCell } from '@/components/table/styles';
import { formatCash } from '@/utils/formatCash';
import styled, { useTheme } from 'styled-components';
import { seekingInterestTableColumns } from '../constants';
import { IInvestorInterestList, TypeWindow } from '../types';

interface OpenOpportunitiesTableBodyProps {
  investorInterestsData: IInvestorInterestList[];
  openPage: (id: string, type: TypeWindow) => void;
  openModalWindow: (type: string, row: IInvestorInterestList) => void;
}

const TableBody = ({ investorInterestsData, openPage, openModalWindow }: OpenOpportunitiesTableBodyProps) => {
  const theme = useTheme();

  if (!investorInterestsData?.length) {
    return <NoResults />;
  }

  return (
    <>
      {investorInterestsData.map((row) => (
        <TableRowWrapper key={row.investment.id}>
          <TableRowCell width={seekingInterestTableColumns[0].width}>
            <RedirectTitleCell id="actionCurioText" onClick={() => openPage(row.investment.id, 'opportunityDetails')}>
              {row.investment.name}
            </RedirectTitleCell>
          </TableRowCell>
          <TableRowCell width={seekingInterestTableColumns[1].width}>
            <TextCell>
              {row.investment.subAssetClass
                ? `${row.investment.assetClass.name} - ${row.investment.subAssetClass.name}`
                : row.investment.assetClass.name}
            </TextCell>
          </TableRowCell>
          <TableRowCell width={seekingInterestTableColumns[2].width}>
            <TextCell>
              {row.investment?.subAssetClasses?.length > 1 ? (
                <BasicTooltip tooltipContent={row.investment.subAssetClasses.toString().replace(/,/g, ', ')}>
                  Multiple ({row.investment.subAssetClasses.length})
                </BasicTooltip>
              ) : (
                row.investment?.subAssetClasses && row.investment?.subAssetClasses[0]
              )}
            </TextCell>
          </TableRowCell>
          <TableRowCell width={seekingInterestTableColumns[3].width}>
            <TextCell>{row.investment.type}</TextCell>
          </TableRowCell>
          <TableRowCell width={seekingInterestTableColumns[4].width}>
            <TextCell>{row.investment.investorDocsDue && getUTCDate(new Date(row.investment.investorDocsDue))}</TextCell>
          </TableRowCell>
          <TableRowCell width={seekingInterestTableColumns[5].width}>
            <TextCell>{row.investorInterests.length ? formatCash(row.totalInvestmentAmount) : <></>}</TextCell>
          </TableRowCell>
          <TableRowCell width={seekingInterestTableColumns[6].width}>
            {row.investment.securityStatus === 'Approved' && (
              <ButtonWrap id="infoWrap">
                {!row.investorInterests.length && !row.totalInvestmentAmount ? (
                  <Button size="sm" onClick={() => openModalWindow('add-to-watchlist', row)}>
                    Add To Watchlist
                  </Button>
                ) : (
                  <Button size="sm" onClick={() => openModalWindow('edit-watchlist', row)}>
                    Edit Watchlist
                  </Button>
                )}
              </ButtonWrap>
            )}
          </TableRowCell>
          <TableRowCell width={seekingInterestTableColumns[7].width}>
            {row.investment.securityStatus !== 'Approved' && (
              <TooltipWrapper>
                <BasicTooltip tooltipContent="Passed On" zIndex={1000}>
                  <MinusScoreIcon width={29} height={29} fill={theme.font.disabled} />
                </BasicTooltip>
              </TooltipWrapper>
            )}
          </TableRowCell>
        </TableRowWrapper>
      ))}
    </>
  );
};

export default TableBody;

const ButtonWrap = styled.div`
  opacity: 0;
`;

const TooltipWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  top: 3px;
`;
