import { EditIcon } from '@/assets/icons/edit';
import { getUTCDate } from '@/components/allocations-page/utils';
import ShowText from '@/components/dueDiligence-page/dueDiligenceRecord/showText';
import { isImageExtension } from '@/components/dueDiligence-page/dueDiligenceRecord/utils';
import Button from '@/components/fat-basicComponents/button';
import FileUpload from '@/components/fat-basicComponents/fileUpload';
import { IOfferingMaterialsAttachments } from '@/components/fat-dealCatalog-page/types';
import { IDocumentRecordFieldEntity } from '@/components/managerUpdates-page/managerUpdateDetails/types';
import { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { DescriptionZeroState } from './zeroState';

const formattedFieldValue = (field: IDocumentRecordFieldEntity) => {
  switch (field.documentField.type) {
    case 'percentage':
      return `${parseFloat(field.value || '0')?.toFixed(2)}%`;
    case 'date':
      return getUTCDate(new Date(field.value)).toUpperCase();
    case 'number':
      return field.value;
    default:
      return field.value;
  }
};

interface DescriptionSectionBodyProps {
  data: IDocumentRecordFieldEntity[];
  uploadedFiles: IOfferingMaterialsAttachments[] | [];
  type: 'opportunityDetails' | 'modifyStrategy';
  onEditClick?: () => void;
}

export const DescriptionSectionBody = ({ data, type, uploadedFiles, onEditClick }: DescriptionSectionBodyProps) => {
  const theme = useTheme();

  const [showZeroState, setShowZeroState] = useState(false);

  useEffect(() => {
    if (!data) return;

    if (type === 'modifyStrategy') {
      const hasFieldsValues = data?.some((field) => field.value);
      setShowZeroState(!hasFieldsValues && !uploadedFiles.length);
      return;
    }

    const hasFieldsValues = data?.slice(1)?.some((field) => field.value);
    setShowZeroState(!hasFieldsValues && !uploadedFiles.length);
  }, [data]);

  const openAttachment = async (url: string) => {
    window.open(url, '_blank');
  };

  if (showZeroState) {
    return <DescriptionZeroState addContent={onEditClick} />;
  }

  return (
    <DescriptionSectionWrapper>
      {onEditClick && (
        <EditIconWrap onClick={onEditClick}>
          <EditButton>
            <EditIcon fill={theme.font.action} />
            Edit
          </EditButton>
        </EditIconWrap>
      )}
      {!!data && type === 'modifyStrategy' && (data?.[0]?.value || data?.[0]?.commentary) && (
        <SummaryWrapper>
          <DescriptionSubTitle>{data?.[0]?.documentField?.name}</DescriptionSubTitle>
          <SummaryDivider />
          <DescriptionText>{data?.[0]?.value ? data[0].value : '--'}</DescriptionText>
        </SummaryWrapper>
      )}
      {!!data && data.slice(1).some((item) => item?.value || item?.commentary) && (
        <>
          <DescriptionWrapper>
            <Divider />
            <Description>
              <DescriptionSubTitle>{data?.[1]?.documentField?.name}</DescriptionSubTitle>
              <SummaryDivider />
              <DescriptionText>
                <ShowText>{data?.[1]?.value ? data?.[1].value : '--'}</ShowText>
              </DescriptionText>
            </Description>
          </DescriptionWrapper>
          <KeyTermsWrapper>
            <Divider />
            <KeyTerms>
              <KeyTermsItemsWrapper>
                {data?.slice(2)?.map((field) => (
                  <KeyTermsItem key={field?.id}>
                    <ItemKey>{field?.documentField?.name}</ItemKey>
                    <ItemValue>{field?.value ? formattedFieldValue(field) : '--'}</ItemValue>
                  </KeyTermsItem>
                ))}
              </KeyTermsItemsWrapper>
            </KeyTerms>
          </KeyTermsWrapper>
        </>
      )}
      {uploadedFiles?.length ? (
        <>
          <PreviewWrapper>
            {uploadedFiles.map((item) => (
              <FileUpload
                key={item.asset.assetKey}
                type={isImageExtension(item.asset.assetKey) ? 'image' : 'document'}
                imageSrc={item.asset.url}
                fileName={item.asset.assetKey.split('/').pop()}
                openFile={() => openAttachment(item.asset.url)}
              />
            ))}
          </PreviewWrapper>
          {type === 'opportunityDetails' && (
            <ForwardDealDocumentsButtonWrapper>
              <Button styleType="outline" size="md">
                Forward Deal Documents
              </Button>
            </ForwardDealDocumentsButtonWrapper>
          )}
        </>
      ) : (
        <></>
      )}
    </DescriptionSectionWrapper>
  );
};

const EditIconWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px 0 20px;
  color: ${({ theme }) => theme.font.action};
`;

const EditButton = styled.div`
  display: flex;
  gap: 4px;
  cursor: pointer;
`;

const DescriptionSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const SummaryWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px 40px;
  background: ${({ theme }) => theme.layer.base};
`;

const SummaryDivider = styled.div`
  width: 1px;
  align-self: stretch;
  background: ${({ theme }) => theme.border.base};
`;

const DescriptionSubTitle = styled.p`
  font-family: Lato;
  font-size: 23px;
  line-height: 24px;
  font-weight: 700;
  line-height: 22.4px;
  color: ${({ theme }) => theme.font.strong};
`;

const DescriptionText = styled.div`
  font-family: Blinker;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  color: ${({ theme }) => theme.font.base};

  #shortTextRowCell {
    max-width: 100%;
  }
`;

const DescriptionWrapper = styled.div`
  display: flex;
  padding: 20px 20px 20px 0;
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  gap: 10px;
`;

const Divider = styled.div`
  min-width: 5px;
  padding: 40px 0px;
  margin-left: -5px;
  background: ${({ theme }) => theme.action.active};
`;

const KeyTermsWrapper = styled.div`
  display: flex;
  padding: 20px 20px 20px 0;
`;

const KeyTerms = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-left: 20px;
  width: 100%;
`;

const KeyTermsItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const KeyTermsItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-radius: 4px;
  background: ${({ theme }) => theme.layer.base};
  padding: 10px 20px;
`;

const ItemKey = styled.div`
  color: ${({ theme }) => theme.font.base};
  font-family: Blinker;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
`;

const ItemValue = styled(ItemKey)`
  font-weight: 600;
`;

const PreviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ForwardDealDocumentsButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
`;
