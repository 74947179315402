import { InfoIcon } from '@/assets/icons/info-icons/info';
import { Banner } from '@/components/basicComponents/banner';
import RadioButton from '@/components/basicComponents/radioButton';
import Button from '@/components/fat-basicComponents/button';
import Select from '@/components/fat-basicComponents/select';
import { useResponsive } from '@/hooks/use-responsive';
import { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { ThreeColumnWrapper } from '../styles';
import { IRenderTemplateRow } from '../types';

interface ContactsComponentProps {
  control: any;
  row: IRenderTemplateRow;
  ignoreRequired: boolean;
}

export const ContactsComponent = ({ control, row, ignoreRequired }: ContactsComponentProps) => {
  const { isMobile } = useResponsive();
  const theme = useTheme();

  const [radioButtonValue, setRadioButtonValue] = useState('');

  return (
    <>
      <ThreeColumnWrapper isMobile={isMobile}>
        <RadioButtonsWrapper>
          {['Enter Contact Info', 'Attach A File'].map((item, index) => (
            <RadioButton
              key={item + index}
              name={row.name}
              radioButtonText={item}
              selectedOption={radioButtonValue}
              handleRadioChange={(event) => setRadioButtonValue(event.target.value)}
            />
          ))}
        </RadioButtonsWrapper>
      </ThreeColumnWrapper>
      <ThreeColumnWrapper isMobile={isMobile}>
        {radioButtonValue === 'Enter Contact Info' && (
          <Select
            label="Contact"
            value={''}
            setValue={() => {}}
            options={[{ label: 'Select', value: '' }]}
            size="md"
            additionalOption={
              <AddButtonWrapper>
                <Button size="sm" styleType="outline" onClick={() => {}}>
                  Add New Contact
                </Button>
              </AddButtonWrapper>
            }
          />
        )}
        {radioButtonValue === 'Attach A File' && (
          <Banner
            title=""
            description="Upload a file with the required information during the Attachments step."
            icon={<InfoIcon width={26} height={26} fill={theme.header.base} />}
            bgColor={theme.layer.base}
            color={theme.font.base}
          />
        )}
      </ThreeColumnWrapper>
    </>
  );
};

const RadioButtonsWrapper = styled.div`
  display: flex;
`;

const AddButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
