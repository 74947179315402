import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import styled, { useTheme } from 'styled-components';

import { ExportDataIcon } from '@/assets/icons/exportDataIcon';
import { FilterIcon } from '@/assets/icons/filter';
import { FilterSettingIcon } from '@/assets/icons/filterSetting';
import { RefreshIcon } from '@/assets/icons/info-icons/refresh';
import { SelectIcon } from '@/assets/icons/selectIcon';
import FilterBar, { IFilterBarData } from '@/components/basicComponents/filterBar';
import Search from '@/components/fat-basicComponents/search';
import FilterSearch from '../../basicComponents/input';
import FilterSelect from '../../basicComponents/select';
import SelectWithCheckBox, { TData } from '../../basicComponents/selectWithCheckBox';
import ChosenFilters from './chosenFilters';
import {
  FilterBarWrap,
  FilterSettingWrap,
  QuickFilterSelect,
  QuickFilterTitle,
  QuickFilterWrapper,
  RefreshButton,
  Results,
  ViewBySelectSelect,
  ViewByText
} from './desktop-filter';
import MoreFilters from './moreFilters';
import { AdditionalFilters, IQuickFilter } from './types';

interface FilterPageProps {
  search: {
    value: string;
    onChange: any;
    placeholder: string;
  };
  primaryFilter?: {
    value: string;
    onChange: (value: string) => void;
    selects: string[];
    withSearch?: boolean;
  };
  withCheckBox?: boolean;
  additionalFilters?: AdditionalFilters;
  withExportData?: boolean;
  setAllocationStatusFilter?: any;
  allocationStatusFilter?: TData[];
  currentTab?: { value: string };
  className?: string;
  isResult?: boolean;
  resultsValue?: number;
  refetch?: any;
  setIsMoreFiltersOpen?: Dispatch<SetStateAction<boolean>>;
  handleDownload: () => void;
  tableColumnControl?: () => void;
  filterBar?: IFilterBarData[];
  onChangeFilterBar?: (filterBarItems: IFilterBarData[]) => void;
  mobileSort?: {
    value: string;
    onChange: (value: string) => void;
    selects: string[];
  };
  viewBySelect?: {
    value: string;
    onChange: (value: string) => void;
    selects: string[];
  };
  useNewFilters?: boolean;
  advisoryFirmQuickFilter?: IQuickFilter;
  viewByQuickFilter?: IQuickFilter;
  quickFilter?: IQuickFilter;
}

const PortraitFilterPage = ({
  search,
  primaryFilter,
  additionalFilters,
  withExportData = false,
  withCheckBox,
  setAllocationStatusFilter,
  allocationStatusFilter,
  currentTab,
  className,
  isResult,
  resultsValue,
  refetch,
  setIsMoreFiltersOpen,
  handleDownload,
  tableColumnControl,
  filterBar,
  onChangeFilterBar,
  mobileSort,
  viewBySelect,
  useNewFilters = false,
  advisoryFirmQuickFilter,
  viewByQuickFilter,
  quickFilter
}: FilterPageProps) => {
  const theme = useTheme();

  const [moreFilter, setMoreFilter] = useState(false);
  const [chosenFilters, setChosenFilters] = useState(!!additionalFilters?.showChosenFilters);
  const [checkBoxData, setCheckBoxData] = useState<TData[]>(allocationStatusFilter ?? []);
  useEffect(() => {
    if (additionalFilters?.filters[0].typeFilter === 'Select A Filter Option' && currentTab) {
      setChosenFilters(false);
    }
  }, [currentTab?.value, additionalFilters]);

  useEffect(() => {
    if (additionalFilters?.filters[0].typeFilter !== 'Select A Filter Option') {
      setChosenFilters(true);
    }
  }, []);

  const checkBoxSelectLabel = useMemo(() => {
    const choosenNames = checkBoxData.filter((el) => el.value);
    let label = '';
    if (choosenNames.length >= 1 && choosenNames.length <= 2) {
      choosenNames.map((el, index) => {
        if (index === 0) {
          label = el.name;
        } else {
          label = label + ', ' + el.name;
        }
      });
      return label;
    } else {
      return choosenNames.length === 0 ? 'Filter by' : choosenNames.length + ' ' + 'Selected';
    }
  }, [checkBoxData]);

  const handleCheckBoxData = (id: number) => {
    const newData = [...checkBoxData];
    newData[id].value = !newData[id].value;
    setCheckBoxData(newData);
  };

  const openMoreFilters = () => {
    setMoreFilter(!moreFilter);
    if (setIsMoreFiltersOpen) {
      setIsMoreFiltersOpen((prev) => !prev);
    }
  };

  const closeMoreFilters = () => {
    setMoreFilter(false);
    if (setIsMoreFiltersOpen) {
      setIsMoreFiltersOpen(false);
    }
  };

  if (useNewFilters) {
    return (
      <>
        <TableToolbar>
          <SearchWrapper>
            <Search value={search.value} onChange={search.onChange} placeholder={search.placeholder} width="100%" />
            {additionalFilters && (
              <FilterWrap onClick={openMoreFilters} moreFilter={moreFilter}>
                <FilterIcon fill={moreFilter ? theme.context.light : theme.font.action} />
              </FilterWrap>
            )}
          </SearchWrapper>
          <FiltersWrapper>
            {viewByQuickFilter && (
              <QuickFilterWrapper>
                <QuickFilterTitle>{viewByQuickFilter.title}</QuickFilterTitle>
                <QuickFilterSelect
                  value={viewByQuickFilter.value}
                  setValue={viewByQuickFilter.onChange}
                  defaultSelectLabel={viewByQuickFilter.defaultValue}
                  options={viewByQuickFilter.options}
                  size="sm"
                  customArrowIcon={<SelectIcon fill={theme.font.action} />}
                />
              </QuickFilterWrapper>
            )}
            {advisoryFirmQuickFilter && (
              <QuickFilterWrapper>
                <QuickFilterTitle>{advisoryFirmQuickFilter.title}</QuickFilterTitle>
                <QuickFilterSelect
                  value={advisoryFirmQuickFilter.value}
                  setValue={advisoryFirmQuickFilter.onChange}
                  defaultSelectLabel={advisoryFirmQuickFilter.defaultValue}
                  options={advisoryFirmQuickFilter.options}
                  size="sm"
                  customArrowIcon={<SelectIcon fill={theme.font.action} />}
                />
              </QuickFilterWrapper>
            )}
            {quickFilter && (
              <QuickFilterWrapper>
                <QuickFilterTitle>{quickFilter.title}</QuickFilterTitle>
                <QuickFilterSelect
                  value={quickFilter.value}
                  setValue={quickFilter.onChange}
                  defaultSelectLabel={quickFilter.defaultValue}
                  options={quickFilter.options}
                  size="sm"
                  customArrowIcon={<SelectIcon fill={theme.font.action} />}
                />
              </QuickFilterWrapper>
            )}
          </FiltersWrapper>
        </TableToolbar>
        {additionalFilters && moreFilter && <MoreFilters onClose={closeMoreFilters} setChosenFilters={setChosenFilters} {...additionalFilters} />}
        {additionalFilters && chosenFilters && <ChosenFilters setChosenFilters={setChosenFilters} {...additionalFilters} />}
        {filterBar && (
          <FilterBarWrap>
            <FilterBar filterBar={filterBar} onChangeFilterBar={onChangeFilterBar} />
          </FilterBarWrap>
        )}
        {mobileSort && (
          <>
            <FilterSelect
              isMobile
              label={'Sort By'}
              paddingContainer={'0 20px'}
              width={'100%'}
              data={mobileSort.selects}
              selected={mobileSort.value}
              setSelected={mobileSort.onChange}
            />
            <TopWrapper>
              <Results>{additionalFilters?.amountResults || resultsValue || 0} Results</Results>
              <RefreshButton
                onClick={() => {
                  if (additionalFilters?.onRefetch) {
                    additionalFilters.onRefetch();
                  } else if (refetch) {
                    refetch();
                  } else null;
                }}
              >
                <RefreshIcon fill={theme.action.primary} />
              </RefreshButton>
              {viewBySelect && (
                <ViewByWrapper>
                  <ViewByText>View By</ViewByText>
                  <ViewBySelectSelect width={'auto'} selected={viewBySelect.value} setSelected={viewBySelect.onChange} data={viewBySelect.selects} />
                </ViewByWrapper>
              )}
            </TopWrapper>
          </>
        )}
      </>
    );
  }

  return (
    <>
      <DefaultSearchWrap>
        <MainSearchWrap>
          {withExportData && (
            <ExportDataWrap onClick={handleDownload}>
              <ExportDataIcon fill={theme.font.action} />
              <ExportDataTitle>Export Data</ExportDataTitle>
            </ExportDataWrap>
          )}
          <SearchAdditionalWrap isCheckBoxPrimaryFilter={Boolean((currentTab?.value === 'pending' && withCheckBox) || primaryFilter)}>
            <CustomFilterSearch
              value={search.value}
              onChange={search.onChange}
              placeholder={search.placeholder}
              width={additionalFilters ? '96%' : '100%'}
            />
            {additionalFilters && (
              <FilterWrap onClick={openMoreFilters} moreFilter={moreFilter}>
                <FilterIcon fill={moreFilter ? theme.context.light : theme.font.action} />
              </FilterWrap>
            )}
            {tableColumnControl && (
              <FilterSettingWrap onClick={tableColumnControl}>
                <FilterSettingIcon fill={theme.font.action} />
              </FilterSettingWrap>
            )}
          </SearchAdditionalWrap>
          {primaryFilter && (
            <FilterSelect
              width={'100%'}
              data={primaryFilter.selects}
              selected={primaryFilter.value}
              setSelected={primaryFilter.onChange}
              withSearch={!!primaryFilter?.withSearch}
              longTitle
            />
          )}
          {currentTab?.value === 'pending' && withCheckBox && (
            <SelectWithCheckBox
              width={'100%'}
              data={checkBoxData}
              setSelected={handleCheckBoxData}
              label={checkBoxSelectLabel}
              setAllocationStatusFilter={setAllocationStatusFilter}
              setCheckBoxData={setCheckBoxData}
            />
          )}
        </MainSearchWrap>
      </DefaultSearchWrap>
      {additionalFilters && moreFilter && <MoreFilters onClose={closeMoreFilters} setChosenFilters={setChosenFilters} {...additionalFilters} />}
      {additionalFilters && chosenFilters && <ChosenFilters setChosenFilters={setChosenFilters} {...additionalFilters} />}
      {filterBar && (
        <FilterBarWrap>
          <FilterBar filterBar={filterBar} onChangeFilterBar={onChangeFilterBar} />
        </FilterBarWrap>
      )}
      {mobileSort && (
        <>
          <FilterSelect
            isMobile
            label={'Sort By'}
            paddingContainer={'0 20px'}
            width={'100%'}
            data={mobileSort.selects}
            selected={mobileSort.value}
            setSelected={mobileSort.onChange}
          />
          <TopWrapper>
            <Results>{additionalFilters?.amountResults || resultsValue || 0} Results</Results>
            <RefreshButton
              onClick={() => {
                if (additionalFilters?.onRefetch) {
                  additionalFilters.onRefetch();
                } else if (refetch) {
                  refetch();
                } else null;
              }}
            >
              <RefreshIcon fill={theme.action.primary} />
            </RefreshButton>
            {viewBySelect && (
              <ViewByWrapper>
                <ViewByText>View By</ViewByText>
                <ViewBySelectSelect width={'auto'} selected={viewBySelect.value} setSelected={viewBySelect.onChange} data={viewBySelect.selects} />
              </ViewByWrapper>
            )}
          </TopWrapper>
        </>
      )}
    </>
  );
};

const SearchAdditionalWrap = styled.div<{ isCheckBoxPrimaryFilter: boolean }>`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: ${({ isCheckBoxPrimaryFilter }) => (isCheckBoxPrimaryFilter ? '10px' : '0')};
`;

const ExportDataTitle = styled.div`
  font-family: Blinker, serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  color: ${({ theme }) => theme.font.action};
`;

const ExportDataWrap = styled.div`
  display: flex;
  cursor: pointer;
  gap: 6px;
  justify-content: center;
  align-items: center;
`;

const FilterWrap = styled.div<{ moreFilter: boolean }>`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ moreFilter, theme }) => (moreFilter ? theme.action.active : theme.layer.base)};
  border: ${({ moreFilter, theme }) => `1px solid ${moreFilter ? theme.action.active : theme.font.action}`};
  border-radius: 3px;
  padding: 10px;
`;

const DefaultSearchWrap = styled.div`
  //width: 100%;
  //display: flex;
  //align-items: flex-end;
  //justify-content: flex-end;
  //gap: 10px;
`;

const MainSearchWrap = styled.div`
  padding: 8px 20px;
`;

const TopWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  margin-top: 10px;
`;

const CustomFilterSearch = styled(FilterSearch)`
  width: 100% !important;
  font-family: Blinker;
  font-size: 13px;
  font-weight: 300;
  line-height: 18.2px;
`;

export const ViewByWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px 5px 30px;
  gap: 6px;
`;

const TableToolbar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const FiltersWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  padding: 0 20px;
`;

export default PortraitFilterPage;
