import { CloseInfoIcon } from '@/assets/icons/info-icons/closeInfo';
import RadioButton from '@/components/basicComponents/radioButton';
import FilterSelect from '@/components/basicComponents/select';
import Input from '@/components/fat-basicComponents/input';
import { formatDollarInputValue, formatMaskedDollarInputValue } from '@/components/fat-basicComponents/input/utils';
import Label from '@/components/fat-basicComponents/label';
import { Section } from '@/components/fat-investors-page/fat-investorDetails-page/fat-entityDetails-page/edit-entity/section';
import { blinkingFrame } from '@/components/table/styles';
import { useResponsive } from '@/hooks/use-responsive';
import { Dispatch, SetStateAction } from 'react';
import { Control, Controller } from 'react-hook-form';
import styled, { useTheme } from 'styled-components';
import { AddButton } from '../../addButton';
import { yseNoOptions } from '../../constatnts';
import { IForm, IListData } from '../../types';
import { addStrategies, delStrategies, onChangeStrategies } from '../../utils';

interface AllocationSettingsSectionProps {
  strategies: IListData;
  setStrategies: Dispatch<SetStateAction<IListData>>;
  tags: IListData;
  setTags: Dispatch<SetStateAction<IListData>>;
  control: Control<IForm, any>;
  openModalWindow: (type: string) => void;
}

export const AllocationSettingsSection = ({ strategies, setStrategies, tags, setTags, control, openModalWindow }: AllocationSettingsSectionProps) => {
  const { isMobile } = useResponsive();
  const theme = useTheme();

  return (
    <Section title="Allocation Settings" collapsable>
      <AllocationSettingsSectionWrap>
        <Line>
          <Controller
            name="minimum"
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Minimum is required'
              }
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <div style={{ width: '100%' }}>
                <Input
                  value={value ? formatMaskedDollarInputValue(value) : ''}
                  onChange={(e) => {
                    const formattedValue = formatDollarInputValue(e.target.value);
                    onChange(formattedValue);
                  }}
                  label="Minimum Investor Commitment"
                  errorText={error?.message}
                  withDollarSign
                  styleType="form"
                  size="md"
                  isRequiredField
                  tooltipWidth={300}
                  tooltip="Provide the investment minimum. Entities with insufficient capacity will be flagged based on this value."
                />
              </div>
            )}
          />
          <CustomSelectWrapper>
            <Label size="md" required>
              Bite Size Type
            </Label>
            <Controller
              name="type"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Bite Size Type is required'
                },
                pattern: {
                  value: /^(?!Select$)/,
                  message: 'Bite Size Type is required'
                }
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <CustomSelect data={['Restricted Alternative', 'Illiquid Alternative']} selected={value} setSelected={onChange} width="100%" />
                  {error?.message && <ErrorText>{error.message}</ErrorText>}
                </>
              )}
            />
          </CustomSelectWrapper>
        </Line>
        <Line>
          <div>
            <Label size="md">Custodian</Label>
            <Controller
              name="custodied"
              control={control}
              render={({ field: { value, onChange } }) => (
                <TogglesWrap isMobile={isMobile}>
                  {yseNoOptions.map((option, index) => (
                    <RadioButton
                      key={option + index}
                      name="custodied"
                      radioButtonText={option}
                      selectedOption={value ? yseNoOptions[0] : yseNoOptions[1]}
                      handleRadioChange={(event) => onChange(event.target.value === yseNoOptions[0])}
                    />
                  ))}
                </TogglesWrap>
              )}
            />
          </div>
        </Line>
        <Line>
          <StrategiesWrap>
            <Label tooltip={'Used for categorization and automated allocation rules'} tooltipWidth={250} size="md">
              Sectors
            </Label>
            {strategies.chosen.map((el, index) => (
              <StrategiesItemWrap key={`strategies-${index}`} loading={index === strategies.chosen.length - 1 && strategies.duplicate ? 1 : 0}>
                <CustomSelect
                  data={strategies.list}
                  selected={strategies.chosen[index]}
                  setSelected={(value) => onChangeStrategies(value, index, strategies, setStrategies)}
                  disabled={
                    strategies.chosen[strategies.chosen.length - 1] !== el || strategies.chosen[strategies.chosen.length - 1] === strategies.duplicate
                  }
                  width="100%"
                  withSearch
                  fontSize="16px"
                />
                <DelItemWrap onClick={() => delStrategies(el, index, strategies, setStrategies)}>
                  <CloseInfoIcon width={21} height={20} fill={theme.font.action} />
                </DelItemWrap>
              </StrategiesItemWrap>
            ))}
            {strategies.list.length && strategies.chosen[strategies.chosen.length - 1] !== 'None' ? (
              <AddButton text="Add" onClick={() => addStrategies(strategies, setStrategies)} />
            ) : (
              <AddButton text="Create New" onClick={() => openModalWindow('create-strategy')} />
            )}
          </StrategiesWrap>
          <StrategiesWrap>
            <Label tooltip={'Used for categorization (informational field only)'} size="md">
              Focus
            </Label>
            {tags.chosen.map((el, index) => (
              <StrategiesItemWrap key={`tags-${index}`} loading={index === tags.chosen.length - 1 && tags.duplicate ? 1 : 0}>
                <CustomSelect
                  data={tags.list}
                  selected={tags.chosen[index]}
                  setSelected={(value) => onChangeStrategies(value, index, tags, setTags)}
                  disabled={tags.chosen[tags.chosen.length - 1] !== el || tags.chosen[tags.chosen.length - 1] === tags.duplicate}
                  width="100%"
                  withSearch
                  fontSize="16px"
                />
                <DelItemWrap onClick={() => delStrategies(el, index, tags, setTags)}>
                  <CloseInfoIcon width={21} height={20} fill={theme.font.action} />
                </DelItemWrap>
              </StrategiesItemWrap>
            ))}
            {tags.list.length && tags.chosen[tags.chosen.length - 1] !== 'None' ? (
              <AddButton text="Add" onClick={() => addStrategies(tags, setTags)} />
            ) : (
              <AddButton text="Create New" onClick={() => openModalWindow('create-tag')} />
            )}
          </StrategiesWrap>
        </Line>
      </AllocationSettingsSectionWrap>
    </Section>
  );
};

const AllocationSettingsSectionWrap = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
`;

const Space = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const Line = styled.span<{ width50?: boolean }>`
  display: flex;
  justify-content: space-between;
  width: ${({ width50 }) => (width50 ? '50%' : '100%')};
  gap: 30px;
  padding-right: ${({ width50 }) => (width50 ? '15px' : '0')};
  margin-top: 4px;
`;

const CustomSelectWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const CustomSelect = styled(FilterSelect)`
  background: ${({ disabled, theme }) => (disabled ? theme.action.disabled : theme.layer[2])};
  border: 1px solid ${({ theme }) => theme.border.base};
  padding: 10px;
  border-radius: 5px;

  #titleSelect {
    font-family: Blinker;
    text-transform: none;
    font-size: 16px;
    line-height: 22.4px;
  }

  #dropDownSelect {
    top: 50px;
  }
`;

const ErrorText = styled.p`
  color: ${({ theme }) => theme.context.error};
  font-family: Blinker;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22.4px;
`;

const TogglesWrap = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  gap: ${({ isMobile }) => (isMobile ? '15px' : '0')};
`;

const StrategiesWrap = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const StrategiesItemWrap = styled.div<{ loading?: boolean | number }>`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 10px;
  position: relative;

  opacity: ${({ loading }) => (loading ? 0.5 : 1)};
  pointer-events: ${({ loading }) => (loading ? 'none' : 'auto')};
  animation: ${({ loading }) => (loading ? blinkingFrame : null)} 1.5s infinite;
`;

const DelItemWrap = styled.div`
  position: relative;
  top: 3px;
  padding-left: 15px;
  cursor: pointer;
`;
