import { Loader } from '@/components/loaders/loader';
import { OPERATIONS_STATUS } from '@/components/opportunities-entity/proposed';
import TableHeader from '@/components/table/fat-tableHeader';
import { FiltersStructure } from '@/components/table/filterPage/types';
import { TableBodyWrapper, TableWrapper } from '@/components/table/styles';
import TablePagination from '@/components/table/tablePagination';
import { IItems, ISort, SORT_ORDER } from '@/components/table/types';
import { buildAbilityFor } from '@/config/ability';
import { useAuth } from '@/hooks/use-auth';
import { useResponsive } from '@/hooks/use-responsive';
import { Allocation } from '@/types/investments';
import { User } from '@/types/user';
import guid from '@/utils/guid';
import { useLazyQuery, useMutation, useQuery, useSubscription } from '@apollo/client';
import { subject } from '@casl/ability';
import lodash from 'lodash';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { columns } from '../constatnts';
import AssignTask from '../fat-modals/assignTask';
import Complete from '../fat-modals/complete';
import EditAmount from '../fat-modals/editAmount';
import EditOwner from '../fat-modals/editOwner';
import SendForSignature from '../fat-modals/sendForSignature';
import Terminate from '../fat-modals/terminate';
import { ModalWindowType } from '../fat-modals/types';
import {
  ALLOCATION,
  ALLOCATION_SUBSCRIPTION,
  RECOMMENDATION_QUERY,
  updateAllocationAmountMutation,
  updateAllocationAssigneeMutation,
  updateAllocationMutation,
  updateAllocationOperationsStatusMutation,
  updateAllocationOwnersMutation
} from '../queries';
import { ACTIONS, IOperationsWorkflowTransitionTask, IPaginationData, IRowData, ModalWindow, RecommendationsQuery } from '../types';
import { TableBody } from './tableBody';
import MobileTable from './tableMobile';

export type TableProps = {
  search: string;
  assigneeFilter: string;
  additionalFiltersData: FiltersStructure | any;
  saveFiltrationResults: (results: number, refetch: () => void) => void;
  openEntityDetails: (investorId: string, entityId: string) => void;
  openInvestorDetails: (id: string) => void;
  openOpportunityDetailsPage: (id: string) => void;
  sort: ISort;
  setSort: (value: ISort) => void;
  paginationData: IPaginationData;
  onChangePaginationData: (limit: number, offset: number) => void;
  setAmountResult?: any;
  onClickEntity: (data: IRowData) => void;
  onClickInvestment: (data: IRowData) => void;
  onClickLastUpdate: (data: IRowData) => void;
  statuses: IOperationsWorkflowTransitionTask[];
  makeScroll: () => void;
};

const Table = ({
  search,
  saveFiltrationResults,
  assigneeFilter,
  additionalFiltersData,
  openEntityDetails,
  openInvestorDetails,
  openOpportunityDetailsPage,
  sort,
  setSort,
  paginationData,
  onChangePaginationData,
  setAmountResult,
  onClickEntity,
  onClickInvestment,
  onClickLastUpdate,
  statuses,
  makeScroll
}: TableProps) => {
  const { isAuthenticated, user, setUserTasks } = useAuth();

  const ability = buildAbilityFor(useAuth().user as User);
  const [allocations, setAllocations] = useState<IRowData[]>([]);
  const [modalWindow, setModalWindow] = useState<ModalWindow>({
    isOpen: false,
    type: 'assign-task'
  });
  const [currentRow, setCurrentRow] = useState<IRowData | null>(null);
  const { isMobile, isTablet } = useResponsive();
  const variables = {
    assigneeFilter: {
      userId: isAuthenticated ? user?.id : '',
      selected: assigneeFilter
    },
    additionalAdvisorFilters: additionalFiltersData.advisors,
    additionalStepFilters: additionalFiltersData.nextTask,
    additionalInvestmentFilters: additionalFiltersData.investments,
    order: sort.asc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
    sort: sort.key,
    search: search.toLowerCase().trim()
  };
  const {
    data,
    refetch,
    networkStatus,
    loading: recommendationLoading
  } = useQuery<RecommendationsQuery>(RECOMMENDATION_QUERY, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: {
      limit: paginationData.limit,
      offset: paginationData.offset,
      includeProposedInvestments: true,
      ...variables
    },
    onCompleted: (data) => {
      setAllocations(
        data.RecommendedAllocations.allocations
          .filter((allocation) => allocation.allocationStatus === 'Outstanding')
          .map((item) => {
            return {
              ...item,
              isAmountHidden:
                item.operationsStatus === (OPERATIONS_STATUS.READY_FOR_CLIENT_REVIEW || OPERATIONS_STATUS.SENT_TO_CLIENT) &&
                item.reason === 'Always Show'
            };
          })
      );
      setAmountResult(data.RecommendedAllocations.total);
    }
  });

  useEffect(() => {
    if (!allocations.length) return;
    makeScroll();
  }, [allocations]);

  useEffect(() => {
    if (data) {
      const filteredData = data.RecommendedAllocations.allocations.filter((allocation) => allocation.allocationStatus === 'Outstanding');
      saveFiltrationResults(filteredData.length, () => refetch(variables));
    }
  }, [data]);

  const [updateAllocations, { data: updateAllocationsData, loading: updateLoading }] = useMutation(updateAllocationMutation, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      data.updateAllocations.forEach((allocation: Allocation) => updateLocalAllocation(allocation));
    }
  });

  const [updateOperationsStatus, { data: updateOperationsStatusData, loading: updateOperationsStatusLoading }] = useMutation(
    updateAllocationOperationsStatusMutation,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        updateLocalAllocation(data.updateAllocationOperationsStatusWorkflowTransitionTask);
      }
    }
  );

  const [updateAllocationAmount, { data: updateAllocationAmountData, loading: updateAllocationAmountLoading }] = useMutation(
    updateAllocationAmountMutation,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        updateLocalAllocation(data.updateAllocationAmount);
      }
    }
  );

  const [updateAllocationAssignee, { data: updateAllocationAssigneeData, loading: updateAllocationAssigneeLoading }] = useMutation(
    updateAllocationAssigneeMutation,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        updateLocalAllocation(data.updateAllocationAssignee);
      }
    }
  );

  const [updateAllocationOwners, { data: updateAllocationOwnersData, loading: updateAllocationOwnersLoading }] = useMutation(
    updateAllocationOwnersMutation,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        updateLocalAllocation(data.updateAllocationOwners);
      }
    }
  );

  const [getAllocation, { loading, error, refetch: refechAllocation, called, data: updatedAllocationData }] = useLazyQuery(ALLOCATION);

  const {
    loading: subscriptionLoading,
    error: subscriptionError,
    data: subscriptionData
  } = useSubscription(ALLOCATION_SUBSCRIPTION, {
    shouldResubscribe: true
  });

  useEffect(() => {
    if (subscriptionError) {
      console.log(subscriptionError);
    }
  }, [subscriptionError]);

  const updateLocalAllocation = (updatedAllocation: any) => {
    const updatedAllocations = allocations.reduce((updatedAllocations: any, allocation) => {
      if (allocation.id === updatedAllocation.id) {
        if (updatedAllocation.allocationStatus === 'Outstanding') {
          return updatedAllocations.concat({
            ...allocation,
            ...updatedAllocation,
            updated: true // use this for animation
          });
        } else {
          return updatedAllocations;
        }
      } else {
        return updatedAllocations.concat({
          ...allocation,
          updated: false // use this for animation
        });
      }
    }, []);
    setAllocations(updatedAllocations);
  };

  useEffect(() => {
    if (subscriptionData) {
      const matchedAllocation = allocations.find((allocation) => allocation.id === subscriptionData.allocationUpdated.id);
      if (matchedAllocation) {
        if (called) {
          refechAllocation({ id: matchedAllocation.id });
        } else {
          getAllocation({ variables: { id: matchedAllocation.id } });
        }
      }
    }
  }, [subscriptionData]);

  useEffect(() => {
    if (updatedAllocationData) {
      updateLocalAllocation(updatedAllocationData.Allocation);
    }
  }, [updatedAllocationData]);

  const openModalWindow = (type: ModalWindowType, row: IRowData) => {
    setModalWindow({ ...modalWindow, isOpen: true, type });
    setCurrentRow(row);
  };
  const closeModalWindow = () => setModalWindow({ ...modalWindow, isOpen: false });

  const changeSelectRow = (row: IRowData, entityName: string) => {
    setCurrentRow(row);
    const entity = row.legalEntity.entities.find((item) => item.entityName === entityName);
    if (!entity) return;
    updateAllocations({
      variables: {
        data: [
          {
            id: row.id,
            selectedGroupLegalEntityId: entity.id
          }
        ]
      }
    });
  };

  const updateStatus = (step: 'next' | 'back', row: IRowData, isLastTask?: boolean) => {
    setCurrentRow(row);

    const currentStatusIndex = statuses.findIndex(
      (status: IOperationsWorkflowTransitionTask) => status.id === row.operationsWorkflowTransitionTask.id
    );
    const newStatus = step === 'next' ? statuses[currentStatusIndex + 1] : statuses[currentStatusIndex - 1];
    if (isLastTask) {
      updateAllocations({
        variables: {
          data: [
            {
              id: row.id,
              committedCapital: row.committedCapital,
              allocationStatus: 'Committed'
            }
          ]
        }
      });
      return;
    }

    updateOperationsStatus({
      variables: {
        data: {
          allocationId: row.id,
          workflowTaskId: newStatus.id
        }
      }
    });
  };

  const actionsByStatus = (row: IRowData): IItems[] => {
    if (user && user.tenant.type === 'advisoryFirm') return [];

    const currentStatusIndex = statuses.findIndex(
      (status: IOperationsWorkflowTransitionTask) => status.id === row.operationsWorkflowTransitionTask.id
    );

    return [
      ...(statuses[currentStatusIndex + 1]
        ? [
            {
              name: row.operationsWorkflowTransitionTask.transitionText,
              onClick: () => updateStatus('next', row)
            }
          ]
        : []),
      {
        name: lodash.startCase(ACTIONS.CHANGE_ASSIGNEE),
        onClick: () => {
          openModalWindow('assign-task', row);
        }
      },
      ...(statuses[currentStatusIndex - 1]
        ? [
            {
              name: lodash.startCase(ACTIONS.GO_BACK_TO_PREVIOUS_STEP),
              onClick: () => updateStatus('back', row)
            }
          ]
        : []),
      {
        name: lodash.startCase(ACTIONS.TERMINATE),
        onClick: () => {
          openModalWindow('terminate', row);
        }
      }
    ];
  };

  const canShowChangeAmountButton = (row: IRowData) => {
    return ability?.can(ACTIONS.CHANGE_AMOUNT, subject('Allocation', { ...row }));
  };

  const filteredColumns = () => {
    if (user && user.tenant.type === 'advisoryFirm') {
      return columns.map((column) => {
        if (column.key !== 'ADVISORY_FIRM' && column.key !== 'assignee') {
          return column;
        }
        return { ...column, width: 0, title: '' };
      });
    }

    return columns;
  };

  const isChosenFilters = document.querySelector('#chosenFliters');

  return (
    <>
      {modalWindow.isOpen && modalWindow.type === 'send-for-signature' && <SendForSignature isOpen={modalWindow.isOpen} onClose={closeModalWindow} />}
      {modalWindow.isOpen && modalWindow.type === 'assign-task' && (
        <AssignTask
          updateAllocationAssignee={updateAllocationAssignee}
          key={guid()}
          isOpen={modalWindow.isOpen}
          onClose={closeModalWindow}
          allocation={currentRow}
          refetch={refetch}
        />
      )}
      {modalWindow.isOpen && modalWindow.type === 'edit-owner' && (
        <EditOwner
          updateAllocationOwners={updateAllocationOwners}
          key={guid()}
          isOpen={modalWindow.isOpen}
          onClose={closeModalWindow}
          allocation={currentRow}
          refetch={refetch}
        />
      )}
      {modalWindow.isOpen && modalWindow.type === 'amount' && (
        <EditAmount
          key={guid()}
          updateAllocationAmount={updateAllocationAmount}
          isOpen={modalWindow.isOpen}
          onClose={closeModalWindow}
          allocation={currentRow}
        />
      )}
      {modalWindow.isOpen && modalWindow.type === 'terminate' && (
        <Terminate
          key={guid()}
          isOpen={modalWindow.isOpen}
          onClose={closeModalWindow}
          id={currentRow?.id ?? ''}
          updateAllocations={updateAllocations}
        />
      )}
      {modalWindow.isOpen && modalWindow.type === 'complete' && (
        <Complete
          key={guid()}
          isOpen={modalWindow.isOpen}
          onClose={closeModalWindow}
          confirmAction={() => {
            updateStatus('next', currentRow as IRowData, true);
            closeModalWindow();
          }}
        />
      )}
      <TableWrapper padding="0 0 30px">
        {isMobile ? (
          <>
            {recommendationLoading ? (
              <Loader />
            ) : (
              <MobileTable
                onClickLastUpdate={onClickLastUpdate}
                onClickInvestment={onClickInvestment}
                onClickEntity={onClickEntity}
                columns={filteredColumns()}
                updateLoading={updateOperationsStatusLoading || updateLoading || updateAllocationAmountLoading || updateAllocationAssigneeLoading}
                recommendationsRows={allocations}
                openModalWindow={openModalWindow}
                currentRow={currentRow}
                changeSelectRow={changeSelectRow}
                actionsByStatus={actionsByStatus}
                openEntityDetails={openEntityDetails}
                openInvestorDetails={openInvestorDetails}
                openOpportunityDetailsPage={openOpportunityDetailsPage}
              />
            )}
          </>
        ) : (
          <>
            <CustomTableHeader
              isChosenFilters={Boolean(isChosenFilters)}
              isTablet={isTablet}
              columns={filteredColumns()}
              refetch={refetch}
              savedSort={sort}
              savedSetSort={setSort}
            />
            <TableBodyWrapper>
              {recommendationLoading ? (
                <Loader />
              ) : (
                <>
                  <TableBody
                    data={allocations}
                    columns={filteredColumns()}
                    openEntityDetails={openEntityDetails}
                    openInvestorDetails={openInvestorDetails}
                    openOpportunityDetailsPage={openOpportunityDetailsPage}
                    updateLoading={updateOperationsStatusLoading || updateLoading || updateAllocationAmountLoading || updateAllocationAssigneeLoading}
                    currentRow={currentRow}
                    openModalWindow={openModalWindow}
                    changeSelectRow={changeSelectRow}
                    canShowChangeAmountButton={canShowChangeAmountButton}
                    actionsByStatus={actionsByStatus}
                  />
                  <PaginationWrap>
                    <TablePagination
                      savePagination={onChangePaginationData}
                      paginationValues={{
                        offset: data?.RecommendedAllocations.offset ?? 0,
                        limit: data?.RecommendedAllocations.limit ?? 0,
                        total: data?.RecommendedAllocations.total ?? 0
                      }}
                      refetch={refetch}
                      color="#00AEEF"
                    />
                  </PaginationWrap>
                </>
              )}
            </TableBodyWrapper>
          </>
        )}
      </TableWrapper>
    </>
  );
};

const CustomTableHeader = styled(TableHeader)<{ isChosenFilters?: boolean; isTablet: boolean }>`
  position: sticky;
  top: ${({ isChosenFilters, isTablet }) => (isChosenFilters ? (isTablet ? '194px' : '139px') : isTablet ? '122px' : '72px')};
  z-index: 8;
  padding-top: 0;
`;

const PaginationWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export default Table;
