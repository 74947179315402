import { ApprovedIcon } from '@/assets/icons/info-icons/approved';
import { InfoIcon } from '@/assets/icons/info-icons/info';
import { BurgerMenuIcon } from '@/assets/static/icons/burger-menu';
import { CloseMenuIcon } from '@/assets/static/icons/close-menu';
import { getUTCDate } from '@/components/allocations-page/utils';
import Tabs, { ITab, TabMobileWrap, TabTitle, TabsControl } from '@/components/basicComponents/fat-tabs';
import BasicTooltip from '@/components/basicComponents/tooltip';
import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import { useResponsive } from '@/hooks/use-responsive';
import React, { Dispatch, SetStateAction, useMemo } from 'react';
import styled, { useTheme } from 'styled-components';
import { Investment } from '../types';

interface DetailsProps {
  tabs?: ITab[];
  activeTab?: string;
  setActiveTab?: (value: string) => void;
  isTabMenuOpen?: boolean;
  setIsTabMenuOpen?: Dispatch<SetStateAction<boolean>>;
  investmentData: Investment | null;
  capitalCalled: number;
  summary: string;
}

export const Details = ({ tabs, activeTab, setActiveTab, isTabMenuOpen, setIsTabMenuOpen, investmentData, capitalCalled, summary }: DetailsProps) => {
  const { isMobile, isDesktop, isTablet, isLargeDesktop } = useResponsive();
  const theme = useTheme();

  const greyTabsData: { name: string; showItem: boolean; value: JSX.Element }[] = useMemo(() => {
    if (!investmentData) {
      return [];
    }
    const { minimum, investorDocsDue, ubtiBlocker, type, committedCapital, custodied, distributionsToDate, NAV } = investmentData;
    const unfundedCommitment = committedCapital || 0 - capitalCalled || 0;

    if (activeTab === 'Details') {
      return [
        {
          name: 'Minmum:',
          showItem: !!minimum,
          value: <FormattingTooltip zIndex={1000}>{minimum}</FormattingTooltip>
        },
        {
          name: 'Investor Docs Due:',
          showItem: !!investorDocsDue,
          value: <>{getUTCDate(new Date(investorDocsDue))}</>
        },
        {
          name: 'Custodian:',
          showItem: true,
          value: <>{custodied ? 'Yes' : 'No'}</>
        },
        {
          name: 'Tax Exempt Suitable:',
          showItem: true,
          value: <>{ubtiBlocker ? 'Yes' : 'No'}</>
        },
        {
          name: 'Type:',
          showItem: !!type,
          value: <>{type}</>
        }
      ];
    }
    return [
      {
        name: 'Committed Capital:',
        showItem: !!committedCapital,
        value: <FormattingTooltip zIndex={1000}>{committedCapital}</FormattingTooltip>
      },
      {
        name: 'Capital Called:',
        showItem: !!capitalCalled,
        value: <FormattingTooltip zIndex={1000}>{capitalCalled}</FormattingTooltip>
      },
      {
        name: 'Unfunded Commitment:',
        showItem: !!unfundedCommitment,
        value: <FormattingTooltip zIndex={1000}>{unfundedCommitment}</FormattingTooltip>
      },
      {
        name: 'NAV:',
        showItem: !!NAV,
        value: <FormattingTooltip zIndex={1000}>{NAV}</FormattingTooltip>
      },
      {
        name: 'Distributions to Date:',
        showItem: !!distributionsToDate,
        value: <FormattingTooltip zIndex={1000}>{distributionsToDate}</FormattingTooltip>
      }
    ];
  }, [capitalCalled, investmentData, activeTab]);

  if (!investmentData) return <></>;

  return (
    <DetailsWrapper isMobile={isMobile}>
      {tabs && activeTab && setActiveTab && setIsTabMenuOpen && (
        <>
          {isDesktop || isTablet || isLargeDesktop ? (
            <CustomTabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
          ) : (
            <TabsControl isMobile={isMobile}>
              {tabs.length !== 1 && (
                <>
                  {isTabMenuOpen ? (
                    <CloseMenuIcon fill={theme.action.primary} onClick={() => setIsTabMenuOpen(!isTabMenuOpen)} style={{ cursor: 'pointer' }} />
                  ) : (
                    <BurgerMenuIcon fill={theme.action.primary} onClick={() => setIsTabMenuOpen(!isTabMenuOpen)} style={{ cursor: 'pointer' }} />
                  )}
                </>
              )}
              <TabMobileWrap onClick={() => setActiveTab(activeTab)} isActive>
                <TabTitle isActive isMobile={isMobile}>
                  {activeTab}
                </TabTitle>
              </TabMobileWrap>
            </TabsControl>
          )}
        </>
      )}
      <DetailsBoby isMobile={isMobile}>
        <DetailsRow>
          {summary && (
            <SummaryWrapper>
              <SummaryText fontWeight={700}>
                Summary: <SummaryText fontWeight={400}>{summary}</SummaryText>
              </SummaryText>
            </SummaryWrapper>
          )}
          <DetailsItems>
            {!!investmentData.assetClass && (
              <DetailsItem>
                <ApprovedIcon width={23} height={23} fill={theme.action.active} />
                <ItemKey>Asset Class:</ItemKey>
                {investmentData.assetClass.name}
              </DetailsItem>
            )}
            {!!investmentData.subAssetClass && (
              <DetailsItem>
                <ApprovedIcon width={23} height={23} fill={theme.action.active} />
                <ItemKey>Sub Asset Class:</ItemKey>
                {investmentData.subAssetClass.name}
              </DetailsItem>
            )}
            {!!investmentData.pacingType && (
              <DetailsItem>
                <ApprovedIcon width={23} height={23} fill={theme.action.active} />
                <ItemKey>Vintage:</ItemKey>
                {investmentData.pacingType === 'EVERGREEN' && 'Evergreen'}
                {investmentData.pacingType === 'DRAWDOWN' && investmentData.pacingStartingYear ? investmentData.pacingStartingYear : 'Drawdown'}
              </DetailsItem>
            )}
            {!!investmentData.subAssetClasses.filter((sector) => sector !== '').length && (
              <DetailsItem>
                <ApprovedIcon width={23} height={23} fill={theme.action.active} />
                <ItemKey>Sectors:</ItemKey>
                {investmentData.subAssetClasses
                  .filter((x, i, a) => a.indexOf(x) == i)
                  .map((el, index) => {
                    const key = 'sector_' + el.trim();

                    if (el.length && el.length > 15) {
                      return (
                        <BasicTooltip key={key} tooltipContent={el.trim()}>
                          <>{`${el.trim().slice(0, 15)} ... ${index !== investmentData.subAssetClasses.length - 1 ? ' ,' : ''}`}</>
                        </BasicTooltip>
                      );
                    }
                    if (el.length) {
                      return (
                        <React.Fragment key={key}>{`${el.trim()} ${index !== investmentData.subAssetClasses.length - 1 ? ' ,' : ''}`}</React.Fragment>
                      );
                    }
                  })}
              </DetailsItem>
            )}
          </DetailsItems>
        </DetailsRow>
        <GreyTabBar>
          {greyTabsData.map(
            ({ name, showItem, value }) =>
              showItem && (
                <GreyTabItem key={name}>
                  <InfoIcon width={16} height={16} fill={theme.font.weak} />
                  <GreyTabItemKey>{name}</GreyTabItemKey>
                  {value}
                </GreyTabItem>
              )
          )}
        </GreyTabBar>
      </DetailsBoby>
    </DetailsWrapper>
  );
};

const DetailsWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: ${({ theme }) => theme.layer[1]};
  border-bottom: 1px solid ${({ theme }) => theme.border.base};
  transition: 1s;
`;

const CustomTabs = styled(Tabs)`
  padding: 0 60px;
  border-bottom: 1px ${({ theme }) => theme.border.base} solid;
`;

const DetailsBoby = styled.div<{ isMobile: boolean }>`
  padding: ${({ isMobile }) => (isMobile ? '20px 40px' : '20px  90px')};
  color: ${({ theme }) => theme.font.base};
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const DetailsRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.border.base};
`;

const SummaryWrapper = styled.div`
  padding: 5px 10px;
`;

const SummaryText = styled.span<{ fontWeight: number }>`
  font-family: Blinker;
  font-size: 20px;
  font-weight: ${({ fontWeight }) => fontWeight};
  line-height: 24px;
`;

const DetailsItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  padding: 0px 10px;
`;

const DetailsItem = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  color: ${({ theme }) => theme.font.strong};
`;

const ItemKey = styled.span`
  font-weight: 400;
  color: ${({ theme }) => theme.font.weak};
`;

const GreyTabBar = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  padding: 0 20px;
`;

const GreyTabItem = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  padding: 5px 10px 5px 5px;
  background: ${({ theme }) => theme.layer[12]};
  font-size: 13px;
  font-weight: 600;
  line-height: 18.2px;
  color: ${({ theme }) => theme.font.base};
`;

const GreyTabItemKey = styled.span`
  font-weight: 400;
  color: ${({ theme }) => theme.font.weak};
`;
