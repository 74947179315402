import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import { RejectedBannerIcon } from '@/assets/icons/info-icons/rejectedBanner';
import { Banner } from '@/components/basicComponents/banner';
import { FormFooter } from '@/components/fat-basicComponents/formFooter';
import { IInvestmentVehicle, Investment } from '@/components/fat-dealCatalog-page/types';
import Header from '@/components/fat-header';
import { GoBackButton } from '@/components/fat-header/goBackButton';
import { PageTitle } from '@/components/fat-header/pageTitle';
import { GET_ASSET_CLASSES } from '@/components/fat-investors-page/fat-addInvestors/queries';
import { IAssetClass } from '@/components/fat-investors-page/fat-addInvestors/types';
import { LoaderOnWholeScreen } from '@/components/loaders/loader-on-whole-screen';
import { useGoBack } from '@/hooks/useGoBack';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ContainerPage, MainWrap } from '../../../styles/common';
import { DialogStatus } from '../../allocations-page/modals/dialog';
import { ALLOCATION_INVESTMENT_QUERY, OWNERS_QUERY } from '../../allocations-page/queries';
import { IOwner } from '../../allocations-page/types';
import { UPDATE_INVESTMENT, getCurrentManagers, getCurrentSubAssetClasses, getCurrentTags } from '../../dueDiligence-page/queries';
import { createInvestmentMutation } from '../../opportunities-entity/modify/constants';
import { ALL_ADVISORY_FIRM_LIST_QUERY } from '../fat-investorInterest/queries';
import { INVESTMENT_QUERY, updateInvestmentRestrictionsMutation, updateInvestmentWorkflowTransitionTaskMutation } from '../queries';
import { Tenant } from '../types';
import { defaultListData, distributionSettingsOptions } from './constatnts';
import CreateManager from './modals/createManager';
import CreateStrategy from './modals/createStrategy';
import CreateTag from './modals/createTag';
import Dialog from './modals/dialog';
import { AllocationSettingsSection } from './sections/allocationSettings';
import { ClosingsSection } from './sections/dates';
import { DetailsSection } from './sections/details';
import { OwnersSection } from './sections/owners';
import { VehiclesSection } from './sections/vehicles';
import { IForm, IListData } from './types';
import { buildData, getAllPossibleOwners, getDefaultFormValues, isSelectorsValid } from './utils';

const sortUniqueNoneEmpty = (arr: string[]) =>
  arr.filter((value, index, array) => array.indexOf(value) === index && value !== '').sort((a: string, b: string) => a.localeCompare(b));

const ModifyStrategy = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const goBack = useGoBack();

  const [searchParams, setSearchParams] = useSearchParams();

  const backToTitle = searchParams.get('backToTitle') || '';
  const investmentId = searchParams.get('investment') || '';

  const taskId = location.state?.taskId;
  const pageTitle = location.state?.pageTitle;
  const securityStatus = location.state?.securityStatus;
  const bannerTitle = location.state?.bannerTitle;
  const bannerDescription = location.state?.bannerDescription;

  const [investment, setInvestment] = useState<Investment | null>(null);
  const [title, setTitle] = useState<string>();
  const [defaultFormValues, setDefaultFormValues] = useState<IForm>();
  const [assetClasses, setAssetClasses] = useState<IAssetClass[]>([]);

  const {
    handleSubmit,
    getValues,
    setValue,
    control,
    setError,
    formState: { errors, isValid },
    reset,
    watch
  } = useForm<IForm>({ defaultValues: defaultFormValues });

  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isDialogOpen, setDialogOpen] = useState<boolean>(false);
  const [dialogStatus, setDialogStatus] = useState<DialogStatus>();
  const [allPossibleOwners, setAllPossibleOwners] = useState<Array<IOwner>>([]);
  const [hasActiveAllocation, setActiveAllocation] = useState<boolean>(!!investment);
  const [modalWindow, setModalWindow] = useState({
    isOpen: false,
    type: 'create-tag'
  });
  const [tags, setTags] = useState<IListData>(defaultListData);
  const [strategies, setStrategies] = useState<IListData>(defaultListData);
  const [managerList, setManagerList] = useState<string[]>([]);
  const [selectedDistributionSettingsOptions, setSelectedDistributionSettingsOptions] = useState(distributionSettingsOptions[0]);
  const [advisoryFirms, setAdvisoryFirms] = useState<IListData>(defaultListData);
  const [updateInvestmentRestrictionsErrors, setUpdateInvestmentRestrictionsErrors] = useState<string[] | null>(null);
  const [vehicles, setVehicles] = useState<IInvestmentVehicle[]>([]);
  const [newInvestmentId, setNewInvestmentId] = useState<string | null>(null);

  const [createInvestment, { loading: createAllocationsLoading }] = useMutation(createInvestmentMutation);
  const [updateInvestment, { loading: updateInvestmentsLoading }] = useMutation(UPDATE_INVESTMENT);
  const [updateInvestmentWorkflowTransitionTask, { loading: updateInvestmentWorkflowTransitionTaskLoading }] = useMutation(
    updateInvestmentWorkflowTransitionTaskMutation
  );
  const [updateInvestmentRestrictions] = useMutation(updateInvestmentRestrictionsMutation);
  const [getActiveAllocationInfo, { loading: allocationInvestmentLoading }] = useLazyQuery(ALLOCATION_INVESTMENT_QUERY);

  const { data: investmentData, loading: loadingInvestment } = useQuery<{ Investment: Investment }>(INVESTMENT_QUERY, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    skip: !investmentId,
    variables: { id: investmentId },
    onError: (error) => {
      console.error(error);
    }
  });

  const { loading: assetClassesLoading } = useQuery<{ assetClasses: IAssetClass[] }>(GET_ASSET_CLASSES, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ assetClasses }) => {
      setAssetClasses(assetClasses);
    },
    onError: (error) => {
      console.error(error);
    }
  });

  const { data: ownersData, loading: ownersLoading } = useQuery(OWNERS_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    variables: {
      id: 'dafd1c4b-f85d-4e56-ad3c-1e60c0199349' // optional
    }
  });

  useQuery(getCurrentManagers, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const sortedManagerList = sortUniqueNoneEmpty([...data.getCurrentManagers.values]);
      setManagerList(sortedManagerList);
    }
  });

  const { data: tagsListData } = useQuery(getCurrentTags, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const sortedTagsList = [...data.getCurrentTags.values].filter((tag: string) => tag !== '');
      setTags({ ...tags, list: sortedTagsList });
    }
  });

  const { data: strategiesListData } = useQuery(getCurrentSubAssetClasses, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const sortedStrategiesList = [...data.getCurrentSubAssetClasses.values].filter((strategy: string) => strategy !== '');
      setStrategies({ ...strategies, list: sortedStrategiesList });
    }
  });

  const { data: advisoryFirmListData } = useQuery<{ allAdvisoryFirmList: Tenant[] }>(ALL_ADVISORY_FIRM_LIST_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const advisoryFirmList = data.allAdvisoryFirmList.map((advisoryFirm) => advisoryFirm.name);
      setAdvisoryFirms({ ...advisoryFirms, list: advisoryFirmList });
    }
  });

  useEffect(() => {
    const investment = investmentData?.Investment;
    if (investment) {
      setInvestment(investment);
      setIsEditMode(!!investment);
      setTags({ ...tags, chosen: investment.tags ?? [] });
      setStrategies({
        ...strategies,
        chosen: investment.subAssetClasses ?? []
      });
      setManagerList(investment.managerId ? sortUniqueNoneEmpty([...managerList, investment.managerId]) : managerList);
      setAdvisoryFirms({ ...advisoryFirms, chosen: investment.advisoryFirms.map((advisoryFirm: Tenant) => advisoryFirm.name) });
      setSelectedDistributionSettingsOptions(investment.showToAllAdvisoryFirms ? distributionSettingsOptions[0] : distributionSettingsOptions[1]);
      setVehicles(investment.investmentVehicles ?? []);
    }
    reset(getDefaultFormValues(investment));
    setDefaultFormValues(getDefaultFormValues(investment));
    setTitle((pageTitle ? pageTitle : investment ? 'Edit Strategy' : 'Add New Strategy') as string);
  }, [investmentData]);

  useEffect(() => {
    const sortedManager = sortUniqueNoneEmpty([...managerList]);
    setManagerList(sortedManager);
  }, [watch('manager')]);

  useEffect(() => {
    setTags((prevTags) => {
      return {
        ...prevTags,
        list: prevTags.list.filter((tag) => !tags.chosen.includes(tag))
      };
    });
  }, [investmentData, tagsListData, tags.chosen]);

  useEffect(() => {
    setStrategies((prevStrategies) => {
      return {
        ...prevStrategies,
        list: prevStrategies.list.filter((strategy) => !strategies.chosen.includes(strategy))
      };
    });
  }, [investmentData, strategiesListData, strategies.chosen]);

  useEffect(() => {
    setAdvisoryFirms((prevAdvisoryFirms) => {
      return {
        ...prevAdvisoryFirms,
        list: prevAdvisoryFirms.list
          .filter((advisoryFirm) => !advisoryFirms.chosen.includes(advisoryFirm))
          .sort((a: string, b: string) => a.localeCompare(b))
      };
    });
  }, [investmentData, advisoryFirmListData, advisoryFirms.chosen]);

  useEffect(() => {
    reset();
  }, [defaultFormValues]);

  useEffect(() => {
    if (investment) {
      getActiveAllocationInfo({
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache',
        variables: {
          id: (investment as Investment).id
        }
      })
        .then((response) => {
          setActiveAllocation(response.data.Investment?.hasActiveAllocation);
        })
        .catch((error) => {});
    }
  }, []);

  useEffect(() => {
    if (ownersData) {
      const { investmentOwners, operationsOwners, complianceOwners } = ownersData.AllocationOwners;
      setAllPossibleOwners(
        getAllPossibleOwners({
          investmentOwners,
          operationsOwners,
          complianceOwners
        })
      );
    }
    if (createAllocationsLoading || updateInvestmentsLoading || updateInvestmentWorkflowTransitionTaskLoading) {
      setDialogStatus(DialogStatus.LOADING);
      setDialogOpen(true);
    }
  }, [ownersData, createAllocationsLoading, updateInvestmentsLoading, updateInvestmentWorkflowTransitionTaskLoading]);

  const openModalWindow = (type: string) => {
    setModalWindow({ ...modalWindow, isOpen: true, type });
  };

  if (!ownersData || ownersLoading || allocationInvestmentLoading || loadingInvestment || assetClassesLoading) {
    return <LoaderOnWholeScreen size={60} />;
  }
  const { investmentOwners, operationsOwners, complianceOwners } = ownersData.AllocationOwners;

  const onCloseDialog = () => setDialogOpen(false);
  const onDialogSuccess = () => {
    reset();
    setDialogOpen(false);

    if (investmentId || newInvestmentId) {
      navigate(`/dealPipeline/opportunityDetails/${investmentId || newInvestmentId}?backToTitle=Manager Pipeline`, { replace: true });
      return;
    }
    goBack({ fallBack: '/dealPipeline' });
  };

  const getInvestmentRestrictionsData = (investmentId: string) => {
    if (
      selectedDistributionSettingsOptions === distributionSettingsOptions[0] ||
      !advisoryFirms.chosen.length ||
      advisoryFirms.chosen[0] === 'None'
    ) {
      const tenantIds = advisoryFirmListData?.allAdvisoryFirmList.map((advisoryFirm) => advisoryFirm.id);
      if (!tenantIds?.length) return;
      return { id: investmentId, tenantIds, showToAllAdvisoryFirms: true };
    }
    const tenantIds = advisoryFirms.chosen
      .map((advisoryFirmName) => {
        const tenant = advisoryFirmListData?.allAdvisoryFirmList.find((advisoryFirm) => advisoryFirm.name === advisoryFirmName);

        if (tenant) {
          return tenant.id;
        }
        return null;
      })
      .filter((tenantId) => tenantId);

    return { id: investmentId, tenantIds, showToAllAdvisoryFirms: false };
  };

  const onUpdateInvestment = async (data: IForm) => {
    if (!investment) return;

    const investmentStrategies = [...strategies.chosen].filter((el) => el.length > 0 && el !== 'None').map((el) => el.trim());
    const investmentTags = [...tags.chosen].filter((el) => el.length > 0 && el !== 'None').map((el) => el.trim());
    const selectedAssetClass = assetClasses.find((assetClass) => assetClass.name === data.assetClass);
    const selectedSubAssetClass = selectedAssetClass?.subAssetClasses.find((subAssetClass) => subAssetClass.name === data.subAssetClass);

    if (taskId) {
      await updateInvestmentWorkflowTransitionTask({
        variables: {
          data: {
            investmentId: investment.id,
            workflowTaskId: taskId
          }
        }
      });
    }

    try {
      await updateInvestment({
        variables: {
          data: {
            ...buildData({
              id: investment.id,
              data: data,
              investmentStrategies,
              investmentTags,
              allPossibleOwners,
              securityStatus: securityStatus ? securityStatus : investmentData.Investment.securityStatus,
              vehicles
            }),
            assetClassId: selectedAssetClass?.id,
            subAssetClassId: selectedSubAssetClass?.id
          }
        }
      });

      await updateInvestmentRestrictions({
        variables: {
          data: getInvestmentRestrictionsData(investmentData.Investment.id)
        }
      });

      setDialogStatus(DialogStatus.SUCCESS);
      setDialogOpen(true);
    } catch (error: any) {
      const message = (error?.graphQLErrors[0]?.extensions?.originalError as { message?: string[] })?.message ?? null;
      if (message) {
        setUpdateInvestmentRestrictionsErrors(message);
      }
      setDialogStatus(DialogStatus.FAILED);
      setDialogOpen(true);
    }
  };

  const onCreateInvestment = async (data: IForm) => {
    const investmentStrategies = [...strategies.chosen].filter((el) => el.length > 0).map((el) => el.trim());
    const investmentTags = [...tags.chosen].filter((el) => el.length > 0 && el !== 'None').map((el) => el.trim());

    const selectedAssetClass = assetClasses.find((assetClass) => assetClass.name === data.assetClass);
    const selectedSubAssetClass = selectedAssetClass?.subAssetClasses.find((subAssetClass) => subAssetClass.name === data.subAssetClass);

    try {
      const { data: newInvestmentData } = await createInvestment({
        variables: {
          data: {
            ...buildData({
              data: data,
              investmentStrategies,
              investmentTags,
              allPossibleOwners,
              securityStatus: 'DRAFT'
            }),
            assetClassId: selectedAssetClass?.id,
            subAssetClassId: selectedSubAssetClass?.id
          }
        }
      });

      await updateInvestmentRestrictions({
        variables: {
          data: getInvestmentRestrictionsData(newInvestmentData.createInvestment.id)
        }
      });

      setNewInvestmentId(newInvestmentData.createInvestment.id);
      setDialogStatus(DialogStatus.SUCCESS);
      setDialogOpen(true);
    } catch (error: any) {
      const message = (error?.graphQLErrors[0]?.extensions?.originalError as { message?: string[] })?.message ?? null;
      if (message) {
        setUpdateInvestmentRestrictionsErrors(message);
      }
      setDialogStatus(DialogStatus.FAILED);
      setDialogOpen(true);
    }
  };

  const onSubmit = (data: IForm) => {
    if (!isSelectorsValid(data)) return;

    if (isEditMode && hasActiveAllocation) {
      setDialogStatus(DialogStatus.WARNING);
      setDialogOpen(true);
    } else if (isEditMode && !hasActiveAllocation) {
      onUpdateInvestment(data);
    } else {
      onCreateInvestment(data);
    }
  };

  const handleClosePage = async () => {
    reset();
    goBack({ fallBack: '/dealPipeline' });
  };

  return (
    <MainWrap>
      <Dialog
        isOpen={isDialogOpen}
        onClose={onCloseDialog}
        onSuccess={onDialogSuccess}
        onCreateInvestment={() => onCreateInvestment(getValues())}
        onUpdateInvestment={() => onUpdateInvestment(getValues())}
        status={dialogStatus}
        isEditMode={isEditMode}
        updateInvestmentRestrictionsErrors={updateInvestmentRestrictionsErrors}
      />
      {modalWindow.isOpen && modalWindow.type === 'create-strategy' && (
        <CreateStrategy
          onClose={() => setModalWindow({ type: 'create-strategy', isOpen: false })}
          isOpen={modalWindow.isOpen}
          setStrategies={setStrategies}
        />
      )}
      {modalWindow.isOpen && modalWindow.type === 'create-tag' && (
        <CreateTag onClose={() => setModalWindow({ type: 'create-tag', isOpen: false })} isOpen={modalWindow.isOpen} setTags={setTags} />
      )}
      {modalWindow.isOpen && modalWindow.type === 'create-manager' && (
        <CreateManager
          onClose={() => setModalWindow({ type: 'create-manager', isOpen: false })}
          isOpen={modalWindow.isOpen}
          managerList={managerList}
          setManagerList={setManagerList}
          getNewManager={(newManager) => setValue('manager', newManager)}
        />
      )}
      <Header modalControl={<GoBackButton handleClose={handleClosePage} backToTitle={backToTitle} />} />
      <PageTitle title={title ?? ''} />
      {bannerTitle && bannerDescription && (
        <Banner icon={<RejectedBannerIcon />} title={bannerTitle} description={bannerDescription} bgColor="#ef9208" />
      )}
      <Form id="opportunitiesForm" onSubmit={handleSubmit(onSubmit, () => window.scrollTo(0, 0))}>
        <CustomContainerPage>
          <SectionsWrap>
            <DetailsSection
              control={control}
              managerList={managerList}
              openModalWindow={openModalWindow}
              assetClasses={assetClasses}
              watch={watch}
              setValue={setValue}
              advisoryFirms={advisoryFirms}
              setAdvisoryFirms={setAdvisoryFirms}
              selectedDistributionSettingsOptions={selectedDistributionSettingsOptions}
              setSelectedDistributionSettingsOptions={setSelectedDistributionSettingsOptions}
            />
            <AllocationSettingsSection
              strategies={strategies}
              setStrategies={setStrategies}
              tags={tags}
              setTags={setTags}
              control={control}
              openModalWindow={openModalWindow}
            />
            <ClosingsSection control={control} errors={errors} />
            <VehiclesSection vehicles={vehicles} setVehicles={setVehicles} />
            <OwnersSection
              control={control}
              investmentOwners={investmentOwners}
              operationsOwners={operationsOwners}
              complianceOwners={complianceOwners}
            />
          </SectionsWrap>
          <FormFooter onCancel={handleClosePage} disableSaveButton={false} />
        </CustomContainerPage>
      </Form>
    </MainWrap>
  );
};

export default ModifyStrategy;

const CustomContainerPage = styled(ContainerPage)`
  padding-top: 40px;
`;

const SectionsWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Form = styled.form`
  width: 100%;
  background: ${({ theme }) => theme.layer.base};
`;
