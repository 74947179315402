import { EditIcon } from '@/assets/icons/edit';
import { ApprovedIcon } from '@/assets/icons/info-icons/approved';
import { ArrowDown } from '@/assets/icons/info-icons/arrowDown';
import { CalendarIcon } from '@/assets/icons/info-icons/calendar';
import { FlagIcon } from '@/assets/icons/info-icons/flag';
import { AiAssistantIcon } from '@/assets/static/icons/ai-assistant';
import { ArrowUp } from '@/assets/static/icons/todo-page/arrow-up';
import { getUTCDate } from '@/components/allocations-page/utils';
import { IFile } from '@/components/dueDiligence-page/dueDiligenceRecord';
import { AssignedTo, AssignedToWrapper, EditAssignedTo, RedirectText } from '@/components/dueDiligence-page/dueDiligenceRecord/sectionStatus';
import {
  ArrowWrapper,
  DropdownBody,
  DropdownHeader,
  DropdownWrapper,
  ExpandableWrapp,
  HeaderInfo,
  Title
} from '@/components/dueDiligence-page/dueDiligenceRecord/tierDropdown';
import { NoteDataType } from '@/components/dueDiligence-page/dueDiligenceRecord/types';
import Button from '@/components/fat-basicComponents/button';
import Header from '@/components/fat-header';
import { GoBackButton } from '@/components/fat-header/goBackButton';
import { PageTitle } from '@/components/fat-header/pageTitle';
import { LoaderOnWholeScreen } from '@/components/loaders/loader-on-whole-screen';
import DropdownActions from '@/components/table/fat-dropdownActions';
import { useAuth } from '@/hooks/use-auth';
import { useResponsive } from '@/hooks/use-responsive';
import { useGoBack } from '@/hooks/useGoBack';
import { MainWrap, PaddingWrap } from '@/styles/common';
import { NetworkStatus, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { MANAGER_UPDATES_EDITABLE_ROLES } from '../../constatnts';
import ChangeAssignee from '../modals/changeAssignee';
import DownloadPDF from '../modals/downloadPDF';
import PublishUpdate from '../modals/publishUpdate';
import UnpublishUpdate from '../modals/unpublishUpdate';
import { GET_DOCUMENT_RECORD_QUERY } from '../queries';
import { DOCUMENT_RECORD_STATUS_ENUM, IDocumentRecordFieldEntity, IGetDocumentRecordQuery } from '../types';
import { AdvancedOptions } from './advanced-options';
import { AiPane } from './ai-pane';
import { EditFieldPage } from './edit-pages';
import Notes from './notes';
import { SectionFields } from './sectionFields';

const getStatusText = (documentRecordData: IGetDocumentRecordQuery) => {
  if (!documentRecordData) return '';
  const { status, publisher, statusUpdatedAt } = documentRecordData;

  if (status === DOCUMENT_RECORD_STATUS_ENUM.Draft) {
    return DOCUMENT_RECORD_STATUS_ENUM[status];
  }

  if (!publisher || !statusUpdatedAt) return DOCUMENT_RECORD_STATUS_ENUM[status];

  if (status === DOCUMENT_RECORD_STATUS_ENUM.Published) {
    return `${DOCUMENT_RECORD_STATUS_ENUM[status]} By ${publisher.firstName} ${publisher.lastName} on ${getUTCDate(
      new Date(statusUpdatedAt)
    ).toUpperCase()}`;
  }
};

export const EditManagerUpdate = () => {
  const { isMobile, isTablet } = useResponsive();
  const goBack = useGoBack();
  const theme = useTheme();
  const params = useParams();
  const { user } = useAuth();

  const [searchParams, setSearchParams] = useSearchParams();

  const backToTitle = searchParams.get('backToTitle') || '';

  const [canUserEdit, setCanUserEdit] = useState(false);
  const [editFieldPageType, setEditFieldPageType] = useState('default');
  const [scrollTo, setScrollTo] = useState(0);
  const [currentField, setCurrentField] = useState<IDocumentRecordFieldEntity | null>(null);
  const [allFields, setAllFields] = useState<IDocumentRecordFieldEntity[] | null>(null);
  const [isAiPaneOpen, setIsAiPaneOpen] = useState(false);
  const [modalWindow, setModalWindow] = useState({
    isOpen: false,
    type: 'publish-update'
  });
  const [isDropdown, setIsDropdown] = useState(true);
  const [arrowHover, setArrowHover] = useState(false);
  const [isNotesOpen, setIsNotesOpen] = useState(false);
  const [noteData, setNoteData] = useState<NoteDataType | null>(null);
  const [files, setFiles] = useState<IFile[]>([]);
  const [generateAnswersLoading, setGenerateAnswersLoading] = useState(false);
  const [isGenerateAnswersLoadingCompleted, setIsGenerateAnswersLoadingCompleted] = useState(false);

  const {
    data: documentRecordData,
    refetch: documentRecordRefetch,
    networkStatus: documentRecordNetworkStatus
  } = useQuery<{ getDocumentRecord: IGetDocumentRecordQuery }>(GET_DOCUMENT_RECORD_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    skip: !params?.updateId,
    variables: {
      id: params?.updateId
    },
    onCompleted: (data) => {
      setAllFields(data.getDocumentRecord.fields);
    },
    onError: (error) => {
      console.error(error);
    }
  });

  useEffect(() => {
    if (MANAGER_UPDATES_EDITABLE_ROLES.some((role) => user.roles.includes(role)) && user.tenant.type === 'fundManager') {
      setCanUserEdit(true);
      return;
    }
  }, [user]);

  useEffect(() => {
    if (editFieldPageType === 'default' && scrollTo) {
      window.scrollTo(0, scrollTo);
      setScrollTo(0);
    }
  }, [editFieldPageType]);

  const openAdvancedOptions = () => {
    setIsAiPaneOpen(false);
    setEditFieldPageType('advanced-options');
  };

  const handleGenerateAnswersLoadingChange = (loadingStatus: boolean, showCompleteIcon: boolean) => {
    setGenerateAnswersLoading(loadingStatus);
    setIsGenerateAnswersLoadingCompleted(showCompleteIcon);
  };

  const closeAdvancedOptions = () => {
    setIsAiPaneOpen(true);
    setEditFieldPageType('default');
  };

  const openModalWindow = (type: string) => {
    setModalWindow({ ...modalWindow, isOpen: true, type });
  };

  const closeModalWindow = () => setModalWindow({ ...modalWindow, isOpen: false });

  const openEditPage = (row: IDocumentRecordFieldEntity) => {
    setScrollTo(window.scrollY);
    setEditFieldPageType(row.documentField.type);
    setCurrentField(row);
    documentRecordRefetch();
  };

  const closeEditPage = () => {
    setEditFieldPageType('default');
    setCurrentField(null);
    documentRecordRefetch();
  };

  // const openNotes = (documentRecordData: IGetDocumentRecordQuery) => {
  //   const { note, updatedAt } = documentRecordData;
  //   setIsNotesOpen(!isNotesOpen);
  //   setNoteData({ note, updateAt: updatedAt, task: '', assignedBy: undefined, assignedTo: undefined });
  // };

  const dropdownActions = () => {
    if (!canUserEdit) return [];

    return [
      ...(documentRecordData?.getDocumentRecord?.status === 'Draft'
        ? [
            {
              name: 'Publish Update',
              onClick: () => openModalWindow('publish-update')
            }
          ]
        : []),
      ...(documentRecordData?.getDocumentRecord?.status === 'Published'
        ? [
            {
              name: 'Unpublish Update',
              onClick: () => openModalWindow('unpublish-update')
            }
          ]
        : [])
    ];
  };

  if (documentRecordNetworkStatus === NetworkStatus.loading || documentRecordNetworkStatus === NetworkStatus.setVariables) {
    return <LoaderOnWholeScreen />;
  }

  if (editFieldPageType === 'advanced-options') {
    return (
      <AdvancedOptions
        handleClose={closeAdvancedOptions}
        documentRecordRefetch={documentRecordRefetch}
        files={files}
        setFiles={setFiles}
        handleGenerateAnswersLoadingChange={handleGenerateAnswersLoadingChange}
        dueDiligenceRecord={{}}
      />
    );
  }

  if (editFieldPageType !== 'default' && editFieldPageType !== 'advanced-options') {
    return (
      <EditManagerUpdatesWrapper>
        {((!isMobile && !isTablet) || !isAiPaneOpen) && (
          <EditFieldPage
            handleClose={closeEditPage}
            allFields={allFields}
            currentField={currentField}
            setIsAiPaneOpen={setIsAiPaneOpen}
            setCurrentField={setCurrentField}
            investmentId={params?.strategyId ?? ''}
            editFieldPageType={editFieldPageType}
            setEditFieldPageType={setEditFieldPageType}
            refetch={documentRecordRefetch}
          />
        )}
        <AiPane
          recordId={documentRecordData?.getDocumentRecord?.id ?? ''}
          type="mu"
          documentRecordRefetch={documentRecordRefetch}
          files={files}
          setFiles={setFiles}
          isAiPaneOpen={isAiPaneOpen}
          onClose={() => setIsAiPaneOpen(false)}
          handleGenerateAnswersLoadingChange={handleGenerateAnswersLoadingChange}
          freeChatOnly={editFieldPageType !== 'default' && editFieldPageType !== 'advanced-options'}
          openAdvancedOptions={openAdvancedOptions}
        />
      </EditManagerUpdatesWrapper>
    );
  }

  return (
    <EditManagerUpdatesWrapper>
      {((!isMobile && !isTablet) || !isAiPaneOpen) && (
        <div style={{ width: '100%' }}>
          {modalWindow.isOpen && modalWindow.type === 'publish-update' && (
            <PublishUpdate
              isOpen={modalWindow.isOpen}
              onClose={closeModalWindow}
              updateId={documentRecordData?.getDocumentRecord?.id ?? null}
              ownerEmail={documentRecordData?.getDocumentRecord?.owner?.email ?? null}
              refetch={documentRecordRefetch}
            />
          )}
          {modalWindow.isOpen && modalWindow.type === 'unpublish-update' && (
            <UnpublishUpdate
              isOpen={modalWindow.isOpen}
              onClose={closeModalWindow}
              updateId={documentRecordData?.getDocumentRecord?.id ?? null}
              refetch={documentRecordRefetch}
            />
          )}
          {modalWindow.isOpen && modalWindow.type === 'change-assignee' && (
            <ChangeAssignee
              isOpen={modalWindow.isOpen}
              onClose={closeModalWindow}
              assignee={documentRecordData?.getDocumentRecord?.assignee ?? null}
              owner={documentRecordData?.getDocumentRecord?.owner ?? null}
              updateId={documentRecordData?.getDocumentRecord?.id ?? null}
              status={documentRecordData?.getDocumentRecord?.status ?? null}
              documentRecordRefetch={documentRecordRefetch}
            />
          )}
          {modalWindow.isOpen && modalWindow.type === 'download-pdf' && (
            <DownloadPDF
              isOpen={modalWindow.isOpen}
              onClose={closeModalWindow}
              canUserEdit={canUserEdit}
              documentRecord={documentRecordData?.getDocumentRecord ?? null}
            />
          )}
          <MainWrap>
            <Header modalControl={<GoBackButton handleClose={() => goBack({ fallBack: '/managerUpdates' })} backToTitle={backToTitle} />} />
            <PageTitle title={documentRecordData?.getDocumentRecord?.name ?? ''} />
          </MainWrap>
          <MainWrap>
            <PaddingWrap>
              <Notes onClick={() => setIsNotesOpen(false)} isOpen={isNotesOpen} data={noteData} />
              {canUserEdit && (
                <StatusWrapper isMobile={isMobile}>
                  <Status isMobile={isMobile}>
                    <div>
                      <FlagIcon fill={theme.font.base} />
                    </div>
                    <SeparateStick />
                    <StatusText>{getStatusText(documentRecordData?.getDocumentRecord)}</StatusText>
                    {!dropdownActions().length || (
                      <DropDownWrapper isMobile={isMobile}>
                        <DropdownActions isMobile isListCenter items={dropdownActions()} />
                      </DropDownWrapper>
                    )}
                  </Status>
                  <AiAssistantButton
                    styleType="outline"
                    onClick={() => setIsAiPaneOpen(!isAiPaneOpen)}
                    size="md"
                    icon={<AiAssistantIcon fill={theme.font.action} />}
                    secondaryIcon={isGenerateAnswersLoadingCompleted && <ApprovedIcon width={20} height={20} fill={theme.context.success} />}
                    isLoading={generateAnswersLoading}
                  >
                    AI Assistant
                  </AiAssistantButton>
                </StatusWrapper>
              )}
              <SectionsWrapper canUserEdit={canUserEdit}>
                <DropdownWrapper>
                  <DropdownHeader isMobile={isMobile} isChecklist={false} isDropdown={isDropdown}>
                    <HeaderInfo>
                      <Title isMobile={isMobile} id="dropdownTitle">
                        Update
                      </Title>
                      {canUserEdit && (
                        <AssignedToWrapper isMobile={isMobile}>
                          <EditAssignedTo isMobile={isMobile} onClick={() => openModalWindow('change-assignee')}>
                            <CalendarIcon fill={theme.context.warning} />
                            <AssignedTo isMobile={isMobile}>
                              Assigned To:{' '}
                              <RedirectText disabled={false}>{`${documentRecordData?.getDocumentRecord?.assignee?.firstName ?? ''} ${
                                documentRecordData?.getDocumentRecord?.assignee?.lastName ?? ''
                              }`}</RedirectText>
                            </AssignedTo>
                            <EditIcon width={16} height={16} fill={theme.font.action} style={{ cursor: 'pointer', margin: '0 20px 0 6px' }} />
                          </EditAssignedTo>
                          {/* <NoteButton isVisible={true} openNotes={() => openNotes(documentRecordData?.getDocumentRecord)} /> */}
                        </AssignedToWrapper>
                      )}
                    </HeaderInfo>
                    <ArrowScoreWrapper isMobile={isMobile} isChecklist={Boolean(openModalWindow)}>
                      <ArrowWrapper
                        isMobile={isMobile}
                        onClick={() => setIsDropdown(!isDropdown)}
                        onMouseEnter={() => setArrowHover(true)}
                        onMouseLeave={() => setArrowHover(false)}
                      >
                        <>
                          {isDropdown ? (
                            <ArrowUp style={{ cursor: 'pointer' }} fill={arrowHover && !isMobile ? theme.context.light : theme.font.action} />
                          ) : (
                            <ArrowDown
                              style={{
                                cursor: 'pointer',
                                width: '28px',
                                height: '28px',
                                padding: '7px'
                              }}
                              fill={arrowHover && !isMobile ? theme.context.light : theme.font.action}
                            />
                          )}
                        </>
                      </ArrowWrapper>
                    </ArrowScoreWrapper>
                  </DropdownHeader>
                  <DropdownBody isMobile={isMobile} isDropdown={isDropdown}>
                    <ExpandableWrapp isMobile={isMobile} isDropdown={isDropdown}>
                      <SectionFields
                        fields={documentRecordData?.getDocumentRecord?.fields ?? []}
                        mode={canUserEdit ? 'edit' : 'view'}
                        openEditPage={openEditPage}
                        refetch={documentRecordRefetch}
                      />
                    </ExpandableWrapp>
                  </DropdownBody>
                </DropdownWrapper>
              </SectionsWrapper>
              <ButtonWrapper>
                <Button onClick={() => openModalWindow('download-pdf')} styleType="outline" size="md">
                  Download
                </Button>
                {canUserEdit && documentRecordData?.getDocumentRecord?.status === 'Draft' && (
                  <Button onClick={() => openModalWindow('publish-update')} size="md">
                    Publish
                  </Button>
                )}
                {canUserEdit && documentRecordData?.getDocumentRecord?.status === 'Published' && (
                  <Button onClick={() => openModalWindow('unpublish-update')} size="md">
                    Unpublish
                  </Button>
                )}
              </ButtonWrapper>
            </PaddingWrap>
          </MainWrap>
        </div>
      )}
      <AiPane
        recordId={documentRecordData?.getDocumentRecord?.id ?? ''}
        type="mu"
        documentRecordRefetch={documentRecordRefetch}
        files={files}
        setFiles={setFiles}
        isAiPaneOpen={isAiPaneOpen}
        onClose={() => setIsAiPaneOpen(false)}
        handleGenerateAnswersLoadingChange={handleGenerateAnswersLoadingChange}
        freeChatOnly={editFieldPageType !== 'default' && editFieldPageType !== 'advanced-options'}
        openAdvancedOptions={openAdvancedOptions}
      />
    </EditManagerUpdatesWrapper>
  );
};

const EditManagerUpdatesWrapper = styled.div`
  display: flex;
  justify-content: center;
  transition: all 0.3s;
`;

const StatusWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  padding: 0 20px;
  margin: 30px 0;
`;

const StatusText = styled.span<{ bold?: boolean; isMoreThan90DaysInPast?: boolean }>`
  font-weight: ${({ bold }) => (bold ? '700' : '600')};
  font-size: 16px;
  line-height: 22px;
  color: ${({ isMoreThan90DaysInPast, theme }) => (isMoreThan90DaysInPast ? theme.context.error : theme.font.base)};
`;

const SectionsWrapper = styled.div<{ canUserEdit: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: ${({ canUserEdit }) => (canUserEdit ? '0' : '50px')};
`;

const SeparateStick = styled.div`
  width: 1px;
  height: 33px;
  background-color: ${({ theme }) => theme.border.base};
`;

const Status = styled.div<{ isMobile: boolean; published?: boolean }>`
  display: flex;
  align-items: center;
  width: ${({ isMobile }) => (isMobile ? '100%' : 'auto')};
  gap: ${({ isMobile }) => (isMobile ? '5px' : '20px')};
  color: ${({ published }) => (published ? '#3dbd4a' : '#373F4E')};
`;

const AiAssistantButton = styled(Button)`
  &:hover {
    svg {
      path {
        fill: ${({ theme }) => theme.context.light};
      }
    }
  }
`;

const DropDownWrapper = styled.div<{ isMobile: boolean }>`
  padding-left: 10px;
  margin-left: ${({ isMobile }) => (isMobile ? 'auto' : '0')};
`;

const ArrowScoreWrapper = styled.div<{ isMobile: boolean; isChecklist: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${({ isMobile }) => (isMobile ? 'space-between' : 'flex-start')};
  margin-top: ${({ isMobile }) => (isMobile ? '10px' : '0')};
  gap: ${({ isMobile }) => (isMobile ? '0' : '30px')};
  width: ${({ isMobile, isChecklist }) => (isMobile && isChecklist ? '100%' : 'auto')};
  position: relative;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 20px 0;
`;

const EditTodoButton = styled.div<{ isMobile: boolean }>`
  background-color: ${({ theme }) => theme.action.default};
  border-radius: 4px;
  padding: 12px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  cursor: pointer;
  width: ${({ isMobile }) => (isMobile ? '100%' : 'auto')};
  opacity: ${({ isMobile }) => (isMobile ? '1' : '0')};
`;
