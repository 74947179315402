import { ElectronicSignatureIcon } from '@/assets/icons/electronicSignature';
import { WetSignatureIcon } from '@/assets/icons/wetSignature';
import RadioButton from '@/components/basicComponents/radioButton';
import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import { Loader } from '@/components/loaders/loader';
import { Controller, useForm, useWatch } from 'react-hook-form';
import styled from 'styled-components';

interface SendForSignatureProps {
  isOpen: boolean;
  onClose: () => void;
}

const SendForSignature = ({ isOpen, onClose }: SendForSignatureProps) => {
  const {
    handleSubmit,
    getValues,
    setValue,
    control,
    setError,
    formState: { errors, isValid },
    reset,
    watch
  } = useForm({ defaultValues: { radioButtonValue: 'Electronic Signature' } });

  const radioButtonValue = useWatch({ control, name: 'radioButtonValue' });

  if (false) {
    return (
      <ModalWrappedContent isOpen={isOpen} onClose={onClose} showCancelButton={false} showCloseIcon={false} isUseOutsideClick={false}>
        <ModalBody alignItems="center">
          <ElectronicSignatureIcon />
          <ModalTitle>Electronic Signatures</ModalTitle>
          <BannerDescription>Please wait as DocuSign prepares documents. </BannerDescription>
          <Loader />
        </ModalBody>
      </ModalWrappedContent>
    );
  }

  return (
    <ModalWrappedContent title="Send for Signature" isOpen={isOpen} onClose={onClose} confirmButton={{ name: 'Continue', onClick: () => {} }}>
      <ModalBody>
        <RadioButtonsWrapper>
          <Controller
            name="radioButtonValue"
            control={control}
            render={({ field: { value, onChange } }) => (
              <>
                {['Electronic Signature', 'Wet Signature'].map((option, index) => (
                  <RadioButton
                    key={option + index}
                    name="radioButtonValue"
                    radioButtonText={option}
                    selectedOption={value}
                    handleRadioChange={(event) => onChange(event.target.value)}
                  />
                ))}
              </>
            )}
          />
        </RadioButtonsWrapper>
        {radioButtonValue === 'Electronic Signature' && (
          <ElectronicSignatureBannerWrapper>
            <div>
              <ElectronicSignatureIcon />
            </div>
            <BannerContent>
              <BannerTitle>Electronic Signature</BannerTitle>
              <BannerDescription>Select this option to initiate Docusign to obtain signatures.</BannerDescription>
            </BannerContent>
          </ElectronicSignatureBannerWrapper>
        )}
        {radioButtonValue === 'Wet Signature' && (
          <ElectronicSignatureBannerWrapper>
            <div>
              <WetSignatureIcon />
            </div>
            <BannerContent>
              <BannerTitle>Wet Signature</BannerTitle>
              <BannerDescription>
                Select this option to download documents and provide wet signatures. Once signed, upload them to submit for review.
              </BannerDescription>
            </BannerContent>
          </ElectronicSignatureBannerWrapper>
        )}
      </ModalBody>
    </ModalWrappedContent>
  );
};

export default SendForSignature;

const ModalBody = styled.div<{ alignItems?: string }>`
  display: flex;
  align-items: ${({ alignItems }) => alignItems || 'flex-start'};
  flex-direction: column;
  gap: 10px;
`;

const ModalTitle = styled.h2`
  text-align: center;
  font-size: 33px;
  font-weight: 400;
  line-height: 46.2px;
  color: ${({ theme }) => theme.font.strong};
`;

const RadioButtonsWrapper = styled.div`
  display: flex;
`;

const ElectronicSignatureBannerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const BannerContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 20px;
  border-left: 1px solid ${({ theme }) => theme.border.base};
`;

const BannerTitle = styled.div`
  font-size: 19px;
  font-weight: 700;
  line-height: 25px;
  color: ${({ theme }) => theme.font.strong};
`;

const BannerDescription = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: ${({ theme }) => theme.font.base};
`;
