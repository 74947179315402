import EditStrategyDescription from '@/components/fat-dealPipeline-page/editStrategyDescription-page';
import useRefresh from '@/hooks/use-refresh';
import { Helmet as Head } from 'react-helmet';

const EditStrategyDescriptionPage = () => {
  useRefresh();
  return (
    <>
      <Head>
        <title>Edit Strategy Description</title>
      </Head>
      <EditStrategyDescription />
    </>
  );
};

export default EditStrategyDescriptionPage;
