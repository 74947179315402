import client from '@/apollo-client';
import { EditTodoIcon } from '@/assets/icons/info-icons/editTodo';
import { AddButtonIcon } from '@/assets/static/icons/add-button-icon';
import Button from '@/components/fat-basicComponents/button';
import { RENDERING_TEMPLATES_QUERY } from '@/components/fat-transactions-page/generateSignaturePacket-page/queries';
import {
  IRenderTemplate,
  IRenderTemplatePage,
  IRenderTemplates
} from '@/components/fat-transactions-page/generateSignaturePacket-page/templateComponents/types';
import { LoaderOnWholeScreen } from '@/components/loaders/loader-on-whole-screen';
import { useResponsive } from '@/hooks/use-responsive';
import { PaddingWrap } from '@/styles/common';
import { useQuery } from '@apollo/client';
import { Dispatch, SetStateAction, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { Section } from '../edit-entity/section';
import { GET_LEGAL_ENTITY_DOCUMENTATION_QUERY } from '../queries';
import { DocumentationDetails } from './documentationDetails';
import { DocumentationZeroState } from './documentationZeroState';
import DeleteConfirmationDialog from './modals/deleteConfirmationDialog';
import { PageRows } from './pageRows';
import { getTemplateWithFormData } from './utils';

interface DocumentationTabProps {
  template: IRenderTemplate | null;
  setTemplate: Dispatch<SetStateAction<IRenderTemplate | null>>;
  handleEditPage: ({ open, page }: { open: boolean; page: IRenderTemplatePage | null }) => void;
}

export const DocumentationTab = ({ template, setTemplate, handleEditPage }: DocumentationTabProps) => {
  const theme = useTheme();
  const params = useParams();
  const { isMobile } = useResponsive();
  const [searchParams, setSearchParams] = useSearchParams();
  const position = searchParams.get('position') || 0;

  const [showZeroState, setShowZeroState] = useState(false);
  const [modalWindow, setModalWindow] = useState({ isOpen: false, type: '' });

  const { loading: renderingTemplatesLoading } = useQuery<{ renderingTemplates: IRenderTemplates[] }>(RENDERING_TEMPLATES_QUERY, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        defaults: true
      }
    },
    onCompleted: async (data) => {
      try {
        const { data: legalEntityDocumentationData } = await client.query({
          query: GET_LEGAL_ENTITY_DOCUMENTATION_QUERY,
          variables: {
            id: params?.entityId
          }
        });

        const template = data.renderingTemplates[0].template;
        const updatedTemplatePages = getTemplateWithFormData(template?.pages ?? [], legalEntityDocumentationData.getLegalEntityDocumentation);
        setTemplate({ ...template, pages: updatedTemplatePages });
        makeScroll(position);
      } catch (error) {
        console.error(error);
        setTemplate(data.renderingTemplates[0].template);
      }
    },
    onError: (error) => {
      console.error(error);
    }
  });

  const makeScroll = (position: string | number) => {
    if (!position) return;
    setTimeout(() => window.scrollTo({ top: Number(position), behavior: 'smooth' }), 0);
    setSearchParams(
      (prev) => {
        prev.delete('position');
        return prev;
      },
      { replace: true }
    );
  };

  const openModalWindow = (type: string) => {
    setModalWindow({ type, isOpen: true });
  };

  const closeModalWindow = () => {
    setModalWindow({ ...modalWindow, isOpen: false });
  };
  console.log(template, 'template');

  if (renderingTemplatesLoading) {
    return <LoaderOnWholeScreen />;
  }

  if (showZeroState) {
    return (
      <>
        <DocumentationDetails />
        <DocumentationZeroState />
      </>
    );
  }

  return (
    <>
      {modalWindow.isOpen && modalWindow.type === 'delete-dialog' && (
        <DeleteConfirmationDialog isOpen={modalWindow.isOpen} onClose={closeModalWindow} />
      )}
      <DocumentationDetails />
      <PaddingWrap>
        <SectionsWrapper>
          {template?.pages?.map((page: IRenderTemplatePage, index) => (
            <Section key={page.title} title={page.title}>
              {page?.formData ? (
                <SectionContent isMobile={isMobile}>
                  <RowsWrapper>{page?.rows?.map((row) => <PageRows key={row.name} row={row} />)}</RowsWrapper>
                  <EditIconWrapper
                    onClick={() => handleEditPage({ open: true, page: template?.pages?.[index] || null })}
                    isMobile={isMobile}
                    id="editButton"
                  >
                    <EditTodoIcon />
                  </EditIconWrapper>
                </SectionContent>
              ) : (
                <SectionZeroStateWrapper>
                  <SectionZeroStateText>No information has been added yet.</SectionZeroStateText>
                  <Button
                    onClick={() => handleEditPage({ open: true, page: template?.pages?.[index] || null })}
                    styleType="outline"
                    icon={<AddButtonIcon fill={theme.font.action} />}
                    size="md"
                    width="max-content"
                  >
                    Add Information
                  </Button>
                </SectionZeroStateWrapper>
              )}
            </Section>
          ))}
        </SectionsWrapper>
      </PaddingWrap>
    </>
  );
};

const SectionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 50px 0;
`;

const SectionZeroStateWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
`;

const SectionZeroStateText = styled.div`
  padding: 10px 0;
  font-size: 19px;
  font-weight: 400;
  line-height: 26.6px;
  color: ${({ theme }) => theme.font.weak};
`;

const SectionContent = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: center;
  gap: 15px;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  padding: ${({ isMobile }) => (isMobile ? '0' : '20px')};

  @media (hover: hover) {
    &:hover #editButton {
      opacity: 1;
    }

    &:hover {
      background-color: ${({ theme }) => theme.layer[12]};
    }
  }
`;

const RowsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

const EditIconWrapper = styled.div<{ isMobile: boolean }>`
  width: ${({ isMobile }) => (isMobile ? '100%' : 'max-content')};
  padding: 12px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.action.default};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  cursor: pointer;
  opacity: ${({ isMobile }) => (isMobile ? '1' : '0')};
  transition: 0.3s ease-in-out;
`;
