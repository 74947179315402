import { AiAssistantIcon } from '@/assets/static/icons/ai-assistant';
import Textarea from '@/components/basicComponents/textarea';
import { LoaderOnWholeScreen } from '@/components/loaders/loader-on-whole-screen';
import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { updateDocumentFieldAnswerMutation } from '../../queries';
import { IDocumentRecordFieldEntity } from '../../types';
import { EditWrapper } from './editWrapper';

interface ShortTextPageProps {
  row: IDocumentRecordFieldEntity | null;
  investmentId: string | null;
  shortTextAdditionalProperties?: {
    maxLength: number;
  };
  refetch: () => void;
  handleClose: () => void;
}

const DEFAULT_ERROR_MESSAGE = `We're sorry. This process did not complete. Please try again.`;

export const ShortTextPage = ({
  row,
  investmentId,
  shortTextAdditionalProperties = { maxLength: 250 },
  refetch,
  handleClose
}: ShortTextPageProps) => {
  const theme = useTheme();

  const [value, setValue] = useState('');
  const [isEditMode, setIsEditMode] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [updateDocumentFieldAnswer, { loading: updateDocumentFieldAnswerLoading }] = useMutation(updateDocumentFieldAnswerMutation, {
    onError: (error) => {
      console.error(error);
      setErrorMessage(DEFAULT_ERROR_MESSAGE);
    }
  });

  useEffect(() => {
    if (!row) return;

    if (row?.value === null && row.AI?.answers.length) {
      setIsEditMode(false);
      setValue(row.AI.answers[0]);
    } else {
      setIsEditMode(true);
      setValue(row?.value || '');
    }
  }, [row]);

  const saveValue = () => {
    setErrorMessage(null);

    updateDocumentFieldAnswer({
      variables: {
        data: {
          fieldId: row.fieldId,
          recordId: row.recordId,
          value: isEditMode ? value : null
        }
      },
      onCompleted: () => {
        refetch();
        setValue('');
        handleClose();
      }
    });
  };

  const editSuggested = (suggestion: string) => {
    setValue(suggestion);
    setIsEditMode(true);
  };

  const clearSuggestion = () => {
    setValue('');
    setIsEditMode(true);
  };

  const startWithAISuggestion = () => {
    setValue(row.AI?.answers[0]);
    setIsEditMode(false);
  };

  const revertPriorDraft = () => {
    if (row?.value) {
      setValue(row.value);
      setIsEditMode(true);
    }
  };

  if (!row) return <></>;

  if (updateDocumentFieldAnswerLoading) {
    return <LoaderOnWholeScreen size={60} />;
  }

  return (
    <EditWrapper
      showAiSuggestion={Boolean(row.AI?.answers.length > 0)}
      isEditMode={isEditMode}
      startWithAISuggestion={startWithAISuggestion}
      editSuggested={editSuggested}
      clearSuggestion={clearSuggestion}
      revertPriorDraft={revertPriorDraft}
      showRevertPriorDraft={Boolean(row?.value) && row?.value !== value}
      handleClose={handleClose}
      row={row}
      investmentId={investmentId}
      recordId={row.recordId}
      confirmButton={{ name: 'Save', onClick: saveValue, disabled: value.length > shortTextAdditionalProperties.maxLength }}
      errorMessage={errorMessage && 'Error'}
      errorDescription={errorMessage || null}
    >
      {isEditMode ? (
        <Textarea value={value} setValue={setValue} maxLength={shortTextAdditionalProperties.maxLength} isValidValue={!!value.length} />
      ) : (
        <AnswerWrap>
          <AiIconWrap>
            <AiAssistantIcon width={28} height={28} fill={theme.font.weak} />
          </AiIconWrap>
          <Answer>
            <span style={{ fontWeight: 700 }}>Suggested {row.AI?.citations.length >= 2 && `(${row.AI?.citations.length - 1} more)`}:</span>{' '}
            {row.AI?.answers[0]}
          </Answer>
        </AnswerWrap>
      )}
    </EditWrapper>
  );
};

const AnswerWrap = styled.div`
  display: flex;
  font-size: 16px;
  background: ${({ theme }) => theme.action.disabled};
  height: 120px;
  padding: 5px 8px;
  border: 1px solid ${({ theme }) => theme.border.base};
  border-radius: 3px;
`;

const AiIconWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-right: 1px solid ${({ theme }) => theme.border.base};
`;

const Answer = styled.div`
  padding-left: 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  color: ${({ theme }) => theme.font.base};
  overflow-y: auto;
`;
