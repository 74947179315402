import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import RedirectTitleCell from '@/components/table/redirectTitleCell';
import { blinkingFrame, TableRowCell, TitleCell } from '@/components/table/styles';
import styled from 'styled-components';
import { CommitmentForecastValueDto } from '../../../types';

interface TableRowProps {
  row: CommitmentForecastValueDto;
  columnWidth: number;
  openOpportunityDetailsPage: (id: string) => void;
}

const TableRow = ({ row, columnWidth, openOpportunityDetailsPage }: TableRowProps) => {
  return (
    <>
      <TableRowWrapper>
        <TableRowCell width={15}>
          <RedirectTitleCell id="actionCurioText" onClick={() => openOpportunityDetailsPage(row.investmentId)}>
            {row.name}
          </RedirectTitleCell>
        </TableRowCell>
        {row.data.map((item) => (
          <TableRowCell key={item.date} width={columnWidth}>
            <TextCell>
              <FormattingTooltip zIndex={1000}>{item.value}</FormattingTooltip>
            </TextCell>
          </TableRowCell>
        ))}
      </TableRowWrapper>
    </>
  );
};

export default TableRow;

const TableRowWrapper = styled.div<{ addAdaptive?: boolean; loading?: boolean | number }>`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 60px;
  background-color: ${({ theme }) => theme.layer[1]};
  border-radius: 8px;
  padding: 0 24px;
  gap: 5px;
  opacity: ${({ loading }) => (loading ? 0.5 : 1)};
  pointer-events: ${({ loading }) => (loading ? 'none' : 'auto')};
  animation: ${({ loading }) => (loading ? blinkingFrame : null)} 1.5s infinite;

  &:hover #allocationInput {
    border: 1px solid ${({ theme }) => theme.border.base};
  }

  &:hover #actionCurioText {
    color: ${({ theme }) => theme.font.action};
  }

  &:hover {
    background-color: ${({ theme }) => theme.layer[12]};
    transition: background-color 100ms ease;
  }
`;

const TextCell = styled(TitleCell)<{ bold?: boolean }>`
  font-weight: ${({ bold }) => (bold ? 600 : 400)};
`;
