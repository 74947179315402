import { EditTodoIcon } from '@/assets/icons/info-icons/editTodo';
import { useResponsive } from '@/hooks/use-responsive';
import styled, { useTheme } from 'styled-components';

export const DocumentationDetails = () => {
  const { isMobile } = useResponsive();
  const theme = useTheme();

  return (
    <DocumentationDetailsWrapper isMobile={isMobile}>
      <Card isMobile={isMobile}>
        <CardTitle>TAX STATUS</CardTitle>
        <CardDesc>Exempt</CardDesc>
      </Card>
      <Card isMobile={isMobile}>
        <CardTitle>ENTITY TYPE</CardTitle>
        <CardDesc>--</CardDesc>
      </Card>
      <Card isMobile={isMobile}>
        <CardTitle>ELIGIBILITY</CardTitle>
        <CardDesc>--</CardDesc>
      </Card>
      <Card short isMobile={isMobile}>
        <EditTodoIcon width={30} height={24} fill={theme.font.action} />
      </Card>
    </DocumentationDetailsWrapper>
  );
};

const DocumentationDetailsWrapper = styled.div<{ isMobile: boolean }>`
  background-color: ${({ theme }) => theme.layer[1]};
  color: ${({ theme }) => theme.font.base};
  display: flex;
  align-items: center;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  gap: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.border.base};
  padding: 20px 16px 16px;
  @media (min-width: 600px) {
    padding: 20px 50px 50px;
  }
`;

const Card = styled.div<{ short?: boolean; isMobile: boolean }>`
  display: flex;
  cursor: ${({ short }) => (short ? 'pointer' : 'default')};
  width: ${({ short, isMobile }) => (isMobile ? '100%' : short ? '7%' : '31%')};
  min-width: 80px;
  height: 80px;
  min-height: 80px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.layer.base};
  border-radius: 10px;
`;

const CardTitle = styled.div`
  color: ${({ theme }) => theme.font.weak};
  font-family: Blinker;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.4px;
`;

const CardDesc = styled.div`
  color: ${({ theme }) => theme.font.strong};
  font-family: Blinker;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: 32.3px;
`;
