import { format } from 'date-fns';
import styled, { useTheme } from 'styled-components';

import { ApprovedIcon } from '@/assets/icons/info-icons/approved';
import { EditTodoIcon } from '@/assets/icons/info-icons/editTodo';
import { RedStarIcon } from '@/assets/icons/info-icons/redStar';
import { AiAssistantIcon } from '@/assets/static/icons/ai-assistant';
import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import ShowText from '@/components/dueDiligence-page/dueDiligenceRecord/showText';
import { decimalToPercentage, isImageExtension } from '@/components/dueDiligence-page/dueDiligenceRecord/utils';
import { useResponsive } from '@/hooks/use-responsive';
import { formatCash } from '@/utils/formatCash';
import { useMutation } from '@apollo/client';
import { headingsPlugin, linkDialogPlugin, linkPlugin, listsPlugin, MDXEditor, MDXEditorMethods } from '@mdxeditor/editor';
import React, { MutableRefObject, useEffect, useRef } from 'react';
import { DefaultTheme } from 'styled-components/dist/types';
import { updateDocumentFieldAnswerMutation } from '../queries';
import { IDocumentRecordFieldEntity } from '../types';
import { extractAndFormatDate } from './edit-pages/dateContent';
import { extractAmount } from './edit-pages/dollarContent';
import { extractNumbersFromString } from './edit-pages/numberContent';
import { extractPercentage } from './edit-pages/percentageContent';

const fieldValue = (
  row: IDocumentRecordFieldEntity,
  index: number,
  theme: DefaultTheme,
  longFormContentEditorRefs: MutableRefObject<MDXEditorMethods[]>,
  longFormContentContainerEditorRefs: MutableRefObject<HTMLDivElement[]>
) => {
  switch (row.documentField.type) {
    case 'long_form_content':
      if (row.value === null) {
        return (
          <>
            {row.AI?.answers.length > 0 ? (
              <LongFormWrap lineHeight="22px">
                <PreviewImageWrapper>
                  {row.attachments.map((item, index) => (
                    <React.Fragment key={item.secure_blob_storage_asset.assetKey}>
                      {isImageExtension(item.secure_blob_storage_asset.assetKey) ? (
                        <ImageWrapper key={item.secure_blob_storage_asset.assetKey + index}>
                          <ImageItem src={item.secure_blob_storage_asset.url} alt={item.secure_blob_storage_asset.assetKey} />
                        </ImageWrapper>
                      ) : (
                        <></>
                      )}
                    </React.Fragment>
                  ))}
                </PreviewImageWrapper>
                <AiRowCellWrap>
                  <AiIconWrap>
                    <AiAssistantIcon width={28} height={28} fill={theme.font.base} />
                  </AiIconWrap>
                  <AiAnswer>
                    <ShowText>
                      <span style={{ fontWeight: 700 }}>Suggested {row.AI?.citations.length >= 2 && `(${row.AI?.citations.length - 1} more)`}:</span>{' '}
                      {row.AI.answers[0]}
                    </ShowText>
                  </AiAnswer>
                </AiRowCellWrap>
              </LongFormWrap>
            ) : (
              <EmptyTodoRowCell>To Do</EmptyTodoRowCell>
            )}
          </>
        );
      }
      return (
        <LongFormWrap lineHeight="inherit">
          <PreviewImageWrapper>
            {row.attachments.map((item, index) => (
              <React.Fragment key={item.secure_blob_storage_asset.assetKey}>
                {isImageExtension(item.secure_blob_storage_asset.assetKey) ? (
                  <ImageWrapper key={item.secure_blob_storage_asset.assetKey + index}>
                    <ImageItem src={item.secure_blob_storage_asset.url} alt={item.secure_blob_storage_asset.assetKey} />
                  </ImageWrapper>
                ) : (
                  <></>
                )}
              </React.Fragment>
            ))}
          </PreviewImageWrapper>
          <ShowText>
            {row.value && (
              <div ref={(el) => (longFormContentContainerEditorRefs.current[index] = el)}>
                <CustomMDXEditor
                  ref={(el) => (longFormContentEditorRefs.current[index] = el)}
                  markdown={row.value || ''}
                  readOnly
                  plugins={[listsPlugin(), headingsPlugin(), linkPlugin(), linkDialogPlugin()]}
                />
              </div>
            )}
          </ShowText>
        </LongFormWrap>
      );
    case 'short_text':
      if (row.value === null) {
        return (
          <>
            {row.AI?.answers.length > 0 ? (
              <AiRowCellWrap>
                <AiIconWrap>
                  <AiAssistantIcon width={28} height={28} fill={theme.font.base} />
                </AiIconWrap>
                <AiAnswer>
                  <ShowText>
                    <span style={{ fontWeight: 700 }}>Suggested {row.AI?.citations.length >= 2 && `(${row.AI?.citations.length - 1} more)`}:</span>{' '}
                    {row.AI.answers[0]}
                  </ShowText>
                </AiAnswer>
              </AiRowCellWrap>
            ) : (
              <EmptyTodoRowCell>To Do</EmptyTodoRowCell>
            )}
          </>
        );
      }
      return <FieldValueWrapper>{row.value}</FieldValueWrapper>;
    case 'number':
      if (row.value === null && row.commentary === null) {
        return (
          <>
            {row.AI?.answers.length > 0 ? (
              <AiRowCellWrap>
                <AiIconWrap>
                  <AiAssistantIcon width={28} height={28} fill={theme.font.base} />
                </AiIconWrap>
                <AiAnswer>
                  <ShowText>
                    <span style={{ fontWeight: 700 }}>Suggested {row.AI?.citations.length >= 2 && `(${row.AI?.citations.length - 1} more)`}: </span>
                    {extractNumbersFromString(row.AI?.answers[0]) && `${extractNumbersFromString(row.AI?.answers[0])} | `}
                    {row.AI.answers[0]}
                  </ShowText>
                </AiAnswer>
              </AiRowCellWrap>
            ) : (
              <EmptyTodoRowCell>To Do</EmptyTodoRowCell>
            )}
          </>
        );
      }
      return (
        <FieldValueWrapper>
          {row.value && row.commentary ? `${row.value} | ${row.commentary}` : (row.value && row.value) || (row.commentary && row.commentary)}
        </FieldValueWrapper>
      );
    case 'dollar':
      if (row.value === null && row.commentary === null) {
        return (
          <>
            {row.AI?.answers.length > 0 ? (
              <AiRowCellWrap>
                <AiIconWrap>
                  <AiAssistantIcon width={28} height={28} fill={theme.font.base} />
                </AiIconWrap>
                <AiAnswer>
                  <ShowText>
                    <span style={{ fontWeight: 700 }}>
                      Suggested
                      {row.AI?.citations.length >= 2 && `(${row.AI?.citations.length - 1} more)`}:{' '}
                    </span>
                    {extractAmount(row.AI?.answers[0]) && `${formatCash(+extractAmount(row.AI?.answers[0]))} | `}
                    {row.AI.answers[0]}
                  </ShowText>
                </AiAnswer>
              </AiRowCellWrap>
            ) : (
              <EmptyTodoRowCell>To Do</EmptyTodoRowCell>
            )}
          </>
        );
      }
      return (
        <FieldValueWrapper>
          {row.value && row.commentary ? (
            <>
              <FormattingTooltip zIndex={1000}>{+row.value}</FormattingTooltip> | {row.commentary}
            </>
          ) : (
            (row.value && <FormattingTooltip zIndex={1000}>{+row.value}</FormattingTooltip>) || (row.commentary && row.commentary)
          )}
        </FieldValueWrapper>
      );
    case 'percentage':
      if (row.value === null && row.commentary === null) {
        return (
          <>
            {row.AI?.answers.length > 0 ? (
              <AiRowCellWrap>
                <AiIconWrap>
                  <AiAssistantIcon width={28} height={28} fill={theme.font.base} />
                </AiIconWrap>
                <AiAnswer>
                  <ShowText>
                    <span style={{ fontWeight: 700 }}>Suggested {row.AI?.citations.length >= 2 && `(${row.AI?.citations.length - 1} more)`}: </span>
                    {extractPercentage(row.AI?.answers[0]) && `${decimalToPercentage(extractPercentage(row.AI?.answers[0]))} | `}
                    {row.AI.answers[0]}
                  </ShowText>
                </AiAnswer>
              </AiRowCellWrap>
            ) : (
              <EmptyTodoRowCell>To Do</EmptyTodoRowCell>
            )}
          </>
        );
      }
      return (
        <FieldValueWrapper>
          {row.value && row.commentary
            ? `${decimalToPercentage(row.value as string)} | ${row.commentary}`
            : (row.value && decimalToPercentage(row.value as string)) || (row.commentary && row.commentary)}
        </FieldValueWrapper>
      );
    case 'date':
      if (row.value === null && row.commentary === null) {
        return (
          <>
            {row.AI?.answers.length > 0 ? (
              <AiRowCellWrap>
                <AiIconWrap>
                  <AiAssistantIcon width={28} height={28} fill={theme.font.base} />
                </AiIconWrap>
                <AiAnswer>
                  <ShowText>
                    <span style={{ fontWeight: 700 }}>Suggested {row.AI?.citations.length >= 2 && `(${row.AI?.citations.length - 1} more)`}: </span>
                    {extractAndFormatDate(row.AI?.answers[0]) && `${format(new Date(extractAndFormatDate(row.AI?.answers[0])), 'LLL d, yyyy')} | `}
                    {row.AI.answers[0]}
                  </ShowText>
                </AiAnswer>
              </AiRowCellWrap>
            ) : (
              <EmptyTodoRowCell>To Do</EmptyTodoRowCell>
            )}
          </>
        );
      }
      return (
        <FieldValueWrapper>
          {row.value && row.commentary
            ? `${format(new Date(row.value), 'LLL d, yyyy')} | ${row.commentary}`
            : (row.value && format(new Date(row.value), 'LLL d, yyyy')) || (row.commentary && row.commentary)}
        </FieldValueWrapper>
      );
  }
};

const getDataForUpdate = (row: IDocumentRecordFieldEntity): { value: string; commentary: string } => {
  switch (row.documentField.type) {
    case 'long_form_content':
      return {
        value: row.AI.answers[0],
        commentary: ''
      };
    case 'short_text':
      return {
        value: row.AI.answers[0],
        commentary: ''
      };
    case 'number':
      return {
        value: extractNumbersFromString(row.AI.answers[0]),
        commentary: row.AI.answers[0]
      };
    case 'dollar':
      return {
        value: extractAmount(row.AI.answers[0]),
        commentary: row.AI.answers[0]
      };
    case 'percentage':
      return {
        value: extractPercentage(row.AI.answers[0]),
        commentary: row.AI.answers[0]
      };
    case 'date':
      return {
        value: extractAndFormatDate(row.AI.answers[0]),
        commentary: row.AI.answers[0]
      };
    default:
      return {
        value: '',
        commentary: ''
      };
  }
};

const showAcceptAiAnswerButton = (row: IDocumentRecordFieldEntity) => {
  if (!row.AI?.answers.length) return false;

  if (row.documentField.type === 'long_form_content' || row.documentField.type === 'short_text') {
    return row.value === null;
  }
  return row.value === null && row.commentary === null;
};

interface SectionFieldsProps {
  fields: IDocumentRecordFieldEntity[];
  mode: 'view' | 'edit';
  refetch?: () => void;
  openEditPage?: (row: IDocumentRecordFieldEntity) => void;
}

export const SectionFields = ({ fields, mode, refetch = () => {}, openEditPage }: SectionFieldsProps) => {
  const { isMobile } = useResponsive();
  const theme = useTheme();

  const longFormContentEditorRefs = useRef<(MDXEditorMethods | null)[]>([]);
  const longFormContentContainerEditorRefs: MutableRefObject<(HTMLDivElement | null)[]> = useRef([]);

  const [updateDocumentFieldAnswer] = useMutation(updateDocumentFieldAnswerMutation, {
    onError: (error) => {
      console.error(error);
    }
  });

  useEffect(() => {
    longFormContentEditorRefs?.current?.forEach((editorRef, index) => {
      if (editorRef) {
        editorRef.setMarkdown(fields[index].value || '');
      }
    });
  }, [fields]);

  useEffect(() => {
    longFormContentContainerEditorRefs?.current?.forEach((containerEditorRef) => {
      if (containerEditorRef) {
        const links: NodeListOf<HTMLAnchorElement> = containerEditorRef.querySelectorAll('a');
        links.forEach((link: HTMLAnchorElement) => {
          link.setAttribute('target', '_blank');
        });
      }
    });
  }, [fields]);

  const acceptAiAnswer = (row: IDocumentRecordFieldEntity) => {
    const { value, commentary } = getDataForUpdate(row);

    updateDocumentFieldAnswer({
      variables: {
        data: {
          fieldId: row.fieldId,
          recordId: row.recordId,
          value,
          commentary
        }
      },
      onCompleted: () => {
        refetch();
      }
    });
  };

  return (
    <>
      {fields.map((row, index) => (
        <RowWrapper key={row.id} isMobile={isMobile}>
          <InfoRowCell>
            {false ? <RedStarIcon width={10} height={10} /> : <NoRequired />}
            {row.documentField.name}
          </InfoRowCell>
          {fieldValue(row, index, theme, longFormContentEditorRefs, longFormContentContainerEditorRefs)}
          {mode === 'edit' && (
            <ButtonsWrapper>
              {showAcceptAiAnswerButton(row) && (
                <AcceptAiAnswerButton onClick={() => acceptAiAnswer(row)} id="editTodoButton" isMobile={isMobile}>
                  <ApprovedIcon width={23} height={23} fill={theme.font.action} />
                </AcceptAiAnswerButton>
              )}
              <EditTodoButton onClick={() => openEditPage(row)} id="editTodoButton" isMobile={isMobile}>
                <EditTodoIcon fill={theme.context.light} />
              </EditTodoButton>
            </ButtonsWrapper>
          )}
        </RowWrapper>
      ))}
    </>
  );
};

const RowWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: ${({ isMobile }) => (isMobile ? 'flex-start' : 'center')};
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  gap: ${({ isMobile }) => (isMobile ? '20px' : '0')};
  padding: 28px 12px;
  border-bottom: 1px solid ${({ theme }) => theme.border.base};

  &:hover #editTodoButton {
    opacity: 1;
  }

  &:hover {
    background-color: ${({ theme }) => theme.layer[2]};
    transition: background-color 100ms ease;
  }
`;

const InfoRowCell = styled.div`
  max-width: 380px;
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.font.strong};
  display: flex;
  gap: 10px;
  align-items: center;
`;

const NoRequired = styled.div`
  width: 10px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
  padding: 0 10px;
  margin-left: auto;
`;

const EditTodoButton = styled.div<{ isMobile: boolean }>`
  background-color: ${({ theme }) => theme.action.default};
  border-radius: 4px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: ${({ isMobile }) => (isMobile ? '100%' : '40px')};
  opacity: ${({ isMobile }) => (isMobile ? '1' : '0')};
`;

const AcceptAiAnswerButton = styled(EditTodoButton)`
  border: 1px solid ${({ theme }) => theme.font.action};
  background: ${({ theme }) => theme.layer.base};
`;

const AiRowCellWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const AiIconWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  height: 100%;
`;

const AiAnswer = styled.div`
  padding-left: 10px;
  border-left: 1px solid ${({ theme }) => theme.border.base};
`;

const EmptyTodoRowCell = styled.div`
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.font.weak};
`;

const FieldValueWrapper = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: ${({ theme }) => theme.font.base};
  max-width: 680px;
  width: 100%;
`;

const LongFormWrap = styled.div<{ lineHeight: string }>`
  display: flex;
  flex-direction: column;
  gap: 10px;

  #shortTextRowCell {
    line-height: ${({ lineHeight }) => lineHeight};
  }
`;

const CustomMDXEditor = styled(MDXEditor)`
  font-family: Blinker;
  font-size: 16px;
  .mdxeditor-root-contenteditable div {
    padding: 0;
  }
  .mdxeditor-root-contenteditable ul,
  .mdxeditor-root-contenteditable ol {
    padding-left: 26px;
  }
`;

const PreviewImageWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const ImageWrapper = styled.div`
  width: 183px;
  height: 120px;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.action.active};
  border-radius: 5px;
  padding: 10px;
`;

const ImageItem = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
