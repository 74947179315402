import { FilterSearchIcon } from '@/assets/icons/filterSearch';
import { CloseInfoIcon } from '@/assets/icons/info-icons/closeInfo';
import { useResponsive } from '@/hooks/use-responsive';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import styled, { useTheme } from 'styled-components';

interface SearchProps {
  value: string;
  onChange: (value: string) => void;
  placeholder: string;
  width?: string;
}

const Search = ({ value, onChange, placeholder, width = '270px' }: SearchProps) => {
  const theme = useTheme();
  const { isMobile, isTablet } = useResponsive();

  const [isActive, setIsActive] = useState(isMobile || isTablet);

  const closeSearch = () => {
    if (isMobile || isTablet) return;

    setIsActive(false);
    onChange('');
  };

  return (
    <FilterSearchContainer width={width} isActive={isActive}>
      <AnimatePresence mode="wait">
        {isActive ? (
          <SearchWrapper
            key="search"
            initial={{ opacity: 0, width: '0%' }}
            animate={{ opacity: 1, width: '100%' }}
            exit={{ opacity: 0, width: '0%' }}
          >
            <SearchIconWrapper borderColor={theme.layer.base} bgColor={theme.layer.base}>
              <FilterSearchIcon fill={theme.font.action} />
            </SearchIconWrapper>
            <Input type="text" value={value} onChange={(e) => onChange(e.target.value)} placeholder={placeholder} autoFocus />
            <SearchIconWrapper
              borderColor={isMobile || isTablet ? theme.layer[1] : theme.action.default}
              bgColor={isMobile || isTablet ? theme.layer[1] : theme.action.default}
              onClick={closeSearch}
            >
              {!isMobile && !isTablet && <CloseInfoIcon fill={theme.context.light} width={10} height={10} />}
            </SearchIconWrapper>
          </SearchWrapper>
        ) : (
          <motion.div key="icon" initial={{ width: '0%' }} exit={{ width: '0%' }} style={{ padding: '5px' }}>
            <SearchIconWrapper borderColor={theme.font.action} bgColor={theme.layer.base} onClick={() => setIsActive(true)}>
              <FilterSearchIcon fill={theme.font.action} />
            </SearchIconWrapper>
          </motion.div>
        )}
      </AnimatePresence>
    </FilterSearchContainer>
  );
};

const FilterSearchContainer = styled.div<{ width: string; isActive: boolean }>`
  width: ${({ width, isActive }) => (isActive ? width : 'max-content')};
`;

const SearchWrapper = styled(motion.div)`
  display: flex;
  gap: 4px;
  border-radius: 24px;
  padding: 4px;
  background: ${({ theme }) => theme.layer[1]};
`;

const SearchIconWrapper = styled.div<{ borderColor: string; bgColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 32px;
  min-height: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid ${({ borderColor }) => borderColor};
  background: ${({ bgColor }) => bgColor};
  cursor: pointer;
`;

const Input = styled.input`
  font-family: Blinker;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  padding: 5px 8px;
  color: ${({ theme }) => theme.font.strong};
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.border.base};
  background: ${({ theme }) => theme.layer[1]};
  width: 100%;

  &:focus {
    outline: none;
  }
`;

export default Search;
