import { ApprovedIcon } from '@/assets/icons/info-icons/approved';
import styled, { useTheme } from 'styled-components';

interface ProgressBarProps {
  steps: { name: string; status: string }[];
}

export const SignaturePacketWizardProgressBar = ({ steps }: ProgressBarProps) => {
  const theme = useTheme();
  return (
    <ProgressBarWrapper>
      <ProgressBar>
        {steps.map((step) => (
          <StepItemWrapper key={step.name}>
            {step.status === 'COMPLETED' ? (
              <ApprovedIcon fill={theme.context.success} />
            ) : (
              <>
                <StepItem isStepInProgress={step.status === 'IN_PROGRESS'}>{step.status === 'IN_PROGRESS' && <Circle />}</StepItem>
              </>
            )}
          </StepItemWrapper>
        ))}
      </ProgressBar>
      <StepName>{steps.find((step) => step.status === 'IN_PROGRESS')?.name || ''}</StepName>
    </ProgressBarWrapper>
  );
};

const ProgressBarWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 30px;
`;

const ProgressBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: max-content;
  padding-bottom: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.border.base};
`;

const StepItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
`;

const StepItem = styled.div<{ isStepInProgress: boolean }>`
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: 2px solid ${({ isStepInProgress, theme }) => (isStepInProgress ? theme.action.hover : theme.layer[2])};
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.layer.base};
`;

const StepName = styled.p`
  color: ${({ theme }) => theme.font.strong};
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
`;

const Circle = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: ${({ theme }) => theme.action.hover};
`;
