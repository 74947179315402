import { FormFooter } from '@/components/fat-basicComponents/formFooter';
import Input from '@/components/fat-basicComponents/input';
import Select from '@/components/fat-basicComponents/select';
import Header from '@/components/fat-header';
import { GoBackButton } from '@/components/fat-header/goBackButton';
import { PageTitle } from '@/components/fat-header/pageTitle';
import { useResponsive } from '@/hooks/use-responsive';
import { MainWrap, PaddingWrap } from '@/styles/common';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { AddressComponent } from '../templateComponents/rowComponents/addressComponent';
import { HeaderComponent, ThreeColumnWrapper, TwoColumnWrapper } from '../templateComponents/styles';
import { IRenderTemplateRow } from '../templateComponents/types';

interface EnterBankAccountInfoModalPageProps {
  row: IRenderTemplateRow | null;
  index: number;
  bankAccountData: [];
  ignoreRequired?: boolean;
  saveBankAccountInfo: (name: string, data: any) => void;
  closeModalWindow: () => void;
}

export const EnterBankAccountInfoModalPage = ({
  row,
  index,
  bankAccountData,
  ignoreRequired = false,
  saveBankAccountInfo,
  closeModalWindow
}: EnterBankAccountInfoModalPageProps) => {
  const { isMobile } = useResponsive();

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    reset
  } = useForm();

  useEffect(() => {
    if (!bankAccountData.length) return;
    reset({ [row.name]: bankAccountData });
  }, [bankAccountData]);

  const onSave = (data: any) => {
    saveBankAccountInfo(row?.name || '', data[row.name]);
    closeModalWindow();
  };

  return (
    <MainWrap>
      <Header modalControl={<GoBackButton handleClose={closeModalWindow} />} />
      <PageTitle title="Enter Bank Account Info" />
      <PaddingWrap>
        <BankAccountInfoForm>
          <BankAccountInfoWrapper isMobile={isMobile}>
            <RowWrapper>
              <HeaderComponent>Beneficiary Bank Details</HeaderComponent>
              <ThreeColumnWrapper isMobile={isMobile}>
                <Controller
                  name={`${row.name}.${index}.moneyTransferType`}
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Select label="Money Transfer Type" value={value || ''} options={[]} setValue={onChange} errorText={error?.message} size="md" />
                  )}
                />
                <Controller
                  name={`${row.name}.${index}.moneyTransferCode`}
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Input
                      label="Money Transfer Code"
                      value={value || ''}
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                      errorText={error?.message}
                      size="md"
                    />
                  )}
                />
                <Controller
                  name={`${row.name}.${index}.beneficiaryBankName`}
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Input
                      label="Beneficiary Bank Name"
                      value={value || ''}
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                      errorText={error?.message}
                      size="md"
                    />
                  )}
                />
              </ThreeColumnWrapper>
            </RowWrapper>
            <RowWrapper>
              <HeaderComponent>Beneficiary Bank Address</HeaderComponent>
              <AddressComponent
                row={{
                  name: `${row.name}.${index}`,
                  component: 'address',
                  config: {
                    ...row.config,
                    labels: {
                      streetAddress: 'Bank Street Address'
                    }
                  }
                }}
                ignoreRequired={ignoreRequired}
                control={control}
              />
            </RowWrapper>
            <RowWrapper>
              <HeaderComponent>Beneficiary Account Details</HeaderComponent>
              <TwoColumnWrapper isMobile={isMobile}>
                <Controller
                  name={`${row.name}.${index}.beneficiaryAccountName`}
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Input
                      label="Beneficiary Account Name"
                      value={value || ''}
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                      errorText={error?.message}
                      size="md"
                    />
                  )}
                />
                <Controller
                  name={`${row.name}.${index}.beneficiaryAccountNumber`}
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Input
                      label="Beneficiary Account Number"
                      value={value || ''}
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                      errorText={error?.message}
                      size="md"
                    />
                  )}
                />
              </TwoColumnWrapper>
              <TwoColumnWrapper isMobile={isMobile}>
                <Controller
                  name={`${row.name}.${index}.forFurtherCredit`}
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Input
                      label="For Further Credit"
                      value={value || ''}
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                      errorText={error?.message}
                      size="md"
                    />
                  )}
                />
              </TwoColumnWrapper>
            </RowWrapper>
          </BankAccountInfoWrapper>
          <FormFooter onCancel={closeModalWindow} onSave={handleSubmit(onSave)} disableSaveButton={false} showRequiredFieldsWarning={false} />
        </BankAccountInfoForm>
      </PaddingWrap>
    </MainWrap>
  );
};

const BankAccountInfoForm = styled.form`
  margin-top: 50px;
`;

const BankAccountInfoWrapper = styled.div<{ isMobile: boolean }>`
  padding: ${({ isMobile }) => (isMobile ? '20px' : '40px')};
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.border.base};
  background-color: ${({ theme }) => theme.layer[1]};
  display: flex;
  flex-direction: column;
  gap: ${({ isMobile }) => (isMobile ? '30px' : '40px')};
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;
