import { InfoIcon } from '@/assets/icons/info-icons/info';
import { DeleteBucketIcon } from '@/assets/static/icons/delete-bucket';
import { deleteInvestmentOfferingMaterialsAttachment } from '@/components/allocations-page/queries';
import { Banner } from '@/components/basicComponents/banner';
import ModalLoader from '@/components/fat-basicComponents/modal/modalLoader';
import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import { IOfferingMaterialsAttachments } from '@/components/fat-dealCatalog-page/types';
import { useMutation } from '@apollo/client';
import { Dispatch, SetStateAction, useState } from 'react';
import styled, { useTheme } from 'styled-components';

interface DeleteAttachmentProps {
  isOpen: boolean;
  attachmentId: string | null;
  setUploadedFiles: Dispatch<SetStateAction<IOfferingMaterialsAttachments[] | []>>;
  onClose: () => void;
}

const DEFAULT_ERROR_MESSAGE = `We're sorry. This process did not complete. Please try again.`;

const DeleteAttachment = ({ isOpen, attachmentId, setUploadedFiles, onClose }: DeleteAttachmentProps) => {
  const theme = useTheme();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [deleteAttachment, { loading: deleteAttachmentLoading }] = useMutation(deleteInvestmentOfferingMaterialsAttachment);

  const saveValue = () => {
    setErrorMessage(null);

    if (!attachmentId) {
      setErrorMessage(DEFAULT_ERROR_MESSAGE);
      return;
    }

    deleteAttachment({
      variables: {
        id: attachmentId
      },
      onCompleted: () => {
        setUploadedFiles((prev) => prev.filter((file) => file.id !== attachmentId));
        onClose();
      }
    });
  };

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      confirmButton={{
        name: 'Yes, Delete',
        onClick: saveValue,
        disabled: deleteAttachmentLoading
      }}
      showCloseIcon={!deleteAttachmentLoading}
      showCancelButton={!deleteAttachmentLoading}
      isUseOutsideClick={!deleteAttachmentLoading}
    >
      <ModalBody>
        <DeleteBucketIcon fill={theme.font.disabled} />
        {deleteAttachmentLoading ? (
          <ModalLoader />
        ) : (
          <>
            <ModalTitle>Please confirm you want to delete this attachment.</ModalTitle>
            {errorMessage && (
              <ErrorBannerWrapper>
                <Banner icon={<InfoIcon width={26} height={26} />} title="Error" description={errorMessage} bgColor={theme.context.error} />
              </ErrorBannerWrapper>
            )}
            <ModalDescription>There is no undo for this operation.</ModalDescription>
          </>
        )}
      </ModalBody>
    </ModalWrappedContent>
  );
};

export default DeleteAttachment;

const ModalBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
`;

const ModalTitle = styled.div`
  font-family: Blinker;
  font-size: 33px;
  font-style: normal;
  font-weight: 400;
  line-height: 46.2px;
  text-align: center;
  color: ${({ theme }) => theme.font.strong};
`;

const ModalDescription = styled.div`
  font-size: 16px;
  font-style: normal;
  text-align: center;
  color: ${({ theme }) => theme.font.base};
`;

const ErrorBannerWrapper = styled.div`
  margin-bottom: 10px;
`;
