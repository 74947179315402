import { AddressComponent } from './rowComponents/addressComponent';
import { AttachmentsComponent } from './rowComponents/attachmentsComponent';
import { BankAccountsComponent } from './rowComponents/bankAccountsComponent';
import { CheckboxGroupComponent } from './rowComponents/checkboxGroupComponent';
import { ContactsComponent } from './rowComponents/contactsComponent';
import { CustomComponent } from './rowComponents/customComponents';
import { HtmlComponent } from './rowComponents/htmlComponent';
import { RadioComponent } from './rowComponents/radioComponent';
import { TextareaComponent } from './rowComponents/textareaComponent';
import { HeaderComponent } from './styles';
import { IRenderTemplateRow } from './types';

interface RowComponentProps {
  control: any;
  row: IRenderTemplateRow;
  mode: 'documentationTab' | 'wizard';
  ignoreRequired?: boolean;
  openModalPage: (type: string, row: IRenderTemplateRow, index?: number) => void;
}

export const RowComponent = ({ control, row, mode, ignoreRequired = false, openModalPage }: RowComponentProps) => {
  switch (row.component) {
    case 'address':
      return <AddressComponent control={control} row={row} ignoreRequired={ignoreRequired} />;
    case 'attachments':
      return <AttachmentsComponent control={control} row={row} ignoreRequired={ignoreRequired} openModalPage={openModalPage} />;
    case 'bankAccounts':
      return <BankAccountsComponent control={control} row={row} mode={mode} ignoreRequired={ignoreRequired} openModalPage={openModalPage} />;
    case 'checkboxGroup':
      return <CheckboxGroupComponent control={control} row={row} ignoreRequired={ignoreRequired} />;
    case 'contacts':
      return <ContactsComponent control={control} row={row} ignoreRequired={ignoreRequired} />;
    case 'custom':
      return <CustomComponent control={control} row={row} ignoreRequired={ignoreRequired} />;
    case 'header':
      return <HeaderComponent>{row?.config?.content || ''}</HeaderComponent>;
    case 'html':
      return <HtmlComponent htmlString={row?.config?.content || ''} />;
    case 'infoBlock':
      return <></>;
    case 'radio':
      return <RadioComponent control={control} row={row} ignoreRequired={ignoreRequired} />;
    case 'textarea':
      return <TextareaComponent control={control} row={row} ignoreRequired={ignoreRequired} />;
    default:
      return <></>;
  }
};
