import Button from '@/components/fat-basicComponents/button';
import styled from 'styled-components';

interface DescriptionZeroStateProps {
  addContent: () => void;
}

export const DescriptionZeroState = ({ addContent }: DescriptionZeroStateProps) => {
  return (
    <ZeroStateWrapper>
      <ZeroStateImageWrapper>
        <ZeroStateImage src="/static/modifyStrategy-page/descriptionZeroState.webp" draggable="false" alt="No Investors" />
      </ZeroStateImageWrapper>
      <ZeroStateTextBlock>
        <ZeroStateText>
          Provide information about this strategy directly or use our AI Assistant to automatically generate content for you.
        </ZeroStateText>
        <Button onClick={addContent} styleType="outline" size="md" width="max-content">
          Add Content
        </Button>
      </ZeroStateTextBlock>
    </ZeroStateWrapper>
  );
};

const ZeroStateWrapper = styled.div`
  padding: 10px 40px;
  display: flex;
  align-items: center;
  gap: 30px;
  border: 1px solid ${({ theme }) => theme.border.base};
`;

const ZeroStateImageWrapper = styled.div`
  max-width: 220px;
  max-height: 220px;
`;

const ZeroStateImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ZeroStateTextBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ZeroStateText = styled.p`
  font-family: Lato;
  font-size: 28px;
  font-weight: 700;
  line-height: 32px;
  color: ${({ theme }) => theme.font.weak};
`;
