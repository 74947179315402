import * as React from 'react';

export const ElectronicSignatureIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={props.width || 70} height={props.height || 63} style={props?.style} viewBox="0 0 70 63" fill="none">
    <g clip-path="url(#clip0_13064_26361)">
      <path
        d="M5.10976 45.3919H5.14467V61.2798H8.53177V9.9729C8.53177 6.67892 11.2089 4.00183 14.5028 4.00183H20.5554C20.5554 4.00183 20.5554 4.10658 20.5554 4.16478V5.92235C20.5554 7.16777 21.568 8.18041 22.8134 8.18041H47.7336C48.9791 8.18041 49.9917 7.16777 49.9917 5.92235V4.16478C49.9917 4.16478 49.9917 4.06003 49.9917 4.00183H56.1374C59.4313 4.00183 62.1084 6.67892 62.1084 9.9729V61.2915H65.4955V42.6334H65.5304C65.6934 42.6334 65.8331 42.4937 65.8331 42.3307V28.7591C65.8331 28.5961 65.6934 28.4564 65.5304 28.4564H65.4955V8.54124C65.4955 3.96691 61.7825 0.253906 57.2082 0.253906H13.432C8.85768 0.253906 5.14467 3.96691 5.14467 8.54124V16.6074H5.10976C4.9468 16.6074 4.80713 16.7471 4.80713 16.91V21.0653C4.80713 21.2283 4.9468 21.368 5.10976 21.368H5.14467V25.5931H5.10976C4.9468 25.5931 4.80713 25.7328 4.80713 25.8957V34.0085C4.80713 34.1714 4.9468 34.3111 5.10976 34.3111H5.14467V36.6856H5.10976C4.9468 36.6856 4.80713 36.8252 4.80713 36.9882V45.1009C4.80713 45.2639 4.9468 45.4036 5.10976 45.4036V45.3919ZM41.8091 4.24626C42.3678 4.24626 42.8218 4.7002 42.8218 5.25889C42.8218 5.81759 42.3678 6.27153 41.8091 6.27153C41.2504 6.27153 40.7965 5.81759 40.7965 5.25889C40.7965 4.7002 41.2504 4.24626 41.8091 4.24626ZM31.7526 5.25889C31.7526 4.90971 32.0436 4.61872 32.3928 4.61872H38.2591C38.6083 4.61872 38.8992 4.90971 38.8992 5.25889C38.8992 5.60808 38.6083 5.89907 38.2591 5.89907H32.3928C32.0436 5.89907 31.7526 5.60808 31.7526 5.25889Z"
        fill="#2B2B2B"
      />
      <path
        d="M57.2544 8.14592C56.7888 8.14592 56.3232 8.14592 55.8577 8.14592C55.3921 8.14592 55.1127 7.75017 55.1244 7.41263C55.136 7.14492 55.1244 6.87721 55.1244 6.6095C55.1244 6.2254 55.4386 5.91113 55.8111 5.91113C56.7655 5.91113 57.7316 5.91113 58.6861 5.91113C59.0702 5.91113 59.3728 6.2254 59.3844 6.6095C59.3844 6.88885 59.3844 7.1682 59.3844 7.44755C59.3844 7.84329 59.0702 8.15756 58.6744 8.15756C58.1972 8.15756 57.7316 8.15756 57.2544 8.15756V8.14592ZM57.2428 7.97133C57.7083 7.97133 58.1856 7.97133 58.6511 7.97133C58.9538 7.97133 59.1866 7.75017 59.1982 7.44755C59.1982 7.17984 59.1982 6.90049 59.1982 6.63278C59.1982 6.36507 59.012 6.15556 58.7443 6.109C58.6628 6.09737 58.5697 6.109 58.4882 6.109C57.6152 6.109 56.7423 6.109 55.8577 6.109C55.5318 6.109 55.299 6.34179 55.299 6.6677C55.299 6.92377 55.299 7.17984 55.299 7.43591C55.299 7.76181 55.5201 7.9946 55.8577 7.9946C56.3232 7.9946 56.7888 7.9946 57.2544 7.9946L57.2428 7.97133Z"
        fill="#575757"
      />
      <path
        d="M54.1587 6.52794C54.0656 6.62106 53.9724 6.71417 53.891 6.80729C53.1693 6.10892 52.0636 6.15548 51.4118 6.80729C51.3186 6.71417 51.2255 6.62106 51.144 6.53958C51.8773 5.74809 53.3206 5.66662 54.1703 6.53958L54.1587 6.52794Z"
        fill="#575757"
      />
      <path
        d="M14.5376 7.03982C14.5376 6.7372 14.5376 6.44621 14.5376 6.14358C14.5376 6.02719 14.5609 6.00391 14.6773 6.00391C14.7704 6.00391 14.8519 6.00391 14.945 6.00391C15.0148 6.00391 15.0614 6.03882 15.0614 6.10866C15.0614 6.72556 15.0614 7.34245 15.0614 7.95934C15.0614 8.02918 15.0265 8.0641 14.9566 8.0641C14.8635 8.0641 14.7587 8.0641 14.6656 8.0641C14.5725 8.0641 14.5492 8.04082 14.5492 7.93606C14.5492 7.63344 14.5492 7.33081 14.5492 7.02818L14.5376 7.03982Z"
        fill="#575757"
      />
      <path
        d="M13.6879 7.27293C13.6879 7.05178 13.6879 6.84227 13.6879 6.62112C13.6879 6.49308 13.7112 6.48145 13.8276 6.48145C13.9091 6.48145 14.0022 6.48145 14.0837 6.48145C14.1535 6.48145 14.2 6.51636 14.2 6.59784C14.2 7.05178 14.2 7.50572 14.2 7.94802C14.2 8.01786 14.1651 8.05278 14.0953 8.06442C13.9905 8.06442 13.8974 8.06442 13.7927 8.06442C13.7112 8.06442 13.6763 8.0295 13.6763 7.94802C13.6763 7.72687 13.6763 7.49408 13.6763 7.27293H13.6879Z"
        fill="#575757"
      />
      <path
        d="M51.9586 7.35474C51.8655 7.26162 51.7724 7.16851 51.6909 7.07539C52.1332 6.53997 53.1575 6.53997 53.6114 7.07539C53.5183 7.16851 53.4252 7.26162 53.3321 7.3431C52.9247 6.97064 52.3544 6.98228 51.9586 7.3431V7.35474Z"
        fill="#575757"
      />
      <path
        d="M13.327 7.51768C13.327 7.65735 13.327 7.80867 13.327 7.94834C13.327 8.01818 13.2921 8.0531 13.2222 8.0531C13.1175 8.0531 13.0244 8.0531 12.9196 8.0531C12.8381 8.0531 12.8032 8.01818 12.8032 7.9367C12.8032 7.64571 12.8032 7.36637 12.8032 7.07538C12.8032 6.9939 12.8381 6.95898 12.9196 6.95898C13.0127 6.95898 13.1175 6.95898 13.2106 6.95898C13.2921 6.95898 13.3154 6.9939 13.3154 7.07538C13.3154 7.21505 13.3154 7.36637 13.3154 7.50604L13.327 7.51768Z"
        fill="#575757"
      />
      <path
        d="M12.4774 7.70352C12.4774 7.785 12.4774 7.86647 12.4774 7.94795C12.4774 8.01779 12.4425 8.06434 12.3726 8.06434C12.2679 8.06434 12.1631 8.06434 12.0584 8.06434C11.9885 8.06434 11.9536 8.02943 11.9536 7.95959C11.9536 7.785 11.9536 7.62204 11.9536 7.44745C11.9536 7.36597 12.0002 7.33105 12.07 7.33105C12.1631 7.33105 12.2562 7.33105 12.3494 7.33105C12.4308 7.33105 12.4658 7.36597 12.4658 7.44745C12.4658 7.52893 12.4658 7.62204 12.4658 7.70352H12.4774Z"
        fill="#575757"
      />
      <path
        d="M52.2378 7.63377C52.4589 7.41261 52.8198 7.41261 53.0642 7.65704C52.9944 7.72688 52.9245 7.78508 52.8547 7.85492C52.7848 7.92475 52.715 7.99459 52.6568 8.05279C52.5171 7.91311 52.3775 7.77344 52.2378 7.63377Z"
        fill="#575757"
      />
      <path
        d="M59.5127 6.65625C59.7455 6.67953 59.8968 6.84248 59.8852 7.05199C59.8735 7.24987 59.7106 7.40118 59.5127 7.40118V6.66789V6.65625Z"
        fill="#575757"
      />
      <path
        d="M56.4745 6.31836C56.6957 6.31836 56.9285 6.31836 57.1496 6.31836C57.3242 6.31836 57.429 6.42311 57.429 6.59771C57.429 6.8887 57.429 7.16804 57.429 7.45903C57.429 7.63362 57.3242 7.73838 57.1613 7.73838C56.7073 7.73838 56.2534 7.73838 55.7994 7.73838C55.6249 7.73838 55.5317 7.64526 55.5317 7.45903C55.5317 7.16804 55.5317 6.87706 55.5317 6.58607C55.5317 6.42311 55.6365 6.33 55.7878 6.33C56.0206 6.33 56.2534 6.33 56.4745 6.33V6.31836Z"
        fill="#575757"
      />
      <path
        d="M41.5066 29.2132C39.8072 27.5836 38.1661 26.0123 36.5249 24.4293C36.4201 24.5224 36.3503 24.569 36.2921 24.6388C34.1504 26.8736 32.0204 29.1084 29.8904 31.3548C29.7391 31.5178 29.6227 31.7506 29.5645 31.9717C29.2502 33.3103 28.9476 34.6488 28.6449 35.9874C28.4936 36.6508 28.354 37.3259 28.2026 38.0708C28.4703 38.0476 28.6217 38.0476 28.7613 38.0126C30.6237 37.5238 32.4743 37.0349 34.325 36.5228C34.5112 36.4762 34.6975 36.3715 34.8255 36.2434C37.037 33.9272 39.2369 31.6109 41.5066 29.2248V29.2132ZM43.043 27.4905C43.6948 26.8736 44.3001 26.3382 44.8588 25.7562C45.3593 25.2325 45.3825 24.5923 44.8937 24.1151C43.753 22.986 42.5891 21.8919 41.4135 20.8094C40.9712 20.4021 40.4474 20.4021 39.9818 20.8094C39.3067 21.3914 38.6666 22.0083 37.9915 22.6368C39.7839 24.3595 41.4251 25.9308 43.043 27.4905Z"
        fill="white"
      />
      <path
        d="M19.5547 42.878C20.2531 40.3406 22.0688 38.9439 24.4316 38.1059C25.3046 37.8032 25.9331 38.0477 26.1543 38.7577C26.3754 39.4444 26.0379 39.9449 25.1882 40.2708C24.0243 40.7015 22.9534 41.2834 22.2434 42.3543C21.58 43.3669 21.8128 43.9954 23 44.2399C24.3618 44.5192 25.7003 44.2166 27.004 43.8558C29.2737 43.2272 31.5201 42.5056 33.8014 41.9236C35.6521 41.458 37.5494 41.1903 39.4582 41.5395C41.2624 41.8654 42.1004 43.0876 41.8793 44.8917C41.8443 45.1943 41.8094 45.4969 41.7745 45.8694C43.0781 45.8578 44.3003 45.7065 45.4759 45.2991C46.7911 44.8451 48.1064 44.3563 49.4217 43.9023C50.2132 43.6346 50.7951 43.9023 51.0279 44.5541C51.2723 45.2525 50.9581 45.7763 50.0968 46.0673C48.2344 46.6958 46.3838 47.3127 44.5214 47.918C44.1955 48.0227 43.8463 48.0576 43.5088 48.1275H41.6698C41.5534 48.0809 41.4253 48.0344 41.3089 48.0111C40.1333 47.8016 39.6328 47.2894 39.5397 46.1022C39.4932 45.5784 39.5281 45.0546 39.5863 44.5309C39.6561 43.9489 39.3768 43.6928 38.8646 43.7045C37.8636 43.7277 36.8277 43.6695 35.8616 43.879C33.8596 44.3097 31.8926 44.88 29.9255 45.4271C27.842 45.9974 25.7935 46.6725 23.582 46.5678C21.3705 46.463 20.3345 45.7763 19.578 43.7859V42.8664L19.5547 42.878Z"
        fill="#2B2B2B"
      />
      <path
        d="M19.5545 43.7977C20.3227 45.788 21.347 46.4748 23.5585 46.5795C25.77 46.6843 27.8186 46.0092 29.9021 45.4388C31.8808 44.8918 33.8478 44.3215 35.8382 43.8908C36.8043 43.6813 37.8402 43.7395 38.8412 43.7162C39.365 43.7046 39.6327 43.9606 39.5628 44.5426C39.5046 45.0664 39.4697 45.5902 39.5163 46.1139C39.6094 47.3012 40.1099 47.8133 41.2855 48.0228C41.4135 48.0461 41.5299 48.1043 41.6463 48.1392H19.5312V43.7977H19.5545Z"
        fill="white"
      />
      <path
        d="M41.5066 29.2134C39.2369 31.5995 37.037 33.9274 34.8255 36.2321C34.6975 36.3717 34.4996 36.4649 34.325 36.5114C32.4743 37.0119 30.612 37.5124 28.7613 38.0013C28.6217 38.0362 28.4703 38.0362 28.2026 38.0595C28.3656 37.3145 28.4936 36.6511 28.6449 35.976C28.9476 34.6375 29.2502 33.2873 29.5645 31.9604C29.6227 31.7392 29.7274 31.5064 29.8904 31.3435C32.0204 29.097 34.1504 26.8623 36.2921 24.6275C36.3503 24.5693 36.4201 24.5111 36.5249 24.418C38.1661 26.0009 39.8072 27.5723 41.5066 29.2018V29.2134ZM29.2502 36.9537C30.6353 36.5813 31.904 36.2437 33.2891 35.8712C32.195 34.8353 31.2173 33.9042 30.1581 32.9032C29.8438 34.2883 29.5645 35.5453 29.2618 36.9421L29.2502 36.9537Z"
        fill="#575757"
      />
      <path
        d="M43.0432 27.4906C41.4254 25.9309 39.7842 24.3596 37.9917 22.6369C38.6784 22.0084 39.3186 21.3915 39.9821 20.8095C40.4476 20.4138 40.9714 20.4138 41.4137 20.8095C42.5893 21.892 43.7533 22.9861 44.8939 24.1151C45.3828 24.5923 45.3595 25.2325 44.859 25.7563C44.3003 26.3383 43.6951 26.8737 43.0432 27.4906Z"
        fill="#8F8F8F"
      />
      <path
        d="M29.2504 36.953C29.5647 35.5562 29.844 34.2992 30.1466 32.9141C31.2058 33.9151 32.1952 34.8462 33.2777 35.8821C31.9042 36.2546 30.6355 36.5921 29.2388 36.9646L29.2504 36.953Z"
        fill="white"
      />
      <path
        d="M68.871 60.5H1.12903C0.505485 60.5 0 61.0055 0 61.629C0 62.2526 0.505485 62.7581 1.12903 62.7581H68.871C69.4945 62.7581 70 62.2526 70 61.629C70 61.0055 69.4945 60.5 68.871 60.5Z"
        fill="#8F8F8F"
      />
      <path
        d="M19.1002 57.7998H15.7364V57.4506C16.0623 57.4506 16.3067 57.4274 16.4697 57.3808C16.6326 57.3342 16.7374 57.2178 16.7956 57.0549C16.8538 56.8919 16.8771 56.6359 16.8771 56.2867V50.7114H16.1321C15.7131 50.7114 15.3872 50.7579 15.1777 50.8394C14.9682 50.9209 14.8169 51.0838 14.7237 51.305C14.6306 51.5261 14.5375 51.852 14.456 52.2827H14.0952L14.2233 50.1992H20.6366L20.7297 52.2827H20.3689C20.2874 51.852 20.1943 51.5378 20.1012 51.305C20.0081 51.0838 19.8568 50.9325 19.6473 50.8394C19.4377 50.7579 19.1235 50.7114 18.6812 50.7114H17.9479V56.2867C17.9479 56.6359 17.9712 56.8919 18.0294 57.0549C18.0876 57.2178 18.1923 57.3342 18.3553 57.3808C18.5182 57.4274 18.7627 57.4506 19.1002 57.4506V57.7998Z"
        fill="#8F8F8F"
      />
      <path
        d="M24.7923 56.7519L25.0134 56.9382C24.8621 57.1477 24.7341 57.3223 24.6061 57.4619C24.478 57.6016 24.3384 57.718 24.187 57.7878C24.0357 57.8577 23.8262 57.9042 23.5818 57.9042C23.1977 57.9042 22.9183 57.7878 22.7321 57.5667C22.5575 57.3339 22.4644 56.9614 22.4644 56.4493V52.8527H21.8242V52.585C22.0919 52.4104 22.3247 52.1892 22.5226 51.8982C22.7205 51.6073 22.9183 51.2115 23.1278 50.7227H23.4188V52.3638H24.7225V52.8527H23.4188V56.1234C23.4188 56.5308 23.4537 56.8218 23.5236 56.9847C23.5934 57.1477 23.7447 57.2291 23.9659 57.2291C24.1521 57.2291 24.2918 57.1942 24.3965 57.1127C24.5013 57.0313 24.6293 56.9149 24.769 56.7519H24.7923Z"
        fill="#8F8F8F"
      />
    </g>
    <defs>
      <clipPath id="clip0_13064_26361">
        <rect width="70" height="62.4925" fill="white" transform="translate(0 0.253906)" />
      </clipPath>
    </defs>
  </svg>
);
