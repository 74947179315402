import { EditIcon } from '@/assets/icons/edit';
import { ITab } from '@/components/basicComponents/fat-tabs';
import { advisoryFirmTableSort, entityTableSort, investorTableSort } from '@/components/fat-activeInvestments-page/investmentDetails/constatnts';
import Table from '@/components/fat-activeInvestments-page/investmentDetails/fat-table/table';
import {
  ICommitmentsByInvestor,
  IInvestorCommitment,
  InvestmentEntitiesByAdvisoryFirmDto,
  InvestmentEntity,
  InvestmentEntityByAdvisoryFirm,
  InvestmentEntityQuery
} from '@/components/fat-activeInvestments-page/investmentDetails/types';
import {
  COMMITMENTS_BY_INVESTOR_QUERY,
  GET_COMMITMENTS_BY_INVESTOR_TOTAL_QUERY,
  GET_INVESTMENT_ENTITIES_BY_ADVISORY_FIRM_TOTAL_QUERY,
  GET_INVESTMENT_ENTITIES_TOTAL_QUERY,
  INVESTMENT_ENTITIES_BY_ADVISORY_FIRM_QUERY,
  INVESTMENT_ENTITIES_QUERY
} from '@/components/fat-activeInvestments-page/queries';
import Button from '@/components/fat-basicComponents/button';
import { ISelectValue } from '@/components/fat-basicComponents/select';
import Header from '@/components/fat-header';
import { GoBackButton } from '@/components/fat-header/goBackButton';
import { PageTitleWrap } from '@/components/fat-header/pageTitle';
import { ALL_ADVISORY_FIRM_LIST_QUERY } from '@/components/fat-investors-page/queries';
import { ITenant } from '@/components/fat-investors-page/types';
import { IMobileSort } from '@/components/fat-pendingCommitments-page/types';
import { LoaderOnWholeScreen } from '@/components/loaders/loader-on-whole-screen';
import { MANAGER_UPDATES_EDITABLE_ROLES } from '@/components/managerUpdates-page/constatnts';
import { GET_DOCUMENT_RECORD_QUERY, LIST_DOCUMENT_RECORDS_QUERY } from '@/components/managerUpdates-page/managerUpdateDetails/queries';
import {
  IDocumentRecordFieldEntity,
  IGetDocumentRecordQuery,
  IListDocumentRecordsQuery
} from '@/components/managerUpdates-page/managerUpdateDetails/types';
import { UpdatesTab } from '@/components/managerUpdates-page/managerUpdateDetails/updatesTab';
import { GET_SETTINGS } from '@/components/settings-page/queries';
import { initialValuesFromTable } from '@/components/table/constants';
import FilterPage from '@/components/table/fat-filterPage';
import TablePagination from '@/components/table/tablePagination';
import { ISort, SORT_ORDER } from '@/components/table/types';
import { useAuth } from '@/hooks/use-auth';
import { useResponsive } from '@/hooks/use-responsive';
import { useGoBack } from '@/hooks/useGoBack';
import { MainWrap, PaddingWrap } from '@/styles/common';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { INVESTMENT_QUERY } from '../fat-seekingInterest-page/queries';
import { Investment } from '../types';
import { DescriptionTab } from './descriptionTab';
import BeginAllocation from './fat-modals/beginAllocation';
import { Details } from './newDetails';

export const OpportunityDetailsPage = () => {
  const navigate = useNavigate();
  const goBack = useGoBack();
  const location = useLocation();
  const params = useParams();
  const theme = useTheme();
  const { user } = useAuth();
  const { isMobile, isTablet } = useResponsive();
  const [searchParams, setSearchParams] = useSearchParams();

  const backToTitle = searchParams.get('backToTitle') || '';
  const activeTab = searchParams.get('tab') || 'Details';
  const q = searchParams.get('q') || '';
  const viewBy = searchParams.get('viewBy') || '';
  const key = searchParams.get('key') || 'name';
  const asc = searchParams.get('asc') === null || searchParams.get('asc') === 'true';
  const primaryFilter = searchParams.get('primaryFilter') || '';
  const limit = searchParams.get('limit') || initialValuesFromTable.initialAmountRowsPerPage[0];
  const offset = searchParams.get('offset') || 0;
  const position = searchParams.get('position') || 0;

  const [tabs, setTabs] = useState<{ value: string; amount: null | number }[]>();
  const [isTabMenuOpen, setIsTabMenuOpen] = useState(false);
  const [documentRecordId, setDocumentRecordId] = useState<string | null>(null);
  const [descriptionSectionFields, setDescriptionSectionFields] = useState<IDocumentRecordFieldEntity[]>([]);
  const [entities, setEntities] = useState<InvestmentEntity[]>([]);
  const [advisoryFirmTableRows, setAdvisoryFirmTableRows] = useState<InvestmentEntityByAdvisoryFirm[]>([]);
  const [investorTableRows, setInvestorTableRows] = useState<IInvestorCommitment[]>([]);
  const [primaryFilterList, setPrimaryFilterList] = useState<ISelectValue[]>([]);
  const [viewByList, setViewByList] = useState<ISelectValue[]>([]);
  const [canUserEdit, setCanUserEdit] = useState(false);
  const [listDocumentRecordsStatusFilter, setListDocumentRecordsStatusFilter] = useState<string[]>([]);
  const [modalWindow, setModalWindow] = useState({ isOpen: false, type: '' });
  const [internalReviewFeatureFlag, setInternalReviewFeatureFlag] = useState<boolean>(false);
  const [mobileSortItems, setMobileSortItems] = useState<IMobileSort[]>([]);
  const [mobileSort, setMobileSort] = useState('');
  const sortItem = mobileSortItems.find((item) => item.title === mobileSort);
  const mobileSortList = mobileSortItems.map((item) => item.title);

  useEffect(() => {
    if (MANAGER_UPDATES_EDITABLE_ROLES.some((role) => user.roles.includes(role)) && user.tenant.type === 'fundManager') {
      setListDocumentRecordsStatusFilter(['Draft', 'Published']);
      setCanUserEdit(true);
      return;
    }
    setListDocumentRecordsStatusFilter(['Published']);
  }, [user]);

  useEffect(() => {
    if (!user) return;

    if (user.tenant.type === 'advisoryFirm') {
      setViewByList([
        { label: 'Investor', value: 'Investor' },
        { label: 'Entity', value: 'Entity' }
      ]);
      if (!viewBy) {
        onChangeViewBy('Investor');
      }
      return;
    }

    if (user.tenant.type === 'fundManager') {
      setViewByList([
        { label: 'Advisory Firm', value: 'Advisory Firm' },
        { label: 'Investor', value: 'Investor' },
        { label: 'Entity', value: 'Entity' }
      ]);
      if (!viewBy) {
        onChangeViewBy('Advisory Firm');
      }
      return;
    }
  }, [user]);

  const filteredMobileSortItems = (items: IMobileSort[]) => {
    if (user && user.tenant.type === 'advisoryFirm') {
      return items.filter((item) => item.key !== 'ADVISORY_FIRMS');
    }
    return items;
  };

  useEffect(() => {
    if (!isMobile) return;

    switch (viewBy) {
      case 'Advisory Firm':
        const filteredAdvisoryFirmTableSort = filteredMobileSortItems(advisoryFirmTableSort);
        setMobileSortItems(filteredAdvisoryFirmTableSort);
        setMobileSort(filteredAdvisoryFirmTableSort[0].title);
        break;
      case 'Investor':
        const filteredInvestorTableSort = filteredMobileSortItems(investorTableSort);
        setMobileSortItems(filteredInvestorTableSort);
        setMobileSort(filteredInvestorTableSort[0].title);
        break;
      case 'Entity':
        const filteredEntityTableSort = filteredMobileSortItems(entityTableSort);
        setMobileSortItems(filteredEntityTableSort);
        setMobileSort(filteredEntityTableSort[0].title);
        break;
      default:
        break;
    }
  }, [viewBy, isMobile]);

  useEffect(() => {
    if (!isMobile) return;
    getCurrentStatus().refetch({
      order: sortItem?.asc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
      sort: sortItem?.key
    });
  }, [mobileSort]);

  useQuery(GET_SETTINGS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      type: 'featureFlag'
    },
    onCompleted: (data) => {
      setInternalReviewFeatureFlag(JSON.parse(data.Settings[0].value));
    },
    onError: (error) => {
      console.error(error);
    }
  });

  const { data: investmentData, loading: loadingInvestment } = useQuery<{ Investment: Investment }>(INVESTMENT_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: { id: params?.strategyId },
    skip: !params?.strategyId,
    onError: (error) => {
      console.error(error);
    }
  });

  const { loading: listDocumentStrategyDescriptionRecordsLoading } = useQuery<{ listDocumentRecords: IListDocumentRecordsQuery }>(
    LIST_DOCUMENT_RECORDS_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
      skip: !params?.strategyId,
      variables: {
        data: {
          investmentId: params?.strategyId,
          sortBy: 'NAME',
          sortDirection: SORT_ORDER.ASC,
          documentTypeFilter: ['sd'],
          limit: 1
        }
      },
      onCompleted: (data) => {
        if (!data.listDocumentRecords.records.length) return;
        setDocumentRecordId(data.listDocumentRecords.records[0].id);
      },
      onError: (error) => {
        console.error(error);
      }
    }
  );

  const { loading: documentRecordLoading } = useQuery<{ getDocumentRecord: IGetDocumentRecordQuery }>(GET_DOCUMENT_RECORD_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    skip: !documentRecordId,
    variables: {
      id: documentRecordId
    },
    onCompleted: (data) => {
      setDescriptionSectionFields(data.getDocumentRecord.fields);
    },
    onError: (error) => {
      console.error(error);
    }
  });

  const { data: listDocumentManagerUpdatesRecordsData, loading: listDocumentManagerUpdatesRecordsLoading } = useQuery<{
    listDocumentRecords: IListDocumentRecordsQuery;
  }>(LIST_DOCUMENT_RECORDS_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    skip: !params?.strategyId || !listDocumentRecordsStatusFilter.length,
    variables: {
      data: {
        investmentId: params?.strategyId,
        sortBy: 'NAME',
        sortDirection: SORT_ORDER.ASC,
        statusFilter: listDocumentRecordsStatusFilter,
        documentTypeFilter: ['mu'],
        limit: 1
      }
    },
    onError: (error) => {
      console.error(error);
    }
  });

  const { data: advisoryFirmsTotalData } = useQuery<{
    InvestmentEntitiesByAdvisoryFirm: InvestmentEntitiesByAdvisoryFirmDto;
  }>(GET_INVESTMENT_ENTITIES_BY_ADVISORY_FIRM_TOTAL_QUERY, {
    notifyOnNetworkStatusChange: true,
    skip: !params?.strategyId,
    variables: {
      id: params?.strategyId
    },
    onError: (error) => {
      console.error(error);
    }
  });

  const { data: investorsTotalData } = useQuery<{ CommitmentsByInvestor: ICommitmentsByInvestor }>(GET_COMMITMENTS_BY_INVESTOR_TOTAL_QUERY, {
    notifyOnNetworkStatusChange: true,
    skip: !params?.strategyId,
    variables: {
      investmentId: params?.strategyId
    },
    onError: (error) => {
      console.error(error);
    }
  });

  const { data: entitiesTotalData } = useQuery<{ InvestmentEntities: InvestmentEntityQuery }>(GET_INVESTMENT_ENTITIES_TOTAL_QUERY, {
    notifyOnNetworkStatusChange: true,
    skip: !params?.strategyId,
    variables: {
      id: params?.strategyId
    },
    onError: (error) => {
      console.error(error);
    }
  });

  const { data: listAdvisors } = useQuery<{ allAdvisoryFirmList: ITenant[] }>(ALL_ADVISORY_FIRM_LIST_QUERY, {
    skip: Boolean(user && user.tenant.type !== 'fundManager'),
    onCompleted: ({ allAdvisoryFirmList }) => {
      setPrimaryFilterList([...new Set(allAdvisoryFirmList.map((el) => ({ label: el.name, value: el.name })))]);
    }
  });

  const {
    data: entitiesData,
    loading: entitiesLoading,
    refetch: entitiesRefetch
  } = useQuery<{ InvestmentEntities: InvestmentEntityQuery }>(INVESTMENT_ENTITIES_QUERY, {
    notifyOnNetworkStatusChange: true,
    skip: !params?.strategyId || viewBy !== 'Entity',
    variables: {
      id: params?.strategyId,
      sort: key,
      order: asc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
      search: q.toLowerCase().trim(),
      rowsPerPage: Number(limit),
      page: Math.floor(Number(offset) / Number(limit)) + 1,
      quickFilter: primaryFilter ? primaryFilter : null
    },
    onCompleted: (data) => {
      setEntities(data.InvestmentEntities.entities);
    }
  });

  const {
    data: advisoryFirmsData,
    loading: advisoryFirmsLoading,
    refetch: advisoryFirmsRefetch
  } = useQuery<{
    InvestmentEntitiesByAdvisoryFirm: InvestmentEntitiesByAdvisoryFirmDto;
  }>(INVESTMENT_ENTITIES_BY_ADVISORY_FIRM_QUERY, {
    notifyOnNetworkStatusChange: true,
    skip: !params?.strategyId || viewBy !== 'Advisory Firm',
    variables: {
      id: params?.strategyId,
      sort: key,
      order: asc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
      search: q.toLowerCase().trim(),
      rowsPerPage: Number(limit),
      page: Math.floor(Number(offset) / Number(limit)) + 1,
      quickFilter: primaryFilter ? primaryFilter : null
    },
    onCompleted: (data) => {
      setAdvisoryFirmTableRows(data.InvestmentEntitiesByAdvisoryFirm.firms);
    }
  });

  const {
    data: investorsData,
    loading: investorsLoading,
    refetch: investorsRefetch
  } = useQuery<{
    CommitmentsByInvestor: ICommitmentsByInvestor;
  }>(COMMITMENTS_BY_INVESTOR_QUERY, {
    notifyOnNetworkStatusChange: true,
    skip: !params?.strategyId || viewBy !== 'Investor',
    variables: {
      investmentId: params?.strategyId,
      sort: key,
      order: asc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
      search: q.toLowerCase().trim(),
      rowsPerPage: Number(limit),
      page: Math.floor(Number(offset) / Number(limit)) + 1,
      quickFilter: primaryFilter ? primaryFilter : null
    },
    onCompleted: (data) => {
      setInvestorTableRows(data.CommitmentsByInvestor.investors);
    }
  });

  useEffect(() => {
    const newTabs: ITab[] = [];

    if (
      advisoryFirmsTotalData?.InvestmentEntitiesByAdvisoryFirm.total ||
      investorsTotalData?.CommitmentsByInvestor.total ||
      entitiesTotalData?.InvestmentEntities.total
    ) {
      newTabs.push({ value: 'Investors', amount: null });
    }

    if (listDocumentManagerUpdatesRecordsData?.listDocumentRecords?.total || location.pathname.includes('/managerUpdates')) {
      newTabs.push({ value: 'Updates', amount: null });
    }

    setTabs(newTabs.length ? [{ value: 'Details', amount: null }, ...newTabs] : []);
  }, [advisoryFirmsTotalData, investorsTotalData, entitiesTotalData, listDocumentManagerUpdatesRecordsData]);

  const getCurrentStatus = () => {
    switch (viewBy) {
      case 'Advisory Firm':
        return {
          capitalCalled: advisoryFirmTableRows.reduce((sum, val) => sum + Number(val.capitalCalled), 0),
          refetch: advisoryFirmsRefetch,
          total: advisoryFirmsData?.InvestmentEntitiesByAdvisoryFirm?.total ?? 0
        };
      case 'Investor':
        return {
          capitalCalled: investorTableRows.reduce((sum, val) => sum + Number(val.capitalCalled), 0),
          refetch: investorsRefetch,
          total: investorsData?.CommitmentsByInvestor?.total ?? 0
        };
      case 'Entity':
        return {
          capitalCalled: entities.reduce((sum, val) => sum + Number(val.capitalCalled), 0),
          refetch: entitiesRefetch,
          total: entitiesData?.InvestmentEntities?.total ?? 0
        };
      default:
        return {
          capitalCalled: 0,
          refetch: () => null as any,
          total: 0
        };
    }
  };

  const onChangeSearch = (searchValue: string) => {
    setSearchParams(
      (prev) => {
        if (!searchValue) {
          prev.delete('q');
          return prev;
        }
        prev.set('q', searchValue);
        return prev;
      },
      { replace: true }
    );
  };

  const onChangeViewBy = (viewByValue: string) => {
    setSearchParams(
      (prev) => {
        prev.set('viewBy', viewByValue);
        prev.delete('limit');
        prev.delete('offset');
        return prev;
      },
      { replace: true }
    );
  };

  const onChangeSort = (value: ISort) => {
    setSearchParams(
      (prev) => {
        prev.set('key', value.key);
        prev.set('asc', value.asc.toString());
        return prev;
      },
      { replace: true }
    );
  };

  const onChangePrimaryFilter = (filter: string) => {
    setSearchParams(
      (prev) => {
        prev.set('primaryFilter', filter);
        return prev;
      },
      { replace: true }
    );
  };

  const saveScrollPostion = () => {
    setSearchParams(
      (prev) => {
        prev.set('position', window.scrollY.toString());
        return prev;
      },
      { replace: true }
    );
  };

  const makeScroll = (position: string | number) => {
    if (!position) return;
    setTimeout(() => window.scrollTo({ top: Number(position), behavior: 'smooth' }), 0);
    setSearchParams(
      (prev) => {
        prev.delete('position');
        return prev;
      },
      { replace: true }
    );
  };

  const onChangePaginationData = (limit: number, offset: number) => {
    setSearchParams(
      (prev) => {
        prev.set('limit', limit.toString());
        prev.set('offset', offset.toString());
        return prev;
      },
      { replace: true }
    );
  };

  const openDetailsPage = (investorId: string, entityId?: string) => {
    saveScrollPostion();
    if (investorId && entityId) {
      navigate(`/investors/${investorId}/${entityId}`);
      return;
    }
    navigate(`/investors/${investorId}`);
  };

  const openAdvisorFirmDetails = (id: string) => {
    onChangeViewBy('Investor');
    const selectedAdvisor = listAdvisors.allAdvisoryFirmList.find((item) => item.id === id)?.name || 'All';
    onChangePrimaryFilter(selectedAdvisor);
  };

  const onChangeActiveTab = (value: string) => {
    setSearchParams(
      (prev) => {
        prev.set('tab', value);
        return prev;
      },
      { replace: true }
    );
  };

  const openModifyInvestment = (id?: string) => {
    navigate(`/dealPipeline/modify?backToTitle=Strategy Details&investment=${encodeURIComponent(id)}`, {
      state: {
        investment: id
      }
    });
  };

  const openAllocatorPage = (id: string) => {
    if (!id) return;

    setSearchParams(
      (prev) => {
        prev.set('position', window.scrollY.toString());
        return prev;
      },
      { replace: true }
    );

    if (user && user.tenant.type === 'fundManager') {
      navigate(`/dealPipeline/allocator/${id}?backToTitle=The Strategy`);
      return;
    }
    navigate(`/dealCatalog/allocator/${id}?backToTitle=The Strategy`);
  };

  const openEditDescriptionPage = (id: string) => {
    navigate(`/dealPipeline/editStrategyDescription/${id}?backToTitle=Strategy Details`);
  };
  const openModalWindow = (type: string) => {
    setModalWindow({ type, isOpen: true });
  };

  const closeModalWindow = () => {
    setModalWindow({ ...modalWindow, isOpen: false });
  };

  if (loadingInvestment || listDocumentStrategyDescriptionRecordsLoading || listDocumentManagerUpdatesRecordsLoading || documentRecordLoading) {
    return <LoaderOnWholeScreen />;
  }

  return (
    <>
      {modalWindow.isOpen && modalWindow.type === 'begin-allocation' && (
        <BeginAllocation
          isOpen={modalWindow.isOpen}
          minimum={investmentData?.Investment?.minimum || 0}
          investmentType={investmentData?.Investment?.type || ''}
          onClose={closeModalWindow}
        />
      )}
      <MainWrap>
        <Header
          modalControl={
            <GoBackButton
              handleClose={() => goBack({ fallBack: location.pathname.substring(0, location.pathname.indexOf('/', 1)) })}
              backToTitle={backToTitle}
            />
          }
        />
        <PageTitleWrap withBorder={false}>
          {investmentData?.Investment.name ?? ''}
          {user && user.tenant.type === 'fundManager' && (
            <IconWrap onClick={() => openModifyInvestment(investmentData?.Investment.id)}>
              <EditIcon fill={theme.action.primary} />
            </IconWrap>
          )}
          {investmentData?.Investment.securityStatus === 'OPEN' && (
            <ButtonsWrapper>
              {internalReviewFeatureFlag && (
                <Button styleType="outline" size="md" onClick={() => openAllocatorPage(investmentData?.Investment.id)}>
                  Bulk Allocate
                </Button>
              )}
              <Button size="md" onClick={() => openModalWindow('begin-allocation')}>
                Begin Allocation
              </Button>
            </ButtonsWrapper>
          )}
        </PageTitleWrap>
        <Details
          investmentData={investmentData?.Investment ?? null}
          capitalCalled={getCurrentStatus().capitalCalled}
          summary={descriptionSectionFields[0]?.value ?? ''}
          tabs={tabs}
          activeTab={activeTab}
          isTabMenuOpen={isTabMenuOpen}
          setIsTabMenuOpen={setIsTabMenuOpen}
          setActiveTab={onChangeActiveTab}
        />
      </MainWrap>
      <MainWrap>
        <OpportunityDetailsPaddingWrap>
          {activeTab === 'Details' && (
            <DescriptionTab
              offeringMaterialsAttachments={investmentData?.Investment?.offeringMaterialsAttachments ?? []}
              descriptionSectionFields={descriptionSectionFields}
              openEditDescriptionPage={() => openEditDescriptionPage(investmentData?.Investment.id)}
            />
          )}
          {activeTab === 'Investors' && (
            <>
              <CustomFilterPage
                isMobile={isMobile}
                isTablet={isTablet}
                search={{
                  value: q,
                  onChange: onChangeSearch,
                  placeholder: 'Search for an investor'
                }}
                resultsValue={getCurrentStatus().total}
                refetch={getCurrentStatus().refetch}
                useNewFilters
                viewByQuickFilter={{
                  title: 'View By',
                  value: viewBy,
                  defaultValue: 'All',
                  options: viewByList,
                  onChange: onChangeViewBy
                }}
                mobileSort={{
                  value: mobileSort,
                  onChange: setMobileSort,
                  selects: mobileSortList
                }}
                {...(user &&
                  user.tenant.type === 'fundManager' && {
                    advisoryFirmQuickFilter: {
                      title: 'Advisory Firm',
                      value: primaryFilter,
                      defaultValue: 'All',
                      options: [{ label: 'All', value: '' }, ...primaryFilterList],
                      onChange: onChangePrimaryFilter
                    }
                  })}
              />
              <Table
                advisoryFirmTableRows={advisoryFirmTableRows}
                entityTableRows={entities}
                investorTableRows={investorTableRows}
                refetch={getCurrentStatus().refetch}
                loading={entitiesLoading || advisoryFirmsLoading || investorsLoading}
                viewByValue={viewBy}
                sort={{ key, asc }}
                setSort={onChangeSort}
                openDetailsPage={openDetailsPage}
                openAdvisorFirmDetails={openAdvisorFirmDetails}
                withFilterBar={false}
              />
              <PaginationWrap>
                <TablePagination
                  savePagination={onChangePaginationData}
                  paginationValues={{
                    limit: Number(limit),
                    offset: Number(offset),
                    total: getCurrentStatus().total
                  }}
                  refetch={() => null as any}
                />
              </PaginationWrap>
            </>
          )}
          {activeTab === 'Updates' && <UpdatesTab managerId={investmentData?.Investment?.managerId || null} backToTitle="Strategy Details" />}
        </OpportunityDetailsPaddingWrap>
      </MainWrap>
    </>
  );
};

const IconWrap = styled.div`
  display: flex;
  cursor: pointer;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  margin-left: auto;
  padding: 8px 0;
  max-width: 350px;
  width: 100%;
`;

const OpportunityDetailsPaddingWrap = styled(PaddingWrap)`
  padding-top: 40px;
  padding-bottom: 40px;
`;

const CustomFilterPage = styled(FilterPage)<{ isMobile: boolean; isTablet: boolean }>`
  position: sticky;
  top: ${({ isMobile, isTablet }) => (isMobile || isTablet ? '50px' : '0')};
  z-index: 9;
  margin-top: 0;
  margin-left: -1px;
  width: calc(100% + 2px);
  padding: ${({ isMobile }) => (isMobile ? '20px 0 0 0' : '20px 0 10px 0')};
`;

const PaginationWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
