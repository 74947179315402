import { useState } from 'react';

import styled from 'styled-components';

import { IInvestmentWorkflowTransitionTask, IWorkflowTransitionTasksHistory, Investment } from '@/components/fat-dealCatalog-page/types';
import { CompletedStatusRow } from '@/components/fat-pendingCommitments-page/fat-stepLists/CompletedStatusRow';
import { FutureStatusRow } from '@/components/fat-pendingCommitments-page/fat-stepLists/FutureStatusRow';
import { NextStepStatusRow } from '@/components/fat-pendingCommitments-page/fat-stepLists/NextStepStatusRow';
import { TerminatedStatusRow } from '@/components/fat-pendingCommitments-page/fat-stepLists/TerminatedStatusRow';
import { Loader } from '@/components/loaders/loader';
import guid from '@/utils/guid';
import { useQuery } from '@apollo/client';
import { LIST_INVESTMENT_WORKFLOW_TRANSITION_TASKS_HISTORY_QUERY } from '../queries';
import { LastUpdateStep } from '../types';

interface StepsListProps {
  row: Investment;
  statuses: IInvestmentWorkflowTransitionTask[];
  initialDate: Date;
  isMobile?: boolean;
}

export const StepsList = ({ row, statuses, initialDate, isMobile = false }: StepsListProps) => {
  const currentTask = row?.investmentWorkflowTransitionTask;
  const [completedSteps, setCompletedSteps] = useState<LastUpdateStep[]>([]);
  const [nextStep, setNextStep] = useState<LastUpdateStep>();
  const [futureSteps, setFutureSteps] = useState<LastUpdateStep[]>([]);

  const { loading } = useQuery<{ listInvestmentWorkflowTransitionTasksHistory: IWorkflowTransitionTasksHistory[] }>(
    LIST_INVESTMENT_WORKFLOW_TRANSITION_TASKS_HISTORY_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
      variables: {
        id: row.id,
        sortDirection: 'DESC'
      },
      onCompleted: ({ listInvestmentWorkflowTransitionTasksHistory: history }) => {
        const listOperationsStatus = statuses.map((task, index) => {
          const currentTaskHistory = history.find((item) => item.taskId === task.id);

          // Adjust date based on the current task
          let date = currentTaskHistory?.createdAt ?? null;
          if (index === 0 && !currentTaskHistory?.createdAt) {
            // first tasks don't get historical data so use createdAt
            date = initialDate;
          } else if (task.order >= currentTask.order) {
            // if the task is before the current task, don't set a completion date
            date = null;
          }

          const step = {
            id: task.id,
            lastUpdate: task.lastUpdate,
            status: task.name,
            order: task.order,
            date
          };

          if (step.id === currentTask.id) setNextStep(step);

          return step;
        });

        const completed = listOperationsStatus.filter((step) => {
          return step.order < currentTask.order;
        });
        setCompletedSteps(completed);

        const future = listOperationsStatus.filter((step) => {
          return step.order > currentTask.order;
        });
        setFutureSteps(row.securityStatus === 'Terminated' ? [] : future);
      }
    }
  );

  if (loading) {
    return <Loader />;
  }

  if (!nextStep && !completedSteps.length && !futureSteps.length) return <NoStatuses>There is no statuses</NoStatuses>;

  return (
    <Container>
      {completedSteps.map(({ lastUpdate, date }) => (
        <CompletedStatusRow key={guid()} isMobile={isMobile} status={lastUpdate} date={date} />
      ))}
      {nextStep && row.securityStatus !== 'Terminated' && <NextStepStatusRow isMobile={isMobile} status={nextStep.status} nextStepTitle="Current:" />}
      {futureSteps.map(({ status, order }) => (
        <FutureStatusRow key={guid()} isMobile={isMobile} index={order || 0} status={status} />
      ))}
      {row.securityStatus === 'Terminated' && <TerminatedStatusRow status={'Terminated'} date={row.updatedAt} isMobile={isMobile} />}
    </Container>
  );
};

const NoStatuses = styled.span`
  text-align: left;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 3px;

  * {
    white-space: nowrap;
  }
`;
