import { IInfoIcon } from '@/assets/icons/info-icons/i-info';
import { Mode } from '@/components/allocations-page/types';
import CheckBox from '@/components/basicComponents/checkbox';
import FilterSelect from '@/components/basicComponents/select';
import BasicTooltip from '@/components/basicComponents/tooltip';
import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import Suitability from '@/components/fat-investors-page/fat-investorDetails-page/fat-entityDetails-page/fat-tableAllocations/suitability';
import Amount from '@/components/fat-pendingCommitments-page/fat-table/cell/amount';
import ClientsAdvisor from '@/components/fat-pendingCommitments-page/fat-table/cell/clientsAdvisor';
import { BodyCellInner } from '@/components/investments-table';
import SuitabilityDetails, { SuitabilityWrapper } from '@/components/opportunities-entity/pendingAllocation/suitabilityDetails';
import { AnchorPopover } from '@/components/table/anchor-drop-down/fat-index';
import NoResults from '@/components/table/noResults';
import RedirectTitleCell from '@/components/table/redirectTitleCell';
import { TableRowCell, TableRowWrapper, TextCell } from '@/components/table/styles';
import guid from '@/utils/guid';
import styled, { useTheme } from 'styled-components';
import { columns } from '../constatnts';
import { IAllocationRow } from '../types';
import PrevStatus from './fat-cell/prevStatus';

interface OpenOpportunitiesTableBodyProps {
  potentialAllocationData: IAllocationRow[];
  mode: Mode;
  selectRow: (row: IAllocationRow) => void;
  openModalWindow: (type: string, row: IAllocationRow) => void;
  updateDecisionReason: (id: string, value: string) => void;
  openInvestorDetails: (id: string) => void;
  openEntityDetails: (investorId: string, entityId: string) => void;
}

const TableBody = ({
  potentialAllocationData,
  mode,
  selectRow,
  openModalWindow,
  updateDecisionReason,
  openInvestorDetails,
  openEntityDetails
}: OpenOpportunitiesTableBodyProps) => {
  const theme = useTheme();
  if (!potentialAllocationData?.length) {
    return <NoResults />;
  }

  return (
    <>
      {potentialAllocationData.map((row) => (
        <TableRowWrapper key={row.legalEntity.id}>
          {mode === Mode.EDIT && (
            <TableRowCell>
              <CheckboxWrapper id={'input'} onClick={() => selectRow(row)}>
                <CheckBox id={'input'} isActive={row.checked} />
              </CheckboxWrapper>
            </TableRowCell>
          )}
          <TableRowCell width={columns[0].width}>
            <RedirectTitleCell id="actionCurioText" onClick={() => openEntityDetails(row.legalEntity?.family.id, row.legalEntity?.id)}>
              {row.legalEntity?.entityName}
            </RedirectTitleCell>
            {/* <Entity
              row={row}
              onRedirect={() => openEntityDetails(row.legalEntity?.family.id, row.legalEntity?.id)}
              hyperTitle={() => openEntityDetails(row.legalEntity?.family.id, row.legalEntity?.id)}
            /> */}
          </TableRowCell>
          <TableRowCell width={columns[1].width}>
            <ClientsAdvisor
              onRedirect={() => openInvestorDetails(row.legalEntity?.family.id)}
              familyName={row.legalEntity?.family.name}
              advisor={row.legalEntity?.advisor.initials}
            />
          </TableRowCell>
          <TableRowCell width={columns[2].width}>
            <TextCell>
              <FormattingTooltip zIndex={1000}>{row.legalEntity?.overallCapacity}</FormattingTooltip>
            </TextCell>
          </TableRowCell>
          <TableRowCell width={columns[3].width}>
            <TextCell>
              <FormattingTooltip zIndex={1000}>{row.legalEntity?.assetClassCapacity}</FormattingTooltip>
            </TextCell>
          </TableRowCell>
          <TableRowCell width={columns[4].width}>
            <AnchorPopover
              showLeft
              zIndex={100}
              rowBody={
                <SuitabilityWrapper>
                  <Suitability suitability={row.suitability} width="80" />
                  {row.legalEntity.ignoreBiteSize ||
                    (((row.investment.type === 'Restricted Alternative' && row.committedCapital !== row.legalEntity.directBiteSize) ||
                      (row.investment.type === 'Illiquid Alternative' && row.committedCapital !== row.legalEntity.fundBiteSize)) && (
                      <BasicTooltip zIndex={1000} tooltipContent={'Differs from default bite size'}>
                        <IInfoIcon fill={theme.context.warning} />
                      </BasicTooltip>
                    ))}
                </SuitabilityWrapper>
              }
              title={row.suitability}
            >
              <SuitabilityDetails data={row.suitabilityCriteria} key={guid()} />
            </AnchorPopover>
          </TableRowCell>
          <TableRowCell width={columns[5].width}>
            <TextCell>
              {mode === Mode.VIEW ? (
                <FormattingTooltip zIndex={1000}>{+row.committedCapital}</FormattingTooltip>
              ) : (
                <Amount showChangeAmountButton={true} amount={row.committedCapital} openWindow={() => openModalWindow('edit-amount', row)} />
              )}
            </TextCell>
          </TableRowCell>
          <TableRowCell width={columns[6].width}>
            {mode === Mode.VIEW ? (
              <TextCell>{row.decisionReason}</TextCell>
            ) : (
              <BodyCellInner>
                {row.decisionReason && (
                  <DecisionSelect
                    openZIndex={31}
                    data={['Yes', 'No']}
                    selected={row.decisionReason}
                    setSelected={(value) => updateDecisionReason(row.legalEntity?.id, value)}
                    width={'100%'}
                    id={'selector'}
                    disabled={!row?.decisionReason}
                  />
                )}
              </BodyCellInner>
            )}
          </TableRowCell>
          <TableRowCell width={columns[7].width}>
            <PrevStatus amount={row.existingCommitment} data={row.preAllocationStatus} />
          </TableRowCell>
        </TableRowWrapper>
      ))}
    </>
  );
};

export default TableBody;

const CheckboxWrapper = styled.div`
  margin-right: 15px;
`;

const DecisionSelect = styled(FilterSelect)`
  border: none !important;

  #titleSelect {
    font-size: 16px;
  }

  #selectIcon {
    opacity: 0;
  }
`;
