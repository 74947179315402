import { ArrowUp } from '@/assets/static/icons/todo-page/arrow-up';
import CheckBox from '@/components/basicComponents/checkbox';
import { useResponsive } from '@/hooks/use-responsive';
import { useState } from 'react';
import styled, { useTheme } from 'styled-components';

interface CheckboxGroupItemProps {
  value: boolean;
  title: string;
  description: string | null;
  onChangeValue: () => void;
}

export const CheckboxGroupItem = ({ value, title, description, onChangeValue }: CheckboxGroupItemProps) => {
  const theme = useTheme();
  const { isMobile } = useResponsive();

  const [isSectionOpen, setIsSectionOpen] = useState(false);

  return (
    <CheckboxSectionWrapper>
      <CheckboxSectionItem>
        <CheckboxWrapper id="permissionCheckbox" onClick={onChangeValue}>
          <CheckBox id="permissionCheckbox" isActive={value} />
          {title}
        </CheckboxWrapper>
        {description ? (
          <CollapseButtonWrapper onClick={() => setIsSectionOpen(!isSectionOpen)}>
            <ArrowUp fill={theme.font.action} style={{ transform: isSectionOpen ? 'rotate(0deg)' : 'rotate(180deg)' }} />
          </CollapseButtonWrapper>
        ) : (
          <Space />
        )}
      </CheckboxSectionItem>
      <BodyContainer isMobile={isMobile} isSectionOpen={isSectionOpen}>
        <ExpandableWrapper isSectionOpen={isSectionOpen}>{description}</ExpandableWrapper>
      </BodyContainer>
    </CheckboxSectionWrapper>
  );
};

const CheckboxSectionWrapper = styled.div`
  padding: 12px 8px;
  border-top: 1px solid ${({ theme }) => theme.border.base};
  border-bottom: 1px solid ${({ theme }) => theme.border.base};
`;

const CheckboxSectionItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 9px;
  color: ${({ theme }) => theme.font.base};
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  cursor: pointer;
`;

const CollapseButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  cursor: pointer;
`;

const Space = styled.div`
  width: 38px;
  height: 38px;
`;

const BodyContainer = styled.div<{ isMobile: boolean; isSectionOpen: boolean }>`
  display: grid;
  grid-template-rows: ${({ isSectionOpen }) => (isSectionOpen ? '1fr' : '0fr')};
  overflow: ${({ isSectionOpen }) => (isSectionOpen ? 'visible' : 'hidden')};
  padding: ${({ isMobile, isSectionOpen }) => (isMobile ? '5px 20px 10px 20px' : isSectionOpen ? '10px 40px 20px 40px' : 0)};
  transition: grid-template-rows 300ms;
`;

const ExpandableWrapper = styled.div<{ isSectionOpen: boolean }>`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
  min-height: 0;
  opacity: ${({ isSectionOpen }) => (isSectionOpen ? '1' : '0')};
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  color: ${({ theme }) => theme.font.weak};
`;
