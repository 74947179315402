import styled from 'styled-components';

interface HtmlComponentProps {
  htmlString: string;
}

export const HtmlComponent = ({ htmlString }: HtmlComponentProps) => {
  return <StyledHtmlComponent dangerouslySetInnerHTML={{ __html: htmlString }} />;
};

const StyledHtmlComponent = styled.div`
  color: ${({ theme }) => theme.font.base};
`;
