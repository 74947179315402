import client from '@/apollo-client';
import { ApprovedIcon } from '@/assets/icons/info-icons/approved';
import { InfoIcon } from '@/assets/icons/info-icons/info';
import { AiAssistantIcon } from '@/assets/static/icons/ai-assistant';
import { addInvestmentOfferingMaterialsAttachment } from '@/components/allocations-page/queries';
import { Banner } from '@/components/basicComponents/banner';
import { IFile } from '@/components/dueDiligence-page/dueDiligenceRecord';
import { isImageExtension } from '@/components/dueDiligence-page/dueDiligenceRecord/utils';
import Button from '@/components/fat-basicComponents/button';
import FileUpload from '@/components/fat-basicComponents/fileUpload';
import { Investment, IOfferingMaterialsAttachments } from '@/components/fat-dealCatalog-page/types';
import Header from '@/components/fat-header';
import { GoBackButton } from '@/components/fat-header/goBackButton';
import { PageTitle } from '@/components/fat-header/pageTitle';
import { SectionsWrap } from '@/components/fat-investors-page/fat-addInvestors';
import { Section } from '@/components/fat-investors-page/fat-investorDetails-page/fat-entityDetails-page/edit-entity/section';
import { LoaderOnWholeScreen } from '@/components/loaders/loader-on-whole-screen';
import { AiPane } from '@/components/managerUpdates-page/managerUpdateDetails/editManagerUpdate/ai-pane';
import { EditFieldPage } from '@/components/managerUpdates-page/managerUpdateDetails/editManagerUpdate/edit-pages';
import { SectionFields } from '@/components/managerUpdates-page/managerUpdateDetails/editManagerUpdate/sectionFields';
import {
  createDocumentRecordMutation,
  GET_DOCUMENT_RECORD_QUERY,
  LIST_DOCUMENT_RECORDS_QUERY
} from '@/components/managerUpdates-page/managerUpdateDetails/queries';
import {
  IDocumentRecordFieldEntity,
  IGetDocumentRecordQuery,
  IListDocumentRecordsQuery
} from '@/components/managerUpdates-page/managerUpdateDetails/types';
import { getAssetSignedUrl } from '@/components/settings-page/queries';
import { SORT_ORDER } from '@/components/table/types';
import { useResponsive } from '@/hooks/use-responsive';
import { useGoBack } from '@/hooks/useGoBack';
import { MainWrap, PaddingWrap } from '@/styles/common';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { DealDocumentsModalPage } from './dealDocumentsModal-page';
import DeleteAttachment from './fat-modals/deleteAttachment';

const GET_INVESTMENT_ATTACHMENTS_QUERY = gql`
  query Investment($id: String!) {
    Investment(id: $id) {
      id
      offeringMaterialsAttachments {
        id
        asset {
          url
          assetKey
        }
        visibility
      }
    }
  }
`;

const EditStrategyDescription = () => {
  const { isMobile, isTablet } = useResponsive();
  const goBack = useGoBack();
  const params = useParams();
  const theme = useTheme();

  const [searchParams, setSearchParams] = useSearchParams();

  const backToTitle = searchParams.get('backToTitle') || '';

  const [uploadedFiles, setUploadedFiles] = useState<IOfferingMaterialsAttachments[] | []>([]);
  const [documentRecordId, setDocumentRecordId] = useState<string | null>(null);
  const [documentRecordLoading, setDocumentRecordLoading] = useState(true);
  const [descriptionSectionFields, setDescriptionSectionFields] = useState<IDocumentRecordFieldEntity[]>([]);
  const [isAiPaneOpen, setIsAiPaneOpen] = useState(false);
  const [generateAnswersLoading, setGenerateAnswersLoading] = useState(false);
  const [isGenerateAnswersLoadingCompleted, setIsGenerateAnswersLoadingCompleted] = useState(false);
  const [currentField, setCurrentField] = useState<IDocumentRecordFieldEntity | null>(null);
  const [editFieldPageType, setEditFieldPageType] = useState('default');
  const [scrollTo, setScrollTo] = useState(0);
  const [aiAiAssistantFiles, setAiAssistantFiles] = useState<IFile[]>([]);
  const [currentAttachment, setCurrentAttachment] = useState<IOfferingMaterialsAttachments | null>(null);
  const [isDealDocumentsModalPageOpen, setIsDealDocumentsModalPageOpen] = useState(false);
  const [modalWindow, setModalWindow] = useState({ isOpen: false, type: '' });

  const [createDocumentRecord] = useMutation(createDocumentRecordMutation);
  const [addAttachment] = useMutation(addInvestmentOfferingMaterialsAttachment);

  useEffect(() => {
    if (editFieldPageType === 'default' && !isDealDocumentsModalPageOpen && scrollTo) {
      window.scrollTo(0, scrollTo);
      setScrollTo(0);
    }
  }, [editFieldPageType, isDealDocumentsModalPageOpen]);

  const { loading: loadingInvestment } = useQuery<{ Investment: Investment }>(GET_INVESTMENT_ATTACHMENTS_QUERY, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    skip: !params?.strategyId,
    variables: { id: params?.strategyId },
    onCompleted: (data) => {
      setUploadedFiles(data?.Investment?.offeringMaterialsAttachments ?? []);
    },
    onError: (error) => {
      console.error(error);
    }
  });

  const { loading: listDocumentRecordsLoading } = useQuery<{ listDocumentRecords: IListDocumentRecordsQuery }>(LIST_DOCUMENT_RECORDS_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    skip: !params?.strategyId,
    variables: {
      data: {
        investmentId: params?.strategyId,
        sortBy: 'NAME',
        sortDirection: SORT_ORDER.ASC,
        documentTypeFilter: ['sd'],
        limit: 1
      }
    },
    onCompleted: (data) => {
      if (data.listDocumentRecords.records.length) {
        setDocumentRecordId(data.listDocumentRecords.records[0].id);
        return;
      }
      createDocumentRecord({
        variables: {
          data: {
            documentType: 'sd',
            investmentId: params?.strategyId
          }
        },
        onCompleted: (data) => {
          setDocumentRecordId(data.createDocumentRecord.id);
        }
      });
    },
    onError: (error) => {
      console.error(error);
    }
  });

  const { refetch: documentRecordRefetch } = useQuery<{ getDocumentRecord: IGetDocumentRecordQuery }>(GET_DOCUMENT_RECORD_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    skip: !documentRecordId,
    variables: {
      id: documentRecordId
    },
    onCompleted: (data) => {
      setDescriptionSectionFields(data.getDocumentRecord.fields);
      setDocumentRecordLoading(false);
    },
    onError: (error) => {
      console.error(error);
      setDocumentRecordLoading(false);
    }
  });

  const addOfferingMaterials = async (assetKey: string, investmentId: string) => {
    try {
      await addAttachment({
        variables: {
          data: {
            id: investmentId,
            assetKey
          }
        },
        onCompleted: (data) => {
          setUploadedFiles((prev) => [...prev, data.addInvestmentOfferingMaterialsAttachment]);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleGenerateAnswersLoadingChange = (loadingStatus: boolean, showCompleteIcon: boolean) => {
    setGenerateAnswersLoading(loadingStatus);
    setIsGenerateAnswersLoadingCompleted(showCompleteIcon);
  };

  const openAdvancedOptions = () => {
    setIsAiPaneOpen(false);
    setEditFieldPageType('advanced-options');
  };

  const openEditPage = (row: IDocumentRecordFieldEntity) => {
    setScrollTo(window.scrollY);
    setEditFieldPageType(row.documentField.type);
    setCurrentField(row);
    documentRecordRefetch();
  };

  const closeEditPage = () => {
    setEditFieldPageType('default');
    setCurrentField(null);
    documentRecordRefetch();
  };

  const openAttachment = async (assetKey: string) => {
    const { data } = await client.query({
      fetchPolicy: 'network-only',
      query: getAssetSignedUrl,
      variables: {
        assetKey: assetKey
      }
    });
    window.open(`${data.getAssetSignedUrl}`, '_blank');
  };

  const openDealDocumentsPage = (attachment?: IOfferingMaterialsAttachments) => {
    setScrollTo(window.scrollY);
    setIsDealDocumentsModalPageOpen(true);
    if (attachment) {
      setCurrentAttachment(attachment);
    }
  };

  const openModalWindow = (type: string, attachment: IOfferingMaterialsAttachments) => {
    setModalWindow({ type, isOpen: true });
    if (attachment) {
      setCurrentAttachment(attachment);
    }
  };

  const closeModalWindow = () => {
    setModalWindow({ ...modalWindow, isOpen: false });
    setCurrentAttachment(null);
  };

  if (loadingInvestment || listDocumentRecordsLoading || documentRecordLoading) {
    return <LoaderOnWholeScreen />;
  }

  if (isDealDocumentsModalPageOpen) {
    return (
      <DealDocumentsModalPage
        currentAttachment={currentAttachment}
        documentRecordId={documentRecordId}
        setUploadedFiles={setUploadedFiles}
        setAiAssistantFiles={setAiAssistantFiles}
        handleClosePage={() => {
          setIsDealDocumentsModalPageOpen(false);
          setCurrentAttachment(null);
        }}
      />
    );
  }

  if (editFieldPageType !== 'default' && editFieldPageType !== 'advanced-options') {
    return (
      <EditStrategyDescriptionWrapper>
        {((!isMobile && !isTablet) || !isAiPaneOpen) && (
          <EditFieldPage
            allFields={descriptionSectionFields}
            currentField={currentField}
            investmentId={params?.strategyId}
            editFieldPageType={editFieldPageType}
            shortTextAdditionalProperties={{ maxLength: 140 }}
            setIsAiPaneOpen={setIsAiPaneOpen}
            setCurrentField={setCurrentField}
            setEditFieldPageType={setEditFieldPageType}
            refetch={documentRecordRefetch}
            handleClose={closeEditPage}
          />
        )}
        <AiPane
          recordId={documentRecordId}
          investmentId={params?.strategyId}
          type="sd"
          documentRecordRefetch={documentRecordRefetch}
          files={aiAiAssistantFiles}
          setFiles={setAiAssistantFiles}
          isAiPaneOpen={isAiPaneOpen}
          onClose={() => setIsAiPaneOpen(false)}
          handleGenerateAnswersLoadingChange={handleGenerateAnswersLoadingChange}
          freeChatOnly={editFieldPageType !== 'default' && editFieldPageType !== 'advanced-options'}
          openAdvancedOptions={openAdvancedOptions}
          saveOfferingMaterials={(assetKey) => addOfferingMaterials(assetKey, params?.strategyId)}
        />
      </EditStrategyDescriptionWrapper>
    );
  }

  return (
    <>
      {modalWindow.isOpen && modalWindow.type === 'delete-attachment' && (
        <DeleteAttachment
          isOpen={modalWindow.isOpen}
          attachmentId={currentAttachment?.id ?? null}
          setUploadedFiles={setUploadedFiles}
          onClose={closeModalWindow}
        />
      )}
      <EditStrategyDescriptionWrapper>
        <MainWrap>
          <Header modalControl={<GoBackButton handleClose={() => goBack({ fallBack: '/dealPipeline' })} backToTitle={backToTitle} />} />
          <PageTitle title="Edit Strategy Description" />
          <PaddingWrap>
            <AiAssistantButtonWrapper>
              <AIAssistantButton
                styleType="outline"
                onClick={() => setIsAiPaneOpen(!isAiPaneOpen)}
                size="md"
                icon={<AiAssistantIcon fill={theme.font.action} />}
                secondaryIcon={isGenerateAnswersLoadingCompleted && <ApprovedIcon width={20} height={20} fill={theme.context.success} />}
                isLoading={generateAnswersLoading}
              >
                AI Assistant
              </AIAssistantButton>
            </AiAssistantButtonWrapper>
            <SectionsWrap>
              <Section title="Description">
                <div>
                  <SectionFields fields={descriptionSectionFields || []} mode="edit" openEditPage={openEditPage} refetch={documentRecordRefetch} />
                </div>
              </Section>
              <Section title="Offering Materials">
                <Banner
                  title=""
                  description="These attachments are available to advisors only after an initial commitment is made."
                  icon={<InfoIcon width={26} height={26} fill={theme.header.base} />}
                  bgColor={theme.layer[1]}
                  color={theme.font.base}
                />
                {uploadedFiles.length ? (
                  <>
                    <PreviewWrapper>
                      {uploadedFiles.map((item: IOfferingMaterialsAttachments) => (
                        <FileUpload
                          key={item.asset.assetKey}
                          type={isImageExtension(item.asset.assetKey) ? 'image' : 'document'}
                          imageSrc={item.asset.url}
                          fileName={item.asset.assetKey.split('/').pop()}
                          additionalContent={
                            <FileVisibility isVisible={item.visibility !== 'Not Visible to Advisors'}>{item.visibility}</FileVisibility>
                          }
                          openFile={() => openAttachment(item.asset.assetKey)}
                          editFile={() => openDealDocumentsPage(item)}
                          deleteFile={() => openModalWindow('delete-attachment', item)}
                        />
                      ))}
                    </PreviewWrapper>
                  </>
                ) : (
                  <></>
                )}
                <Button onClick={() => openDealDocumentsPage()} styleType="outline" size="md" width="max-content">
                  Upload
                </Button>
              </Section>
            </SectionsWrap>
          </PaddingWrap>
        </MainWrap>
        <AiPane
          recordId={documentRecordId}
          investmentId={params?.strategyId}
          type="sd"
          documentRecordRefetch={documentRecordRefetch}
          files={aiAiAssistantFiles}
          setFiles={setAiAssistantFiles}
          isAiPaneOpen={isAiPaneOpen}
          onClose={() => setIsAiPaneOpen(false)}
          handleGenerateAnswersLoadingChange={handleGenerateAnswersLoadingChange}
          freeChatOnly={editFieldPageType !== 'default' && editFieldPageType !== 'advanced-options'}
          openAdvancedOptions={openAdvancedOptions}
          saveOfferingMaterials={(assetKey) => addOfferingMaterials(assetKey, params?.strategyId)}
        />
      </EditStrategyDescriptionWrapper>
    </>
  );
};

export default EditStrategyDescription;

const EditStrategyDescriptionWrapper = styled.div`
  display: flex;
  justify-content: center;
  transition: all 0.3s;
`;

const AiAssistantButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 30px 0 20px;
`;

const AIAssistantButton = styled(Button)`
  &:hover {
    svg {
      path {
        fill: ${({ theme }) => theme.context.light};
      }
    }
  }
`;

const PreviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const FileVisibility = styled.div<{ isVisible: boolean }>`
  padding: 6px 20px;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.2px;
  color: ${({ isVisible }) => (isVisible ? '#277326' : '#ce7d03')};
  border-radius: 5px;
  background: ${({ isVisible }) => (isVisible ? '#EFFFEA' : '#ffeaca')};
`;
