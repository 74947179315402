import Textarea from '@/components/basicComponents/textarea';
import { FormFooter } from '@/components/fat-basicComponents/formFooter';
import Header from '@/components/fat-header';
import { GoBackButton } from '@/components/fat-header/goBackButton';
import { PageTitle } from '@/components/fat-header/pageTitle';
import { useResponsive } from '@/hooks/use-responsive';
import { MainWrap, PaddingWrap } from '@/styles/common';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FirmSettingsContainer } from '.';
import { IFirmSettings } from './types';

interface EditDisclosureTextProps {
  handleClose: () => void;
  createFirmSetting: (type: string, key: string, value: string) => void;
  updateFirmSetting: (id: string, value: string) => void;
  currentRow: IFirmSettings | null;
}

export const EditDisclosureText = ({ handleClose, createFirmSetting, updateFirmSetting, currentRow }: EditDisclosureTextProps) => {
  const { isMobile } = useResponsive();
  const [searchParams, setSearchParams] = useSearchParams();

  const rowId = searchParams.get('id') || '';
  const edit = searchParams.get('edit') || '';
  const fieldName = searchParams.get('fieldName') || '';

  const [disclosureTextValue, setDisclosureTextValue] = useState('');

  useEffect(() => {
    if (currentRow?.value) {
      setDisclosureTextValue(currentRow.value);
    }
    return () => {
      setDisclosureTextValue('');
    };
  }, [currentRow]);

  const saveValue = (key: string) => {
    if (rowId !== 'null') {
      updateFirmSetting(rowId, disclosureTextValue);
      return;
    }
    createFirmSetting('templateStyling', key, disclosureTextValue);
  };

  return (
    <>
      <MainWrap>
        <Header modalControl={<GoBackButton handleClose={handleClose} backToTitle="Firm Settings" />} />
        <PageTitle title={fieldName} />
      </MainWrap>
      <MainWrap>
        <PaddingWrap>
          <FirmSettingsContainer isMobile={isMobile}>
            <Textarea value={disclosureTextValue} setValue={setDisclosureTextValue} textareaHeight="250px" />
            <FormFooter onCancel={handleClose} onSave={() => saveValue(edit)} disableSaveButton={false} showRequiredFieldsWarning={false} />
          </FirmSettingsContainer>
        </PaddingWrap>
      </MainWrap>
    </>
  );
};
