import { IOwner } from '@/components/allocations-page/types';
import FilterSelect from '@/components/basicComponents/select';
import Label from '@/components/fat-basicComponents/label';
import { Section } from '@/components/fat-investors-page/fat-investorDetails-page/fat-entityDetails-page/edit-entity/section';
import { defaultSelectValue } from '@/components/opportunities-entity/modify/constants';
import { useResponsive } from '@/hooks/use-responsive';
import { Control, Controller } from 'react-hook-form';
import styled from 'styled-components';
import { IForm } from '../../types';
import { getOwnerValues } from '../../utils';

const OWNER_TOOL_TIP = 'Each owner will be the default assignee for relevant tasks associated with this investment.';

interface OwnersSectionProps {
  control: Control<IForm, any>;
  investmentOwners: IOwner[];
  operationsOwners: IOwner[];
  complianceOwners: IOwner[];
}

export const OwnersSection = ({ control, investmentOwners, operationsOwners, complianceOwners }: OwnersSectionProps) => {
  const { isMobile } = useResponsive();

  return (
    <Section title="Owners" collapsable>
      <Line isMobile={isMobile}>
        <CustomSelectWrapper>
          <Label size="md" required tooltip={OWNER_TOOL_TIP} tooltipWidth={300}>
            Investment Owner
          </Label>
          <Controller
            name="investmentOwner"
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Investment Owner is a required field'
              },
              pattern: {
                value: /^(?!Select$)/,
                message: 'Investment Owner is a required field'
              }
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <CustomSelect data={[defaultSelectValue, ...getOwnerValues(investmentOwners)]} selected={value} setSelected={onChange} width="100%" />
                {error?.message && <ErrorText>{error.message}</ErrorText>}
              </>
            )}
          />
        </CustomSelectWrapper>
        <CustomSelectWrapper>
          <Label size="md" required tooltip={OWNER_TOOL_TIP} tooltipWidth={300}>
            Operations Owner
          </Label>
          <Controller
            name="operationsOwner"
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Operations Owner is a required field'
              },
              pattern: {
                value: /^(?!Select$)/,
                message: 'Operations Owner is a required field'
              }
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <CustomSelect data={[defaultSelectValue, ...getOwnerValues(operationsOwners)]} selected={value} setSelected={onChange} width="100%" />
                {error?.message && <ErrorText>{error.message}</ErrorText>}
              </>
            )}
          />
        </CustomSelectWrapper>
        <CustomSelectWrapper>
          <Label size="md" required tooltip={OWNER_TOOL_TIP} tooltipWidth={300}>
            Compliance Owner
          </Label>
          <Controller
            name="complianceOwner"
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Compliance Owner is a required field'
              },
              pattern: {
                value: /^(?!Select$)/,
                message: 'Compliance Owner is a required field'
              }
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <CustomSelect data={[defaultSelectValue, ...getOwnerValues(complianceOwners)]} selected={value} setSelected={onChange} width="100%" />
                {error?.message && <ErrorText>{error.message}</ErrorText>}
              </>
            )}
          />
        </CustomSelectWrapper>
      </Line>
    </Section>
  );
};

const Line = styled.span<{ isMobile: boolean; width50?: boolean }>`
  display: flex;
  justify-content: space-between;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  width: ${({ width50, isMobile }) => (width50 && !isMobile ? '50%' : '100%')};
  gap: 30px;
  padding-right: ${({ width50, isMobile }) => (width50 && !isMobile ? '15px' : '0')};
  margin-top: 4px;
`;

const CustomSelectWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const CustomSelect = styled(FilterSelect)`
  background-color: ${({ theme }) => theme.layer[2]};
  border: 1px solid ${({ theme }) => theme.border.base};
  padding: 10px;
  border-radius: 5px;

  #titleSelect {
    font-family: Blinker;
    text-transform: none;
    font-size: 16px;
    line-height: 22.4px;
  }

  #dropDownSelect {
    top: 50px;
  }
`;

const ErrorText = styled.p`
  color: ${({ theme }) => theme.context.error};
  font-family: Blinker;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22.4px;
`;
