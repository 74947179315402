import { getUTCDate } from '@/components/allocations-page/utils';
import { IRenderTemplateField, IRenderTemplatePage } from '@/components/fat-transactions-page/generateSignaturePacket-page/templateComponents/types';
import _ from 'lodash';

const removeEmptyValuesFromFormData = (formData: any): any => {
  const cleanedData = _.transform(
    formData,
    (result: any, value, key) => {
      if (_.isArray(value)) {
        const nestedCleanedArray = value.map((item) => removeEmptyValuesFromFormData(item)).filter((item) => !_.isEmpty(item));
        if (nestedCleanedArray.length > 0) {
          result[key] = nestedCleanedArray;
        }
      } else if (_.isObject(value)) {
        const nestedCleanedData = removeEmptyValuesFromFormData(value);
        if (!_.isEmpty(nestedCleanedData)) {
          result[key] = nestedCleanedData;
        }
      } else if (!_.isNil(value) && value && value !== '') {
        result[key] = value;
      }
    },
    {}
  );

  return cleanedData;
};

export const getTemplateWithFormData = (pages: IRenderTemplatePage[], legalEntityDocumentationData: string) => {
  return pages.map((page) => {
    const formData = removeEmptyValuesFromFormData(JSON.parse(legalEntityDocumentationData)[_.camelCase(page.title)] || {});
    console.log(formData, 'formData');

    const updatedRows = page.rows.map((row) => {
      switch (row.component) {
        case 'custom':
          const updatedFields = row.fields.map((field) => {
            return addValueToField(field, formData);
          });
          return { ...row, fields: updatedFields };
        case 'textarea':
          return { ...row, value: formData?.[row.name] ?? '' };
        case 'checkboxGroup':
          const updatedItems = row.config.items.map((item: any) => {
            return { ...item, value: formData?.[item.name] ?? false };
          });
          return { ...row, config: { ...row.config, items: updatedItems } };
        case 'radio':
          return {
            ...row,
            value: (row?.config?.items.find((item: any) => item.value === formData?.[row.name])?.label || '') ?? row?.config?.items?.[0]?.label ?? ''
          };
        case 'address':
          if (!row?.dependsOn) {
            return { ...row, value: formData?.[row.name] };
          }
          if (row?.dependsOn && formData?.[row?.dependsOn.name] === row?.dependsOn?.value) {
            return { ...row, value: formData?.[row.name] };
          }
          return row;
        case 'bankAccounts':
          return {
            ...row,
            value: formData?.[row.name]
          };
        default:
          return row;
      }
    });

    if (Object.keys(formData).length) {
      return { ...page, rows: updatedRows, formData };
    }
    return { ...page, rows: updatedRows };
  });
};

export const addValueToField = (field: IRenderTemplateField, formData: any) => {
  if (!Object.keys(formData).length) return field;
  const matchingField = Object.keys(formData).find((key) => key === field.name);

  switch (field.component) {
    case 'date':
      return matchingField ? { ...field, value: getUTCDate(formData[matchingField]) } : field;
    case 'phoneNumber':
      if (!Object.keys(formData[matchingField] || {}).length) return field;

      const code = Object.values(formData[matchingField])[0] as string;
      const number = Object.values(formData[matchingField])[1] as string;
      return matchingField ? { ...field, value: `(${code.replace(/\s*\(.*?\)/, '')}) ${number}` } : field;
    default:
      return matchingField ? { ...field, value: formData[matchingField] } : field;
  }
};
