import axios from 'axios';
import { useEffect } from 'react';
import packageJson from '../../package.json';

const useRefresh = () => {
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/system/status`).then((response) => {
      const [major, minor, patch, buildNumber] = packageJson?.version?.split('.');
      const [newMmajor, newMminor, newPatch, newBuildNumber] = response?.data?.fe_build?.split('.');
      console.log(`comparing local version ${buildNumber} with server version ${newBuildNumber}`);
      if (newBuildNumber > buildNumber) {
        console.log('new build available, updating');
        window.location.reload();
      }
    });

    return () => {};
  }, []);
};

export default useRefresh;
