import client from '@/apollo-client';
import { FormFooter } from '@/components/fat-basicComponents/formFooter';
import Header from '@/components/fat-header';
import { GoBackButton } from '@/components/fat-header/goBackButton';
import { PageTitle } from '@/components/fat-header/pageTitle';
import { updateLegalEntityMutation } from '@/components/fat-investors-page/fat-addInvestors/queries';
import { EnterBankAccountInfoModalPage } from '@/components/fat-transactions-page/generateSignaturePacket-page/enterBankAccountInfoModalPage';
import { RowComponent } from '@/components/fat-transactions-page/generateSignaturePacket-page/templateComponents';
import {
  IRenderTemplate,
  IRenderTemplatePage,
  IRenderTemplateRow
} from '@/components/fat-transactions-page/generateSignaturePacket-page/templateComponents/types';
import { useResponsive } from '@/hooks/use-responsive';
import { MainWrap, PaddingWrap } from '@/styles/common';
import { useMutation } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormTemplate, generateSchemaFromTemplate } from '@trace-8/trace8-schema';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { GET_LEGAL_ENTITY_DOCUMENTATION_QUERY } from '../queries';

interface EditModalPageProps {
  template: IRenderTemplate | null;
  currentEditPage: IRenderTemplatePage;
  handleCloseEditPage: () => void;
}

export const EditModalPage = ({ template, currentEditPage, handleCloseEditPage }: EditModalPageProps) => {
  const { isMobile } = useResponsive();
  const params = useParams();

  const [modalPage, setModalPage] = useState({ isOpen: false, type: '' });
  const [currentRow, setCurrentRow] = useState<IRenderTemplateRow | null>(null);
  const [bankAccountIndex, setBankAccountIndex] = useState<number | null>(null);

  const schema = template ? generateSchemaFromTemplate({ ...template, pages: [currentEditPage] } as FormTemplate, true) : null;
  const { handleSubmit, getValues, setValue, control, setError, reset, watch } = useForm({
    resolver: schema ? zodResolver(schema) : null
  });

  const [updateEntity, { loading: updateEntityLoading }] = useMutation(updateLegalEntityMutation, {
    onError: (error) => {
      window.scrollTo(0, 0);
    }
  });

  useEffect(() => {
    if (!currentEditPage.formData) return;
    reset(currentEditPage.formData);
  }, [currentEditPage]);

  const onSubmit = async (data: any) => {
    try {
      const { data: legalEntityDocumentationData } = await client.query({
        query: GET_LEGAL_ENTITY_DOCUMENTATION_QUERY,
        variables: {
          id: params?.entityId
        }
      });
      const legalEntityDocumentation = JSON.parse(legalEntityDocumentationData.getLegalEntityDocumentation);
      const camelCaseTitle = _.camelCase(currentEditPage.title);
      const currentFormData = legalEntityDocumentation?.[camelCaseTitle];

      const dataToUpdate = {
        ...legalEntityDocumentation,
        [camelCaseTitle]: {
          ...currentFormData,
          ...data
        }
      };

      updateEntity({
        variables: {
          data: {
            id: params?.entityId,
            kyc: JSON.stringify(dataToUpdate)
          }
        },
        onCompleted: () => {
          handleCloseEditPage();
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const openModalPage = (type: string, row: IRenderTemplateRow, index: number = null) => {
    setModalPage({ type, isOpen: true });
    setCurrentRow(row);
    index !== null && setBankAccountIndex(index);
  };

  const closeModalWindow = () => {
    setModalPage({ type: '', isOpen: false });
    setCurrentRow(null);
    setBankAccountIndex(null);
  };

  const saveBankAccountInfo = (name: string, data: any) => {
    setValue(name, data);
  };

  return (
    <>
      {modalPage.isOpen && modalPage.type === 'enter-bank-account-info' && (
        <EnterBankAccountInfoModalPage
          row={currentRow}
          index={bankAccountIndex}
          bankAccountData={getValues(currentRow?.name || '') || []}
          saveBankAccountInfo={saveBankAccountInfo}
          closeModalWindow={closeModalWindow}
        />
      )}
      {!modalPage.isOpen && (
        <MainWrap>
          <Header modalControl={<GoBackButton handleClose={handleCloseEditPage} backToTitle="" />} />
          <PageTitle title={currentEditPage.title} />
          <PaddingWrap>
            <form
              id={`${_.camelCase(currentEditPage.title)}Form`}
              onSubmit={handleSubmit(onSubmit, () => {
                const validationResult = schema.safeParse(getValues());
                console.log(validationResult, 'validationResult');
                window.scrollTo(0, 0);
              })}
            >
              <EditPageWrapper isMobile={isMobile}>
                {currentEditPage?.rows?.map((row) => (
                  <RowComponent key={row.name} control={control} row={row} mode="documentationTab" ignoreRequired openModalPage={openModalPage} />
                ))}
              </EditPageWrapper>
              <FormFooter onCancel={handleCloseEditPage} disableSaveButton={false} showRequiredFieldsWarning={false} />
            </form>
          </PaddingWrap>
        </MainWrap>
      )}
    </>
  );
};

const EditPageWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-top: 30px;
  padding: ${({ isMobile }) => (isMobile ? '20px 30px' : '40px 60px')};
  background: ${({ theme }) => theme.layer[1]};
  border: 1px solid ${({ theme }) => theme.border.base};
  border-radius: 10px;
`;
