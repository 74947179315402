import Header from '@/components/fat-header';
import { GoBackButton } from '@/components/fat-header/goBackButton';
import { PageTitle } from '@/components/fat-header/pageTitle';
import FooterModal from '@/components/fat-settings-page/importData/footer';
import { LoaderOnWholeScreen } from '@/components/loaders/loader-on-whole-screen';
import { useResponsive } from '@/hooks/use-responsive';
import { useGoBack } from '@/hooks/useGoBack';
import { MainWrap, PaddingWrap } from '@/styles/common';
import { useQuery } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormTemplate, generateSchemaFromTemplate } from '@trace-8/trace8-schema';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { AddAttachmentFileModalPage } from './addAttachmentFileModalPage';
import { Details } from './details';
import { EnterBankAccountInfoModalPage } from './enterBankAccountInfoModalPage';
import { SignaturePacketWizardProgressBar } from './progressBar';
import { RENDERING_TEMPLATES_QUERY } from './queries';
import { RowComponent } from './templateComponents';
import { IRenderTemplate, IRenderTemplatePage, IRenderTemplateRow, IRenderTemplates } from './templateComponents/types';

const getInitialWizardSteps = (templateData: IRenderTemplate | null): { name: string; status: string }[] => {
  if (!templateData) return [];
  const inititalSteps = templateData.pages.map((page, index) => {
    return { name: page.title, status: index === 0 ? 'IN_PROGRESS' : 'NOT_STARTED' };
  });
  return inititalSteps;
};

export const GenerateSignaturePacket = () => {
  const { isMobile } = useResponsive();
  const goBack = useGoBack();

  const [template, setTemplate] = useState<FormTemplate | null>(null);
  const [currentStepPage, setCurrentStepPage] = useState<IRenderTemplatePage | null>(null);
  const schema = template ? generateSchemaFromTemplate({ ...template, pages: [currentStepPage] } as FormTemplate) : null;

  const {
    handleSubmit,
    getValues,
    setValue,
    control,
    setError,
    formState: { errors, isValid },
    reset,
    watch,
    trigger,
    clearErrors
  } = useForm({
    resolver: schema ? zodResolver(schema) : null
  });

  const [signaturePacketWizardSteps, setSignaturePacketWizardSteps] = useState([]);
  const [modalPage, setModalPage] = useState({ isOpen: false, type: '' });
  const [currentRow, setCurrentRow] = useState<IRenderTemplateRow | null>(null);
  const [bankAccountIndex, setBankAccountIndex] = useState<number | null>(null);

  const { loading: renderingTemplatesLoading } = useQuery<{ renderingTemplates: IRenderTemplates[] }>(RENDERING_TEMPLATES_QUERY, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setTemplate(data.renderingTemplates[0].template);
      setCurrentStepPage(data.renderingTemplates[0].template.pages[0]);
      setSignaturePacketWizardSteps(getInitialWizardSteps(data.renderingTemplates[0].template));
    },
    onError: (error) => {
      console.error(error);
    }
  });

  const prevStep = () => {
    const currentStepIndex = signaturePacketWizardSteps.findIndex((step) => step.status === 'IN_PROGRESS');
    if (currentStepIndex === 0) return;

    const updatedSignaturePacketWizardSteps = signaturePacketWizardSteps.map((step, index) => {
      if (currentStepIndex === index) {
        return { ...step, status: 'NOT_STARTED' };
      }
      if (currentStepIndex - 1 === index) {
        return { ...step, status: 'IN_PROGRESS' };
      }

      return step;
    });

    setCurrentStepPage(template.pages[currentStepIndex - 1]);
    setSignaturePacketWizardSteps(updatedSignaturePacketWizardSteps);
  };

  const nextStep = (data: any) => {
    clearErrors();
    const validationResult = schema.safeParse(data);
    console.log(validationResult, 'validationResult');

    const currentStepIndex = signaturePacketWizardSteps.findIndex((step) => step.status === 'IN_PROGRESS');
    if (currentStepIndex === signaturePacketWizardSteps.length - 1) return;

    const updatedSignaturePacketWizardSteps = signaturePacketWizardSteps.map((step, index) => {
      if (currentStepIndex === index) {
        return { ...step, status: 'COMPLETED' };
      }
      if (currentStepIndex + 1 === index) {
        return { ...step, status: 'IN_PROGRESS' };
      }

      return step;
    });

    setCurrentStepPage(template.pages[currentStepIndex + 1]);
    setSignaturePacketWizardSteps(updatedSignaturePacketWizardSteps);
  };

  const openModalPage = (type: string, row: IRenderTemplateRow, index: number = null) => {
    setModalPage({ type, isOpen: true });
    setCurrentRow(row);
    index !== null && setBankAccountIndex(index);
  };

  const closeModalWindow = () => {
    setModalPage({ type: '', isOpen: false });
    setCurrentRow(null);
    setBankAccountIndex(null);
  };

  const saveBankAccountInfo = (name: string, data: any) => {
    setValue(name, data);
  };

  if (renderingTemplatesLoading) {
    return <LoaderOnWholeScreen />;
  }

  return (
    <>
      {modalPage.isOpen && modalPage.type === 'enter-bank-account-info' && (
        <EnterBankAccountInfoModalPage
          row={currentRow}
          index={bankAccountIndex}
          bankAccountData={getValues(currentRow?.name || '') || []}
          saveBankAccountInfo={saveBankAccountInfo}
          closeModalWindow={closeModalWindow}
        />
      )}
      {modalPage.isOpen && modalPage.type === 'add-attachment-file' && <AddAttachmentFileModalPage closeModalWindow={closeModalWindow} />}
      {!modalPage.isOpen && (
        <MainWrap>
          <Header modalControl={<GoBackButton handleClose={() => goBack({ fallBack: '/investors' })} backToTitle="Pending Commitments" />} />
          <PageTitle title={'Bow River Fund XIII'} />
          <Details />
          <PaddingWrap>
            <StepWrapper isMobile={isMobile}>
              <SignaturePacketWizardProgressBar steps={signaturePacketWizardSteps} />
              {currentStepPage?.rows?.map((row) => (
                <RowComponent key={row.name} control={control} row={row} mode="wizard" openModalPage={openModalPage} />
              ))}
            </StepWrapper>
            <FooterModal
              startOver={signaturePacketWizardSteps[0]?.status === 'COMPLETED' ? prevStep : null}
              startOverButtonName="Back"
              confirmButton={{
                name: 'Save And Continue',
                onClick: handleSubmit(nextStep),
                disabled: false
              }}
            />
          </PaddingWrap>
        </MainWrap>
      )}
    </>
  );
};

const StepWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin: 30px 0 150px 0;
  padding: ${({ isMobile }) => (isMobile ? '20px 30px' : '40px 60px')};
  background: ${({ theme }) => theme.layer[1]};
  border: 1px solid ${({ theme }) => theme.border.base};
  border-radius: 10px;
`;
