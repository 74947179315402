import { UPDATE_INVESTMENT } from '@/components/dueDiligence-page/queries';
import { IInvestmentWorkflowTransitionTask, Investment } from '@/components/fat-dealCatalog-page/types';
import TableHeader from '@/components/table/fat-tableHeader';
import { IItems, ISort } from '@/components/table/types';
import { useResponsive } from '@/hooks/use-responsive';
import { NetworkStatus, useMutation } from '@apollo/client';
import { useState } from 'react';
import styled from 'styled-components';
import { Loader } from '../../loaders/loader';
import { TableBodyWrapper, TableWrapper } from '../../table/styles';
import { columns } from '../constatnts';
import ChangeAssignee from '../fat-modals/changeAssignee';
import CloseFund from '../fat-modals/closeFund';
import DeleteInvestment from '../fat-modals/deleteInvestment';
import FullyRealized from '../fat-modals/fullyRealized';
import Terminate from '../fat-modals/terminate';
import { updateInvestmentWorkflowTransitionTaskMutation } from '../queries';
import MobileTable from './mobileTable';
import TableBody from './tableBody';

interface TableProps {
  tableRows: Investment[];
  sort: ISort;
  setSort: (value: ISort) => void;
  refetch: () => void;
  investmentNetworkStatus: NetworkStatus;
  openModifyInvestment: (id: string) => void;
  openInvestorInterestPage: (id: string, name: string) => void;
  openOpportunityDetailsPage: (id: string) => void;
  openInvestmentForAllocations: (row: Investment) => void;
  publishToAdvisoryFirms: (row: Investment) => void;
  statuses: IInvestmentWorkflowTransitionTask[];
  filterPageHeight: number;
  openMobileModal: (data: Investment, modalType: string) => void;
}

const Table = ({
  tableRows,
  sort,
  setSort,
  refetch,
  investmentNetworkStatus,
  openModifyInvestment,
  openInvestorInterestPage,
  openOpportunityDetailsPage,
  openInvestmentForAllocations,
  publishToAdvisoryFirms,
  statuses,
  filterPageHeight,
  openMobileModal
}: TableProps) => {
  const { isMobile, isTablet } = useResponsive();

  const [modalWindow, setModalWindow] = useState({ isOpen: false, type: 'terminate' });
  const [currentRow, setCurrentRow] = useState<Investment | null>(null);

  const [updateInvestmentWorkflowTransitionTask, { loading: updateInvestmentWorkflowTransitionTaskLoading }] = useMutation(
    updateInvestmentWorkflowTransitionTaskMutation
  );
  const [updateInvestment, { loading: updateInvestmentLoading }] = useMutation(UPDATE_INVESTMENT);

  const updateStatus = async (row: Investment, step: 'next' | 'back') => {
    setCurrentRow(row);
    const currentStatusIndex = statuses.findIndex((status) => status.id === row.investmentWorkflowTransitionTask.id);
    const newStatus = step === 'next' ? statuses[currentStatusIndex + 1] : statuses[currentStatusIndex - 1];

    await updateInvestmentWorkflowTransitionTask({
      variables: {
        data: {
          investmentId: row.id,
          workflowTaskId: newStatus.id
        }
      },
      onCompleted: () => {
        refetch();
      }
    });
  };

  const getPreviousSecurityStatus = (row: Investment) => {
    switch (row.investmentWorkflowTransitionTask.name) {
      case 'Draft':
        return 'DRAFT';
      case 'In Research':
        return 'IN_RESEARCH';
      case 'Other Recommended':
        return 'OTHER_RECOMMENDED';
      case 'Seeking Interest':
        return 'APPROVED';
      case 'Accepting Commitments':
        return 'OPEN';
      case 'Closed':
        return 'ACTIVE';
      case 'Fully Realized':
        return 'REALIZED';
      default:
        return null;
    }
  };

  const restorePreviousStatus = (row: Investment) => {
    if (!getPreviousSecurityStatus(row)) return;
    setCurrentRow(row);

    updateInvestment({
      variables: {
        data: {
          id: row.id,
          securityStatus: getPreviousSecurityStatus(row)
        }
      },
      onCompleted: () => {
        refetch();
      }
    });
  };

  const actionsByStatus = (row: Investment): IItems[] => {
    if (!row.investmentWorkflowTransitionTask) return [];
    const currentStatusIndex = statuses.findIndex((status) => status.id === row.investmentWorkflowTransitionTask.id);

    return [
      ...(row.securityStatus !== 'Terminated'
        ? [
            {
              name: 'Edit',
              onClick: () => openModifyInvestment(row.id)
            }
          ]
        : []),
      ...(statuses[currentStatusIndex + 1] && (row.securityStatus === 'Draft' || statuses[currentStatusIndex].name === 'Draft')
        ? [
            {
              name: row.investmentWorkflowTransitionTask.transitionText,
              onClick: () => publishToAdvisoryFirms(row)
            }
          ]
        : []),
      ...(statuses[currentStatusIndex + 1] && (row.securityStatus === 'In Research' || row.securityStatus === 'Other Recommended')
        ? [
            {
              name: row.investmentWorkflowTransitionTask.transitionText,
              onClick: () => updateStatus(row, 'next')
            }
          ]
        : []),
      ...(statuses[currentStatusIndex + 1] && statuses[currentStatusIndex].name === 'Seeking Interest' && row.securityStatus === 'Approved'
        ? [
            {
              name: row.investmentWorkflowTransitionTask.transitionText,
              onClick: () => openInvestmentForAllocations(row)
            }
          ]
        : []),
      ...(statuses[currentStatusIndex + 1] && row.securityStatus === 'Open'
        ? [
            {
              name: row.investmentWorkflowTransitionTask.transitionText,
              onClick: () => openModalWindow('close-fund', row)
            }
          ]
        : []),
      ...(statuses[currentStatusIndex + 1] && row.securityStatus === 'Active'
        ? [
            {
              name: row.investmentWorkflowTransitionTask.transitionText,
              onClick: () => openModalWindow('fully-realized', row)
            }
          ]
        : []),
      {
        name: 'View Investor Interest',
        onClick: () => openInvestorInterestPage(row.id, row.name)
      },
      ...(row.securityStatus !== 'Terminated'
        ? [
            {
              name: 'Change Assignee',
              onClick: () => openModalWindow('change-assignee', row)
            }
          ]
        : []),
      ...(statuses[currentStatusIndex - 1] && row.securityStatus !== 'Terminated'
        ? [
            {
              name: 'Go Back To Previous Step',
              onClick: () => updateStatus(row, 'back')
            }
          ]
        : []),
      ...(row.securityStatus !== 'Terminated'
        ? [
            {
              name: 'Terminate',
              onClick: () => {
                openModalWindow('terminate', row);
              }
            }
          ]
        : []),
      ...(row.securityStatus === 'Terminated'
        ? [
            {
              name: 'Restore',
              onClick: () => restorePreviousStatus(row)
            }
          ]
        : []),
      ...(row.securityStatus === 'Draft' || row.securityStatus === 'In Research' || row.securityStatus === 'Other Recommended'
        ? [
            {
              name: 'Delete',
              onClick: () => openModalWindow('delete-investment', row)
            }
          ]
        : [])
    ];
  };

  const openModalWindow = (type: string, row: Investment) => {
    setModalWindow({ type, isOpen: true });
    setCurrentRow(row);
  };

  const closeModalWindow = () => {
    setModalWindow({ ...modalWindow, isOpen: false });
    setCurrentRow(null);
  };

  const renderDialog = (type: string) => {
    switch (type) {
      case 'terminate':
        return <Terminate isOpen={modalWindow.isOpen} onClose={closeModalWindow} currentRow={currentRow} refetch={refetch} />;
      case 'close-fund':
        return <CloseFund isOpen={modalWindow.isOpen} onClose={closeModalWindow} currentRow={currentRow} refetch={refetch} statuses={statuses} />;
      case 'fully-realized':
        return <FullyRealized isOpen={modalWindow.isOpen} onClose={closeModalWindow} currentRow={currentRow} refetch={refetch} statuses={statuses} />;
      case 'change-assignee':
        return <ChangeAssignee isOpen={modalWindow.isOpen} onClose={closeModalWindow} currentRow={currentRow} refetch={refetch} />;
      case 'delete-investment':
        return <DeleteInvestment isOpen={modalWindow.isOpen} onClose={closeModalWindow} currentRow={currentRow} refetch={refetch} />;
      default:
        return <></>;
    }
  };

  if (isMobile) {
    return (
      <div>
        {renderDialog(modalWindow.type)}
        {investmentNetworkStatus === NetworkStatus.loading || investmentNetworkStatus === NetworkStatus.setVariables ? (
          <Loader />
        ) : (
          <MobileTable
            tableRows={tableRows}
            openMobileModal={openMobileModal}
            openOpportunityDetailsPage={openOpportunityDetailsPage}
            actionsByStatus={actionsByStatus}
          />
        )}
      </div>
    );
  }

  return (
    <TableWrapper padding="0">
      {renderDialog(modalWindow.type)}
      <CustomTableHeader
        filterPageHeight={filterPageHeight}
        isTablet={isTablet}
        refetch={refetch}
        columns={columns}
        savedSort={sort}
        savedSetSort={setSort}
      />
      <TableBodyWrapper>
        {investmentNetworkStatus === NetworkStatus.loading || investmentNetworkStatus === NetworkStatus.setVariables ? (
          <Loader />
        ) : (
          <TableBody
            tableRows={tableRows}
            actionsByStatus={actionsByStatus}
            statuses={statuses}
            openOpportunityDetailsPage={openOpportunityDetailsPage}
            updateLoading={
              updateInvestmentWorkflowTransitionTaskLoading || updateInvestmentLoading || investmentNetworkStatus === NetworkStatus.refetch
            }
            currentRow={currentRow}
          />
        )}
      </TableBodyWrapper>
    </TableWrapper>
  );
};

export default Table;

const CustomTableHeader = styled(TableHeader)<{ filterPageHeight: number; isTablet: boolean }>`
  position: sticky;
  top: ${({ isTablet, filterPageHeight }) => (isTablet ? `${filterPageHeight + 50}px` : `${filterPageHeight}px`)};
  z-index: 8;
  padding-top: 0;
`;
