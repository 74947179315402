import * as React from 'react';

export const WetSignatureIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={props.width || 60} height={props.height || 55} style={props?.style} viewBox="0 0 60 55" fill="none">
    <g clip-path="url(#clip0_13064_26217)">
      <path
        d="M41.7427 17.6106C38.5162 14.456 35.3983 11.4119 32.2649 8.35205C32.0632 8.52555 31.9236 8.62019 31.815 8.74637C27.7509 13.0681 23.6868 17.3897 19.6382 21.7272C19.3435 22.0427 19.1263 22.5001 19.0332 22.9259C18.4283 25.5126 17.8543 28.0993 17.2804 30.7018C17.0012 31.9951 16.7375 33.2885 16.4272 34.7238C16.9391 34.6765 17.2184 34.6765 17.4976 34.5976C21.0343 33.6513 24.5555 32.7049 28.0767 31.727C28.4179 31.6324 28.7902 31.4273 29.0229 31.175C33.2266 26.6956 37.4148 22.2162 41.7271 17.5948L41.7427 17.6106ZM44.6744 14.2825C45.8998 13.0996 47.0632 12.0586 48.118 10.9388C49.0643 9.91354 49.1263 8.68328 48.1956 7.75269C46.0394 5.57608 43.8212 3.46255 41.572 1.3648C40.7344 0.591942 39.7261 0.591942 38.8574 1.3648C37.5855 2.50042 36.36 3.68337 35.057 4.89786C38.4696 8.22587 41.5875 11.27 44.6744 14.2825Z"
        fill="white"
      />
      <path
        d="M0 44.0454C1.33402 39.1401 4.77766 36.443 9.27611 34.8184C10.9359 34.2191 12.1303 34.708 12.5491 36.0802C12.9679 37.4051 12.332 38.383 10.7187 38.9982C8.50052 39.8341 6.46846 40.9382 5.13444 43.0359C3.87797 45.0075 4.31231 46.2062 6.57704 46.6952C9.16753 47.2472 11.7115 46.6479 14.1934 45.9381C18.5057 44.7236 22.7715 43.3198 27.1148 42.2C30.636 41.2852 34.2347 40.7805 37.8645 41.4587C41.2927 42.0896 42.8904 44.4397 42.4561 47.957C42.3785 48.5406 42.332 49.1242 42.2544 49.8497C44.7208 49.8182 47.0476 49.5342 49.2968 48.7456C51.8097 47.8623 54.2916 46.916 56.8046 46.0485C58.3092 45.528 59.4106 46.0327 59.8449 47.3103C60.3102 48.6668 59.7208 49.6762 58.0765 50.2282C54.5398 51.4427 51.0031 52.6414 47.4664 53.8086C46.8459 54.0137 46.1944 54.0925 45.5429 54.2187H42.0372C41.8046 54.1398 41.5719 54.0294 41.3392 53.9821C39.1055 53.5878 38.1593 52.5941 37.9731 50.2913C37.8956 49.2819 37.9421 48.2567 38.0662 47.263C38.1903 46.1431 37.6784 45.6542 36.6856 45.67C34.7777 45.7173 32.8232 45.5911 30.9772 46.0012C27.1768 46.8371 23.4385 47.9412 19.6846 48.998C15.7291 50.1021 11.8201 51.4112 7.61634 51.2061C3.41262 51.0011 1.45812 49.6762 0 45.8277L0 44.0454Z"
        fill="#2B2B2B"
      />
      <path
        d="M41.7423 17.6109C37.43 22.2323 33.2418 26.7117 29.038 31.1911C28.7898 31.4434 28.4331 31.6485 28.0918 31.7431C24.5706 32.721 21.0339 33.6674 17.5127 34.6137C17.249 34.6926 16.9543 34.6768 16.4424 34.7399C16.7371 33.3046 17.0008 32.0113 17.2955 30.7179C17.8695 28.1312 18.4434 25.5287 19.0484 22.942C19.1415 22.5162 19.3586 22.0588 19.6533 21.7433C23.7019 17.4059 27.7661 13.0842 31.8302 8.76248C31.9388 8.6363 32.0784 8.54166 32.28 8.36816C35.4134 11.428 38.5313 14.4722 41.7578 17.6267L41.7423 17.6109ZM18.4434 32.5791C21.0804 31.8535 23.5003 31.2069 26.1063 30.4971C24.0432 28.5097 22.1663 26.6959 20.1497 24.7716C19.5603 27.453 19.0329 29.8977 18.4279 32.5948L18.4434 32.5791Z"
        fill="#575757"
      />
      <path
        d="M44.674 14.2825C41.5871 11.27 38.4693 8.22587 35.0566 4.89786C36.3596 3.68337 37.5851 2.50042 38.8571 1.3648C39.7257 0.591942 40.734 0.591942 41.5716 1.3648C43.8209 3.44678 46.0235 5.57608 48.1952 7.75269C49.1259 8.68328 49.0794 9.91354 48.1177 10.9388C47.0628 12.0744 45.8995 13.0996 44.674 14.2825Z"
        fill="#8F8F8F"
      />
      <path
        d="M18.4438 32.5791C19.0333 29.8819 19.5762 27.453 20.1657 24.7559C22.1822 26.6959 24.0436 28.494 26.1222 30.4813C23.5007 31.1911 21.0964 31.8535 18.4594 32.5633L18.4438 32.5791Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_13064_26217">
        <rect width="60" height="53.4375" fill="white" transform="translate(0 0.78125)" />
      </clipPath>
    </defs>
  </svg>
);
