import { DescriptionSectionBody } from '@/components/fat-dealPipeline-page/fat-modify/sections/descriptionSection/descriptionSectionBody';
import { IDocumentRecordFieldEntity } from '@/components/managerUpdates-page/managerUpdateDetails/types';
import { useAuth } from '@/hooks/use-auth';
import styled from 'styled-components';
import { IOfferingMaterialsAttachments } from '../types';

interface DescriptionTabProps {
  offeringMaterialsAttachments: IOfferingMaterialsAttachments[];
  descriptionSectionFields: IDocumentRecordFieldEntity[];
  openEditDescriptionPage: () => void;
}

export const DescriptionTab = ({ offeringMaterialsAttachments, descriptionSectionFields, openEditDescriptionPage }: DescriptionTabProps) => {
  const { user } = useAuth();

  if (user && user.tenant.type === 'fundManager') {
    return (
      <DetailsSection>
        <DescriptionSectionBody
          data={descriptionSectionFields}
          type="opportunityDetails"
          uploadedFiles={offeringMaterialsAttachments}
          onEditClick={openEditDescriptionPage}
        />
      </DetailsSection>
    );
  }

  return <></>;
};

const DetailsSection = styled.div`
  padding: 40px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.border.base};
  background: ${({ theme }) => theme.layer[1]};
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
