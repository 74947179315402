import styled from 'styled-components';

export const TwoColumnWrapper = styled.div<{ isMobile: boolean }>`
  display: grid;
  grid-template-columns: ${({ isMobile }) => (isMobile ? '1fr' : '1fr 1fr')};
  gap: ${({ isMobile }) => (isMobile ? '15px' : '30px')};
`;

export const ThreeColumnWrapper = styled.div<{ isMobile: boolean }>`
  display: grid;
  grid-template-columns: ${({ isMobile }) => (isMobile ? '1fr' : '1fr 1fr 1fr')};
  gap: ${({ isMobile }) => (isMobile ? '15px' : '30px')};
`;

export const HeaderComponent = styled.div`
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  color: ${({ theme }) => theme.font.strong};
`;
