import {
  MobileRow,
  MobileRowTitle,
  MobileRowValue,
  MobileTableRowWrap,
  MobileTableWrapper,
  NoResults
} from '@/components/table/mobileTable/mobileTable';
import { format } from 'date-fns';

import { IInfoIcon } from '@/assets/icons/info-icons/i-info';
import { PreAllocationStatusType } from '@/components/allocations-page/types';
import BasicTooltip from '@/components/basicComponents/tooltip';
import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import Suitability from '@/components/client-entity-details/tableAllocations/suitability';
import { SuitabilityWrapper } from '@/components/opportunities-entity/pendingAllocation/suitabilityDetails';
import styled, { useTheme } from 'styled-components';
import { columns } from '../../constatnts';
import { IAllocationRow } from '../../types';

interface MobileTableProps {
  potentialAllocationData: IAllocationRow[];
  openMobileModal: (data: IAllocationRow, modalType: string) => void;
  openInvestorDetails: (id: string) => void;
  openEntityDetails: (investorId: string, entityId: string) => void;
}

const MobileTable = ({ potentialAllocationData, openMobileModal, openInvestorDetails, openEntityDetails }: MobileTableProps) => {
  const theme = useTheme();

  const getPreAllocationStatus = (value: PreAllocationStatusType) => {
    if (value?.alreadyCommitted) {
      return `Committed,  ${value.committedAt ? format(new Date(value.committedAt), 'LLL d, yyyy').toUpperCase() : ''}`;
    } else if (value?.internalReviewRejections) {
      return `Internal Review Rejected,  ${
        value.internalReviewRejectionAt ? format(new Date(value.internalReviewRejectionAt), 'LLL d, yyyy').toUpperCase() : ''
      }`;
    } else if (value?.terminated) {
      return `Terminated,  ${value.terminatedAt ? format(new Date(value.terminatedAt), 'LLL d, yyyy').toUpperCase() : ''}`;
    } else {
      return '';
    }
  };

  const TableRow = ({ el }: { el: IAllocationRow }) => {
    return (
      <MobileTableRowWrap key={el.legalEntity.id}>
        <MobileRow>
          <MobileRowTitle>{columns[0].title}</MobileRowTitle>
          <MobileRowValue isRedirect onClick={() => openEntityDetails(el.legalEntity?.family.id, el.legalEntity?.id)}>
            {el.legalEntity?.entityName}
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[1].title}</MobileRowTitle>
          <MobileRowValue isRedirect onClick={() => openInvestorDetails(el.legalEntity?.family.id)}>
            {el.legalEntity?.family.name}
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[2].title}</MobileRowTitle>
          <MobileRowValue>
            <FormattingTooltip zIndex={1000}>{+el.legalEntity?.overallCapacity}</FormattingTooltip>
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[3].title}</MobileRowTitle>
          <MobileRowValue>
            <FormattingTooltip zIndex={1000}>{+el.legalEntity?.assetClassCapacity}</FormattingTooltip>
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[4].title}</MobileRowTitle>
          <MobileRowValue onClick={() => openMobileModal(el, 'suitability')}>
            <SuitabilityWrapper>
              <Suitability suitability={el.suitability} width="100" />
            </SuitabilityWrapper>
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[5].title}</MobileRowTitle>
          <AmountMobileRowValue>
            {el.committedCapital !== el.legalEntity.fundBiteSize && (
              <BasicTooltip tooltipContent={'Differs from default bite size'}>
                <IInfoIcon style={{ display: 'flex', alignItems: 'center' }} fill={theme.context.warning} />
              </BasicTooltip>
            )}
            <FormattingTooltip zIndex={1000}>{+el.committedCapital}</FormattingTooltip>
          </AmountMobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[6].title}</MobileRowTitle>
          <MobileRowValue>{el.decisionReason}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>History</MobileRowTitle>
          <MobileRowValue>{getPreAllocationStatus(el.preAllocationStatus)}</MobileRowValue>
        </MobileRow>
      </MobileTableRowWrap>
    );
  };

  return (
    <MobileTableWrapper>
      {potentialAllocationData.length ? (
        potentialAllocationData.map((el, index) => {
          return <TableRow el={el} key={index} />;
        })
      ) : (
        <NoResults>No Results</NoResults>
      )}
    </MobileTableWrapper>
  );
};

export default MobileTable;

const AmountMobileRowValue = styled(MobileRowValue)`
  gap: 5px;
`;
