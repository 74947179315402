import { DatePicker } from '@/components/fat-form-field';
import { Section } from '@/components/fat-investors-page/fat-investorDetails-page/fat-entityDetails-page/edit-entity/section';
import { useResponsive } from '@/hooks/use-responsive';
import { Control, FieldErrors } from 'react-hook-form';
import styled from 'styled-components';
import { IForm } from '../../types';

interface ClosingsSectionProps {
  control: Control<IForm, any>;
  errors: FieldErrors<IForm>;
}

export const ClosingsSection = ({ control, errors }: ClosingsSectionProps) => {
  const { isMobile } = useResponsive();

  return (
    <Section title="Closings" collapsable>
      <DatesSectionWrap>
        <Line isMobile={isMobile}>
          <DatePicker
            title={'Investor Docs Due'}
            nameField={'investorDocsDue'}
            control={control}
            errors={errors}
            tooltip={'Provide advisors with a due date for investor docs.'}
            size="md"
          />
          <DatePicker
            title={'Next Close'}
            nameField={'nextClose'}
            control={control}
            errors={errors}
            tooltip={'Set the Next Close date that you wish to track.  You can edit this date at any time.'}
            tooltipWidth={300}
            size="md"
          />
        </Line>
      </DatesSectionWrap>
    </Section>
  );
};

const DatesSectionWrap = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
`;

const Line = styled.span<{ isMobile: boolean; width50?: boolean }>`
  display: flex;
  justify-content: space-between;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  width: ${({ width50, isMobile }) => (width50 && !isMobile ? '50%' : '100%')};
  gap: 30px;
  padding-right: ${({ width50, isMobile }) => (width50 && !isMobile ? '15px' : '0')};
  margin-top: 4px;
`;
