import { SortIcon } from '@/assets/icons/sortIcon';
import { useResponsive } from '@/hooks/use-responsive';
import { useState } from 'react';
import styled, { useTheme } from 'styled-components';

interface DetailsProps {}

export const Details = ({}: DetailsProps) => {
  const { isMobile } = useResponsive();
  const theme = useTheme();
  const [isDetailsOpen, setIsDetailsOpen] = useState(true);

  return (
    <DetailsWrapper isOpen={isDetailsOpen} isMobile={isMobile}>
      <DetailsBoby isMobile={isMobile} isOpen={isDetailsOpen}>
        <ExpandableWrapper isMobile={isMobile} isOpen={isDetailsOpen}>
          <TwoColumnWrapper isMobile={isMobile}>
            <DetailsItemWrapper>
              Amount
              <DetailsItemValue>--</DetailsItemValue>
            </DetailsItemWrapper>
            <DetailsItemWrapper>
              Vehicle
              <DetailsItemValue>--</DetailsItemValue>
            </DetailsItemWrapper>
          </TwoColumnWrapper>
          <TwoColumnWrapper isMobile={isMobile}>
            <DetailsItemWrapper>
              Investor Docs Due
              <DetailsItemValue>--</DetailsItemValue>
            </DetailsItemWrapper>
            <DetailsItemWrapper>
              Vehicle Tax Status
              <DetailsItemValue>--</DetailsItemValue>
            </DetailsItemWrapper>
          </TwoColumnWrapper>
          <TwoColumnWrapper isMobile={isMobile}>
            <DetailsItemWrapper>
              Investor Entity Tax Status
              <DetailsItemValue>--</DetailsItemValue>
            </DetailsItemWrapper>
            <DetailsItemWrapper>
              Investor Entity Type
              <DetailsItemValue>--</DetailsItemValue>
            </DetailsItemWrapper>
          </TwoColumnWrapper>
        </ExpandableWrapper>
      </DetailsBoby>

      <HideDetailsWrap isMobile={isMobile} onClick={() => setIsDetailsOpen(!isDetailsOpen)}>
        <ArrowBtn isRotate={isDetailsOpen}>
          <SortIcon fill={theme.action.primary} />
        </ArrowBtn>
        <ControlDetailsTitle isMobile={isMobile}>{isDetailsOpen ? 'Hide Details' : 'Show Details'}</ControlDetailsTitle>
      </HideDetailsWrap>
    </DetailsWrapper>
  );
};

const DetailsWrapper = styled.div<{ isOpen: boolean; isMobile: boolean }>`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: ${({ theme }) => theme.layer[1]};
  border-bottom: 1px solid ${({ theme }) => theme.border.base};
  transition: 1s;
`;

const DetailsBoby = styled.div<{ isOpen: boolean; isMobile: boolean }>`
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  display: grid;
  grid-template-rows: ${({ isOpen }) => (isOpen ? '1fr' : '0fr')};
  overflow: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  transition: grid-template-rows 300ms;
  padding: ${({ isMobile }) => (isMobile ? '20px 40px' : '20px  90px')};
  color: ${({ theme }) => theme.font.base};
`;

const ExpandableWrapper = styled.div<{ isMobile: boolean; isOpen: boolean }>`
  min-height: 0;
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

const TwoColumnWrapper = styled.div<{ isMobile: boolean }>`
  display: grid;
  grid-template-columns: ${({ isMobile }) => (isMobile ? '1fr' : '1fr 1fr')};
  gap: ${({ isMobile }) => (isMobile ? '3px' : '30px')};
`;

const DetailsItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  color: ${({ theme }) => theme.font.base};
  padding: 5px 20px;
  border-radius: 4px;
  background: ${({ theme }) => theme.layer.base};
`;

const DetailsItemValue = styled.div`
  font-weight: 600;
`;

const HideDetailsWrap = styled.div<{ isMobile: boolean }>`
  margin-left: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px 30px;
  gap: 12.5px;
`;

const ArrowBtn = styled.div<{ isRotate: boolean }>`
  transform: ${({ isRotate }) => (isRotate ? 'rotate(180deg)' : 'rotate(0deg)')};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
`;

const ControlDetailsTitle = styled.div<{ isMobile: boolean }>`
  font-family: Blinker, serif;
  font-size: ${({ isMobile }) => (isMobile ? '13px' : '16px')};
  font-weight: 600;
  line-height: 22px;
  color: ${({ theme }) => theme.action.primary};
`;
