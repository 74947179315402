import Label from '@/components/fat-basicComponents/label';
import { useResponsive } from '@/hooks/use-responsive';
import { Controller, useWatch } from 'react-hook-form';
import styled from 'styled-components';
import { IRenderTemplateRow } from '../../types';
import { CheckboxGroupItem } from './checkboxGroupItem';

interface CheckboxGroupComponentProps {
  control: any;
  row: IRenderTemplateRow;
  ignoreRequired: boolean;
}

export const CheckboxGroupComponent = ({ control, row, ignoreRequired }: CheckboxGroupComponentProps) => {
  const { isMobile } = useResponsive();
  const { label, config, dependsOn } = row;
  const dependentValue = dependsOn ? useWatch({ control, name: dependsOn.name }) : null;

  if (dependsOn && dependentValue !== dependsOn.value) return <></>;

  return (
    <>
      <CheckboxesWrapper isMobile={isMobile}>
        {label && <Label size="md">{label}</Label>}
        {config?.items?.map((item: any) => (
          <Controller
            key={item.name}
            name={item.name}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <CheckboxGroupItem
                  key={item.name}
                  value={!!value}
                  onChangeValue={() => onChange(!value)}
                  title={item.label}
                  description={item.description}
                />
                {error?.message && <ErrorText>{error?.message}</ErrorText>}
              </>
            )}
          />
        ))}
      </CheckboxesWrapper>
    </>
  );
};

const CheckboxesWrapper = styled.div<{ isMobile: boolean }>`
  padding: ${({ isMobile }) => (isMobile ? '20px 10px 10px;' : '30px 20px 20px')};
`;

const ErrorText = styled.p`
  color: ${({ theme }) => theme.context.error};
  font-family: Blinker;
  font-weight: 400;
  font-size: 16px;
  line-height: 22.4px;
`;
