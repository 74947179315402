import client from '@/apollo-client';
import { RejectedBannerIcon } from '@/assets/icons/info-icons/rejectedBanner';
import { Banner } from '@/components/basicComponents/banner';
import RadioButton from '@/components/basicComponents/radioButton';
import Textarea from '@/components/basicComponents/textarea';
import { DropFileArea } from '@/components/dueDiligence-page/dueDiligenceRecord/dropFileArea';
import FileNotSupported from '@/components/dueDiligence-page/modals/fileNotSupported';
import FileUpload from '@/components/fat-basicComponents/fileUpload';
import { FormFooter } from '@/components/fat-basicComponents/formFooter';
import Label from '@/components/fat-basicComponents/label';
import Header from '@/components/fat-header';
import { GoBackButton } from '@/components/fat-header/goBackButton';
import { PageTitle } from '@/components/fat-header/pageTitle';
import { createSettingAttachment, deleteSettingAttachment, getSettingAttachmentUploadUrl } from '@/components/settings-page/queries';
import { useResponsive } from '@/hooks/use-responsive';
import { MainWrap, PaddingWrap } from '@/styles/common';
import { ApolloError, useMutation } from '@apollo/client';
import { ChangeEvent, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { FirmSettingsContainer } from '.';
import { fileExtensions } from './constants';
import { IFirmSettings } from './types';
import { isURL } from './utils';

interface EditWatermarkProps {
  handleClose: () => void;
  createFirmSetting: (type: string, key: string, value: string) => void;
  updateFirmSetting: (id: string, value: string) => void;
  currentRow: IFirmSettings | null;
  watermarkImg: string;
}

const radioButtonItems = ['None', 'Text', 'Image'];

export const EditWatermark = ({ handleClose, createFirmSetting, updateFirmSetting, currentRow, watermarkImg }: EditWatermarkProps) => {
  const theme = useTheme();
  const { isMobile, isTablet } = useResponsive();
  const [searchParams, setSearchParams] = useSearchParams();

  const rowId = searchParams.get('id') || '';
  const fieldName = searchParams.get('fieldName') || '';

  const [selectedOption, setSelectedOption] = useState(radioButtonItems[0]);
  const [watermarkText, setWatermarkText] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const [fileUrl, setFileUrl] = useState('');
  const [drag, setDrag] = useState(false);
  const [isAttachmentDeleted, setIsAttachmentDeleted] = useState(false);
  const [modalWindow, setModalWindow] = useState({ isOpen: false, type: 'not-supported' });
  const [addAttachmentError, setAddAttachmentError] = useState<ApolloError | null>(null);
  const [deletedFile, setDeletedFile] = useState('');

  useEffect(() => {
    if (currentRow?.value) {
      const parsedValue = JSON.parse(currentRow.value);
      if (!parsedValue.value) {
        setSelectedOption(radioButtonItems[0]);
        return;
      }
      if (isURL(parsedValue.value)) {
        setFileUrl(watermarkImg);
        setSelectedOption(radioButtonItems[2]);
        return;
      }
      if (parsedValue.value !== 'None') {
        setWatermarkText(parsedValue.value);
        setSelectedOption(radioButtonItems[1]);
        return;
      }
      setSelectedOption(radioButtonItems[0]);
    }
    return () => {
      setWatermarkText('');
    };
  }, [currentRow]);

  const [createAttachment] = useMutation(createSettingAttachment);
  const [deleteAttachment] = useMutation(deleteSettingAttachment);

  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
  };

  const onDropHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const uploadFiles = e.dataTransfer.files;
    setDrag(false);

    if (fileExtensions.includes(uploadFiles[0]?.name.split('.').pop()?.toUpperCase() ?? '')) {
      setFile(uploadFiles[0]);
      setFileUrl(URL.createObjectURL(uploadFiles[0]));
      return;
    }
    setModalWindow({ isOpen: true, type: 'not-supported' });
  };
  const handleUploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const uploadFiles = e.target.files;

    if (uploadFiles && fileExtensions.includes(uploadFiles[0]?.name.split('.').pop()?.toUpperCase() ?? '')) {
      setFile(uploadFiles[0]);
      setFileUrl(URL.createObjectURL(uploadFiles[0]));
      return;
    }
    setModalWindow({ isOpen: true, type: 'not-supported' });
  };

  const deletePreviewItem = () => {
    if (!file && currentRow?.value && isURL(JSON.parse(currentRow.value).value)) {
      setDeletedFile(JSON.parse(currentRow.value).id);
    }
    setFile(null);
    setFileUrl('');
  };

  const logoUpload = async (file: File) => {
    const isValueImage = currentRow?.value && isURL(JSON.parse(currentRow.value).value);
    try {
      const { data: firmSettingsLogoUrl } = await client.query({
        query: getSettingAttachmentUploadUrl,
        variables: {
          data: {
            fieldType: 'firmSetting',
            key: 'logo',
            contentType: file.type,
            filename: file.name.replace(/\s/g, '')
          }
        }
      });
      await fetch(firmSettingsLogoUrl.getSettingAttachmentUploadUrl.url, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type
        }
      });

      await createAttachment({
        variables: {
          assetKey: firmSettingsLogoUrl.getSettingAttachmentUploadUrl.assetKey
        },
        onCompleted: (data) => {
          if (isValueImage && !isAttachmentDeleted) {
            deleteAttachment({
              variables: { id: JSON.parse(currentRow.value).id }
            });
          }
          if (rowId !== 'null') {
            updateFirmSetting(
              rowId,
              JSON.stringify({
                value: data.createSettingAttachment.url,
                assetKey: firmSettingsLogoUrl.getSettingAttachmentUploadUrl.assetKey,
                id: data.createSettingAttachment.id
              })
            );
            return;
          }
          createFirmSetting(
            'templateStyling',
            'watermark',
            JSON.stringify({
              value: data.createSettingAttachment.url,
              assetKey: firmSettingsLogoUrl.getSettingAttachmentUploadUrl.assetKey,
              id: data.createSettingAttachment.id
            })
          );
        },
        onError: (error) => {
          handleAttachmentError(error);
        }
      });
    } catch (error) {
      console.log(error, 'error');
      handleAttachmentError(error as ApolloError);
    }
  };

  const handleAttachmentError = (error: ApolloError) => {
    console.log(error, 'error');
    if (error.message.includes('duplicate key value violates unique constraint') || error.message.includes('Asset key already exists')) {
      setAddAttachmentError(error);
      setFile(null);
      setFileUrl(currentRow?.value ? JSON.parse(currentRow.value).value : '');
    }
  };

  const saveValue = () => {
    if (deletedFile) {
      deleteAttachment({
        variables: { id: deletedFile },
        onCompleted: () => {
          setIsAttachmentDeleted(true);
          updateFirmSetting(rowId, '');
        }
      });
    }

    if (file) {
      logoUpload(file);
      return;
    }

    if (selectedOption === 'Image' && !isAttachmentDeleted) {
      handleClose();
      return;
    }

    if (selectedOption !== 'Image' && currentRow?.value && isURL(JSON.parse(currentRow.value).value)) {
      deleteAttachment({
        variables: { id: JSON.parse(currentRow.value).id }
      });
    }

    const value = selectedOption === 'None' ? 'None' : watermarkText;
    if (rowId !== 'null') {
      updateFirmSetting(rowId, JSON.stringify({ value: value }));
      return;
    }
    createFirmSetting('templateStyling', 'watermark', JSON.stringify({ value: value }));
  };

  const openLogoImage = (url: string) => {
    window.open(url, '_blank');
  };

  return (
    <>
      {modalWindow.isOpen && modalWindow.type === 'not-supported' && (
        <FileNotSupported isOpen={modalWindow.isOpen} onClose={() => setModalWindow({ ...modalWindow, isOpen: false })} />
      )}
      <MainWrap>
        <Header modalControl={<GoBackButton handleClose={handleClose} backToTitle="Firm Settings" />} />
        <PageTitle title={fieldName} />
      </MainWrap>
      <MainWrap>
        {addAttachmentError && (
          <Banner
            icon={<RejectedBannerIcon fill={theme.context.light} />}
            title="File name already exists"
            description="In order to upload your file please give it a unique name."
            bgColor={theme.context.error}
          />
        )}
        <PaddingWrap>
          <FirmSettingsContainer isMobile={isMobile}>
            <Label>Watermark Type</Label>
            <RadioButtonsWrap>
              {radioButtonItems.map((item) => (
                <RadioButton
                  key={item}
                  name="watermark"
                  radioButtonText={item}
                  selectedOption={selectedOption}
                  handleRadioChange={handleRadioChange}
                />
              ))}
            </RadioButtonsWrap>
            {selectedOption === 'None' && <Label>Watermark None</Label>}
            {selectedOption === 'Text' && (
              <>
                <Label>Watermark Text</Label>
                <WatermarkTextareaWrapper isTablet={isTablet} isMobile={isMobile}>
                  <Textarea value={watermarkText} setValue={setWatermarkText} maxLength={80} textareaHeight="44px" />
                </WatermarkTextareaWrapper>
              </>
            )}
            {selectedOption === 'Image' && (
              <>
                {fileUrl && (
                  <FileUpload
                    fileName="Watermark"
                    imageSrc={fileUrl}
                    type="image"
                    openFile={() => openLogoImage(fileUrl)}
                    deleteFile={deletePreviewItem}
                  />
                )}
                <DropFileArea
                  id="watermark"
                  fileExtensions={fileExtensions}
                  handleUploadFile={handleUploadFile}
                  onDropHandler={onDropHandler}
                  drag={drag}
                  setDrag={setDrag}
                />
              </>
            )}
            <FormFooter onCancel={handleClose} onSave={saveValue} disableSaveButton={false} showRequiredFieldsWarning={false} />
          </FirmSettingsContainer>
        </PaddingWrap>
      </MainWrap>
    </>
  );
};

const RadioButtonsWrap = styled.label`
  display: flex;
`;

const WatermarkTextareaWrapper = styled.div<{ isTablet: boolean; isMobile: boolean }>`
  width: ${({ isMobile, isTablet }) => (isMobile || isTablet ? '100%' : '50%')};
`;
