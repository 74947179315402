import * as React from 'react';

export const FilterSearchIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg width={props.width || 12} height={props.height || 12} style={props?.style} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.57502 7.54589H8.03576L7.84229 7.36064C8.59285 6.48553 8.97522 5.35369 8.90914 4.20269C8.84306 3.0517 8.33365 1.97105 7.48787 1.18759C6.64209 0.40413 5.5257 -0.0212223 4.37302 0.000815499C3.22034 0.0228533 2.121 0.490564 1.30578 1.30578C0.490564 2.121 0.0228533 3.22034 0.000815499 4.37302C-0.0212223 5.5257 0.40413 6.64209 1.18759 7.48787C1.97105 8.33365 3.0517 8.84304 4.20269 8.90912C5.35369 8.9752 6.48553 8.59283 7.36064 7.84227L7.54589 8.03164V8.57502L10.9791 12L12 10.975L8.57502 7.54589ZM4.45847 7.54589C3.84784 7.54589 3.25093 7.36481 2.74321 7.02556C2.23549 6.68631 1.83976 6.20412 1.60608 5.63997C1.3724 5.07582 1.31125 4.45504 1.43038 3.85614C1.54951 3.25724 1.84356 2.70713 2.27535 2.27535C2.70713 1.84356 3.25726 1.54951 3.85616 1.43038C4.45506 1.31125 5.07582 1.3724 5.63997 1.60608C6.20412 1.83976 6.68631 2.23549 7.02556 2.74321C7.36481 3.25093 7.54589 3.84784 7.54589 4.45847C7.54643 4.86407 7.46693 5.2658 7.31197 5.64062C7.157 6.01545 6.92963 6.356 6.64283 6.6428C6.35603 6.9296 6.01545 7.157 5.64062 7.31197C5.2658 7.46693 4.86407 7.54643 4.45847 7.54589Z"
      fill={props.fill || '#4587EC'}
    />
  </svg>
);
