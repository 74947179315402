import { ApprovedIcon } from '@/assets/icons/info-icons/approved';
import { RejectedBannerIcon } from '@/assets/icons/info-icons/rejectedBanner';
import { Banner } from '@/components/basicComponents/banner';
import RadioButton from '@/components/basicComponents/radioButton';
import FilterSelect from '@/components/basicComponents/select';
import Input from '@/components/fat-basicComponents/input';
import { formatDollarInputValue, formatMaskedDollarInputValue } from '@/components/fat-basicComponents/input/utils';
import Label from '@/components/fat-basicComponents/label';
import ModalLoader from '@/components/fat-basicComponents/modal/modalLoader';
import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import { ALL_ADVISORY_FIRM_LIST_QUERY } from '@/components/fat-dealPipeline-page/fat-investorInterest/queries';
import { createLegalEntityMutation } from '@/components/fat-investors-page/fat-addInvestors/queries';
import { LIST_ENTITY_QUERY } from '@/components/fat-investors-page/queries';
import { IListEntity, IListEntityQuery, ITenant, ListCallback } from '@/components/fat-investors-page/types';
import { createAllocationMutation } from '@/components/opportunities-entity/pendingAllocation/constants';
import { SORT_ORDER } from '@/components/table/types';
import { useAuth } from '@/hooks/use-auth';
import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

interface IForm {
  advisoryFirm: string;
  investorOption: string | null;
  selectedInvestorEntity: string;
  newInvestorName: string;
  amount: string;
}

const defaultFormValues: IForm = {
  advisoryFirm: 'Select',
  investorOption: null,
  selectedInvestorEntity: 'Select',
  newInvestorName: '',
  amount: ''
};

const radioButtonOptions = ['Select an Existing Investor', 'Add a New Investor'];

const DEFAULT_ERROR_MESSAGE = `We're sorry. This process did not complete. Please try again.`;

interface BeginAllocationProps {
  isOpen: boolean;
  minimum: number;
  investmentType: string;
  onClose: () => void;
}

const BeginAllocation = ({ isOpen, minimum, investmentType, onClose }: BeginAllocationProps) => {
  const { user } = useAuth();
  const params = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const { handleSubmit, setValue, watch, control } = useForm({ defaultValues: defaultFormValues });

  const [listAdvisoryFirms, setListAdvisoryFirms] = useState<string[]>([]);
  const [listEntities, setListEntities] = useState<string[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const menuConfig: { [key: string]: boolean } = JSON.parse(process.env.REACT_APP_MENU_CONFIG);

  const [createLegalEntity, { loading: createLegalEntityLoading }] = useMutation(createLegalEntityMutation);
  const [createAllocations, { loading: createAllocationsLoading }] = useMutation(createAllocationMutation);

  useEffect(() => {
    setValue('amount', minimum.toString());
  }, [minimum]);

  const { data: advisoryFirmListData, loading: advisoryFirmListLoading } = useQuery<{ allAdvisoryFirmList: ITenant[] }>(
    ALL_ADVISORY_FIRM_LIST_QUERY,
    {
      skip: Boolean(user && user.tenant.type === 'advisoryFirm'),
      variables: {
        data: {
          sortBy: 'NAME',
          sortDirection: SORT_ORDER.ASC
        }
      },
      onCompleted: (data) => {
        setListAdvisoryFirms([...new Set(data.allAdvisoryFirmList.map((el) => el.name))]);
      }
    }
  );

  const { data: listEntitiesData, loading: listEntitiesLoading } = useQuery<IListEntityQuery>(LIST_ENTITY_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      data: {
        sortBy: 'ENTITY',
        sortDirection: SORT_ORDER.ASC,
        limit: 1000,
        offset: 0,
        includeRecommendations: true,
        filter: watch('advisoryFirm') !== 'Select' ? watch('advisoryFirm') : null
      }
    },
    onCompleted: ({ listEntities }: { listEntities: ListCallback<IListEntity> }) => {
      setListEntities(listEntities.data.map((item) => item.entityName));
    },
    onError: (error) => {
      console.error(error);
      if (listEntitiesData) {
        setListEntities(listEntitiesData.listEntities.data.map((item) => item.entityName));
      }
    }
  });

  const saveValue = async (data: IForm) => {
    setErrorMessage(null);
    const { advisoryFirm, investorOption, selectedInvestorEntity, newInvestorName, amount } = data;

    try {
      if (investorOption === radioButtonOptions[1]) {
        const { data } = await createLegalEntity({
          variables: {
            data: {
              entityName: newInvestorName,
              ...(advisoryFirm !== 'Select' && { tenantId: advisoryFirmListData?.allAdvisoryFirmList.find((el) => el.name === advisoryFirm)?.id })
            }
          }
        });

        await createAllocations({
          variables: {
            data: [
              {
                investmentId: params?.strategyId,
                legalEntityId: data.createLegalEntity.id,
                committedCapital: parseFloat(amount),
                allocationStatus: 'Outstanding'
              }
            ]
          }
        });

        if (menuConfig?.transactions) {
          navigate(`/transactions/generateSignaturePacket`);
          return;
        }

        setShowSuccessDialog(true);
        return;
      }
      await createAllocations({
        variables: {
          data: [
            {
              investmentId: params?.strategyId,
              legalEntityId: listEntitiesData.listEntities.data.find((entity) => entity.entityName === selectedInvestorEntity)?.id,
              committedCapital: parseFloat(amount),
              allocationStatus: 'Outstanding'
            }
          ]
        }
      });

      if (menuConfig?.transactions) {
        navigate(`/transactions/generateSignaturePacket`);
        return;
      }
      setShowSuccessDialog(true);
    } catch (error) {
      setErrorMessage(DEFAULT_ERROR_MESSAGE);
    }
  };

  const shouldShowAmountField = () => {
    const investorOptionValue = watch('investorOption');
    const selectedInvestorValue = watch('selectedInvestorEntity');
    const newInvestorNameValue = watch('newInvestorName');

    return (
      (investorOptionValue === radioButtonOptions[0] && selectedInvestorValue !== 'Select') ||
      (investorOptionValue === radioButtonOptions[1] && newInvestorNameValue)
    );
  };

  const shouldSelectInvestorField = () => {
    const advisoryFirmValue = watch('advisoryFirm');
    const investorOptionValue = watch('investorOption');

    return (
      (user && user.tenant.type === 'fundManager' && advisoryFirmValue !== 'Select' && investorOptionValue === radioButtonOptions[0]) ||
      (user && user.tenant.type === 'advisoryFirm' && investorOptionValue === radioButtonOptions[0])
    );
  };

  if (showSuccessDialog) {
    return (
      <ModalWrappedContent
        isOpen={isOpen}
        onClose={onClose}
        showCancelButton={false}
        confirmButton={{
          name: 'OK',
          onClick: () => onClose()
        }}
      >
        <SuccessModalBody>
          <ApprovedIcon width={60} height={60} />
          <ModalTitle>Success</ModalTitle>
          <ModalSubTitle>
            Your requested allocation can be monitored on the <span style={{ fontWeight: 700 }}>Pending Commitments</span> Page.
          </ModalSubTitle>
        </SuccessModalBody>
      </ModalWrappedContent>
    );
  }

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      confirmButton={{
        name: 'Confirm',
        onClick: handleSubmit(saveValue),
        disabled: createLegalEntityLoading || createAllocationsLoading
      }}
      showRequiredFields={!createLegalEntityLoading && !createAllocationsLoading}
      showCloseIcon={!createLegalEntityLoading && !createAllocationsLoading}
      showCancelButton={!createLegalEntityLoading && !createAllocationsLoading}
      isUseOutsideClick={!createLegalEntityLoading && !createAllocationsLoading}
    >
      <ModalBody>
        <ModalTitle>Begin Allocation</ModalTitle>
        {createLegalEntityLoading || createAllocationsLoading ? (
          <ModalLoader />
        ) : (
          <>
            <ModalSubTitle>Select an Existing Investor or Add a New Investor.</ModalSubTitle>
            {errorMessage && (
              <Banner
                icon={<RejectedBannerIcon fill={theme.context.light} />}
                title="Error"
                description={errorMessage}
                bgColor={theme.context.error}
              />
            )}
            {user && user.tenant.type === 'fundManager' && (
              <FieldWrapper>
                <Label required>Advisory Firm</Label>
                <Controller
                  name="advisoryFirm"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Advisory Firm is required'
                    },
                    pattern: {
                      value: /^(?!Select$)/,
                      message: 'Advisory Firm is required'
                    }
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <>
                      <CustomSelect
                        data={listAdvisoryFirms}
                        selected={value}
                        setSelected={(newSelectedValue) => {
                          setValue('selectedInvestorEntity', 'Select');
                          onChange(newSelectedValue);
                        }}
                        withSearch
                        width="100%"
                        loading={advisoryFirmListLoading}
                      />
                      {error?.message && <ErrorText>{error.message}</ErrorText>}
                    </>
                  )}
                />
              </FieldWrapper>
            )}
            <FieldWrapper>
              <RadioButtonsWrapper>
                <Controller
                  name="investorOption"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <>
                      {radioButtonOptions.map((option, index) => (
                        <RadioButton
                          key={option + index}
                          name="investorOption"
                          radioButtonText={option}
                          selectedOption={value}
                          handleRadioChange={(event) => onChange(event.target.value)}
                        />
                      ))}
                    </>
                  )}
                />
              </RadioButtonsWrapper>
            </FieldWrapper>
            {shouldSelectInvestorField() && (
              <FieldWrapper>
                <Label required>Select Investor</Label>
                <Controller
                  name="selectedInvestorEntity"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Investor is required'
                    },
                    pattern: {
                      value: /^(?!Select$)/,
                      message: 'Investor is required'
                    }
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <>
                      <CustomSelect
                        data={listEntities}
                        selected={value}
                        setSelected={(selectedEntityName) => {
                          onChange(selectedEntityName);
                          const entity = listEntitiesData.listEntities.data.find((entity) => entity.entityName === selectedEntityName);

                          if (entity?.ignoreBiteSize) {
                            setValue('amount', minimum.toString());
                            return;
                          }
                          if (investmentType === 'Restricted Alternative') {
                            setValue('amount', entity?.defaultBiteSize1?.toString() ?? '');
                            return;
                          }
                          if (investmentType === 'Illiquid Alternative') {
                            setValue('amount', entity?.defaultBiteSize2?.toString() ?? '');
                            return;
                          }
                        }}
                        withSearch
                        width="100%"
                        loading={listEntitiesLoading}
                      />
                      {error?.message && <ErrorText>{error.message}</ErrorText>}
                    </>
                  )}
                />
              </FieldWrapper>
            )}
            {watch('investorOption') === radioButtonOptions[1] && (
              <FieldWrapper>
                <Controller
                  name="newInvestorName"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Investor Name is required'
                    }
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Input
                      label="Investor Entity Name"
                      value={value}
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                      errorText={error?.message}
                      size="lg"
                      isRequiredField
                      autoFocus
                    />
                  )}
                />
              </FieldWrapper>
            )}
            {shouldShowAmountField() && (
              <FieldWrapper>
                <Controller
                  name="amount"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Amount is required'
                    },
                    min: {
                      value: 1,
                      message: 'Amount must be greater than 0'
                    },
                    validate: (value) => {
                      if (parseFloat(value) < minimum) {
                        return 'Cannot be less than fund minimum';
                      }
                    }
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <>
                      <Input
                        value={value ? formatMaskedDollarInputValue(value) : ''}
                        onChange={(e) => {
                          const formattedValue = formatDollarInputValue(e.target.value);
                          onChange(formattedValue);
                        }}
                        label="Amount"
                        errorText={error?.message}
                        isRequiredField
                        withDollarSign
                      />
                      <Description>Minimum: ${formatMaskedDollarInputValue(minimum.toString())}</Description>
                    </>
                  )}
                />
              </FieldWrapper>
            )}
          </>
        )}
      </ModalBody>
    </ModalWrappedContent>
  );
};

export default BeginAllocation;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const SuccessModalBody = styled(ModalBody)`
  align-items: center;
`;

const ModalTitle = styled.h2`
  text-align: center;
  font-size: 33px;
  font-weight: 400;
  line-height: 46.2px;
  color: ${({ theme }) => theme.font.strong};
`;

const ModalSubTitle = styled.p`
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  color: ${({ theme }) => theme.font.base};
`;

const FieldWrapper = styled.div`
  padding-top: 15px;
`;

const CustomSelect = styled(FilterSelect)`
  background-color: ${({ theme }) => theme.layer[1]};
  border: 1px solid ${({ theme }) => theme.border.base};
  padding: 10px 12px;
  border-radius: 5px;

  #titleSelect {
    text-transform: none;
    font-family: Blinker;
    font-size: 19px;
    line-height: 26.6px;
  }

  #dropDownSelect {
    top: 50px;
  }
`;

const ErrorText = styled.p`
  color: ${({ theme }) => theme.context.error};
  font-family: Blinker;
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 26.6px;
`;

const RadioButtonsWrapper = styled.div`
  display: flex;
`;

const Description = styled.span`
  padding-top: 10px;
  font-weight: 300;
  font-size: 13px;
  line-height: 18px;
  color: ${({ theme }) => theme.font.weak};
`;
