import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import NoResults from '@/components/table/noResults';
import RedirectTitleCell from '@/components/table/redirectTitleCell';
import { TableRowCell, TableRowWrapper, TextCell } from '@/components/table/styles';
import styled from 'styled-components';
import { CommitmentForecastValueDto } from '../../../types';

interface TableBodyProps {
  tableData: CommitmentForecastValueDto[];
  columnWidth: number;
  openOpportunityDetailsPage: (id: string) => void;
}

const TableBody = ({ tableData, columnWidth, openOpportunityDetailsPage }: TableBodyProps) => {
  if (!tableData?.length) {
    return <NoResults />;
  }

  return (
    <TableBodyWrapper>
      {tableData.map((row) => (
        <TableRowWrapper key={row.id}>
          <TableRowCell width={15}>
            <RedirectTitleCell id="actionCurioText" onClick={() => openOpportunityDetailsPage(row.investmentId)}>
              {row.name}
            </RedirectTitleCell>
          </TableRowCell>
          {row.data.map((item) => (
            <TableRowCell key={item.date} width={columnWidth}>
              <TextCell>
                <FormattingTooltip zIndex={1000}>{item.value}</FormattingTooltip>
              </TextCell>
            </TableRowCell>
          ))}
        </TableRowWrapper>
      ))}
    </TableBodyWrapper>
  );
};

const TableBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export default TableBody;
