import { EditIcon } from '@/assets/icons/edit';
import { RefreshIcon } from '@/assets/icons/info-icons/refresh';
import { RefreshButton, Results, TopWrapper, ViewBySelectSelect, ViewByText } from '@/components/table/fat-filterPage/desktop-filter';
import { TableColumns } from '@/components/table/types';
import guid from '@/utils/guid';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { Section } from '../../../edit-entity/section';
import { AssetClassForecastValueDto, CommitmentForecastValueDto, TransformedAssetClassForecastValueDto } from '../../types';
import { getUniqueAssetClassColumnsWithDates, getUniqueCommitmentColumnsWithDates, groupAssetClasses } from '../../utils';
import AssetClassTable from './assetClassTable/table';
import CommitmentTable from './commitmentTable/table';

const VIEW_BY_SELECTS = ['Asset Class', 'Existing Commitments'];

interface CashFlowForecastSectionProps {
  assetClassContributions: AssetClassForecastValueDto[];
  assetClassDistributions: AssetClassForecastValueDto[];
  existingCommitmentContributions: CommitmentForecastValueDto[];
  existingCommitmentDistributions: CommitmentForecastValueDto[];
  targetSubAssetClasses: boolean;
  commitmentPlanId: string | null;
  openOpportunityDetailsPage: (id: string) => void;
}

export const CashFlowForecastSection = ({
  assetClassContributions,
  assetClassDistributions,
  existingCommitmentContributions,
  existingCommitmentDistributions,
  targetSubAssetClasses,
  commitmentPlanId,
  openOpportunityDetailsPage
}: CashFlowForecastSectionProps) => {
  const navigate = useNavigate();
  const params = useParams();
  const theme = useTheme();

  const [viewBySelect, setViewBySelect] = useState('Asset Class');

  const [assetClassTableHeaderColumns, setAssetClassTableHeaderColumns] = useState<TableColumns[]>([]);
  const [contributionsAssetClassTableData, setContributionsAssetClassTableData] = useState<TransformedAssetClassForecastValueDto[]>([]);
  const [distributionsAssetClassTableData, setDistributionsAssetClassTableData] = useState<TransformedAssetClassForecastValueDto[]>([]);
  const [assetClassColumnWidth, setAssetClassColumnWidth] = useState(0);

  const [commitmentTableHeaderColumns, setCommitmentTableHeaderColumns] = useState<TableColumns[]>([]);
  const [contributionsCommitmentTableData, setContributionsCommitmentTableData] = useState<CommitmentForecastValueDto[]>([]);
  const [distributionsCommitmentTableData, setDistributionsCommitmentTableData] = useState<CommitmentForecastValueDto[]>([]);
  const [commitmentColumnWidth, setCommitmentColumnWidth] = useState(0);

  useEffect(() => {
    if (assetClassContributions.length) {
      const tableColumns = getUniqueAssetClassColumnsWithDates(assetClassContributions);
      const tableColumnsWithWidth = tableColumns.map((column) => ({ ...column, width: 85 / tableColumns.length }));
      setAssetClassColumnWidth(85 / tableColumns.length);
      setAssetClassTableHeaderColumns([{ title: 'Asset Class', key: null, width: 15, keySort: 'deal' }, ...tableColumnsWithWidth]);
      return;
    }
    if (assetClassDistributions.length) {
      const tableColumns = getUniqueAssetClassColumnsWithDates(assetClassDistributions);
      const tableColumnsWithWidth = tableColumns.map((column) => ({ ...column, width: 85 / tableColumns.length }));
      setAssetClassColumnWidth(85 / tableColumns.length);
      setAssetClassTableHeaderColumns([{ title: 'Asset Class', key: null, width: 15, keySort: 'deal' }, ...tableColumnsWithWidth]);
      return;
    }
  }, [assetClassContributions, assetClassDistributions]);

  useEffect(() => {
    if (!assetClassContributions.length) return;

    const transformedData = groupAssetClasses(assetClassContributions);
    const filteredTableData = transformedData.filter((data) => data.values.some((valueItem) => valueItem.value !== 0));
    setContributionsAssetClassTableData(filteredTableData);
  }, [assetClassContributions]);

  useEffect(() => {
    if (!assetClassDistributions.length) return;

    const transformedData = groupAssetClasses(assetClassDistributions);
    const filteredTableData = transformedData.filter((data) => data.values.some((valueItem) => valueItem.value !== 0));
    setDistributionsAssetClassTableData(filteredTableData);
  }, [assetClassDistributions]);

  useEffect(() => {
    if (existingCommitmentContributions.length) {
      const tableColumnsDates = getUniqueCommitmentColumnsWithDates(existingCommitmentContributions);
      const tableColumnsWithWidth = tableColumnsDates.map((column) => ({ ...column, width: 85 / tableColumnsDates.length }));
      setCommitmentColumnWidth(85 / tableColumnsDates.length);
      setCommitmentTableHeaderColumns([{ title: 'Commitment', key: null, width: 15, keySort: 'commitment' }, ...tableColumnsWithWidth]);
      return;
    }
    if (existingCommitmentDistributions.length) {
      const tableColumnsDates = getUniqueCommitmentColumnsWithDates(existingCommitmentDistributions);
      const tableColumnsWithWidth = tableColumnsDates.map((column) => ({ ...column, width: 85 / tableColumnsDates.length }));
      setCommitmentColumnWidth(85 / tableColumnsDates.length);
      setCommitmentTableHeaderColumns([{ title: 'Commitment', key: null, width: 15, keySort: 'commitment' }, ...tableColumnsWithWidth]);
      return;
    }
  }, [existingCommitmentContributions, existingCommitmentDistributions]);

  useEffect(() => {
    if (!existingCommitmentContributions) return;

    const tableColumnsDates = getUniqueCommitmentColumnsWithDates(existingCommitmentContributions);

    const transformedData: CommitmentForecastValueDto[] = existingCommitmentContributions.map((commitment) => {
      if (commitment.data.length === tableColumnsDates.length) {
        return { ...commitment, id: guid() };
      }

      const updatedData = tableColumnsDates.map((item) => {
        const findCommitmentData = commitment.data.find((dataItem) => dataItem.date === item.key);
        if (findCommitmentData) {
          return findCommitmentData;
        }
        return { date: item.key, value: null, actual: false };
      });

      return { ...commitment, id: guid(), data: updatedData };
    });

    setContributionsCommitmentTableData(transformedData);
  }, [existingCommitmentContributions]);

  useEffect(() => {
    if (!existingCommitmentDistributions) return;

    const tableColumnsDates = getUniqueCommitmentColumnsWithDates(existingCommitmentDistributions);

    const transformedData: CommitmentForecastValueDto[] = existingCommitmentDistributions.map((commitment) => {
      if (commitment.data.length === tableColumnsDates.length) {
        return { ...commitment, id: guid() };
      }

      const updatedData = tableColumnsDates.map((item) => {
        const findCommitmentData = commitment.data.find((dataItem) => dataItem.date === item.key);
        if (findCommitmentData) {
          return findCommitmentData;
        }
        return { date: item.key, value: null, actual: false };
      });

      return { ...commitment, id: guid(), data: updatedData };
    });

    setDistributionsCommitmentTableData(transformedData);
  }, [existingCommitmentDistributions]);

  return (
    <Section id="cashFlowForecast" title="Cash Flow Forecast">
      <TableWrapper>
        <TopWrapper>
          {viewBySelect === 'Existing Commitments' && (
            <>
              <Results>{existingCommitmentContributions.length + existingCommitmentDistributions.length} Results</Results>
              <RefreshButton onClick={() => {}}>
                <RefreshIcon width={15} height={15} fill={theme.action.primary} />
              </RefreshButton>
            </>
          )}
          <WrapperSelector padding={viewBySelect === 'Existing Commitments' ? '5px 10px 5px 30px' : '5px 0'}>
            <ViewByText>View By</ViewByText>
            <ViewBySelectSelect width={'auto'} selected={viewBySelect} setSelected={setViewBySelect} data={VIEW_BY_SELECTS} />
          </WrapperSelector>
        </TopWrapper>
        {viewBySelect === 'Asset Class' && (
          <>
            {contributionsAssetClassTableData?.length || distributionsAssetClassTableData?.length ? (
              <AssetClassTable
                contributionsTableData={contributionsAssetClassTableData}
                distributionsTableData={distributionsAssetClassTableData}
                tableHeaderColumns={assetClassTableHeaderColumns}
                loading={false}
                columnWidth={assetClassColumnWidth}
                targetSubAssetClasses={targetSubAssetClasses}
              />
            ) : (
              <CommitmentPlanNoResults>
                No commitments are scheduled. This forecast is limited to existing commitments only. Edit the commitment plan to add future
                investments.
                {!commitmentPlanId || (
                  <EditCommitmentPlanButtonWrapper
                    onClick={() => {
                      navigate(
                        `/investors/${params.investorId}/${params.entityId}/editCommitmentPlan?commitmentPlanId=${commitmentPlanId}&backToTitle=All Commitment Plans`
                      );
                    }}
                  >
                    <EditIcon fill={theme.font.action} />
                    <AddText>Edit Commitment Plan</AddText>
                  </EditCommitmentPlanButtonWrapper>
                )}
              </CommitmentPlanNoResults>
            )}
          </>
        )}
        {viewBySelect === 'Existing Commitments' && (
          <CommitmentTable
            contributionsTableData={contributionsCommitmentTableData}
            distributionsTableData={distributionsCommitmentTableData}
            tableHeaderColumns={commitmentTableHeaderColumns}
            loading={false}
            columnWidth={commitmentColumnWidth}
            openOpportunityDetailsPage={openOpportunityDetailsPage}
          />
        )}
      </TableWrapper>
    </Section>
  );
};

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const WrapperSelector = styled.div<{ padding: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ padding }) => padding};
  gap: 6px;
`;

const CommitmentPlanNoResults = styled.div`
  font-size: 18px;
  color: ${({ theme }) => theme.font.base};
`;

const EditCommitmentPlanButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
`;

const AddText = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.font.action};
`;
