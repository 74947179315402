import { CreateAllocationsByDateDto } from '../edit-commitment-plan-page/types';

export interface PaginatedCommitmentPlans {
  total: number;
  limit: number;
  offset: number;
  data: ICommitmentPlan[];
}

export interface ICommitmentPlan {
  id: string;
  name: string;
  startDate: Date;
  startingPortfolioValue: number;
  drawdownTarget: number;
  startingAllocation: number;
  growthRate: number;
  legalEntityId: string;
  active: boolean;
  planAllocations: CreateAllocationsByDateDto[];
  forecastSummary: ForecastSummaryDto;
  forecast: ForecastDto;
  drawdownTargets: DrawdownTargetsDto;
  assetClassDrawdownTargets: AssetClassDrawdownTargetsDto;
  isAutoPlan: boolean;
  autoPlanStatus: AUTO_PLAN_STATUS_ENUM;
  autoPlanError: string;
}

export interface AssetClassDrawdownTargetsDto {
  actuals: DrawdownTargetPercentagesWithSubAssetClassesDto[];
  targets: DrawdownTargetPercentagesWithSubAssetClassesDto[];
}

export interface DrawdownTargetPercentagesWithSubAssetClassesDto {
  name: string;
  portfolioPercentage: number;
  privateCapitalPercentage: number;
  subAssetClassDrawdownTargets: DrawdownTargetPercentagesDto[];
}

export interface DrawdownTargetsDto {
  actuals: DrawdownTargetBreakdownDto;
  targets: DrawdownTargetBreakdownDto;
}

export interface DrawdownTargetBreakdownDto {
  drawdown: DrawdownTargetPercentagesWithSubAssetClassesDto[];
  evergreen: DrawdownTargetPercentagesWithSubAssetClassesDto[];
}

export interface DrawdownTargetPercentagesWithSubAssetClassesDto {
  id: string;
  name: string;
  portfolioPercentage: number;
  privateCapitalPercentage: number;
  subAssetClassDrawdownTargets: DrawdownTargetPercentagesDto[];
}

export interface DrawdownTargetPercentagesDto {
  id: string;
  name: string;
  portfolioPercentage: number;
  privateCapitalPercentage: number;
}

export interface ForecastSummaryDto {
  assetClassForecasts: AssetClassForecastSummaryDto[];
  fiveYearDate: Date;
  tenYearDate: Date;
}

export interface AssetClassForecastSummaryDto {
  name: string;
  target: number;
  asOfForecast: number;
  fiveYearForecast: number;
  tenYearForecast: number;
  subAssetClassForecasts: SubAssetClassForecastSummaryDto[];
}

export interface SubAssetClassForecastSummaryDto {
  name: string;
  target: number;
  asOfForecast: number;
  fiveYearForecast: number;
  tenYearForecast: number;
}

export interface ForecastDto {
  netCommitments: SimpleForecastValueDto[];
  totalNAVs: SimpleForecastValueDto[];
  uncalledCapital: SimpleForecastValueDto[];
  netContributions: SimpleForecastValueDto[];
  netDistributions: SimpleForecastValueDto[];
  cumulativeContributions: SimpleForecastValueDto[];
  cumulativeDistributions: SimpleForecastValueDto[];
  existingCommitmentNAVs: CommitmentForecastValueDto[];
  existingCommitmentDistributions: CommitmentForecastValueDto[];
  existingCommitmentContributions: CommitmentForecastValueDto[];
  assetClassNAVs: AssetClassForecastValueDto[];
  assetClassContributions: AssetClassForecastValueDto[];
  assetClassDistributions: AssetClassForecastValueDto[];
  assetClassPercentages: AssetClassForecastValueDto[];
}

export interface CommitmentForecastValueDto {
  id?: string;
  investmentId: string;
  name: string;
  data: SimpleForecastValueDto[];
}

export interface SimpleForecastValueDto {
  date: string;
  value: number;
  actual: boolean;
}

export interface AssetClassForecastValueDto {
  name: string;
  date: string;
  value: number;
  actual: boolean;
  subAssetClasses: SubAssetClassForecastValueDto[];
}

export interface SubAssetClassForecastValueDto {
  name: string;
  date: string;
  value: number;
  actual: boolean;
}

export interface TransformedAssetClassForecastValueDto {
  name: string;
  values: { date: string; value: number }[];
  subAssetClasses: TransformedSubAssetClassForecastValueDto[];
}

export interface TransformedSubAssetClassForecastValueDto {
  name: string;
  values: { date: string; value: number }[];
}

export enum AUTO_PLAN_STATUS_ENUM {
  IN_PROGRESS = 'IN_PROGRESS',
  WAITING_FOR_REVIEW = 'WAITING_FOR_REVIEW',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED'
}
