import { EditTodoIcon } from '@/assets/icons/info-icons/editTodo';
import { InfoIcon } from '@/assets/icons/info-icons/info';
import { AddButtonIcon } from '@/assets/static/icons/add-button-icon';
import { DeleteItemIcon } from '@/assets/static/icons/delete-item';
import { Banner } from '@/components/basicComponents/banner';
import RadioButton from '@/components/basicComponents/radioButton';
import Button from '@/components/fat-basicComponents/button';
import Select from '@/components/fat-basicComponents/select';
import { useResponsive } from '@/hooks/use-responsive';
import _ from 'lodash';
import React, { useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import styled, { useTheme } from 'styled-components';
import { ThreeColumnWrapper } from '../styles';
import { IRenderTemplateRow } from '../types';

interface BankAccountsComponentProps {
  control: any;
  row: IRenderTemplateRow;
  mode: 'documentationTab' | 'wizard';
  ignoreRequired: boolean;
  openModalPage: (type: string, row: IRenderTemplateRow, index?: number) => void;
}

export const maskedBankAccountFields = ['moneyTransferCode', 'beneficiaryAccountNumber', 'forFurtherCredit'];

export const BankAccountsComponent = ({ control, row, mode, ignoreRequired, openModalPage }: BankAccountsComponentProps) => {
  const { isMobile } = useResponsive();
  const theme = useTheme();
  const { fields, remove } = useFieldArray({
    control,
    name: row.name
  });

  const [radioButtonValue, setRadioButtonValue] = useState('Enter Bank Account Info');

  return (
    <>
      <ThreeColumnWrapper isMobile={isMobile}>
        <RadioButtonsWrapper>
          {['Enter Bank Account Info', 'Attach A File'].map((item, index) => (
            <RadioButton
              key={item + index}
              name={row.name}
              radioButtonText={item}
              selectedOption={radioButtonValue}
              handleRadioChange={(event) => setRadioButtonValue(event.target.value)}
            />
          ))}
        </RadioButtonsWrapper>
      </ThreeColumnWrapper>
      {radioButtonValue === 'Enter Bank Account Info' && !fields?.length && (
        <ThreeColumnWrapper isMobile={isMobile}>
          {mode === 'wizard' && (
            <Select
              label="Bank Account"
              value={''}
              setValue={() => {}}
              options={[{ label: 'Select', value: '' }]}
              isRequiredField={!ignoreRequired}
              size="md"
              additionalOption={
                <AddButtonWrapper>
                  <Button
                    size="sm"
                    styleType="outline"
                    type="button"
                    onClick={() => openModalPage('enter-bank-account-info', row, row?.config?.selectType === 'multiple' ? fields?.length : 0)}
                  >
                    Add New Bank Account
                  </Button>
                </AddButtonWrapper>
              }
            />
          )}
          {mode === 'documentationTab' && (
            <AddSectionWrapper>
              <AddSectionText>No banks have been added yet.</AddSectionText>
              <Button
                onClick={() => openModalPage('enter-bank-account-info', row, row?.config?.selectType === 'multiple' ? fields?.length : 0)}
                styleType="outline"
                icon={<AddButtonIcon fill={theme.font.action} />}
                size="md"
                width="max-content"
              >
                Add
              </Button>
            </AddSectionWrapper>
          )}
        </ThreeColumnWrapper>
      )}
      {radioButtonValue === 'Attach A File' && (
        <ThreeColumnWrapper isMobile={isMobile}>
          <Banner
            title=""
            description="Upload a file with the required information during the Attachments step."
            icon={<InfoIcon width={26} height={26} fill={theme.header.base} />}
            bgColor={theme.layer.base}
            color={theme.font.base}
          />
        </ThreeColumnWrapper>
      )}
      {radioButtonValue === 'Enter Bank Account Info' && (
        <BankAccountsSectionContent>
          {fields?.map((item: any, index: number) => (
            <React.Fragment key={item.id}>
              <BankAccountsWrapper isMobile={isMobile}>
                <BankAccountsItemsWrapper>
                  {Object.entries(item).map(([key, value]) => (
                    <React.Fragment key={key}>
                      {!!value && key !== 'id' && (
                        <TwoColumnWrapper isMobile={isMobile}>
                          <BankAccountsItem>{_.startCase(key)}</BankAccountsItem>
                          <BankAccountsItem isBold>
                            {maskedBankAccountFields.includes(key) ? (value as string).replace(/./g, '*') : (value as string)}
                          </BankAccountsItem>
                        </TwoColumnWrapper>
                      )}
                    </React.Fragment>
                  ))}
                </BankAccountsItemsWrapper>
                <ButtonsWrapper id="editButton" isMobile={isMobile}>
                  <DeleteIconWrapper isMobile={isMobile} onClick={() => remove(index)}>
                    <DeleteItemIcon width={23} height={23} fill={theme.context.error} />
                  </DeleteIconWrapper>
                  <EditIconWrapper isMobile={isMobile} id="editButton" onClick={() => openModalPage('enter-bank-account-info', row, index)}>
                    <EditTodoIcon />
                  </EditIconWrapper>
                </ButtonsWrapper>
              </BankAccountsWrapper>
              {row?.config?.selectType === 'multiple' && <Divider />}
            </React.Fragment>
          ))}
          {!!(fields?.length && row?.config?.selectType === 'multiple') && (
            <Button
              onClick={() => openModalPage('enter-bank-account-info', row, fields?.length)}
              styleType="outline"
              icon={<AddButtonIcon fill={theme.font.action} />}
              size="md"
              width="max-content"
            >
              Add
            </Button>
          )}
        </BankAccountsSectionContent>
      )}
    </>
  );
};

const RadioButtonsWrapper = styled.div`
  display: flex;
`;

const AddButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const BankAccountsSectionContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  margin: 20px 0;
  background-color: ${({ theme }) => theme.border.base};
`;

const BankAccountsWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: center;
  gap: 15px;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  padding: ${({ isMobile }) => (isMobile ? '0' : '20px')};
  transition: 0.1s ease-in-out;

  @media (hover: hover) {
    &:hover #editButton {
      opacity: 1;
    }

    &:hover {
      background-color: ${({ theme }) => theme.layer[12]};
    }
  }
`;

const BankAccountsItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TwoColumnWrapper = styled.div<{ isMobile: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  width: ${({ isMobile }) => (isMobile ? '100%' : '60%')};
  padding: 2px 10px;
`;

const BankAccountsItem = styled.div<{ isBold?: boolean }>`
  font-family: Blinker;
  font-size: 16px;
  font-weight: ${({ isBold }) => (isBold ? 600 : 400)};
  line-height: 22.4px;
  color: ${({ theme }) => theme.font.base};
  padding: 4px;
`;

const ButtonsWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  width: ${({ isMobile }) => (isMobile ? '100%' : 'max-content')};
  gap: 10px;
  margin-left: ${({ isMobile }) => (isMobile ? '0' : 'auto')};
  opacity: ${({ isMobile }) => (isMobile ? '1' : '0')};
  transition: 0.1s ease-in-out;
`;

const EditIconWrapper = styled.div<{ isMobile: boolean }>`
  width: ${({ isMobile }) => (isMobile ? '100%' : 'max-content')};
  padding: 12px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.action.default};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  cursor: pointer;
  opacity: ${({ isMobile }) => (isMobile ? '1' : '0')};
`;

const DeleteIconWrapper = styled.div<{ isMobile: boolean }>`
  width: ${({ isMobile }) => (isMobile ? '100%' : '40px')};
  height: 40px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.context.error};
`;

const AddSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-left: 10px;
`;

const AddSectionText = styled.p`
  padding: 10px 0;
  font-size: 19px;
  font-weight: 400;
  line-height: 26.6px;
  color: ${({ theme }) => theme.font.weak};
`;
