import { useAuth } from '@/hooks/use-auth';
import { useResponsive } from '@/hooks/use-responsive';
import { MainWrap } from '@/styles/common';
import { SELECTORS } from '@/types/enums';
import { Assignee } from '@/types/selectors/enums';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import Header from '../fat-header';
import MobileModal from '../mobileModal';
import { sortByAlphabet } from '../recommendations-page/utils';
import { initialValuesFromTable } from '../table/constants';
import FilterPage from '../table/fat-filterPage';
import { DATA_TYPE } from '../table/filterPage';
import { AdditionalFilter, FiltersItems, FiltersStructure } from '../table/filterPage/types';
import { createNewFilter } from '../table/filterPage/utils';
import { ADVISORS_QUERY } from '../table/queries';
import { IAdvisor, ISort } from '../table/types';
import { recommendationsSort } from './constatnts';
import { EntityInfoList } from './entityInfoList';
import { StepsList } from './fat-stepLists';
import Table from './fat-table/table';
import { InvestmentInfoList } from './investmentInfoList';
import { INVESTMENTS_QUERY, LIST_ALLOCATION_OPERATIONS_STATUS_QUERY } from './queries';
import { FiltrationResults, IMobileSort, IOperationsWorkflowTransitionTask, IRowData, InvestmentsFilter } from './types';

export const PendingCommitments = () => {
  const { isMobile, isTablet, isPortrait } = useResponsive();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const q = searchParams.get('q') || '';
  const key = searchParams.get('key') || 'name';
  const asc = searchParams.get('asc') === null || searchParams.get('asc') === 'true';
  const limit = searchParams.get('limit') || initialValuesFromTable.initialAmountRowsPerPage[0];
  const offset = searchParams.get('offset') || 0;
  const position = searchParams.get('position') || 0;

  const [mobileSortItems, setMobileSortItems] = useState<IMobileSort[]>([]);
  const [mobileSort, setMobileSort] = useState('');
  const sortItem = mobileSortItems.find((item) => item.title === mobileSort);
  const mobileSortList = mobileSortItems.map((item) => item.title);
  const mobileSortValue = { key: sortItem?.key, asc: sortItem?.asc } as ISort;
  const [assigneeFilter, setAssigneeFilter] = useState<string>(Assignee.ALL_ASSIGNEES);
  const [amountResult, setAmountResult] = useState<number>(0);
  const [filtrationResults, setFiltrationResults] = useState<FiltrationResults>({ results: 0, refetch: () => null });
  const [additionalFilters, setAdditionalFilters] = useState<AdditionalFilter[]>([createNewFilter('Select A Filter Option')]);
  const [filtersItems, setFiltersItems] = useState<FiltersItems>({});
  const [statuses, setStatuses] = useState<IOperationsWorkflowTransitionTask[]>([]);

  const [isEntityModalOpen, setIsEntityModalOpen] = useState(false);
  const [entityModalData, setEntityModalData] = useState<IRowData>();

  const [isInvestmentModalOpen, setIsInvestmentModalOpen] = useState(false);
  const [investmentModalData, setInvestmentModalData] = useState<IRowData>();

  const [isLastUpdateModalOpen, setIsLastUpdateModalOpen] = useState(false);
  const [lastUpdateModalData, setLastUpdateModalData] = useState<IRowData>();

  const { user } = useAuth();

  const onClickEntity = (data: IRowData) => {
    setEntityModalData(data);
    setIsEntityModalOpen(true);
  };

  const onClickInvestment = (data: IRowData) => {
    setInvestmentModalData(data);
    setIsInvestmentModalOpen(true);
  };

  const onClickLastUpdate = (data: IRowData) => {
    setLastUpdateModalData(data);
    setIsLastUpdateModalOpen(true);
  };

  useQuery<{ Advisors: IAdvisor[] }>(ADVISORS_QUERY, {
    skip: user?.tenant.type !== 'advisoryFirm',
    onCompleted: (data) =>
      setFiltersItems({
        ...filtersItems,
        clientAdvisors: [SELECTORS.ALL_ADVISORS, ...data.Advisors.map(({ initials }) => initials)]
      })
  });
  useQuery<{ RecommendationInvestmentsList: InvestmentsFilter[] }>(INVESTMENTS_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setFiltersItems({
        ...filtersItems,
        investments: [SELECTORS.ALL_INVESTMENTS, ...sortByAlphabet([...new Set(data.RecommendationInvestmentsList.map(({ name }) => name))])]
      });
    }
  });
  useQuery<{
    listAllocationOperationsStatusWorkflowTransitionTasks: IOperationsWorkflowTransitionTask[];
  }>(LIST_ALLOCATION_OPERATIONS_STATUS_QUERY, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setFiltersItems({
        ...filtersItems,
        nextTask: [SELECTORS.ANY_NEXT_TASK, ...data.listAllocationOperationsStatusWorkflowTransitionTasks.map((item) => item.name)]
      });
      setStatuses(data.listAllocationOperationsStatusWorkflowTransitionTasks);
    }
  });

  useEffect(() => {
    if (!user) return;

    if (user.tenant.type === 'fundManager') {
      setMobileSortItems(recommendationsSort);
      setMobileSort(recommendationsSort[0].title);
      setFiltersItems({
        ...filtersItems,
        advisoryFirm: [SELECTORS.ALL_ADVISORY_FIRM]
      });
      return;
    }
    if (user.tenant.type === 'advisoryFirm') {
      const advisoryFirmMobileSort = recommendationsSort.slice(2);
      setMobileSortItems(advisoryFirmMobileSort);
      setMobileSort(advisoryFirmMobileSort[0].title);
      setFiltersItems({
        ...filtersItems,
        clientAdvisors: [SELECTORS.ALL_ADVISORS]
      });
      return;
    }
  }, [user]);

  const saveFiltrationResults = (results: number, refetch: () => void) => {
    setFiltrationResults({ results, refetch });
  };
  const filterStructure = () => {
    const unitedFilters = additionalFilters.reduce<FiltersStructure>(
      (acc, { selected, operator, typeFilter }) => {
        return acc[typeFilter as keyof FiltersStructure]
          ? {
              ...acc,
              [typeFilter]: [...acc[typeFilter as keyof FiltersStructure], { selected, operator }]
            }
          : acc;
      },
      {
        advisors: [],
        investments: [],
        nextTask: []
      }
    );
    return unitedFilters;
  };

  const openEntityDetails = (investorId: string, entityId: string) => {
    saveScrollPostion();
    navigate(`/investors/${investorId}/${entityId}?backToTitle=Pending Commitments`);
  };

  const openInvestorDetails = (id: string) => {
    saveScrollPostion();
    navigate(`/investors/${id}?backToTitle=Pending Commitments`);
  };

  const openOpportunityDetailsPage = (id: string) => {
    saveScrollPostion();
    navigate(`opportunityDetails/${id}?backToTitle=Pending Commitments`);
  };

  const onChangeSearch = (searchValue: string) => {
    setSearchParams(
      (prev) => {
        if (!searchValue) {
          prev.delete('q');
          return prev;
        }
        prev.set('q', searchValue);
        prev.delete('limit');
        prev.delete('offset');
        return prev;
      },
      { replace: true }
    );
  };

  const onChangePaginationData = (limit: number, offset: number) => {
    setSearchParams(
      (prev) => {
        prev.set('limit', limit.toString());
        prev.set('offset', offset.toString());
        return prev;
      },
      { replace: true }
    );
  };

  const onChangeSort = (value: ISort) => {
    setSearchParams(
      (prev) => {
        prev.set('key', value.key);
        prev.set('asc', value.asc.toString());
        return prev;
      },
      { replace: true }
    );
  };

  const saveScrollPostion = () => {
    setSearchParams(
      (prev) => {
        prev.set('position', window.scrollY.toString());
        return prev;
      },
      { replace: true }
    );
  };

  const makeScroll = () => {
    if (!position) return;
    setTimeout(() => window.scrollTo({ top: Number(position), behavior: 'smooth' }), 0);
    setSearchParams(
      (prev) => {
        prev.delete('position');
        return prev;
      },
      { replace: true }
    );
  };

  return (
    <MainWrap>
      {isEntityModalOpen && entityModalData ? (
        <MobileModal
          title={entityModalData.legalEntity.entityName}
          onClose={() => setIsEntityModalOpen(false)}
          onClick={() => openEntityDetails(entityModalData.legalEntity.family.id, entityModalData.legalEntity.id)}
        >
          <EntityInfoList allocation={entityModalData} />
        </MobileModal>
      ) : (
        <></>
      )}

      {isInvestmentModalOpen && investmentModalData ? (
        <MobileModal
          title={investmentModalData.investment.name}
          onClose={() => setIsInvestmentModalOpen(false)}
          onClick={() => openOpportunityDetailsPage(investmentModalData.investment.id)}
        >
          <InvestmentInfoList row={investmentModalData} />
        </MobileModal>
      ) : (
        <></>
      )}

      {isLastUpdateModalOpen && lastUpdateModalData ? (
        <MobileModal title={'Updates'} onClose={() => setIsLastUpdateModalOpen(false)} onClick={() => null}>
          <StepsList row={lastUpdateModalData} isMobile={isMobile} />
        </MobileModal>
      ) : (
        <></>
      )}
      <MainWrap>
        <Header />
      </MainWrap>
      <PaddingWrap>
        <CustomFilterPage
          isMobile={isMobile}
          isTablet={isTablet}
          // withExportData
          search={{
            value: q,
            onChange: onChangeSearch,
            placeholder: 'Search'
          }}
          // primaryFilter={{
          //   value: assigneeFilter,
          //   onChange: setAssigneeFilter,
          //   selects: Object.values(Assignee),
          // }}
          isResult
          // additionalFilters={{
          //   filtersItems,
          //   filters: additionalFilters,
          //   applyFilters: setAdditionalFilters,
          //   amountResults: amountResult,
          //   showChosenFilters: !!investments || !!router.query.filterBy,
          //   onRefetch: filtrationResults.refetch,
          // }}
          queryParams={{
            userId: user?.id ?? '',
            selected: Assignee.ALL_ASSIGNEES,
            offset: Number(offset) ?? 0,
            limit: Number(limit) ?? 100,
            includeProposedInvestments: true
          }}
          refetch={filtrationResults.refetch}
          resultsValue={amountResult}
          dataType={DATA_TYPE.RECOMMENDATIONS}
          mobileSort={{
            value: mobileSort,
            selects: mobileSortList,
            onChange: setMobileSort
          }}
        />
        <Table
          onClickLastUpdate={onClickLastUpdate}
          onClickInvestment={onClickInvestment}
          onClickEntity={onClickEntity}
          search={q}
          assigneeFilter={assigneeFilter}
          saveFiltrationResults={saveFiltrationResults}
          additionalFiltersData={filterStructure()}
          openEntityDetails={openEntityDetails}
          openInvestorDetails={openInvestorDetails}
          openOpportunityDetailsPage={openOpportunityDetailsPage}
          sort={isMobile ? mobileSortValue : { key, asc }}
          setSort={onChangeSort}
          paginationData={{ limit: Number(limit), offset: Number(offset) }}
          onChangePaginationData={onChangePaginationData}
          setAmountResult={setAmountResult}
          statuses={statuses}
          makeScroll={makeScroll}
        />
      </PaddingWrap>
    </MainWrap>
  );
};

const PaddingWrap = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  @media (min-width: 600px) {
    padding-left: 50px;
    padding-right: 50px;
  }
`;
const CustomFilterPage = styled(FilterPage)<{ isMobile: boolean; isTablet: boolean }>`
  position: sticky;
  top: ${({ isMobile, isTablet }) => (isMobile || isTablet ? '50px' : '0')};
  z-index: 9;
  margin-top: 0;
  margin-left: -1px;
  width: calc(100% + 2px);
  padding: ${({ isMobile }) => (isMobile ? '20px 0 0 0' : '20px 0 10px 0')};
`;
