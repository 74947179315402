import { gql } from '@apollo/client';

export const RENDERING_TEMPLATES_QUERY = gql`
  query renderingTemplates($input: RenderingTemplateQueryInput) {
    renderingTemplates(input: $input) {
      id
      name
      template
      investmentId
      category
      entityType
      taxExempt
      initial
      eligibility
    }
  }
`;
