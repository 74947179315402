import { EditIcon } from '@/assets/icons/edit';
import styled, { useTheme } from 'styled-components';

interface PageTitleProps {
  title: string;
  onEditClick?: () => void;
  withBorder?: boolean;
}

export const PageTitle = ({ title, onEditClick, withBorder = true }: PageTitleProps) => {
  const theme = useTheme();

  return (
    <PageTitleWrap withBorder={withBorder}>
      {title}
      {onEditClick && (
        <IconWrap onClick={onEditClick}>
          <EditIcon fill={theme.action.primary} />
        </IconWrap>
      )}
    </PageTitleWrap>
  );
};

export const PageTitleWrap = styled.div<{ withBorder: boolean }>`
  display: flex;
  align-items: center;
  gap: 20px;
  color: ${({ theme }) => theme.font.strong};
  font-family: Blinker;
  font-size: 33px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  background: ${({ theme }) => theme.layer[1]};
  border-bottom: ${({ theme, withBorder }) => (withBorder ? `1px solid ${theme.border.base}` : 'none')};

  padding: 10px 16px;
  @media (min-width: 600px) {
    padding: 10px 50px;
  }
`;

const IconWrap = styled.div`
  display: flex;
  cursor: pointer;
`;
