import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import styled from 'styled-components';
import { IAllocationRow } from '../types';

interface SubmitToProps {
  onClose: () => void;
  isOpen: boolean;
  changedAllocations: IAllocationRow[];
  updateAllocationRequest: () => void;
}

const SubmitTo = ({ onClose, isOpen, changedAllocations, updateAllocationRequest }: SubmitToProps) => {
  const chosenRows = changedAllocations.filter((row) => row.checked);
  const recomendationAmount = chosenRows.filter((row) => row.decision).length;
  const terminatedAmount = chosenRows.length - recomendationAmount;

  const saveValue = () => {
    updateAllocationRequest();
    onClose();
  };

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      title="Confirmation"
      confirmButton={{
        name: 'Confirm',
        onClick: saveValue
      }}
    >
      <ModalBody>
        <Text fontWeight="400">Please review.</Text>
        <Text fontWeight="400">
          <CountWrap>
            <Count>{`${recomendationAmount}`}</Count>
          </CountWrap>
          allocation(s) will be initiated.
        </Text>
        <Text fontWeight="400">
          <CountWrap>
            <Count>{`${terminatedAmount} `}</Count>
          </CountWrap>
          allocation(s) will be marked as Internal Review Rejected.
        </Text>
      </ModalBody>
    </ModalWrappedContent>
  );
};

export default SubmitTo;

const ModalBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
`;

export const Text = styled.span<{ fontWeight: string }>`
  color: ${({ theme }) => theme.font.base};
  text-align: center;
  font-size: 19px;
  font-weight: ${({ fontWeight }) => fontWeight};
  line-height: 26.6px;
`;

export const CountWrap = styled.div`
  background-color: ${({ theme }) => theme.footer.base};
  display: inline-block;
  border-radius: 12px;
  color: ${({ theme }) => theme.context.light};
  font-size: 13px;
  margin-right: 6px;
  padding: 1px 10px;
`;

export const Count = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 13px;
  font-weight: 700;
  line-height: 18.2px;
`;
