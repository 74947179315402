import Input from '@/components/fat-basicComponents/input';
import Select from '@/components/fat-basicComponents/select';
import { useResponsive } from '@/hooks/use-responsive';
import { COUNTRIES, UNITED_STATES_STATES } from '@trace-8/trace8-schema';
import { Controller, useWatch } from 'react-hook-form';
import { TwoColumnWrapper } from '../styles';
import { IRenderTemplateRow } from '../types';

interface AddressComponentProps {
  control: any;
  row: IRenderTemplateRow;
  ignoreRequired: boolean;
}

export const AddressComponent = ({ control, row, ignoreRequired }: AddressComponentProps) => {
  const { isMobile } = useResponsive();
  const { config, validation, dependsOn } = row;
  const dependentValue = dependsOn ? useWatch({ control, name: dependsOn.name }) : null;

  if (dependsOn && dependentValue !== dependsOn.value) return <></>;

  return (
    <>
      <TwoColumnWrapper isMobile={isMobile}>
        <Controller
          name={`${row.name}.${config?.mapping?.['streetAddress'] ?? 'streetAddress'}`}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              label={config?.labels?.['streetAddress'] ?? 'Entity Street Address'}
              value={value || ''}
              onChange={(e) => {
                onChange(e.target.value);
              }}
              errorText={error?.message}
              isRequiredField={!!validation?.required && !ignoreRequired}
              size="md"
            />
          )}
        />
      </TwoColumnWrapper>
      <TwoColumnWrapper isMobile={isMobile}>
        <TwoColumnWrapper isMobile={isMobile}>
          <Controller
            name={`${row.name}.${config?.mapping?.['city'] ?? 'city'}`}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                label={config?.labels?.['city'] ?? 'City'}
                value={value || ''}
                onChange={(e) => {
                  onChange(e.target.value);
                }}
                errorText={error?.message}
                isRequiredField={!!validation?.required && !ignoreRequired}
                size="md"
              />
            )}
          />
          <Controller
            name={`${row.name}.${config?.mapping?.['state'] ?? 'state'}`}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Select
                label={config?.labels?.['state'] ?? 'State'}
                value={value || ''}
                setValue={onChange}
                options={[{ value: '', label: 'Select' }, ...UNITED_STATES_STATES.map((state) => ({ value: state, label: state }))]}
                errorText={error?.message}
                isRequiredField={!!validation?.required && !ignoreRequired}
                size="md"
                withSearch
              />
            )}
          />
        </TwoColumnWrapper>
        <TwoColumnWrapper isMobile={isMobile}>
          <Controller
            name={`${row.name}.${config?.mapping?.['zipCode'] ?? 'zipCode'}`}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                label={config?.labels?.['zipCode'] ?? 'Zip'}
                value={value || ''}
                onChange={(e) => {
                  onChange(e.target.value);
                }}
                errorText={error?.message}
                isRequiredField={!!validation?.required && !ignoreRequired}
                size="md"
              />
            )}
          />
          <div>
            <Controller
              name={`${row.name}.${config?.mapping?.['country'] ?? 'country'}`}
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Select
                  label={config?.labels?.['country'] ?? 'Country'}
                  value={value || ''}
                  setValue={onChange}
                  options={[{ value: '', label: 'Select' }, ...COUNTRIES.map((country) => ({ value: country, label: country }))]}
                  errorText={error?.message}
                  isRequiredField={!!validation?.required && !ignoreRequired}
                  size="md"
                  withSearch
                />
              )}
            />
          </div>
        </TwoColumnWrapper>
      </TwoColumnWrapper>
    </>
  );
};
