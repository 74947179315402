import client from '@/apollo-client';
import { RejectedBannerIcon } from '@/assets/icons/info-icons/rejectedBanner';
import { Banner } from '@/components/basicComponents/banner';
import Textarea from '@/components/basicComponents/textarea';
import { DropFileArea } from '@/components/dueDiligence-page/dueDiligenceRecord/dropFileArea';
import FileNotSupported from '@/components/dueDiligence-page/modals/fileNotSupported';
import FileUpload from '@/components/fat-basicComponents/fileUpload';
import { FormFooter } from '@/components/fat-basicComponents/formFooter';
import Input from '@/components/fat-basicComponents/input';
import Label from '@/components/fat-basicComponents/label';
import Header from '@/components/fat-header';
import { GoBackButton } from '@/components/fat-header/goBackButton';
import { PageTitle } from '@/components/fat-header/pageTitle';
import { createSettingAttachment, deleteSettingAttachment, getSettingAttachmentUploadUrl } from '@/components/settings-page/queries';
import { useResponsive } from '@/hooks/use-responsive';
import { MainWrap, PaddingWrap } from '@/styles/common';
import { ApolloError, useMutation } from '@apollo/client';
import { ChangeEvent, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { FirmSettingsContainer } from '.';
import { fileExtensions } from './constants';
import { IFirmSettings } from './types';

interface EditFirmFieldsProps {
  handleClose: () => void;
  createFirmSetting: (type: string, key: string, value: string) => void;
  updateFirmSetting: (id: string, value: string) => void;
  currentRow: IFirmSettings | null;
  logoImg: string;
}

export const EditFirmFields = ({ handleClose, createFirmSetting, updateFirmSetting, currentRow, logoImg }: EditFirmFieldsProps) => {
  const theme = useTheme();
  const { isMobile } = useResponsive();
  const [searchParams, setSearchParams] = useSearchParams();

  const rowId = searchParams.get('id') || '';
  const rowKey = searchParams.get('edit') || '';
  const fieldName = searchParams.get('fieldName') || '';

  const [value, setValue] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const [fileUrl, setFileUrl] = useState('');
  const [drag, setDrag] = useState(false);
  const [isAttachmentDeleted, setIsAttachmentDeleted] = useState(false);
  const [modalWindow, setModalWindow] = useState({ isOpen: false, type: 'not-supported' });
  const [addAttachmentError, setAddAttachmentError] = useState<ApolloError | null>(null);
  const [deletedFile, setDeletedFile] = useState('');

  const [createAttachment] = useMutation(createSettingAttachment);
  const [deleteAttachment] = useMutation(deleteSettingAttachment);

  useEffect(() => {
    if (currentRow?.value) {
      if (currentRow.key === 'address') {
        const getAddressValue = currentRow.value.split('\\n').join('\n');
        setValue(getAddressValue);
        return;
      }
      if (currentRow.key === 'logo') {
        setFileUrl(logoImg);
        return;
      }
      setValue(currentRow.value);
    }
    return () => {
      setValue('');
    };
  }, [currentRow]);

  const onDropHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const uploadFiles = e.dataTransfer.files;
    setDrag(false);

    if (fileExtensions.includes(uploadFiles[0]?.name.split('.').pop()?.toUpperCase() ?? '')) {
      setFile(uploadFiles[0]);
      setFileUrl(URL.createObjectURL(uploadFiles[0]));
      return;
    }
    setModalWindow({ isOpen: true, type: 'not-supported' });
  };
  const handleUploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const uploadFiles = e.target.files;

    if (uploadFiles && fileExtensions.includes(uploadFiles[0]?.name.split('.').pop()?.toUpperCase() ?? '')) {
      setFile(uploadFiles[0]);
      setFileUrl(URL.createObjectURL(uploadFiles[0]));
      return;
    }
    setModalWindow({ isOpen: true, type: 'not-supported' });
  };

  const deletePreviewItem = () => {
    if (!file && currentRow?.value) {
      setDeletedFile(JSON.parse(currentRow.value).id);
    }
    setFile(null);
    setFileUrl('');
  };

  const logoUpload = async (file: File) => {
    try {
      const { data: firmSettingsLogoUrl } = await client.query({
        query: getSettingAttachmentUploadUrl,
        variables: {
          data: {
            fieldType: 'firmSetting',
            key: 'logo',
            contentType: file.type,
            filename: file.name.replace(/\s/g, '')
          }
        }
      });

      await fetch(firmSettingsLogoUrl.getSettingAttachmentUploadUrl.url, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type
        }
      });

      createAttachment({
        variables: {
          assetKey: firmSettingsLogoUrl.getSettingAttachmentUploadUrl.assetKey
        },
        onCompleted: (data) => {
          if (currentRow?.value && !isAttachmentDeleted) {
            deleteAttachment({
              variables: { id: JSON.parse(currentRow.value).id }
            });
          }
          if (rowId !== 'null') {
            updateFirmSetting(
              rowId,
              JSON.stringify({
                value: data.createSettingAttachment.url,
                assetKey: firmSettingsLogoUrl.getSettingAttachmentUploadUrl.assetKey,
                id: data.createSettingAttachment.id
              })
            );
            return;
          }
          createFirmSetting(
            'firmSetting',
            'logo',
            JSON.stringify({
              value: data.createSettingAttachment.url,
              assetKey: firmSettingsLogoUrl.getSettingAttachmentUploadUrl.assetKey,
              id: data.createSettingAttachment.id
            })
          );
        },
        onError: (error) => {
          handleAttachmentError(error);
        }
      });
    } catch (error) {
      console.log(error, 'error');
      handleAttachmentError(error as ApolloError);
    }
  };

  const handleAttachmentError = (error: ApolloError) => {
    console.log(error, 'error');
    if (error.message.includes('duplicate key value violates unique constraint') || error.message.includes('Asset key already exists')) {
      setAddAttachmentError(error);
      setFile(null);
      setFileUrl(currentRow?.value ? JSON.parse(currentRow.value).value : '');
    }
  };

  const saveValue = (key: string, value: string) => {
    if (deletedFile) {
      deleteAttachment({
        variables: { id: deletedFile },
        onCompleted: () => {
          setIsAttachmentDeleted(true);
          updateFirmSetting(rowId, '');
        }
      });
    }

    if (file) {
      logoUpload(file);
      return;
    }

    if (currentRow?.key === 'logo' && !isAttachmentDeleted) {
      handleClose();
      return;
    }
    if (rowId !== 'null') {
      updateFirmSetting(rowId, value);
      return;
    }
    createFirmSetting('firmSetting', key, value);
  };

  const handleChangePhone = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const regex = /^[0-9+\-()\s]*$/;
    if (regex.test(value)) {
      setValue(value);
    }
  };

  const openLogoImage = (url: string) => {
    window.open(url, '_blank');
  };

  const fieldValue = (type: string) => {
    switch (type) {
      case 'firmName':
        return <Input type={'text'} value={value} onChange={(event) => setValue(event.target.value)} />;
      case 'email':
        return <Input type={'text'} value={value} onChange={(event) => setValue(event.target.value)} />;
      case 'website':
        return <Input type={'text'} value={value} onChange={(event) => setValue(event.target.value)} />;
      case 'address':
        return <Textarea value={value} setValue={setValue} maxLength={250} />;
      case 'logo':
        return (
          <>
            {fileUrl && (
              <FileUpload fileName="Logo" imageSrc={fileUrl} type="image" openFile={() => openLogoImage(fileUrl)} deleteFile={deletePreviewItem} />
            )}
            <DropFileArea
              id="firmLogo"
              fileExtensions={fileExtensions}
              handleUploadFile={handleUploadFile}
              onDropHandler={onDropHandler}
              drag={drag}
              setDrag={setDrag}
            />
          </>
        );
      case 'phone':
        return <Input type="text" value={value} onChange={handleChangePhone} />;
    }
  };

  return (
    <>
      {modalWindow.isOpen && modalWindow.type === 'not-supported' && (
        <FileNotSupported isOpen={modalWindow.isOpen} onClose={() => setModalWindow({ ...modalWindow, isOpen: false })} />
      )}
      <MainWrap>
        <Header modalControl={<GoBackButton handleClose={handleClose} backToTitle="Firm Settings" />} />
        <PageTitle title={fieldName} />
      </MainWrap>
      <MainWrap>
        {addAttachmentError && (
          <Banner
            icon={<RejectedBannerIcon fill={theme.context.light} />}
            title="File name already exists"
            description="In order to upload your file please give it a unique name."
            bgColor={theme.context.error}
          />
        )}
        <PaddingWrap>
          <FirmSettingsContainer isMobile={isMobile}>
            <Label>{fieldName}</Label>
            {fieldValue(rowKey)}
            <FormFooter onCancel={handleClose} onSave={() => saveValue(rowKey, value)} disableSaveButton={false} showRequiredFieldsWarning={false} />
          </FirmSettingsContainer>
        </PaddingWrap>
      </MainWrap>
    </>
  );
};
