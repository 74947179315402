import { MinusScoreIcon } from '@/assets/icons/minusScore';
import { getUTCDate } from '@/components/allocations-page/utils';
import BasicTooltip from '@/components/basicComponents/tooltip';
import NoResults from '@/components/table/noResults';
import RedirectTitleCell from '@/components/table/redirectTitleCell';
import guid from '@/utils/guid';
import styled, { useTheme } from 'styled-components';
import { TableRowCell, TableRowWrapper, TextCell } from '../../table/styles';
import { otherRecommendedTableColumns } from '../constants';
import { IInvestorInterestList, TypeWindow } from '../types';

interface OtherRecommendedTableBodyProps {
  otherRecommendedData: IInvestorInterestList[];
  openPage: (id: string, type: TypeWindow) => void;
}

const TableBody = ({ otherRecommendedData, openPage }: OtherRecommendedTableBodyProps) => {
  const theme = useTheme();

  if (!otherRecommendedData?.length) {
    return <NoResults />;
  }

  return (
    <>
      {otherRecommendedData.map((row) => (
        <TableRowWrapper key={row.investment.id}>
          <TableRowCell width={otherRecommendedTableColumns[0].width}>
            <RedirectTitleCell id="actionCurioText" onClick={() => openPage(row.investment.id, 'opportunityDetails')}>
              {row.investment.name}
            </RedirectTitleCell>
          </TableRowCell>
          <TableRowCell width={otherRecommendedTableColumns[1].width}>
            <TextCell>
              {row.investment.subAssetClass
                ? `${row.investment.assetClass.name} - ${row.investment.subAssetClass.name}`
                : row.investment.assetClass.name}
            </TextCell>
          </TableRowCell>
          <TableRowCell width={otherRecommendedTableColumns[2].width}>
            <TextCell>
              {row.investment?.subAssetClasses?.length > 1 ? (
                <BasicTooltip tooltipContent={row.investment.subAssetClasses.toString().replace(/,/g, ', ')}>
                  Multiple ({row.investment.subAssetClasses.length})
                </BasicTooltip>
              ) : (
                row.investment?.subAssetClasses && row.investment?.subAssetClasses[0]
              )}
            </TextCell>
          </TableRowCell>
          <TableRowCell width={otherRecommendedTableColumns[3].width}>
            <TextCell>{row.investment.type}</TextCell>
          </TableRowCell>
          <TableRowCell width={otherRecommendedTableColumns[4].width}>
            <TextCell>{row.investment.investorDocsDue && getUTCDate(new Date(row.investment.investorDocsDue))}</TextCell>
          </TableRowCell>
          <TableRowCell width={otherRecommendedTableColumns[5].width}>
            {row.investment.securityStatus === 'Terminated' && (
              <TooltipWrapper>
                <BasicTooltip tooltipContent="Passed On" zIndex={1000}>
                  <MinusScoreIcon width={29} height={29} fill={theme.font.disabled} />
                </BasicTooltip>
              </TooltipWrapper>
            )}
          </TableRowCell>
        </TableRowWrapper>
      ))}
    </>
  );
};

export default TableBody;

const TooltipWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  top: 3px;
`;
