import client from '@/apollo-client';
import { EditTodoIcon } from '@/assets/icons/info-icons/editTodo';
import ShowText from '@/components/dueDiligence-page/dueDiligenceRecord/showText';
import { TierDropdown } from '@/components/dueDiligence-page/dueDiligenceRecord/tierDropdown';
import { EmptyTodoRowCell, FieldValueWrapper, InfoRowCell, RowWrapper } from '@/components/dueDiligence-page/dueDiligenceRecord/tierSection';
import Header from '@/components/fat-header';
import { GoBackButton } from '@/components/fat-header/goBackButton';
import { PageTitle } from '@/components/fat-header/pageTitle';
import { LoaderOnWholeScreen } from '@/components/loaders/loader-on-whole-screen';
import { CREATE_SETTING, GET_SETTINGS, getAssetSignedUrl, UPDATE_SETTING } from '@/components/settings-page/queries';
import { useResponsive } from '@/hooks/use-responsive';
import { MainWrap, PaddingWrap } from '@/styles/common';
import { useMutation, useQuery } from '@apollo/client';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { basicInformationItems, templateStylingItems } from './constants';
import { EditDisclosureText } from './editDisclosureText';
import { EditFirmFields } from './editFirmFields';
import { EditFontStyle } from './editFontStyle';
import { EditWatermark } from './editWatermark';
import { IFirmSettings, ISetting } from './types';
import { isURL } from './utils';

interface FirmSettingsProps {
  backToTitle: string;
  handleClose: () => void;
}

interface ValuesMap {
  [key: string]: {
    value: any;
    id: string | null;
  };
}

const getTemplateStylingValue = (value: string, fieldKey: string, watermarkImg: string) => {
  switch (fieldKey) {
    case 'headerFontStyle':
      const parsedHeaderFont = JSON.parse(value);
      return (
        <FieldValueWrapper
          style={{
            fontFamily: parsedHeaderFont.fontFamily,
            color: `#${parsedHeaderFont.fontColor}`,
            fontWeight: parsedHeaderFont.fontWeight
          }}
        >
          {parsedHeaderFont.fontFamily}, {parsedHeaderFont.fontColor}, {parsedHeaderFont.fontWeight}
        </FieldValueWrapper>
      );
    case 'bodyFontStyle':
      const parsedBodyFont = JSON.parse(value);
      return (
        <FieldValueWrapper
          style={{
            fontFamily: parsedBodyFont.fontFamily,
            color: `#${parsedBodyFont.fontColor}`,
            fontWeight: parsedBodyFont.fontWeight
          }}
        >
          {parsedBodyFont.fontFamily}, {parsedBodyFont.fontColor}, {parsedBodyFont.fontWeight}
        </FieldValueWrapper>
      );
    case 'disclosureText':
      return <ShowText>{value}</ShowText>;
    case 'watermark':
      return isURL(JSON.parse(value).value) ? (
        <ImageWrapper>
          <ImageItem src={watermarkImg} alt="logo" />
        </ImageWrapper>
      ) : (
        <FieldValueWrapper>{JSON.parse(value).value}</FieldValueWrapper>
      );
    default:
      return <></>;
  }
};

const getBasicInformationValue = (value: string, fieldKey: string, logoImg: string) => {
  switch (fieldKey) {
    case 'address':
      return (
        <ShowText>
          {value.split('\\n').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </ShowText>
      );
    case 'logo':
      return (
        <ImageWrapper>
          <ImageItem src={logoImg} alt="logo" />
        </ImageWrapper>
      );

    default:
      return value;
  }
};

export const FirmSettings = ({ backToTitle, handleClose }: FirmSettingsProps) => {
  const navigate = useNavigate();
  const { isMobile } = useResponsive();
  const [searchParams, setSearchParams] = useSearchParams();

  const action = searchParams.get('action') || '';
  const rowId = searchParams.get('id') || '';
  const windowType = searchParams.get('windowType') || 'default';

  const [firmSettingsData, setFirmSettingsData] = useState<IFirmSettings[]>([]);
  const [templateStyling, setTemplateStyling] = useState<IFirmSettings[]>([]);
  const [currentRow, setCurrentRow] = useState<IFirmSettings | null>(null);
  const [scrollTo, setScrollTo] = useState(0);
  const [logoImg, setLogoImg] = useState('');
  const [watermarkImg, setWatermarkImg] = useState('');

  const mappedData = (keyArray: any, valueArray: ISetting[], setMappedArray: Dispatch<SetStateAction<IFirmSettings[] | undefined>>) => {
    const valuesMap: ValuesMap = {};
    for (const item of valueArray) {
      valuesMap[item.key] = {
        value: JSON.parse(item.value),
        id: item.id
      };
    }

    const mappedItems = keyArray.map((item: any) => {
      const mappedValue = valuesMap[item.key] || { value: '', id: null };
      return {
        fieldName: item.fieldName,
        value: mappedValue.value,
        key: item.key,
        id: mappedValue.id
      };
    });

    setMappedArray(mappedItems || []);
  };

  const { refetch: firmSettingsRefetch, loading: firmSettingsLoading } = useQuery(GET_SETTINGS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      type: 'firmSetting'
    },
    onCompleted: (data) => {
      mappedData(basicInformationItems, data.Settings, setFirmSettingsData);
    }
  });

  const { refetch: templateStylingRefetch, loading: templateStylingLoading } = useQuery(GET_SETTINGS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      type: 'templateStyling'
    },
    onCompleted: (data) => {
      mappedData(templateStylingItems, data.Settings, setTemplateStyling);
    }
  });

  const [createSetting] = useMutation(CREATE_SETTING);
  const [updateSetting] = useMutation(UPDATE_SETTING);

  const updateImageUrl = async (assetKey: string, setImage: Dispatch<SetStateAction<string>>) => {
    const { data } = await client.query({
      query: getAssetSignedUrl,
      fetchPolicy: 'network-only',
      variables: {
        assetKey: assetKey
      }
    });
    setImage(data.getAssetSignedUrl);
  };

  useEffect(() => {
    if (firmSettingsLoading && !firmSettingsData.length) return;

    firmSettingsData?.forEach((item) => {
      if (item.key === 'logo') {
        try {
          const parsedValue = item?.value ? JSON.parse(item.value) : null;
          if (!parsedValue) return;
          if (parsedValue.value) {
            updateImageUrl(parsedValue.assetKey, setLogoImg);
          }
        } catch (error) {
          console.error(error);
        }
      }
    });
  }, [firmSettingsData]);

  useEffect(() => {
    if (templateStylingLoading && !templateStyling.length) return;

    templateStyling?.forEach((item) => {
      if (item.key === 'watermark') {
        try {
          const parsedValue = item?.value ? JSON.parse(item.value) : null;
          if (!parsedValue) return;
          if (parsedValue.value && isURL(parsedValue.value)) {
            updateImageUrl(parsedValue.assetKey, setWatermarkImg);
          }
        } catch (error) {
          console.error(error);
        }
      }
    });
  }, [templateStyling]);

  useEffect(() => {
    if (!rowId || rowId === 'null') return;

    if (windowType === 'edit-firm-settings') {
      const row = firmSettingsData?.find((item) => item.id === rowId);
      setCurrentRow(row || null);
      return;
    }
    const row = templateStyling?.find((item) => item.id === rowId);
    setCurrentRow(row || null);
  }, [firmSettingsData, templateStyling]);

  useEffect(() => {
    if (windowType === 'default') {
      window.scrollTo(0, scrollTo);
    }
  }, [windowType, firmSettingsLoading, templateStylingLoading]);

  const createFirmSetting = (type: string, key: string, value: string) => {
    createSetting({
      variables: {
        data: {
          key,
          type,
          value: JSON.stringify(value),
          description: ''
        }
      },
      onCompleted: () => {
        firmSettingsRefetch();
        templateStylingRefetch();
        handleCloseEdit();
      }
    });
  };

  const updateFirmSetting = (id: string, value: string) => {
    updateSetting({
      variables: {
        data: {
          id,
          value: JSON.stringify(value),
          description: ''
        }
      },
      onCompleted: () => {
        firmSettingsRefetch();
        templateStylingRefetch();
        handleCloseEdit();
      }
    });
  };

  const handleRedirect = (row: IFirmSettings, windowType: string) => {
    navigate(`/settings?action=${action}&windowType=${windowType}&edit=${row.key}&fieldName=${row.fieldName}&id=${row.id}`);

    setScrollTo(window.scrollY);
    setCurrentRow(row);
  };

  const handleCloseEdit = () => {
    const newPath = `/settings?action=${action}`;
    navigate(newPath);
    setCurrentRow(null);
  };

  if (firmSettingsLoading || templateStylingLoading) {
    return <LoaderOnWholeScreen size={60} />;
  }

  return (
    <>
      {windowType === 'edit-firm-settings' && (
        <EditFirmFields
          handleClose={handleCloseEdit}
          createFirmSetting={createFirmSetting}
          updateFirmSetting={updateFirmSetting}
          currentRow={currentRow}
          logoImg={logoImg}
        />
      )}
      {(windowType === 'edit-headerFontStyle' || windowType === 'edit-bodyFontStyle') && (
        <EditFontStyle
          handleClose={handleCloseEdit}
          createFirmSetting={createFirmSetting}
          updateFirmSetting={updateFirmSetting}
          currentRow={currentRow}
        />
      )}
      {windowType === 'edit-watermark' && (
        <EditWatermark
          handleClose={handleCloseEdit}
          createFirmSetting={createFirmSetting}
          updateFirmSetting={updateFirmSetting}
          currentRow={currentRow}
          watermarkImg={watermarkImg}
        />
      )}
      {windowType === 'edit-disclosureText' && (
        <EditDisclosureText
          handleClose={handleCloseEdit}
          createFirmSetting={createFirmSetting}
          updateFirmSetting={updateFirmSetting}
          currentRow={currentRow}
        />
      )}
      {windowType === 'default' && (
        <>
          <MainWrap>
            <Header modalControl={<GoBackButton handleClose={handleClose} backToTitle={backToTitle} />} />
            <PageTitle title="Firm Settings" />
          </MainWrap>
          <MainWrap>
            <PaddingWrap>
              <SettingsContainer>
                <TierDropdown title="Basic Information">
                  {firmSettingsData?.map((item, index) => (
                    <RowWrapper key={item.fieldName + index} disabled={false} isMobile={isMobile}>
                      <InfoRowCell>{item.fieldName}</InfoRowCell>
                      {item.value ? (
                        <FieldValueWrapper>{getBasicInformationValue(item.value, item.key, logoImg)}</FieldValueWrapper>
                      ) : (
                        <EmptyTodoRowCell>To Do</EmptyTodoRowCell>
                      )}
                      <EditTodoButton isMobile={isMobile} id="editTodoButton" onClick={() => handleRedirect(item, 'edit-firm-settings')}>
                        <EditTodoIcon />
                      </EditTodoButton>
                    </RowWrapper>
                  ))}
                </TierDropdown>
                <TierDropdown title="Template Styling">
                  {templateStyling?.map((item, index) => (
                    <RowWrapper key={item.fieldName + index} disabled={false} isMobile={isMobile}>
                      <InfoRowCell>{item.fieldName}</InfoRowCell>
                      {item.value ? <>{getTemplateStylingValue(item.value, item.key, watermarkImg)}</> : <EmptyTodoRowCell>To Do</EmptyTodoRowCell>}
                      <EditTodoButton isMobile={isMobile} id="editTodoButton" onClick={() => handleRedirect(item, `edit-${item.key}`)}>
                        <EditTodoIcon />
                      </EditTodoButton>
                    </RowWrapper>
                  ))}
                </TierDropdown>
              </SettingsContainer>
            </PaddingWrap>
          </MainWrap>
        </>
      )}
    </>
  );
};

const SettingsContainer = styled.div`
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const EditTodoButton = styled.div<{ isMobile: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin-left: auto;
  cursor: pointer;
  height: 40px;
  width: ${({ isMobile }) => (isMobile ? '100%' : '40px')};
  background-color: ${({ theme }) => theme.action.default};
  opacity: ${({ isMobile }) => (isMobile ? '1' : '0')};
`;

const ImageWrapper = styled.div`
  width: 183px;
  height: 120px;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.action.active};
  border-radius: 5px;
  padding: 10px;
`;

const ImageItem = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const FirmSettingsContainer = styled.div<{ isMobile: boolean }>`
  border: 1px solid ${({ theme }) => theme.border.base};
  border-radius: 10px;
  margin: 50px 0;
  padding: ${({ isMobile }) => (isMobile ? '20px 30px' : '20px 60px')};
  background: ${({ theme }) => theme.layer.base};
`;
