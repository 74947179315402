import client from '@/apollo-client';
import { RejectedBannerIcon } from '@/assets/icons/info-icons/rejectedBanner';
import {
  addInvestmentOfferingMaterialsAttachment,
  GET_URL_FOR_UPLOAD_OFFERING_MATERIALS,
  updateInvestmentOfferingMaterialsAttachment
} from '@/components/allocations-page/queries';
import { Banner } from '@/components/basicComponents/banner';
import { IFile } from '@/components/dueDiligence-page/dueDiligenceRecord';
import { DropFileArea } from '@/components/dueDiligence-page/dueDiligenceRecord/dropFileArea';
import { isImageExtension } from '@/components/dueDiligence-page/dueDiligenceRecord/utils';
import FileNotSupported from '@/components/dueDiligence-page/modals/fileNotSupported';
import { createDealAssistantAIAsset, GET_AI_ASSETS, processDealAssistantAIAsset } from '@/components/dueDiligence-page/queries';
import FileUpload from '@/components/fat-basicComponents/fileUpload';
import { FormFooter } from '@/components/fat-basicComponents/formFooter';
import Select from '@/components/fat-basicComponents/select';
import { IOfferingMaterialsAttachments } from '@/components/fat-dealCatalog-page/types';
import Header from '@/components/fat-header';
import { GoBackButton } from '@/components/fat-header/goBackButton';
import { PageTitle } from '@/components/fat-header/pageTitle';
import { getAssetSignedUrl } from '@/components/settings-page/queries';
import { useResponsive } from '@/hooks/use-responsive';
import { MainWrap, PaddingWrap } from '@/styles/common';
import { useMutation } from '@apollo/client';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

interface DealDocumentsModalPageProps {
  currentAttachment: IOfferingMaterialsAttachments | null;
  documentRecordId: string;
  setUploadedFiles: Dispatch<SetStateAction<IOfferingMaterialsAttachments[] | []>>;
  setAiAssistantFiles: Dispatch<SetStateAction<IFile[]>>;
  handleClosePage: () => void;
}

const defaultFormValues = {
  visibility: ''
};

const DEFAULT_ERROR_MESSAGE = `We're sorry. This process did not complete. Please try again.`;
const visibilityOptions = [
  { label: 'Not Visible to Advisors', value: 'Not Visible to Advisors' },
  { label: 'Visible Pre Commitment', value: 'Visible Pre Commitment' },
  { label: 'Visible Post Commitment', value: 'Visible Post Commitment' }
];
const fileExtensions = ['PDF', 'DOC', 'DOCX', 'XLS', 'XLSX', 'CSV', 'JPEG', 'PNG', 'GIF'];

const allowFileExtensions = (files: FileList | null) => {
  return Array.from(files || []).filter((file: File) => {
    return fileExtensions.some((ext) => file.name.toLowerCase().endsWith(ext.toLowerCase()));
  });
};

export const DealDocumentsModalPage = ({
  currentAttachment,
  documentRecordId,
  setUploadedFiles,
  setAiAssistantFiles,
  handleClosePage
}: DealDocumentsModalPageProps) => {
  const { isMobile } = useResponsive();
  const params = useParams();
  const theme = useTheme();
  const { handleSubmit, control, reset } = useForm({ defaultValues: defaultFormValues });

  const [file, setFile] = useState<File | null>(null);
  const [drag, setDrag] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [modalWindow, setModalWindow] = useState({ isOpen: false, type: '' });
  const [uploadFilesLoading, setUploadFilesLoading] = useState(false);

  const [updateAttachment, { loading: updateAttachmentLoading }] = useMutation(updateInvestmentOfferingMaterialsAttachment);
  const [addAttachment] = useMutation(addInvestmentOfferingMaterialsAttachment);
  const [createAIAsset] = useMutation(createDealAssistantAIAsset);
  const [processAIAsset] = useMutation(processDealAssistantAIAsset);

  useEffect(() => {
    if (!currentAttachment) return;
    reset({ visibility: currentAttachment.visibility });
    setIsEditMode(true);
  }, [currentAttachment]);

  const onDropHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const uploadFiles = e.dataTransfer.files;

    setDrag(false);
    if (!allowFileExtensions(uploadFiles).length) {
      setModalWindow({ isOpen: true, type: 'not-supported' });
      return;
    }
    setFile(allowFileExtensions(uploadFiles)[0]);
  };

  const handleUploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const uploadFiles = e.target.files;

    if (!allowFileExtensions(uploadFiles).length) {
      setModalWindow({ isOpen: true, type: 'not-supported' });
      return;
    }
    setFile(allowFileExtensions(uploadFiles)[0]);
  };

  const saveAttachments = async (files: File[], investmentId: string, visibility: string) => {
    try {
      setUploadFilesLoading(true);

      for await (const file of files) {
        const { data } = await client.query({
          query: GET_URL_FOR_UPLOAD_OFFERING_MATERIALS,
          variables: {
            data: {
              contentType: file.type,
              investmentId: investmentId,
              filename: file.name.replace(/\s/g, '')
            }
          }
        });

        const { url, assetKey } = data.getInvestmentOfferingMaterialsAttachmentUploadUrl;
        await fetch(url, {
          method: 'PUT',
          body: file,
          headers: {
            'Content-Type': file.type
          }
        });

        await addAttachment({
          variables: {
            data: {
              id: investmentId,
              assetKey,
              visibility
            }
          },
          onCompleted: (data) => {
            setUploadedFiles((prev) => [...prev, data.addInvestmentOfferingMaterialsAttachment]);
          }
        });

        if (file.name.toLowerCase().endsWith('.pdf')) {
          await createAIAsset({
            variables: {
              data: {
                investmentId: investmentId,
                recordId: documentRecordId,
                assetKey,
                status: 'CREATED',
                type: 'sd',
                filename: file.name
              }
            }
          });

          await processAIAsset({
            variables: {
              data: {
                investmentId: investmentId,
                recordId: documentRecordId,
                filename: file.name,
                type: 'sd',
                assetKey
              }
            }
          });
        }
      }

      const pdfFiles = files.filter((file) => file.name.toLowerCase().endsWith('.pdf'));
      if (pdfFiles.length) {
        const { data: aiAssetsData } = await client.query({
          query: GET_AI_ASSETS,
          variables: {
            data: {
              recordId: documentRecordId
            }
          }
        });
        setAiAssistantFiles(
          aiAssetsData.getDealAssistentAIAssets.map((file: any) => ({
            ...file,
            name: file.filename
          }))
        );
      }
      handleClosePage();
    } catch (error) {
      console.error(error);
      setErrorMessage(DEFAULT_ERROR_MESSAGE);
    } finally {
      setUploadFilesLoading(false);
    }
  };

  const onUpdateAttachment = async (visibility: string) => {
    try {
      if (currentAttachment.visibility !== visibility) {
        await updateAttachment({
          variables: {
            data: {
              id: currentAttachment.id,
              visibility: visibility
            }
          },
          onCompleted: () => {
            setUploadedFiles((prev) =>
              prev.map((file) => {
                if (file.id === currentAttachment.id) {
                  return { ...file, visibility };
                }
                return file;
              })
            );
          }
        });
      }
      handleClosePage();
    } catch (error) {
      console.error(error);
      setErrorMessage(DEFAULT_ERROR_MESSAGE);
    }
  };

  const onSubmit = async (data: typeof defaultFormValues) => {
    setErrorMessage(null);
    if (isEditMode) {
      onUpdateAttachment(data.visibility);
      return;
    }
    saveAttachments([file], params?.strategyId, data.visibility);
  };

  const openAttachment = async (assetKey: string) => {
    const { data } = await client.query({
      fetchPolicy: 'network-only',
      query: getAssetSignedUrl,
      variables: {
        assetKey: assetKey
      }
    });
    window.open(`${data.getAssetSignedUrl}`, '_blank');
  };

  return (
    <>
      {modalWindow.isOpen && modalWindow.type === 'not-supported' && (
        <FileNotSupported isOpen={modalWindow.isOpen} onClose={() => setModalWindow({ isOpen: false, type: '' })} />
      )}
      <MainWrap>
        <Header modalControl={<GoBackButton handleClose={handleClosePage} backToTitle="Edit Strategy Details" />} />
        <PageTitle title="Deal Documents" />
      </MainWrap>
      <MainWrap>
        {errorMessage && (
          <Banner icon={<RejectedBannerIcon fill={theme.context.light} />} title="Error" description={errorMessage} bgColor={theme.context.error} />
        )}
        <PaddingWrap>
          <DealDocumentsForm onSubmit={handleSubmit(onSubmit, () => window.scrollTo(0, 0))}>
            <DealDocumentsWrapper isMobile={isMobile}>
              <TwoColumnWrapper isMobile={isMobile}>
                <Controller
                  name="visibility"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Visibility is required'
                    }
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Select
                      value={value}
                      setValue={onChange}
                      options={visibilityOptions}
                      errorText={error?.message}
                      label="Visibility"
                      size="md"
                      isRequiredField
                    />
                  )}
                />
              </TwoColumnWrapper>
              {isEditMode && currentAttachment && (
                <FileUpload
                  type={isImageExtension(currentAttachment.asset.assetKey) ? 'image' : 'document'}
                  imageSrc={currentAttachment.asset.url}
                  fileName={currentAttachment.asset.assetKey.split('/').pop()}
                  openFile={() => openAttachment(currentAttachment.asset.assetKey)}
                />
              )}
              {!isEditMode && (
                <>
                  {file ? (
                    <FileUpload
                      type={file.type.includes('image') ? 'image' : 'document'}
                      imageSrc={URL.createObjectURL(file)}
                      fileName={file.name}
                      openFile={() => {
                        window.open(URL.createObjectURL(file), '_blank');
                      }}
                    />
                  ) : (
                    <DropFileArea
                      id="deal-documents"
                      handleUploadFile={handleUploadFile}
                      onDropHandler={onDropHandler}
                      drag={drag}
                      setDrag={setDrag}
                      maxSize={10}
                      fileExtensions={fileExtensions}
                    />
                  )}
                </>
              )}
            </DealDocumentsWrapper>
            <FormFooter
              onCancel={handleClosePage}
              disableSaveButton={(!isEditMode && !file) || updateAttachmentLoading || uploadFilesLoading}
              isLoading={updateAttachmentLoading || uploadFilesLoading}
            />
          </DealDocumentsForm>
        </PaddingWrap>
      </MainWrap>
    </>
  );
};

const DealDocumentsForm = styled.form`
  margin-top: 50px;
`;

const DealDocumentsWrapper = styled.div<{ isMobile: boolean }>`
  padding: ${({ isMobile }) => (isMobile ? '20px' : '40px')};
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.border.base};
  background-color: ${({ theme }) => theme.layer[1]};
  display: flex;
  flex-direction: column;
  gap: ${({ isMobile }) => (isMobile ? '15px' : '25px')};
`;

const TwoColumnWrapper = styled.div<{ isMobile: boolean }>`
  display: grid;
  grid-template-columns: ${({ isMobile }) => (isMobile ? '1fr' : '1fr 1fr')};
  gap: ${({ isMobile }) => (isMobile ? '15px' : '30px')};
`;
