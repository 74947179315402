import { SelectIcon } from '@/assets/icons/selectIcon';
import BasicTooltip from '@/components/basicComponents/tooltip';
import { Loader } from '@/components/loaders/loader';
import { useClickOutside } from '@/hooks/useClickOutside';
import { FadeAnimation } from '@/styles/animations';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import styled, { css, useTheme } from 'styled-components';
import Label from '../label';

export interface ISelectValue {
  label: string;
  value: string;
}

interface SelectProps {
  value?: string;
  setValue?: (value: string) => void;
  options?: ISelectValue[];
  size?: 'sm' | 'md' | 'lg';
  styleType?: 'default' | 'form' | 'success' | 'error' | 'disabled';
  width?: string;
  loading?: boolean;
  description?: string;
  errorText?: string;
  label?: string;
  tooltip?: string;
  tooltipWidth?: number;
  isRequiredField?: boolean;
  defaultSelectLabel?: string;
  withSearch?: boolean;
  className?: string;
  customArrowIcon?: React.ReactNode;
  additionalOption?: React.ReactNode;
}

const getSelectSize = (size: SelectProps['size']) => {
  switch (size) {
    case 'sm':
      return css`
        padding: 8px 5px 8px 5px;
        border-radius: 3px;
      `;
    case 'md':
      return css`
        padding: 10px;
        border-radius: 4px;
      `;
    case 'lg':
      return css`
        padding: 10px 12px 10px 12px;
        border-radius: 5px;
      `;
    default:
      return '';
  }
};

const getSelectStyleType = (styleType: SelectProps['styleType'], isDefaultSelectValue: boolean) => {
  switch (styleType) {
    case 'default':
      return css`
        background-color: ${({ theme }) => theme.layer[1]};
        border: 1px solid ${({ theme }) => theme.border.base};
        color: ${({ theme }) => (isDefaultSelectValue ? theme.font.weak : theme.font.strong)};

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0 30px ${({ theme }) => theme.layer[1]} inset;
          -webkit-text-fill-color: ${({ theme }) => theme.font.strong};
        }
      `;
    case 'form':
      return css`
        background-color: ${({ theme }) => theme.layer[2]};
        border: 1px solid ${({ theme }) => theme.border.base};
        color: ${({ theme }) => (isDefaultSelectValue ? theme.font.weak : theme.font.strong)};

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0 30px ${({ theme }) => theme.layer[2]} inset;
          -webkit-text-fill-color: ${({ theme }) => theme.font.strong};
        }
      `;
    case 'success':
      return css`
        background-color: #efffea;
        border: 1px solid ${({ theme }) => theme.context.success};
        color: ${({ theme }) => (isDefaultSelectValue ? theme.font.weak : '#2e2e2e')};

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0 30px #efffea inset;
          -webkit-text-fill-color: #2e2e2e;
        }
      `;
    case 'error':
      return css`
        background-color: #ffeaec;
        border: 1px solid ${({ theme }) => theme.context.error};
        color: ${({ theme }) => (isDefaultSelectValue ? theme.font.weak : '#2e2e2e')};

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0 30px #ffeaec inset;
          -webkit-text-fill-color: #2e2e2e;
        }
      `;
    case 'disabled':
      return css`
        background-color: ${({ theme }) => theme.action.disabled};
        border: 1px solid ${({ theme }) => theme.border.base};
        color: ${({ theme }) => theme.font.disabled};
      `;
    default:
      return '';
  }
};

const getFontProperties = (size: SelectProps['size']) => {
  switch (size) {
    case 'sm':
      return css`
        font-size: 13px;
        line-height: 18.2px;
      `;
    case 'md':
      return css`
        font-size: 16px;
        line-height: 22.4px;
      `;
    case 'lg':
      return css`
        font-size: 19px;
        line-height: 26.6px;
      `;
    default:
      return '';
  }
};

const getSelectListTopPosition = (size: SelectProps['size']) => {
  switch (size) {
    case 'sm':
      return css`
        top: 42px;
      `;
    case 'md':
      return css`
        top: 50px;
      `;
    case 'lg':
      return css`
        top: 55px;
      `;
    default:
      return '';
  }
};

const Select = ({
  value,
  setValue,
  options = [],
  size = 'lg',
  styleType = 'default',
  width = '100%',
  loading = false,
  description,
  errorText,
  label,
  tooltip,
  tooltipWidth = 0,
  isRequiredField = false,
  defaultSelectLabel = 'Select',
  className,
  withSearch = false,
  customArrowIcon,
  additionalOption
}: SelectProps) => {
  const theme = useTheme();

  const [search, setSearch] = useState('');
  const [selectedValue, setSelectedValue] = useState(defaultSelectLabel);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [filteredSelectOptions, setFilteredSelectOptions] = useState<ISelectValue[]>([]);
  const [isTitleOverflowing, setIsTitleOverflowing] = useState(false);

  const titleRef = useRef<HTMLDivElement | null>(null);
  const selectRef = useRef<HTMLDivElement | null>(null);
  useClickOutside(selectRef, () => setIsSelectOpen(false));

  useEffect(() => {
    if (!options.length) return;
    setFilteredSelectOptions(options);
  }, [options]);

  useEffect(() => {
    if (!value) {
      setSelectedValue(defaultSelectLabel);
      return;
    }

    const selectedOption = options.find((option) => option.value === value);
    setSelectedValue(selectedOption ? selectedOption.label : defaultSelectLabel);
  }, [value, options]);

  useEffect(() => {
    const { current } = titleRef;
    if (current) {
      setIsTitleOverflowing(current.scrollWidth > current.clientWidth);
    }
  }, [selectedValue]);

  const handleSelectClick = (event: any) => {
    if (styleType === 'disabled') return;
    if (event.target.id !== 'input') {
      setIsSelectOpen(!isSelectOpen);
    }
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearch(value);
    const selectOptionsCopy = [...options];
    const filteredList = selectOptionsCopy.filter((item) => item.value.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1);
    if (value === '' && filteredList.length === 0) {
      setFilteredSelectOptions(options);
    } else {
      setFilteredSelectOptions(filteredList);
    }
  };

  return (
    <SelectContainer width={width}>
      {label && (
        <Label required={isRequiredField} size={size} tooltip={tooltip} tooltipWidth={tooltipWidth}>
          {label}
        </Label>
      )}
      <SelectWrapper
        styleType={styleType}
        size={size}
        isDefaultSelectValue={selectedValue === defaultSelectLabel}
        className={className}
        onClick={(event) => handleSelectClick(event)}
        ref={selectRef}
      >
        {isTitleOverflowing ? (
          <BasicTooltip tooltipContent={selectedValue} tooltipWidth={300} position="bottom" zIndex={100}>
            <FilterTitle id="titleSelect" ref={titleRef}>
              {selectedValue}
            </FilterTitle>
          </BasicTooltip>
        ) : (
          <FilterTitle id="titleSelect" ref={titleRef}>
            {selectedValue}
          </FilterTitle>
        )}
        <SelectIconWrap isOpen={isSelectOpen}>
          {customArrowIcon ?? <SelectIcon fill={styleType === 'disabled' ? theme.font.disabled : theme.action.primary} />}
        </SelectIconWrap>
        <FilterItems id="dropdownSelect" width={width} isOpen={isSelectOpen} size={size}>
          {withSearch && (
            <SearchWrap id="input">
              <SearchInput id="input" placeholder="Type Here To Filter Results" value={search} onChange={onChange} size={size} />
              <Divider />
            </SearchWrap>
          )}
          {loading ? (
            <Loader size={20} />
          ) : (
            <>
              {filteredSelectOptions.length ? (
                <>
                  {filteredSelectOptions?.map((item) => (
                    <SelectOption
                      key={item.value}
                      size={size}
                      isActive={item.value === value}
                      onClick={() => setValue(item.value)}
                      isDefaultSelectValue={defaultSelectLabel === item.label}
                    >
                      {item.label}
                    </SelectOption>
                  ))}
                </>
              ) : (
                <NoResults size={size}>No Results</NoResults>
              )}
            </>
          )}
          {additionalOption && (
            <AdditionalOptionWrapper>
              <AdditionalOption>{additionalOption}</AdditionalOption>
            </AdditionalOptionWrapper>
          )}
        </FilterItems>
      </SelectWrapper>
      {description && <Description>{description}</Description>}
      {errorText && <ErrorText size={size}>{errorText}</ErrorText>}
    </SelectContainer>
  );
};

export default Select;

const SelectContainer = styled.div<{ width: string }>`
  width: ${({ width }) => width};
`;

const SelectWrapper = styled.div<{ styleType: SelectProps['styleType']; size: SelectProps['size']; isDefaultSelectValue: boolean }>`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  cursor: ${({ styleType }) => (styleType === 'disabled' ? 'not-allowed' : 'pointer')};

  ${({ size }) => getSelectSize(size)}
  ${({ styleType, isDefaultSelectValue }) => getSelectStyleType(styleType, isDefaultSelectValue)}
  ${({ size }) => getFontProperties(size)}

  #basicTooltip {
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 93%;
  }
`;

const SearchWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 11px 10px 0;
  gap: 11px;
`;

const SearchInput = styled.input<{ size: SelectProps['size'] }>`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.border.base};
  background-color: ${({ theme }) => theme.layer[1]};
  color: ${({ theme }) => theme.font.strong};

  &:focus {
    outline: none;
  }

  ${({ size }) => getFontProperties(size)}
  ${({ size }) => getSelectSize(size)}
`;

const Divider = styled.div`
  position: relative;
  z-index: 100;
  display: flex;
  background: ${({ theme }) => theme.border.base};
  height: 1px;
  width: 110%;
  margin-left: -20px;
  margin-right: -20px;
`;

const SelectIconWrap = styled.div<{ isOpen: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  transform-origin: center;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: all 0.2s linear;
`;

const FilterItems = styled(FadeAnimation)<{ isOpen: boolean; width: string; size: SelectProps['size'] }>`
  max-height: 260px;
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 1000;
  ${({ size }) => getSelectListTopPosition(size)}
  right: 0;
  width: 100%;
  border: ${({ isOpen, theme }) => `${isOpen ? 1 : 0}px solid ${theme.border.base}`};
  background: ${({ theme }) => theme.layer[1]};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  overflow-y: auto;
  overflow-x: hidden;
  overscroll-behavior: none;

  &::-webkit-scrollbar {
    width: 10px;
    left: -100px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.layer[2]};
    border: 1px solid ${({ theme }) => theme.border.base};
    padding: 2px;
    border-radius: 10px;
    margin: 6px 0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.layer[4]};
    background-clip: padding-box;
    border-radius: 10px;
  }
`;

const FilterTitle = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
`;

const SelectOption = styled.div<{ size: SelectProps['size']; isDefaultSelectValue: boolean; isActive?: boolean }>`
  color: ${({ theme, isDefaultSelectValue }) => (isDefaultSelectValue ? theme.font.weak : theme.font.strong)};
  text-align: left;
  width: 100%;
  cursor: pointer;
  background: ${({ isActive, theme }) => (isActive ? theme.layer[12] : theme.layer[1])};

  &:hover {
    background: ${({ theme }) => theme.layer[12]};
  }

  ${({ size }) => getSelectSize(size)}
`;

const NoResults = styled.div<{ size: SelectProps['size'] }>`
  color: ${({ theme }) => theme.font.strong};
  text-align: center;
  width: 100%;
  white-space: nowrap;
  background: ${({ theme }) => theme.layer[1]};

  ${({ size }) => getSelectSize(size)}
`;

const AdditionalOptionWrapper = styled.div`
  position: sticky;
  bottom: 0;
  background-color: ${({ theme }) => theme.layer[1]};
`;

const AdditionalOption = styled.div`
  padding: 10px 0;
  margin: 0 10px;
  border-top: 1px solid ${({ theme }) => theme.border.base};
`;

const Description = styled.span`
  font-weight: 300;
  font-size: 13px;
  line-height: 18.2px;
  color: ${({ theme }) => theme.font.weak};
`;

const ErrorText = styled.p<{ size: SelectProps['size'] }>`
  color: ${({ theme }) => theme.context.error};
  font-family: Blinker;
  font-style: normal;
  font-weight: 400;

  ${({ size }) => getFontProperties(size)}
`;
