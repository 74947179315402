import RadioButton from '@/components/basicComponents/radioButton';
import Label from '@/components/fat-basicComponents/label';
import { Controller, useWatch } from 'react-hook-form';
import styled from 'styled-components';
import { IRenderTemplateRow } from '../types';

interface RadioComponentProps {
  control: any;
  row: IRenderTemplateRow;
  ignoreRequired: boolean;
}

const getSelectedOption = (value: boolean, items: any) => {
  return items.find((item: any) => item.value === value)?.label || '';
};

export const RadioComponent = ({ control, row, ignoreRequired }: RadioComponentProps) => {
  const { name, label, config, dependsOn } = row;
  const items = config?.items || [];
  const dependentValue = dependsOn ? useWatch({ control, name: dependsOn.name }) : null;

  if (dependsOn && dependentValue !== dependsOn.value) return <></>;

  return (
    <div>
      {label && <Label size="md">{label}</Label>}
      <RadioButtonsWrapper>
        <Controller
          name={name}
          control={control}
          render={({ field: { value, onChange } }) => (
            <>
              {items.map((item: any, index: number) => (
                <RadioButton
                  key={item + index}
                  name={name}
                  radioButtonText={item.label}
                  selectedOption={getSelectedOption(!!value, items)}
                  handleRadioChange={(event) => {
                    onChange(!!items.find((item: any) => item.label === event.target.value)?.value);
                  }}
                />
              ))}
            </>
          )}
        />
      </RadioButtonsWrapper>
    </div>
  );
};

const RadioButtonsWrapper = styled.div`
  display: flex;
`;
