import { InfoIcon } from '@/assets/icons/info-icons/info';
import { PlusIcon } from '@/assets/icons/info-icons/plus';
import { Banner } from '@/components/basicComponents/banner';
import RadioButton from '@/components/basicComponents/radioButton';
import FilterSelect from '@/components/basicComponents/select';
import Input from '@/components/fat-basicComponents/input';
import Label from '@/components/fat-basicComponents/label';
import { IAssetClass, ISubAssetClass } from '@/components/fat-investors-page/fat-addInvestors/types';
import { Section } from '@/components/fat-investors-page/fat-investorDetails-page/fat-entityDetails-page/edit-entity/section';
import { defaultSelectValue } from '@/components/opportunities-entity/modify/constants';
import { blinkingFrame } from '@/components/table/styles';
import { useResponsive } from '@/hooks/use-responsive';
import { useStatusData } from '@/hooks/useStatusData';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Control, Controller, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import styled, { useTheme } from 'styled-components';
import { AddButton } from '../../addButton';
import { distributionSettingsOptions, yseNoOptions } from '../../constatnts';
import { IForm, IListData, PACING_TYPE } from '../../types';
import { addStrategies, delStrategies, onChangeStrategies } from '../../utils';

interface DetailsSectionProps {
  control: Control<IForm, any>;
  managerList: string[];
  advisoryFirms: IListData;
  assetClasses: IAssetClass[];
  selectedDistributionSettingsOptions: string;
  watch: UseFormWatch<IForm>;
  setValue: UseFormSetValue<IForm>;
  openModalWindow: (type: string) => void;
  setAdvisoryFirms: Dispatch<SetStateAction<IListData>>;
  setSelectedDistributionSettingsOptions: Dispatch<SetStateAction<string>>;
}

const findSubAssetClass = (assetClasses: IAssetClass[], currentSubAssetClassName: string) => {
  for (const assetClass of assetClasses) {
    const subAssetClass = assetClass.subAssetClasses.find((subAssetClass) => subAssetClass.name === currentSubAssetClassName);
    if (subAssetClass) {
      return subAssetClass;
    }
  }
  return null;
};

export const DetailsSection = ({
  control,
  managerList,
  assetClasses,
  advisoryFirms,
  selectedDistributionSettingsOptions,
  setAdvisoryFirms,
  openModalWindow,
  setSelectedDistributionSettingsOptions,
  watch,
  setValue
}: DetailsSectionProps) => {
  const { isMobile } = useResponsive();
  const theme = useTheme();
  const { data: statusData } = useStatusData();

  const [subAssetClasses, setSubAssetClasses] = useState<ISubAssetClass[]>([]);
  const [prevAssetClass, setPrevAssetClass] = useState(() => watch('assetClass'));
  const [showPacingTypeSelect, setShowPacingTypeSelect] = useState(false);
  const [shwoAdditionalPacingFields, setShowAdditionalPacingFields] = useState(false);

  useEffect(() => {
    const currentAssetClass = watch('assetClass');

    if (currentAssetClass !== prevAssetClass) {
      const assetClass = assetClasses.find((assetClass) => assetClass.name === currentAssetClass);
      setSubAssetClasses(assetClass?.subAssetClasses || []);
      setValue('subAssetClass', defaultSelectValue);
      // setValue('pacingType', null);
      setPrevAssetClass(currentAssetClass);
      return;
    }

    if (currentAssetClass === defaultSelectValue) {
      setSubAssetClasses([]);
      setValue('subAssetClass', defaultSelectValue);
      return;
    }

    const assetClass = assetClasses.find((assetClass) => assetClass.name === currentAssetClass);
    setSubAssetClasses(assetClass?.subAssetClasses || []);
    setPrevAssetClass(currentAssetClass);
  }, [watch('assetClass')]);

  useEffect(() => {
    if (statusData?.target_sub_asset_classes) return;

    const currentAssetClass = assetClasses.find((assetClass) => assetClass.name === watch('assetClass'));
    if (currentAssetClass) {
      setShowPacingTypeSelect(currentAssetClass.illiquid);
      return;
    }
    setShowPacingTypeSelect(false);
  }, [watch('assetClass')]);

  useEffect(() => {
    if (!statusData?.target_sub_asset_classes) return;

    if (watch('subAssetClass') === defaultSelectValue) {
      setValue('pacingType', null);
    }

    const currentSubAssetClass = findSubAssetClass(assetClasses, watch('subAssetClass'));
    if (currentSubAssetClass) {
      setShowPacingTypeSelect(currentSubAssetClass.illiquid);
      return;
    }
    setShowPacingTypeSelect(false);
  }, [watch('subAssetClass')]);

  useEffect(() => {
    setShowAdditionalPacingFields(showPacingTypeSelect && watch('pacingType') === PACING_TYPE.DRAWDOWN);
  }, [showPacingTypeSelect, watch('pacingType')]);

  useEffect(() => {
    if (showPacingTypeSelect && !watch('pacingType')) {
      setValue('pacingType', PACING_TYPE.DRAWDOWN);
    }
  }, [showPacingTypeSelect]);

  return (
    <Section title="Details" collapsable>
      <DetailsSectionWrap>
        <Line isMobile={isMobile}>
          <Controller
            name="name"
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Name is required'
              }
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                value={value}
                onChange={(e) => {
                  onChange(e.target.value);
                }}
                label="Name"
                placeholder="Friendly name for use internally and with clients"
                errorText={error?.message}
                styleType="form"
                size="md"
                isRequiredField
              />
            )}
          />
          <CustomSelectWrapper>
            <Label size="md" required>
              Manager
            </Label>
            <Controller
              name="manager"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Manager is required'
                },
                pattern: {
                  value: /^(?!Select$)/,
                  message: 'Manager is required'
                }
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <CustomSelect
                    data={[defaultSelectValue, ...managerList]}
                    selected={value}
                    setSelected={onChange}
                    withSearch
                    width="100%"
                    additionalOption={
                      <AddManagerWrapper onClick={() => openModalWindow('create-manager')}>
                        <PlusIcon width={18} height={18} fill={theme.font.action} />
                        <AddText>Add New Manager</AddText>
                      </AddManagerWrapper>
                    }
                  />
                  {error?.message && <ErrorText>{error.message}</ErrorText>}
                </>
              )}
            />
          </CustomSelectWrapper>
        </Line>
        <Line isMobile={isMobile} width50={!statusData?.target_sub_asset_classes}>
          <CustomSelectWrapper>
            <Label size="md" required>
              Asset Class
            </Label>
            <Controller
              name="assetClass"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Asset Class is required'
                },
                pattern: {
                  value: /^(?!Select$)/,
                  message: 'Asset Class is required'
                }
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <CustomSelect
                    data={[defaultSelectValue, ...assetClasses.map((assetClass) => assetClass.name)]}
                    selected={value}
                    setSelected={onChange}
                    width="100%"
                  />
                  {error?.message && <ErrorText>{error.message}</ErrorText>}
                </>
              )}
            />
          </CustomSelectWrapper>
          {statusData?.target_sub_asset_classes && (
            <CustomSelectWrapper>
              <Label size="md" required>
                Sub Asset Class
              </Label>
              <Controller
                name="subAssetClass"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Sub Asset Class is required'
                  },
                  pattern: {
                    value: /^(?!Select$)/,
                    message: 'Sub Asset Class is required'
                  }
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <>
                    <CustomSelect
                      data={[defaultSelectValue, ...subAssetClasses.map((subAssetClass) => subAssetClass.name)]}
                      selected={value}
                      setSelected={onChange}
                      width="100%"
                      disabled={!subAssetClasses.length}
                    />
                    {error?.message && <ErrorText>{error.message}</ErrorText>}
                  </>
                )}
              />
            </CustomSelectWrapper>
          )}
        </Line>
        {showPacingTypeSelect && (
          <PacingFieldsWrapper>
            <Line width50 isMobile={isMobile}>
              <CustomSelectWrapper>
                <Label
                  size="md"
                  tooltipWidth={300}
                  tooltip="Pacing model forecasts will assume that evergreen funds are held indefinitely and that all investor capital is transferred as part of the initial subscription."
                  required
                >
                  Pacing Type
                </Label>
                <Controller
                  name="pacingType"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Pacing Type is required'
                    }
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <>
                      <CustomSelect data={Object.values(PACING_TYPE)} selected={value} setSelected={onChange} width="100%" />
                      {error?.message && <ErrorText>{error.message}</ErrorText>}
                    </>
                  )}
                />
              </CustomSelectWrapper>
            </Line>
            {shwoAdditionalPacingFields && (
              <Line width50 isMobile={isMobile}>
                <Controller
                  name="pacingStartingYear"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Pacing Starting Year is required'
                    },
                    pattern: {
                      value: /^\d{4}$/,
                      message: 'Must be four digits only'
                    }
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Input
                      value={value}
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                      label="Pacing Starting Year"
                      placeholder="Enter a year using four digits"
                      errorText={error?.message}
                      styleType="form"
                      size="md"
                      tooltipWidth={300}
                      tooltip="Provide a year and quarter that roughly corresponds to the inception of the fund.  This is an input to the pacing model and cash flow forecasts."
                      isRequiredField
                    />
                  )}
                />
                <CustomSelectWrapper>
                  <Label
                    size="md"
                    tooltipWidth={300}
                    tooltip="Provide a year and quarter that roughly corresponds to the inception of the fund.  This is an input to the pacing model and cash flow forecasts."
                    required
                  >
                    Pacing Starting Quarter
                  </Label>
                  <Controller
                    name="pacingStartingQuarter"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Pacing Starting Quarter is required'
                      },
                      pattern: {
                        value: /^(?!Select$)/,
                        message: 'Sub Asset Class is required'
                      }
                    }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <>
                        <CustomSelect data={['Q1', 'Q2', 'Q3', 'Q4']} selected={value} setSelected={onChange} width="100%" />
                        {error?.message && <ErrorText>{error.message}</ErrorText>}
                      </>
                    )}
                  />
                </CustomSelectWrapper>
              </Line>
            )}
          </PacingFieldsWrapper>
        )}
        <Line isMobile={isMobile} width50>
          <div>
            <Label
              size="md"
              tooltip="Answer Yes if this strategy is suitable for tax exempt investors. Either there is a UBTI Blocker in place, such as an offshore vehicle, or there is no risk of generating UBTI"
              tooltipWidth={300}
            >
              Tax Exempt Suitable
            </Label>
            <Controller
              name="ubtiBlocker"
              control={control}
              render={({ field: { value, onChange } }) => (
                <TogglesWrap isMobile={isMobile}>
                  {yseNoOptions.map((option, index) => (
                    <RadioButton
                      key={option + index}
                      name="ubtiBlocker"
                      radioButtonText={option}
                      selectedOption={value ? yseNoOptions[0] : yseNoOptions[1]}
                      handleRadioChange={(event) => onChange(event.target.value === yseNoOptions[0])}
                    />
                  ))}
                </TogglesWrap>
              )}
            />
          </div>
        </Line>
        <div>
          <DistributionSettingsWrapper isMobile={isMobile}>
            <DistributionSettingsOptionsWrapper>
              <Label size="md">Restrictions</Label>
              {distributionSettingsOptions.map((option, index) => (
                <RadioButton
                  key={option + index}
                  name="restrictions"
                  radioButtonText={option}
                  selectedOption={selectedDistributionSettingsOptions}
                  handleRadioChange={(event) => setSelectedDistributionSettingsOptions(event.target.value)}
                />
              ))}
            </DistributionSettingsOptionsWrapper>
            <Banner
              title=""
              description="This strategy will be shown to all advisory firms by default. Optionally, you may restrict those firms who have access."
              icon={<InfoIcon width={26} height={26} fill={theme.header.base} />}
              bgColor={theme.layer[1]}
              color={theme.font.base}
            />
          </DistributionSettingsWrapper>
          {selectedDistributionSettingsOptions === distributionSettingsOptions[1] && (
            <Line isMobile={isMobile} width50>
              <StrategiesWrap>
                {advisoryFirms.chosen.map((el, index) => (
                  <StrategiesItemWrap key={`advisoryFirm-${index}`}>
                    <CustomSelect
                      data={advisoryFirms.list}
                      selected={advisoryFirms.chosen[index]}
                      setSelected={(value) => onChangeStrategies(value, index, advisoryFirms, setAdvisoryFirms)}
                      disabled={advisoryFirms.chosen[advisoryFirms.chosen.length - 1] !== el}
                      width="100%"
                      withSearch
                      fontSize="16px"
                    />
                    <DelItemWrap onClick={() => delStrategies(el, index, advisoryFirms, setAdvisoryFirms)}>
                      <img src="/static/icons/blue-cross.svg" alt="cross" />
                    </DelItemWrap>
                  </StrategiesItemWrap>
                ))}
                {advisoryFirms.list.length && advisoryFirms.chosen[advisoryFirms.chosen.length - 1] !== 'None' ? (
                  <AddButton text="Add" onClick={() => addStrategies(advisoryFirms, setAdvisoryFirms)} />
                ) : (
                  <></>
                )}
              </StrategiesWrap>
            </Line>
          )}
        </div>
      </DetailsSectionWrap>
    </Section>
  );
};

const DetailsSectionWrap = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
`;

const PacingFieldsWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 30px;
`;

const Line = styled.span<{ isMobile: boolean; width50?: boolean }>`
  display: flex;
  justify-content: space-between;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  width: ${({ width50, isMobile }) => (width50 && !isMobile ? '50%' : '100%')};
  gap: 30px;
  padding-right: ${({ width50, isMobile }) => (width50 && !isMobile ? '15px' : '0')};
  margin-top: 4px;
`;

const CustomSelectWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const AddManagerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
`;

const AddText = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.font.action};
`;

const TogglesWrap = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  gap: ${({ isMobile }) => (isMobile ? '15px' : '0')};
`;

const CustomSelect = styled(FilterSelect)`
  background-color: ${({ theme }) => theme.layer[2]};
  border: 1px solid ${({ theme }) => theme.border.base};
  padding: 10px;
  border-radius: 5px;

  #titleSelect {
    font-family: Blinker;
    text-transform: none;
    font-size: 16px;
    line-height: 22.4px;
  }

  #dropDownSelect {
    top: 50px;
  }
`;

const ErrorText = styled.p`
  color: ${({ theme }) => theme.context.error};
  font-family: Blinker;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22.4px;
`;

const DistributionSettingsWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: ${({ isMobile }) => (isMobile ? 'flex-start' : 'flex-end')};
  flex-direction: ${({ isMobile }) => (isMobile ? 'column-reverse' : 'row')};
  gap: ${({ isMobile }) => (isMobile ? '15px' : '0')};
`;

const DistributionSettingsOptionsWrapper = styled.div`
  max-width: 343px;
  width: 100%;
  color: ${({ theme }) => theme.font.base};
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
`;

const StrategiesWrap = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const StrategiesItemWrap = styled.div<{ loading?: boolean | number }>`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 10px;
  position: relative;

  opacity: ${({ loading }) => (loading ? 0.5 : 1)};
  pointer-events: ${({ loading }) => (loading ? 'none' : 'auto')};
  animation: ${({ loading }) => (loading ? blinkingFrame : null)} 1.5s infinite;
`;

const DelItemWrap = styled.div`
  position: relative;
  top: 3px;
  padding-left: 15px;
  cursor: pointer;
`;
