import { ActiveInvestmentsMenuIcon } from '@/assets/icons/fat-menu-icons/activeInvestments';
import { DealPipelineMenuIcon } from '@/assets/icons/fat-menu-icons/dealPipeline';
import { InvestorsMenuIcon } from '@/assets/icons/fat-menu-icons/investors';
import { PendingCommitmentsMenuIcon } from '@/assets/icons/fat-menu-icons/pendingCommitments';
import { ReportingDataMenuIcon } from '@/assets/icons/fat-menu-icons/reportingData';
import { SettingsMenuIcon } from '@/assets/icons/fat-menu-icons/settings';
import { AllocationsMenuIcon } from '@/assets/icons/menu-icons/allocations';
import { ComplianceMenuIcon } from '@/assets/icons/menu-icons/compliance';
import { DashboardMenuIcon } from '@/assets/icons/menu-icons/dashboard';
import { DealRegistryMenuIcon } from '@/assets/icons/menu-icons/dealRegistry';
import { DueDiligenceMenuIcon } from '@/assets/icons/menu-icons/dueDiligence';
import { FamiliesMenuIcon } from '@/assets/icons/menu-icons/families';
import { ManagerUpdatesMenuIcon } from '@/assets/icons/menu-icons/managerUpdates';
import { TodoCalendar } from '@/assets/icons/todo-calendar';

export type Tabs = {
  label: string;
  path: string;
  icon: JSX.Element;
  subTitle?: string;
  disabled?: boolean;
};

const tabs: Tabs[] = [
  {
    label: 'Deal Registry',
    subTitle: 'View all deals throughout their lifecycle',
    path: 'dealRegistry',
    icon: <DealRegistryMenuIcon style={{ minWidth: '28px' }} />
  },
  {
    label: 'Due Diligence',
    subTitle: '',
    path: 'dueDiligence',
    icon: <DueDiligenceMenuIcon style={{ minWidth: '28px' }} />
  },
  {
    label: 'Manager Pipeline',
    path: 'dealCatalog',
    subTitle: 'Allocate to opportunities that are currently accepting commitments.',
    icon: <AllocationsMenuIcon style={{ minWidth: '28px' }} />
  },
  {
    label: 'Manager Pipeline',
    path: 'dealPipeline',
    subTitle: 'Control when and how strategies appear to advisors',
    icon: <DealPipelineMenuIcon style={{ minWidth: '28px' }} />
  },
  {
    label: 'Investors',
    path: 'investors',
    subTitle: 'Find and view each investor within your firm.',
    icon: <InvestorsMenuIcon style={{ minWidth: '28px' }} />
  },
  {
    label: 'Pending Commitments',
    subTitle: 'Manage all tasks to finalize private capital transactions',
    path: 'pendingCommitments',
    icon: <PendingCommitmentsMenuIcon style={{ minWidth: '28px' }} />
  },
  {
    label: 'Transactions',
    subTitle: 'Complete investor documentation and collect signatures to finalize private capital transactions.',
    path: 'transactions',
    icon: <PendingCommitmentsMenuIcon style={{ minWidth: '28px' }} />
  },
  {
    label: 'Active Commitments',
    subTitle: 'Search  across all managers where you have active investments',
    path: 'activeInvestments',
    icon: <ActiveInvestmentsMenuIcon style={{ minWidth: '28px' }} />
  },
  {
    label: 'Manager Updates',
    subTitle: 'Log all quarterly updates',
    path: 'managerUpdates',
    icon: <ManagerUpdatesMenuIcon style={{ minWidth: '28px' }} />
  },
  {
    label: 'Reporting Data',
    subTitle: 'Obtain a capital activity upload formated for your reporting system',
    path: 'reportingData',
    icon: <ReportingDataMenuIcon style={{ minWidth: '28px' }} />
  },
  {
    label: 'Settings',
    path: 'settings',
    icon: <SettingsMenuIcon style={{ minWidth: '28px' }} />
  },
  {
    label: 'To Do',
    path: 'todo',
    icon: <TodoCalendar style={{ minWidth: '28px' }} fill={'#FAFAFA'} />,
    disabled: true
  },
  { label: 'Dashboard', path: 'dashboard', icon: <DashboardMenuIcon style={{ minWidth: '28px' }} /> },

  {
    label: 'Clients',
    subTitle: 'Find and view each client within your firm.',
    path: 'clients',
    icon: <FamiliesMenuIcon style={{ minWidth: '28px' }} />
  },
  { label: 'Allocations', path: 'allocations', icon: <AllocationsMenuIcon style={{ minWidth: '28px' }} /> },
  { label: 'Pending Investments', path: 'recommendations', icon: <PendingCommitmentsMenuIcon style={{ minWidth: '28px' }} /> },
  { label: 'Active Investments', path: 'investments', icon: <ActiveInvestmentsMenuIcon style={{ minWidth: '28px' }} /> },
  {
    label: 'Compliance',
    path: 'compliance',
    icon: <ComplianceMenuIcon style={{ minWidth: '28px' }} />
  }
  // { label: 'Settings', path: 'settings', icon: <SettingsMenuIcon style={{ minWidth: '28px' }} /> }
];

export default tabs;
