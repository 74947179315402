import RadioButton from '@/components/basicComponents/radioButton';
import Button from '@/components/fat-basicComponents/button';
import Select from '@/components/fat-basicComponents/select';
import { useResponsive } from '@/hooks/use-responsive';
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import styled from 'styled-components';
import { ThreeColumnWrapper } from '../styles';
import { IRenderTemplateRow } from '../types';

interface AttachmentsComponentProps {
  control: any;
  row: IRenderTemplateRow;
  ignoreRequired: boolean;
  openModalPage: (type: string, row: IRenderTemplateRow) => void;
}

export const AttachmentsComponent = ({ control, row, ignoreRequired, openModalPage }: AttachmentsComponentProps) => {
  const { isMobile } = useResponsive();

  const [radioButtonValue, setRadioButtonValue] = useState('Add Attachment Files');

  return (
    <>
      <ThreeColumnWrapper isMobile={isMobile}>
        <RadioButtonsWrapper>
          {['Add Attachment Files', 'No Attachment Files'].map((option, index) => (
            <RadioButton
              key={option + index}
              name={row.name}
              radioButtonText={option}
              selectedOption={radioButtonValue}
              handleRadioChange={(event) => setRadioButtonValue(event.target.value)}
            />
          ))}
        </RadioButtonsWrapper>
      </ThreeColumnWrapper>
      {radioButtonValue === 'Add Attachment Files' && (
        <ThreeColumnWrapper isMobile={isMobile}>
          <Controller
            name="attachmentFile"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Select
                label="Add an Attachment File"
                value={value || ''}
                options={[{ label: 'Select', value: '' }]}
                setValue={onChange}
                errorText={error?.message}
                isRequiredField={!ignoreRequired}
                size="md"
                additionalOption={
                  <AddNewAttachmentButtonWrapper>
                    <Button size="sm" styleType="outline" onClick={() => openModalPage('add-attachment-file', row)}>
                      Add New Attachment
                    </Button>
                  </AddNewAttachmentButtonWrapper>
                }
              />
            )}
          />
        </ThreeColumnWrapper>
      )}
    </>
  );
};

const RadioButtonsWrapper = styled.div`
  display: flex;
  font-size: 16px;
`;

const AddNewAttachmentButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
