import { DatePickerIcon } from '@/assets/static/icons/date-picker';
import { TextField } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { format } from 'date-fns';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';
import styled, { css, useTheme } from 'styled-components';
import Label from '../label';

interface DatePickerProps {
  value?: string | number;
  onChange?: (string: string) => void;
  size?: 'sm' | 'md' | 'lg';
  styleType?: 'default' | 'form' | 'success' | 'error' | 'disabled';
  width?: string;
  description?: string;
  errorText?: string;
  label?: string;
  tooltip?: string;
  tooltipWidth?: number;
  isRequiredField?: boolean;
  placeholder?: string;
  autoFocus?: boolean;
  className?: string;
}

const getDatePickerSize = (size: DatePickerProps['size']) => {
  switch (size) {
    case 'sm':
      return css`
        padding: 8px 5px 8px 5px;
        border-radius: 3px;
      `;
    case 'md':
      return css`
        padding: 10px;
        border-radius: 4px;
      `;
    case 'lg':
      return css`
        padding: 10px 12px 10px 12px;
        border-radius: 5px;
      `;
    default:
      return '';
  }
};

const getDatePickerStyleType = (styleType: DatePickerProps['styleType']) => {
  switch (styleType) {
    case 'default':
      return css`
        background-color: ${({ theme }) => theme.layer[1]};
        border: 1px solid ${({ theme }) => theme.border.base};
        color: ${({ theme }) => theme.font.strong};

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0 30px ${({ theme }) => theme.layer[1]} inset;
          -webkit-text-fill-color: ${({ theme }) => theme.font.strong};
        }
      `;
    case 'form':
      return css`
        background-color: ${({ theme }) => theme.layer[2]};
        border: 1px solid ${({ theme }) => theme.border.base};
        color: ${({ theme }) => theme.font.strong};

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0 30px ${({ theme }) => theme.layer[2]} inset;
          -webkit-text-fill-color: ${({ theme }) => theme.font.strong};
        }
      `;
    case 'success':
      return css`
        background-color: #efffea;
        border: 1px solid ${({ theme }) => theme.context.success};
        color: #2e2e2e;

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0 30px #efffea inset;
          -webkit-text-fill-color: #2e2e2e;
        }
      `;
    case 'error':
      return css`
        background-color: #ffeaec;
        border: 1px solid ${({ theme }) => theme.context.error};
        color: #2e2e2e;

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0 30px #ffeaec inset;
          -webkit-text-fill-color: #2e2e2e;
        }
      `;
    case 'disabled':
      return css`
        background-color: ${({ theme }) => theme.action.disabled};
        border: 1px solid ${({ theme }) => theme.border.base};
        color: ${({ theme }) => theme.font.disabled};
      `;
    default:
      return '';
  }
};

const getFontProperties = (size: DatePickerProps['size']) => {
  switch (size) {
    case 'sm':
      return css`
        font-size: 13px;
        line-height: 18.2px;
      `;
    case 'md':
      return css`
        font-size: 16px;
        line-height: 22.4px;
      `;
    case 'lg':
      return css`
        font-size: 19px;
        line-height: 26.6px;
      `;
    default:
      return '';
  }
};

const DatePicker = ({
  value = '',
  onChange,
  size = 'lg',
  styleType = 'default',
  width = '100%',
  description,
  errorText,
  label,
  tooltip,
  tooltipWidth = 0,
  isRequiredField = false,
  placeholder = 'MM/DD/YYYY',
  autoFocus = false,
  className
}: DatePickerProps) => {
  const theme = useTheme();

  const [open, setOpen] = useState(false);

  const handleValue = (date: Dayjs | any) => {
    if (dayjs(date).isValid()) {
      const chosenDate = format(new Date(date.year(), date.month(), date.date()), 'MM/dd/yyyy');
      onChange(chosenDate);
      return;
    }
    onChange(date);
  };

  return (
    <DatePickerWrapper className={className}>
      {label && (
        <Label required={isRequiredField} size={size} tooltip={tooltip} tooltipWidth={tooltipWidth}>
          {label}
        </Label>
      )}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StyledDesktopDatePicker
          width={width}
          PaperProps={{
            sx: {
              bgcolor: theme.layer[1],
              color: theme.font.strong,
              border: `1px solid ${theme.border.base}`,
              marginTop: '8px',
              '& .MuiPickersDay-root': {
                bgcolor: theme.layer[2],
                color: theme.font.strong
              },
              '& .MuiButtonBase-root': {
                color: theme.font.strong,
                '&:hover': {
                  bgcolor: theme.action.hover
                }
              },
              '& .MuiTypography-root': {
                color: theme.font.weak
              },
              '& .MuiPickersDay-today': {
                border: `1px solid ${theme.border.base}`
              },
              '& .PrivatePickersFadeTransitionGroup-root': {
                '&::-webkit-scrollbar': {
                  width: '10px',
                  left: '-100px'
                },
                '&::-webkit-scrollbar-track': {
                  background: theme.layer[2],
                  border: '1px solid',
                  borderColor: theme.border.base,
                  padding: '2px',
                  borderRadius: '10px',
                  margin: '6px 0'
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: theme.layer[4],
                  backgroundClip: 'padding-box',
                  borderRadius: '10px'
                }
              }
            }
          }}
          components={{
            OpenPickerIcon: () => <DatePickerIcon fill={theme.font.action} style={{ position: 'absolute', right: '28px' }} />
          }}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          inputFormat="MM/DD/YYYY"
          value={value}
          onChange={(d) => handleValue(d)}
          autoFocus={autoFocus}
          disabled={styleType === 'disabled'}
          renderInput={(params) => {
            const { inputProps, ...rest } = params;
            return <Placeholder {...rest} {...inputProps} styleType={styleType} size={size} placeholder={placeholder} />;
          }}
        />
      </LocalizationProvider>
      {description && <Description>{description}</Description>}
      {errorText && <ErrorText size={size}>{errorText}</ErrorText>}
    </DatePickerWrapper>
  );
};

export default DatePicker;

const DatePickerWrapper = styled.div`
  width: 100%;
`;

const StyledDesktopDatePicker = styled(DesktopDatePicker)<{ width: string }>`
  position: relative;
  box-shadow: none;
  width: ${({ width }) => width};

  .MuiInputBase-root {
    padding: 0;
  }
  .MuiOutlinedInput-notchedOutline {
    border: 0;
  }
  .MuiInputAdornment-root {
    margin: 0;
  }
  .MuiInputBase-input {
    height: auto;
  }
`;

const Placeholder = styled(
  ({ styleType, size, placeholder, ...props }: { styleType: DatePickerProps['size']; size: DatePickerProps['styleType']; placeholder: string }) => (
    <TextField {...props} placeholder={placeholder} />
  )
)<{ size: DatePickerProps['size']; styleType: DatePickerProps['styleType'] }>`
  &.MuiTextField-root .MuiOutlinedInput-input {
    ${({ size }) => getDatePickerSize(size)}
    ${({ styleType }) => getDatePickerStyleType(styleType)}
    ${({ size }) => getFontProperties(size)}
    cursor: ${({ styleType }) => (styleType === 'disabled' ? 'not-allowed' : 'auto')};
  }
`;

const Description = styled.span`
  font-weight: 300;
  font-size: 13px;
  line-height: 18.2px;
  color: ${({ theme }) => theme.font.weak};
`;

const ErrorText = styled.p<{ size: DatePickerProps['size'] }>`
  color: ${({ theme }) => theme.context.error};
  font-family: Blinker;
  font-style: normal;
  font-weight: 400;

  ${({ size }) => getFontProperties(size)}
`;
